import { AppStateStoreReducer } from "AppState/redux-io/Reducers";
import { IAppStateStore } from "AppState/Types";
import { Dispatch } from "redux";
import BuildAppStateThunks, { IAppStateThunks } from "AppState/redux-io/Thunks";
import BuildAppStateActions, { IAppStateActions } from "./redux-io/Actions";
import { GatewayProcessor, StripeGateway } from "ares-core/UI/Checkout/Gateway";

export class AppStateApi {
  store_: IAppStateStore;
	dispatcher_?: Dispatch;
	// Thunks and Actions
	Api: IAppStateThunks;
	Actions: IAppStateActions;
	private _currentGateway: GatewayProcessor;

  initializeApp: CallableFunction;
  //
  constructor() {
    this.store_ = {
      endpoint: "",
	  landingUrl: "",
	  companyUrl: "",
      busy: true,
		};
		// Default Initialization
		this.Api = BuildAppStateThunks(this.dispatcher_!);
		this.Actions = BuildAppStateActions(this.dispatcher_!);

    // Will be called when the store is initialized
		this.initializeApp = () => {};
		// Set the default processor
		this._currentGateway = new StripeGateway("Stripe");
  }
  public Store = (s?: IAppStateStore) => {
    if (s) this.store_ = s;
    return this.store_;
  };
  public Reducer = () => {
    return AppStateStoreReducer;
  };
  public Dispatcher = (d?: Dispatch) => {
    if (d) {
      this.dispatcher_ = d;
			this.Api = BuildAppStateThunks(this.dispatcher_);
			this.Actions = BuildAppStateActions(this.dispatcher_);
    }
    return this.dispatcher_;
  };
	//
	public gatewayProcessor = () => { return this._currentGateway; }
}

const AppState: AppStateApi = new AppStateApi();
export default AppState;

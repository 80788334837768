import { ReservationEditData } from 'ares-core/Models';
import { Reducer } from "redux";
import { ReservationActionType, ReservationActions } from "./Actions";
import { IReservationStore } from "../Types";
import Reservation from "../ReservationApi";
import {EventDateReservationList, ReservationData} from "../../Models/Reservation";
import {VwEventTimeSummary} from "../../Models";
import {capitalizeObjectKeys} from "../../Utils";

export const ReservationStoreReducer: Reducer<IReservationStore, ReservationActions> = (
  state: IReservationStore = Reservation.Store(),
  action
) => {
  switch (action.type) {
	case ReservationActionType.SET_RES_DELIVERY_ADDRESS: {
		let uRes:ReservationData | null = state.currentRes;
		uRes!.address = action.address.addressText;
		uRes!.lat = action.address.lat;
		uRes!.lon = action.address.lon;
		return {
        	...state,
			currentRes: uRes,
      		};
	}
	case ReservationActionType.SET_RES_ANSWERS: {
		let uRes:ReservationData | null = state.currentRes;
		uRes!.answersJson = action.answers;
     	return {
        ...state,
				currentRes: uRes,
		};
	}
	case ReservationActionType.SET_RES_ITEM: {
		return {
		...state, resListItem: action.resListItem,
		};
	}
    case ReservationActionType.SET_RES_CURRENT: {
		return {
		...state,
				currentRes: action.currentRes,

		};
	}
	case ReservationActionType.SET_RES_EDIT_DATA: {
		return {
		...state,
				reservationEditData: action.reservationEditData,
		};
	}
	case ReservationActionType.RESERVATION_IN_PROGRESS: {
		var changedState = { ...state };

			changedState = { ...state, busy: action.bOn };

		return changedState;
	}
	case ReservationActionType.SET_RES_SUMMARY: {
		let data = action.data;
		// TODO: Move capitalizedSummary to an interceptor or change the model name
		if(action.data) {
			const capitalizedSummary = capitalizeObjectKeys<VwEventTimeSummary>(action.data?.eventTimeSummary,['eventDateId']);
			data = {...action.data, eventTimeSummary: capitalizedSummary}
		}
		return { ...state, eventDateSummary: data};
	}
	case ReservationActionType.UPDATE_RES_SUMMARY: {
		var summary = state.eventDateSummary;
		if (summary) {
			var reservationList = summary.reservations;
			if (reservationList) {
				var item = reservationList.find(r => r.reservationId === action.reservation.reservationId);
				if (item) {
					item.checkedIn = (action.reservation.checkedIn ? new Date(action.reservation.checkedIn) : undefined );
					item.checkedInBy = action.reservation.checkedInBy;
				}
			}
		}
		return { ...state, eventDateSummary: summary};
	}
	case ReservationActionType.SET_PUBLIC_NOTE: {
		const {eventDateSummary} = state;
		let changedState = {...state}
		const eventTimeSummary: VwEventTimeSummary | undefined = eventDateSummary?.eventTimeSummary
		if (eventTimeSummary) {
			const newEventTime:VwEventTimeSummary = {...eventTimeSummary,publicNote: action.publicNote}
			let newEventDateSummary: EventDateReservationList | null = null;
			if (eventDateSummary) {
				newEventDateSummary = {
					...eventDateSummary,
					eventTimeSummary:newEventTime
				}
			} else {
				newEventDateSummary = {
					answersSummary: [],
					reservations: [],
					eventTimeSummary:newEventTime,
					requiredPersonnel : []
				}
			}
			changedState = {...changedState,eventDateSummary:newEventDateSummary}
		  }
		  return changedState
	  }
	case ReservationActionType.SET_RES_CALENDAR: {
		return { ...state, calendarEvents: action.calendarEvents};
	}
	case ReservationActionType.SET_REFUND_RESULT: {
		return { ...state, refundResults: action.result};
	}
	case ReservationActionType.UPDATE_CHECK_IN_OUT : {
		if(state.reservationEditData === null){
			var summary = state.eventDateSummary;
			if (summary) {
				var reservationList = summary.reservations;
				if (reservationList) {
					var item = reservationList.find(r => r.reservationId === action.result.reservationId);
					if (item) {
						item.checkedIn = (action.result.checkedIn ? new Date(action.result.checkedIn) : undefined );
						item.checkedInBy = action.result.checkedInBy;
					}
				}
			}
		return { ...state, eventDateSummary: summary};
		}else{
			return {
				...state, 
				reservationEditData : {
					...state.reservationEditData!,
					reservationData : {
						...state.reservationEditData!.reservationData!,
						checkedIn : action.result.checkedIn,
						checkedInBy : action.result.checkedInBy,
						eventDateId : action.result.eventDateId,
						ticketEdits : state.reservationEditData?.reservationData.ticketEdits?.map(item =>{
							item.ticketPersons = item.ticketPersons?.map(ticket =>{
								let incomingTicket = action.result.ticketPersonCheckedIns.find(x => x.ticketPersonId === ticket.ticketPersonId)
								if(incomingTicket){
									ticket.checkedIn = incomingTicket.checkedIn ? new Date(incomingTicket.checkedIn) : undefined
									ticket.checkedInBy = incomingTicket.checkedInBy
								}
								return ticket
							})
							return item
						})
					}
				}
			};
		}
	}
    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
		break;
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: ReservationActions) => {};

import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface ISeatsPreviewProps {
    url: string;
}
export const SeatsPreview = (props: ISeatsPreviewProps) => {
    const classes = useStyles(props);
    const [fullSize, setFullSize] = useState(false);
    const clickHandler = () => {
        setFullSize(!fullSize);
    };
    return (<>
        <Button variant="contained" color="secondary" onClick={clickHandler} >
            View Seating Chart
        </Button>
        {fullSize? <div className={classes.divZoom}
            onClick={clickHandler}>
            <div className={classes.closeBox}>X</div>
            <img src={props.url}
                className={classes.chartImgFull}
                onClick={clickHandler}
            />
        </div> : null}
    </>
    );
}

const useStyles = makeStyles({
    chartImgThumb: {
        height: "auto",
        width: "100%",
        cursor: "zoom-in"
    },
    divZoom: {
        position: "absolute",
        top: "50%",
        left: "50%",
         transform: "translate(-50%, -50%)",
        height: "100%",
        maxHeight: 500,
        width: "100%",
        maxWidth: 500,
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
        backgroundColor: "#ccc",
        textAlign: "center",
        zIndex: 9999,
    },
    chartImgFull: {
        height: "auto",
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%"
    },
    closeBox: {
        position: "absolute",
        top: 3,
        right: 6
    }
});
// TASK-2535 - Remove
import React, {ReactElement, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        padding: 0
    },
}));

export const DialogHooks = () => {
    const [open,setOpen] = useState<boolean>(false);

    const handleClickOpen = () : void => {
        setOpen(true);
    };

    const handleClose = () : void => {
        setOpen(false);
    };
    return [ open, handleClickOpen, handleClose ] as const
}
export interface DialogSettings {
    open : boolean;
    handleClose : () => void;
    children? : any;
    DialogText? : string;
    ButtonActions? : {
        handleCancelActionText? : string;
        handleCancelActionFunction? : () => void;
        handleSuccessActionText? : string;
        handleSuccessActionFunction? : () => void;
        loadingSuccess?: boolean
    },
    isFullSize? : boolean;
    maxWidth?: 'lg'
        | 'md'
        | 'sm'
        | 'xl'
        | 'xs'
        | false,
    customButtons? : any
}

const Transition = React.forwardRef(function Transition(props : TransitionProps & {
    children?: React.ReactElement
}, ref : React.Ref < unknown >,) {
    return <Slide direction="up" ref={ref} {...props}/>;
});

const DialogComponent = ( { open, handleClose, ButtonActions, children, DialogText, isFullSize = false, maxWidth, customButtons } : DialogSettings ) => {
    const classes = useStyles();

    return (
        <Dialog
            fullScreen ={ isFullSize }
            TransitionComponent={ isFullSize ? Transition : undefined }
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth ?? 'lg'}
        >
            {
                isFullSize ?
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="small"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            { DialogText }
                        </Typography>
                    </Toolbar>
                </AppBar>
                :
                <DialogTitle id="alert-dialog-title">{DialogText}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            }
            <DialogContent style={{
                backgroundColor: "white"
            }}>
                    { children }
            </DialogContent>
            {
                ButtonActions ? 
                    <DialogActions>
                        <Button onClick={ButtonActions.handleCancelActionFunction} color="primary" autoFocus>
                            { ButtonActions.handleCancelActionText  }
                        </Button>
                        {
                            ButtonActions.handleSuccessActionFunction &&
                                <Button disabled={ ButtonActions.loadingSuccess } onClick={ButtonActions.handleSuccessActionFunction} color="primary" variant={'contained'}>
                                    { ButtonActions.loadingSuccess ? '...Please Wait' :  ButtonActions.handleSuccessActionText }
                                </Button>
                        }
                    </DialogActions>
                    : ( customButtons && 
                        <DialogActions>
                            { customButtons}
                        </DialogActions>
                    ) 
            }
        </Dialog>
    );
};

export default DialogComponent;

import { loadStripe, Stripe, StripeConstructorOptions } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_KEY, REACT_APP_STRIPE_VERSION } from 'Config/environment';
import {isEmpty} from "../../Utils";

export enum GatewayID {
	NotSet = -9999999,
	PayPalExpress = -1,
	MPPG = 0,
	Stripe = 1,
	Square = 2,
	StripeConnect = 3,
	StripeIntent = 4
}

export const getGatewayById = (gatewayId : number, processorSpecificJson?: string) => {
    const processor = GatewayID[gatewayId];
    const parsedProcessorData = parseJson(processorSpecificJson);
    let returnable : GatewayProcessor;
	let stripeAccount: string | null;

    switch (processor) {
        case "MPPG":
            returnable = new MPPGGateway("MPPG");
            break;
        case "Stripe":
			returnable = new StripeGateway("Stripe");
            break;
        case "StripeConnect":
			stripeAccount = getStripeConnectAccount(parsedProcessorData);
			returnable = stripeAccount ? new StripeGateway("Stripe", {stripeAccount: stripeAccount}) : new StripeGateway("Stripe") ;
            break;
		case "StripeIntent":
			stripeAccount = getStripeConnectAccount(parsedProcessorData);
			const stripeOptions: StripeConstructorOptions = { apiVersion: REACT_APP_STRIPE_VERSION };
			if(stripeAccount) {
				stripeOptions.stripeAccount = stripeAccount;
			}
			returnable = new StripeIntentGateway("Stripe Intent", stripeOptions);
			break;
        case "Square":
            returnable = new StripeGateway("Square");
            break;
        default:
            returnable = new MPPGGateway("MPPG");
    }
    return returnable;
}

const parseJson = (json?: string): object | null => {
	if(!isEmpty(json) && json) {
		const parsed = JSON.parse(json);
		if(!isEmpty(parsed)) {
			return parsed;
		}
	}
	return null
}

const getStripeConnectAccount = (parsedProcessorData: object | null): string | null => {
	if(isEmpty(parsedProcessorData) || !parsedProcessorData) {
		return null;
	}

	if(parsedProcessorData.hasOwnProperty('Account')) {
		const connectAccount = (parsedProcessorData as any)['Account'];
		return connectAccount;
	} else {
		return null;
	}
}

export abstract class GatewayProcessor {
	private _name: string;

	constructor(name: string){
		this._name = name;
	}

	public abstract gatewayID(): GatewayID;
	public name() { return this._name; }
}

export abstract class StripeProcessor extends GatewayProcessor {
	constructor(name: string){
		super(name);
	}
	public abstract stripeKey: string;
	public abstract stripePromise: Promise<Stripe | null>;
}


// Legacy Stripe payment processor.  Used for Stipe and StripeConnect.
export class StripeGateway extends StripeProcessor  {
	stripeKey: string;
	stripePromise: Promise<Stripe | null>;

	constructor(name: string, options?: StripeConstructorOptions){
		super(name);
		this.stripeKey = REACT_APP_STRIPE_KEY;
		this.stripePromise = loadStripe(this.stripeKey, options);
	}

	public gatewayID() { return GatewayID.Stripe; }
}

// New Stripe payment processor
export class StripeIntentGateway extends StripeProcessor {
	stripeKey: string;
	stripePromise: Promise<Stripe | null>;

	constructor(name: string, options?: StripeConstructorOptions){
		super(name);
        // Using stripe key from .env file or default to test key.
		this.stripeKey = REACT_APP_STRIPE_KEY;
		this.stripePromise = loadStripe(this.stripeKey, options);
	}

	public gatewayID() { return GatewayID.StripeIntent; }
}


export class SquareGateway extends GatewayProcessor {
	public applicationId: string;
	public locationId: string;

	constructor(name: string){
		super(name);
		// Eventually Retrieve and Set these values at app startup or put on constructor
		this.applicationId = 'sandbox-sq0idb-vWl4Q6ivqzgK-Kz1WBB50w';
		this.locationId = 'LTP5Z4M7BB3XJ';
	}

	public gatewayID() { return GatewayID.Square; }
}

export class MPPGGateway extends GatewayProcessor {
	constructor(name: string){
		super(name);
	}
	public gatewayID() { return GatewayID.MPPG; }
}

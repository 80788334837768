import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";

import { IAddressMultilineBase, IAddressMultiline } from "../../Models/Address";
import { Locale } from "../../UI"
import { countryInfo, getCountryInfo } from "./countryInfo";
import { isEmpty } from "../../Utils";

export interface IAddressMultilineProps {
	multiLineAddress: IAddressMultiline
	onChange: (multiLineAddress: IAddressMultiline, isValid: boolean) => void;
	onlyRequireZip?: boolean;
	noZipEntry?: boolean;
	validate?: boolean;
}

function allValid(fieldValidity: IAddressMultilineBase<boolean>, onlyRequireZip: boolean): boolean {
	if (onlyRequireZip) {
		return fieldValidity.zip ?? false;
	}
	for (var field in fieldValidity) {
		if ((fieldValidity as any)[field] !== true) {
			return false;
		}
	}
	return true;
}

export default function AddressMultiline({ multiLineAddress, onChange, onlyRequireZip = false, noZipEntry = false, validate = false }: IAddressMultilineProps) {
	const [isValid, setIsValid] = useState<IAddressMultilineBase<boolean>>({} as IAddressMultilineBase<boolean>);
	const [hasBlurred, setHasBlurred] = useState<IAddressMultilineBase<boolean>>({} as IAddressMultilineBase<boolean>);
	const generateValidityObj = (multiLineAddress: IAddressMultiline, isValid: any) => {
		const validity = {...isValid};
		// Make the assumption that any values initially set are valid.
		for (var prop in multiLineAddress) {
			//validation to prevent error by the undefined
			if((multiLineAddress as any)[prop] === undefined){
				(validity as any)[prop] = false
			}else{
				(validity as any)[prop] = (multiLineAddress as any)[prop].length > 0;
			}
		}
		validity.address2 = true; // This is an optional field
		if (noZipEntry) {
			validity.zip = true;
		}
		const infoCountry = getCountryInfo(multiLineAddress.country)
		//in case that the country doesn't has states, set to True to allow the payment proccess 
		if(isEmpty(infoCountry.states)){
			validity.state = true
		}

		return validity
	}

	useEffect(() => {
		setIsValid(generateValidityObj(multiLineAddress,isValid));
		if(validate) {
			onChange(multiLineAddress, (noZipEntry && onlyRequireZip) || allValid(isValid, onlyRequireZip))
		}
	}, []);

	useEffect(() => {
		setIsValid(generateValidityObj(multiLineAddress,isValid));
	}, [multiLineAddress]);

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const newValue = { ...hasBlurred };
		(newValue as any)[event.target.name] = true;
		setHasBlurred(newValue);
	}
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = { ...multiLineAddress };
		(newValue as any)[event.target.name] = event.target.value;
		const newValidity = { ...isValid };
		(newValidity as any)[event.target.name] = event.target.required ? event.target.validity.valid : true;
		if (event.target.name === "country") {
			currentCountryInfo = getCountryInfo(event.target.value);
			newValue.state = undefined;
			newValidity.state = !(currentCountryInfo.states && currentCountryInfo.states.length > 0);
			if (noZipEntry) {
				newValidity.zip = true;
			} else {
				newValidity.zip = currentCountryInfo.zipRegex ? (multiLineAddress.zip && currentCountryInfo.zipRegex.test(multiLineAddress.zip) ? true : false) : true;
			}
		}
		onChange(newValue, (noZipEntry && onlyRequireZip) || allValid(newValidity, onlyRequireZip));
		setIsValid(newValidity);
	}
	let currentCountryInfo = getCountryInfo(multiLineAddress.country);
	const isRequired = !onlyRequireZip;

	const handleZipChangeString = (zipCode: string) => {
		const newValidity = { ...isValid, zip: currentCountryInfo.zipRegex ? currentCountryInfo.zipRegex.test(zipCode) : true };
		setIsValid(newValidity);
		onChange({ ...multiLineAddress, zip: zipCode }, (noZipEntry && onlyRequireZip) || allValid(newValidity, onlyRequireZip));
	}
	const handleZipChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleZipChangeString(event.target.value);
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name="country"
						value={multiLineAddress.country}
						label={Locale.country}
						onChange={handleChange}
						onBlur={handleBlur}
						required
						fullWidth
						select
						SelectProps={{ native: true }}
					>
						{countryInfo.map(cc =>(
							<option key={cc.abbreviation} value={cc.abbreviation}>
								{cc.name}
							</option>
						))}
					</TextField>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name="address1"
						value={multiLineAddress.address1 || ""}
						label={Locale.street}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isRequired && hasBlurred.address1 && !isValid.address1}
						required={isRequired}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name="address2"
						value={multiLineAddress.address2 || ""}
						label={Locale.apt}
						onChange={handleChange}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name="city"
						value={multiLineAddress.city || ""}
						label={Locale.city}
						onChange={handleChange}
						onBlur={handleBlur}
						error={isRequired && hasBlurred.city && !isValid.city}
						required={isRequired}
						fullWidth
					/>
				</Grid>
			</Grid>
			{currentCountryInfo && currentCountryInfo.states && currentCountryInfo.states.length > 0 ?
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="state"
							value={multiLineAddress.state || ""}
							label={Locale.state}
							onChange={handleChange}
							onBlur={handleBlur}
							required={isRequired}
							error={isRequired && hasBlurred.state && !isValid.state}
							fullWidth
							select
							SelectProps={{ native: true }}
						>
							<option key={"null"} value={""} ></option>
							{currentCountryInfo.states.map(cc =>
								<option key={cc.abbreviation} value={cc.abbreviation}>
									{cc.name}
								</option>
							)}
						</TextField>
					</Grid>
				</Grid>
				: null}
			{currentCountryInfo.zipRegex && !noZipEntry ?
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="zip"
							value={multiLineAddress.zip || ""}
							label={Locale.zip}
							onChange={handleZipChangeEvent}
							onBlur={handleBlur}
							required
							error={hasBlurred.zip && !isValid.zip}
							fullWidth
						/>
					</Grid>
				</Grid> : null}
		</>
	);
}

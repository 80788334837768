import React, { MouseEventHandler, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";
import { Close, Home } from "@material-ui/icons";
import { CompanyLogo } from "modules/Common";
import { OpenCartButton } from "modules/Cart";
import { AppState, IApplicationStore } from "AppState";
import { LinkCompany } from "ares-core/LinkCompany";
import { isInIframe } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
    icon: {
      right: "-2px",
      top: 0,
      color: "#fff",
      position: "absolute",
      left: "auto",
      padding: theme.spacing(2),
    },
    cartIcon: {
      marginRight: theme.spacing(3),
      textTransform: "none",
    },
    logo: {
      width: "auto",
      maxWidth: "70px",
      objectFit: "cover",
    },
    logo_container: {
      width : '10%',
      marginRight: theme.spacing(3),
      justifyContent: "flex-end",
    },
  }),
);

interface LinkHeaderProps {
  handleAppClose: MouseEventHandler;
  allowInFrame: boolean;
  companyName: string;
  urlFriendlyName: string;
  justLogo?: boolean;
  logoUrl?: string;
}

export const LinkHeaderComponent = (props: LinkHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { allowInFrame, logoUrl } = props;
  const [inIframe] = useState(() => isInIframe());
  // validate if a previous route exists
  const handleHomeNav = () => {
	var url = AppState.Store().landingUrl;
    history.push(url);
    resetStore()
  };
  useEffect(()=>{
    if(window.location.pathname === AppState.Store().landingUrl){
      LinkCompany.ClearReservation();
    }
  },[window.location.pathname])
  // Use cases:
  // When no logoUrl hide logo
  // calling thunks with no params, clears currentEvent, eventDates & eventTimes
  const resetStore = () => {
    LinkCompany.Api.loadEventDetails();
    LinkCompany.Api.loadEventDates();
  };
  return (
    <AppBar position="relative">
      <Toolbar variant="dense" className={classes.container}>
        <Box flexBasis="100%">
          {!props.allowInFrame && !inIframe ? (
            <CompanyLogo
              companyName={props.companyName}
              logo={props.logoUrl}
              showLogo={!!props.logoUrl}
              showName={!!props.companyName && !props.justLogo}
            />
          ) : null}
        </Box>
        <IconButton id="home-btn" onClick={handleHomeNav} disabled={window.location.pathname.toLowerCase().includes('reschedule')}>
          <Home />
        </IconButton>
        <Box flexGrow="1" />
        <Box
          display="flex"
          alignItems="center"
          className={classes.logo_container}
        >
          <Typography style={{ fontSize: "11px" }}>Powered By</Typography>
          <img
            className={classes.logo}
            src="/indexic_white_logo.svg"
            alt="Indexic logo"
          />
        </Box>
        <OpenCartButton
          companyName={props.urlFriendlyName}
          btnClasses={classes}
        />
        <IconButton
          id="close-btn"
          className={classes.icon}
          onClick={props.handleAppClose}
        >
          <Close style={{ fontSize: 16 }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: IApplicationStore) => {
  return {
    companyName: state.company.name,
    urlFriendlyName: state.company.urlFriendlyName,
    justLogo: state.company.justLogo,
    logoUrl: state.company.logoUrl,
    allowInFrame: state.company.allowInFrame,
  };
};

export default connect(mapStateToProps)(LinkHeaderComponent);

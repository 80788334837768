import React, { useState } from 'react';
import ReactImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import "./aResImageGallery.css";

export interface IImageGalleryPros {
	images: string | string[];
	showThumbnails?: boolean;
}

/**
 * @description ImageGallery will fit to its parent container.  Thumbnails are only 
 * shown if more than one image is included in the array of images.
 * 
 * ```npm install react-image-gallery @types/react-image-gallery```
 * also requires node-sass.
 * 
 * @param images - URL of a single image or an array of URLs for multiple images.
 * @param showThumbnails - Optional Whether or not to show a thumbnail index of the images
 * 	the default is true.  No thumbnails are shown if there is only 1 image. 
 */
export default function ImageGallery({ images, showThumbnails = true }: IImageGalleryPros) {
	const imagesParam = Array.isArray(images) ? images : [images];
	const imgArray = imagesParam.map(img => { return ({ original: img, thumbnail: img }) });
	const showThumbs = showThumbnails && imgArray.length > 1;
	return <ReactImageGallery
		items={imgArray}
		showPlayButton={false}
		autoPlay={false}
		useBrowserFullscreen={false}
		showThumbnails={showThumbs}
		additionalClass={showThumbs ? "" : "image-gallery-no-thumbs"}
	/>
}
import "core-js";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import {} from "./Config/environment";
import { getTheme, CacheBuster, AppLoadingIndicator } from "ares-core/UI";
import { store } from "AppState";
import { version } from "../package.json";
import App from "App";
import "index.css";

const root = document.getElementById("root");
const theme = getTheme();

  render(
      <Provider store={store}>
        <CacheBuster version={version}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <AppLoadingIndicator version={version} />}
                />
                <Route path="/" component={App} />
              </Switch>
            </Router>
          </ThemeProvider>
        </CacheBuster>
      </Provider>
    ,
    root,
  );
import { Action, ActionCreator, Dispatch } from "redux";
import {INotification} from "../../Models/Notifications";

export enum NotificationsActionType {
    ADD_NOTIFICATION = "ADD_NOTIFICATION",
    EDIT_NOTIFICATION = "EDIT_NOTIFICATION",
    SHOW_NOTIFICATION = "SHOW_NOTIFICATION",
    CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS",
    SET_NOTIFICATIONS = "SET_NOTIFICATIONS",
    SET_CONFIG = "SET_CONFIG"
}

// Loading Data
export interface IAddNotification extends Action<NotificationsActionType.ADD_NOTIFICATION> {
    notification: INotification;
}
export const addNotification: ActionCreator<IAddNotification> = (t: INotification) => {
    return { notification: t, type: NotificationsActionType.ADD_NOTIFICATION };
};
export interface IShowNotification extends Action<NotificationsActionType.SHOW_NOTIFICATION> {
}
export const showNotification: ActionCreator<IShowNotification> = () => {
    return { type: NotificationsActionType.SHOW_NOTIFICATION };
};
export interface ISetNotifications extends Action<NotificationsActionType.SET_NOTIFICATIONS> {
    notifications: INotification[]
}
export const setNotifications: ActionCreator<ISetNotifications> = (t: INotification[]) => {
    return { notifications: t, type: NotificationsActionType.SET_NOTIFICATIONS };
};
export interface IClearNotifications extends Action<NotificationsActionType.CLEAR_NOTIFICATIONS> {
}
export const clearNotifications: ActionCreator<IClearNotifications> = (t: INotification[]) => {
    return { type: NotificationsActionType.CLEAR_NOTIFICATIONS };
};
export type NotificationsActions =
    | IAddNotification
    | IShowNotification
    | ISetNotifications
    | IClearNotifications

export interface INotificationsActions {
    readonly AddNotification: CallableFunction;
    readonly ShowNotification: CallableFunction;
    readonly SetNotifications: CallableFunction;
    readonly ClearNotifications: CallableFunction;
    // readonly SetLinkCompanyEvents: CallableFunction;

}

// Convenience Function to link Action Dispatcher
const BuildNotificationsActions = (dispatcher: Dispatch) => {
    const actions:INotificationsActions  = {
        AddNotification: (notification:IAddNotification) => {
            dispatcher(addNotification(notification));
        },
        ShowNotification: () => {
            dispatcher(showNotification());
        },
        SetNotifications: (notifications:INotification[]) => {
            dispatcher(setNotifications(notifications))
        },
        ClearNotifications: () => {
            dispatcher(clearNotifications())
        }
    };

    return actions;
};

export default BuildNotificationsActions;

import {GeneralPortalService} from "./GeneralPortalService";
import {CoreStatus} from "../Types";
import {EventCalendarItem} from "../Models";
import {capitalizeObjectKeys} from "../Utils";

class PortalEventService extends GeneralPortalService {
    constructor() {
        super('/portal/Events');
    }

    loadEventSummaries(companyId: number) {
        let uri = this.createUrl(['Summaries']);
        return this.getRequest(uri, companyId.toString());
    }

    async loadCalendarByEvent(companyId: number, events: Array<number> | number, startDay: Date, endDay: Date, allEvents: boolean) {
        let uri = this.createUrl([
            'EventCalendarByEvent',
            companyId.toString(),
            events.toString(),
            startDay.toISOString(),
            endDay.toISOString(),
            allEvents.toString(),
        ]);
        const result = await this.getRequest<EventCalendarItem[]>(uri, companyId.toString());
        if (result.status === CoreStatus.SUCCESS) {
            // Remember use this on all payloads from the server
            result.payload = (result.payload || []).map(item => capitalizeObjectKeys<EventCalendarItem>(item,['eventDateId']));
        }
        return result;
    }
}

const service = new PortalEventService();
export default service;

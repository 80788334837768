import {
  Store,
  createStore,
  applyMiddleware,
  Reducer,
  combineReducers,
  compose,
} from "redux";
import { default as ReduxThunk } from "redux-thunk";
import { AppState } from "AppState";
import { IAppStateStore } from "AppState/Types";

import { Credentials } from "ares-core/Credentials";
import { ICredentialStore } from "ares-core/Credentials/Types";
import { Event, IEventStore } from "ares-core/Event";
import { ILinkCompanyStore } from "ares-core/LinkCompany";
import LinkCompany from "ares-core/LinkCompany/LinkCompanyApi";
import { Shopping, IShoppingStore } from "ares-core/Shopping";
import {INotificationsStore} from "../ares-core/Notifications/Types";
import Notifications from "ares-core/Notifications/NotificationsApi";
// The top-level state object
export interface IApplicationStore {
  appState: IAppStateStore;
  user: ICredentialStore;
  event: IEventStore;
  // Add Indexic Stores Here
  shopping: IShoppingStore;
  company: ILinkCompanyStore;
  notifications:INotificationsStore
}

export const reducers: Reducer<IApplicationStore> = combineReducers<
  IApplicationStore
>({
  appState: AppState.Reducer(),
  user: Credentials.Reducer(),
  event: Event.Reducer(),
  // Add Indexic Reducers Here
  shopping: Shopping.Reducer(),
  company: LinkCompany.Reducer(),
  notifications: Notifications.Reducer()
});

function configureStore(): Store<IApplicationStore> {
  // This supports Redux Extensions and the Extension does not have to be installed.
  var store = null;
  let hasExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ !== undefined;
  if (hasExtension && process.env.NODE_ENV === "development") {
    store = createStore(
      reducers,
      undefined,
      compose(
        applyMiddleware(ReduxThunk),
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
          (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
      ),
    );
  } // Production
  else store = createStore(reducers, undefined, applyMiddleware(ReduxThunk));

  // The APIs need the Redux Dispatcher
  AppState.Dispatcher(store.dispatch);
  Credentials.Dispatcher(store.dispatch);
  Event.Dispatcher(store.dispatch);
  // Add Indexic Dispatchers Here
  Shopping.Dispatcher(store.dispatch);
  LinkCompany.Dispatcher(store.dispatch);
  Notifications.Dispatcher(store.dispatch);
  return store;
}

// Map and Set the Stores from the main Redux Store
export const ApplicationStore = (state: IApplicationStore) => ({
  appState: AppState.Store(state.appState),
  user: Credentials.Store(state.user),
  event: Event.Store(state.event),
  // Map and Set Indexic Stores Here
  shopping: Shopping.Store(state.shopping),
  company: LinkCompany.Store(state.company),
  notifications:Notifications.Store(state.notifications)
});

const store = configureStore();
export default store;

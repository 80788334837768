import { Action, ActionCreator, Dispatch } from "redux";
import { IUserCredentials } from "ares-core/Models";

export enum CredentialActionType {
	SET_CRED_ROLES = "[CREDS] SET_ROLES",
	SET_COMPANY_ID = "[CREDS]  SET_COMPANY_ID",
	SET_ARESERVATION_CREDENTIALS = "[CREDS]  SET_ARESERVATION_CREDENTIALS",
	SET_AWAIVER_CREDENTIALS = "[CREDS] SET_AWAIVER_CREDENTIALS",
}

// Credentials Roles
// Format from server is SysAdmin_Company_Reports
export interface ISetRolesAction extends Action<CredentialActionType.SET_CRED_ROLES> {
	sysAdminRole:boolean;
	companyRoles: number;
	reportsRoles: number;
}
export const setRolesAction: ActionCreator<ISetRolesAction> = (s,c,r) => {

  return {
		sysAdminRole: s,
		companyRoles: c,
		reportsRoles: r,
		type: CredentialActionType.SET_CRED_ROLES };
};

export interface ISetCompanyIdAction extends Action<CredentialActionType.SET_COMPANY_ID> {
	companyId: number;
}

export const setCompanyId: ActionCreator<ISetCompanyIdAction> = ( company ) =>{
	return {
		companyId : company,
		type : CredentialActionType.SET_COMPANY_ID
	}
}
// Credentials
export interface ISetaReservationCredentialsAction extends Action<CredentialActionType.SET_ARESERVATION_CREDENTIALS> {
	credentials: IUserCredentials;
  }
  export const setaReservationCredentialsAction: ActionCreator<ISetaReservationCredentialsAction> = c => {
	return { credentials: c, type: CredentialActionType.SET_ARESERVATION_CREDENTIALS };
  };
  
  // Credentials
  export interface ISetaWaiverCredentialsAction extends Action<CredentialActionType.SET_AWAIVER_CREDENTIALS> {
	credentials: IUserCredentials;
  }
  export const setaWaiverCredentialsAction: ActionCreator<ISetaWaiverCredentialsAction> = c => {
	return { credentials: c, type: CredentialActionType.SET_AWAIVER_CREDENTIALS };
  };

export type CredentialActions =
	| ISetRolesAction
	| ISetCompanyIdAction
	| ISetaReservationCredentialsAction
	| ISetaWaiverCredentialsAction
	;
export interface ICredentialActions {
	readonly SetRoles: CallableFunction;
	readonly SetCompanyId: ( companyId : number ) => any;
	readonly SetAReservationCredentials: (credentials: IUserCredentials) => any;
	readonly SetAWaiverCredentials: (credentials: IUserCredentials) => any;
}

// Convenience Function to link Action Dispatcher
const BuildCredentialActions = (dispatcher: Dispatch) => {
	const CredentialActions: ICredentialActions = {
		SetRoles: (sysAdminRole: boolean, companyRoles: number, reportsRoles: number) => {
			dispatcher(setRolesAction(sysAdminRole,companyRoles,reportsRoles));
		},
		SetCompanyId : ( companyId : number) => {
			dispatcher( setCompanyId(companyId) )
		},
		SetAReservationCredentials: (credentials: IUserCredentials) => {
			dispatcher( setaReservationCredentialsAction(credentials) )
		},
		SetAWaiverCredentials: (credentials: IUserCredentials) => {
			dispatcher( setaWaiverCredentialsAction(credentials) )
		}
	}
	
	return CredentialActions;
};
	
export default BuildCredentialActions;

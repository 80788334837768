import React from 'react'
import { IBaseQuestionInputProps } from '../Question';
import {TextField } from '@material-ui/core'
export interface ITextQuestionProps extends IBaseQuestionInputProps{
  error: any
}
export function TextQuestion(props: ITextQuestionProps) {
    return (
        <TextField
          required
          error={props.error}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          multiline
        />
      );
}

import {
    CatalogItem,
    CheckoutPost,
    ICartDTO,
    ICheckoutConfiguration,
    PrecheckoutData,
    ReservationData,
} from "../../Models";
import {IShoppingStore} from "../../Shopping";
import {GeneralLinkService} from "../GeneralLinkService";
import {isEmpty} from "../../Utils";
import { ICoreResult } from "ares-core/Types";
import { LinkAddTipRequest } from "./LinkTypes";



const CASH_LASTNAME = '{Cash}';
const CASH_PROCESS_PAYMENT = '{Cash}';
class LinkCartService extends GeneralLinkService {
    readonly retryTimes = 10
    retryCount = 0
    constructor() {
        super('/api/cart');
    }

    getCart(companyId:string, cartId:string) {
        const uri = this.createUrl([companyId,cartId]);
        return this.getRequest(uri);
    }

    createCheckoutDTO(store: IShoppingStore, config?:Partial<ICheckoutConfiguration>): CheckoutPost {
        const dto:CheckoutPost = {
            shippingInfo: store.shippingInfo,
            billingInfo: store.billingInfo,
            processorPaymentData: store.processorPaymentData,
            cartId: store.cart.cartId,
            howHeardAnswerID: store.howHeardAnswerID!,
            sendConfirmation:config?.sendConfirmation
        }
        return dto;
    }

    createCheckoutCashDTO(store:IShoppingStore,transactionCategoryId = 1, config?:ICheckoutConfiguration): CheckoutPost {
        const dto = this.createCheckoutDTO(store,config);
        let { billingInfo } = dto;
        if(isEmpty(billingInfo)) {
            billingInfo = {lastName:CASH_LASTNAME}
        } else {
            if(isEmpty(billingInfo.lastName)) {
                billingInfo.lastName = CASH_LASTNAME;
            }
        }
        if(transactionCategoryId > -1) {
            dto.transactionCategoryId = transactionCategoryId;
        }
        return { ...dto, billingInfo, processorPaymentData: CASH_PROCESS_PAYMENT}
    }

    async checkout(cartId:string, data:CheckoutPost) {
        const uri = this.createUrl(['Checkout', cartId]);
        let result: ICoreResult = await this.postRequest<CheckoutPost>(uri,data);
        return result;
        // const timeout : number = 10000 //ms
        // this.retryCount ++; //this will count how many times it was trying to make the reservation
        //if the user leaves the page we stop retrying
        // if( window.location.pathname !== '/checkout' || this.retryCount === this.retryTimes){
        //     this.retryCount = 0
        //     return result
        // }
        // else if(result.payload.message === `timeout of ${timeout}ms exceeded`){ //if the request reach the timeout 
        //     //since there is no server error response or code, we have to validate with this string when the timeout is reached
        //     //retry the checkout if the @timeout gets reached 
        //     this.checkout(cartId, data)
        // }

        // if(result.status === CoreStatus.SUCCESS){
        //     const { checkoutTransaction, cartDTO } : ICheckoutResult = result.payload
        //     if(checkoutTransaction === null && cartDTO.errors.length === 0){
        //         setTimeout(() => {
        //             // we wait 3 seconds until have to retry the checkout
        //             this.checkout(cartId, data)
        //         }, 3000);
        //     }
        //     return result
        // }
    }

    preCheckout(cartId: string) {
        let uri = this.createUrl(['PreCheckout', cartId]);
        return this.getRequest<PrecheckoutData>(uri);
    }

    applyCoupon(companyId: string, cartId: string, coupon: string) {
        const uri = this.createUrl([companyId,'ApplyCode',cartId, coupon]);
        return this.postRequest(uri,{});
    }

    addCatalogItem(companyId: string, cartId: string, item: CatalogItem) {
        const uri = this.createUrl([companyId,'catalog',cartId]);
        return this.postRequest<CatalogItem>(uri,item);
    }

    removeCatalogItem(companyId: string, cartId: string, item: CatalogItem) {
        const uri = this.createUrl([companyId,'catalog', cartId, item.catalogId.toString()]);
        return this.deleteRequest<CatalogItem>(uri,item);
    }

    addReservation(companyId: string, cartId: string, data: ReservationData) {
        let uri = this.createUrl([companyId,'reservation',cartId]);
        return this.postRequest<ReservationData>(uri,data);
    }

    removeReservation(companyId: string, cartId: string, reservationId: number, catalogId?: number) {
        let uri = this.createUrl([companyId,'reservation',cartId,reservationId.toString()]);
        if (catalogId) {
            uri += `/${catalogId}`;
        }
        return this.deleteRequest<null>(uri,null);
    }

    emptyCart(companyID: string, cartId: string) {
        const uri = this.createUrl([companyID.toString(),'Empty',cartId]);
        return this.deleteRequest(uri,{});
    }

    addGiftCartdInformation(company_name: string, giftCardInformation: any, cartId: string, catalogId: number){
        const uri = this.createUrl([company_name,'GiftCard', catalogId.toString(),cartId]);
        return this.postRequest(uri,giftCardInformation);
    }

    editGiftCartdInformation(company_name: string, giftCardInformation: any, cartId: string, catalogId: number){
        const uri = this.createUrl([company_name,'GiftCard', catalogId.toString(),cartId]);
        return this.putRequest(uri,giftCardInformation);
    }

    deleteGiftCartdInformation(company_name: string, recipientId: number, cartId: string, catalogId: number){
        const uri = this.createUrl([company_name,'GiftCard', catalogId.toString() ,recipientId.toString(), cartId]);
        return this.deleteRequest(uri,{});
    }

    getGiftCardInformation(company_name : string, catalogId : number ,recipientId : number, cartId : string){
        const uri = this.createUrl([company_name,'GiftCard', catalogId.toString(), recipientId.toString(), cartId]);
        return this.getRequest(uri)
    }

    addTip(cartId: string, tip: LinkAddTipRequest): Promise<ICoreResult<ICartDTO>> {
        let uri: string =  this.createUrl(['AddTip', cartId]);
        return this.postRequest(uri, tip);
    }
}

const service = new LinkCartService();
export default service;

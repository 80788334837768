import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { LinkCompany, ILinkCompanyStore } from "ares-core/LinkCompany";
import { Event, IEventStore } from "ares-core/Event";

import { IApplicationStore, IAppStateStore } from "AppState";
import { EventHeader, ReservationWizard } from "modules/EventReservation";

import { IShoppingStore } from "ares-core/Shopping";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimeUtils } from "ares-core";

import { EventHeaderProps } from "./components/EventHeader";
import { isEmpty } from "ares-core/Utils";
import { LinkEventDetail, VwEventDisplay } from "ares-core/Models";
import { Card, CardContent, CircularProgress } from "@material-ui/core";

interface EventReservationProps {
  event : IEventStore;
  events : VwEventDisplay[]
  currentEvent : LinkEventDetail
  companyID : number
}

/**
 * DisplayEventReservation only renders event header & reservation wizard,
 * loads specific event details into LinkCompany.currentEvent, & keeps state
 * for restrictions dialog acknowledgement.
 *
 */
export const EventReservation = (props: EventReservationProps) => {
  const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);
  const [busy, setBusy] = useState(true); // this state will let us know when an event has been loaded
  type eventUrlParams = { [key: string]: string };
  const {
    urlFriendlyCompanyName,
    urlFriendlyEventName,
    urlEventDate,
  }: eventUrlParams = useParams();

  const history = useHistory();
  // Set the Selected Date for initialization
  useEffect(() => {
    if (urlEventDate) {
      LinkCompany.Actions.SetLastReservationDate(urlEventDate);
    } else {
      const today = DateTimeUtils.toYYYYMMDD(new Date());
      LinkCompany.Actions.SetLastReservationDate(today);
    }
  }, [urlEventDate]);

  // Landing URL is /CompanyName/EventName/:EventDate . At this point the Company Data has been
  // retrieved but the events / event details may or may not have been retrieved. MapStateToProps
  // maps the events and currentEvent. We need both.
  useEffect(() => {
    const localFunction = async () =>{
      if (props.events && props.events.length > 0) {
        // Find the event by its name from the url
        const selectedEvents = props.events.filter(
          (event) =>
            event.urlFriendlyName.toUpperCase() ===
            urlFriendlyEventName.toUpperCase(),
        );
        if (selectedEvents.length !== 1) {
          // Try Getting Boost Events
            if(props.currentEvent.eventId < 1) {
              await LinkCompany.Api.loadEventDetailsByName(urlFriendlyEventName);
              setBusy(false)
            } else {
              history.push(`/event/${urlFriendlyCompanyName}`);
            }
        } else {
            if (selectedEvents[0].eventId !== props.currentEvent.eventId) {
              await LinkCompany.Api.loadEventDetails(selectedEvents[0].eventId); // Now get the Event Details
            }
            setBusy(false);
        }
      } else if (props.events === null) {
        // Load Company Events and come back again
        await LinkCompany.Api.loadCompanyEvents(props.companyID);
        setBusy(false)
      } else if (props.events && props.events.length === 0) {
        await LinkCompany.Api.loadEventDetailsByName(urlFriendlyEventName);
        setBusy(false)
      }
    }
    localFunction()
  }, [
    history,
    props.events,
    props.currentEvent,
    urlFriendlyEventName,
    urlFriendlyCompanyName,
  ]);

  const EventHeaderComponentProps = {
    ...props.events.filter(
      (ev) => ev.eventId === props.currentEvent.eventId,
    )[0],
    restrictions: props.currentEvent.restrictions,
    handleAcknowledge: setAcknowledgeDialog,
  } as EventHeaderProps;

  return (
    <section>
      <EventHeader {...EventHeaderComponentProps} />
      {
        busy ? 
          <Card style={{ height: "25rem", textAlign: "center" }}>
            <CardContent>
              <CircularProgress />
            </CardContent>
          </Card>
        :
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ReservationWizard/>
        </MuiPickersUtilsProvider>
      }
    </section>
  );
};

/**
 *
 * This mapStateToProps uses urlFriendlyEventName to single out event because
 * LinkCompany.LoadEventDetails requires eventID found on Event.
 */
const mapStateToProps = (
  { company, event }: IApplicationStore) => {
  return {
    events : company.events,
    currentEvent : company.currentEvent,
    companyID : company.companyID,
    event : event
  };
};

export default connect(mapStateToProps)(React.memo(EventReservation));

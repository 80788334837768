

export interface ICarouselContent {
    TagLineMarkdown: string;
    ImageUrl: string;
    IdKey: number;
    readonly Type: string;
}
export  enum Section {
    NONE="",HEAD="HEAD", BODY="BODY"
}

export  enum Position {
   NONE="",TOP="TOP", BOTTOM="BOTTOM"
}

type SectionStrings = keyof typeof Section;
type PositionStrings = keyof typeof Position;

export interface IInjectedContent {
    IdKey: number;
    Section: Section;
    Position: Position;
    HtmlContent: string;
    IsValid: ()=>boolean;
}
export class InjectedContent implements IInjectedContent
{
    
    constructor(newIdKey: number)
    {
        this.IdKey = newIdKey;
    }
    IdKey = -1;
    IsValid = ()=> { return this.Section != Section.NONE && this.Position != Position.NONE && this.HtmlContent.length > 0;};
    Section = Section.NONE;
    Position = Position.NONE;
    HtmlContent="";

}

export interface ICompanyWebPageModel {
    InjectedItems: IInjectedContent[];
    CarouselItems: ICarouselContent[];
    AboutUsMarkdown: string;
    AboutUsImageUrl: string;
    readonly Type: string;
}
export class CarouselContent implements ICarouselContent {
    constructor(newIdKey: number)
    {
        this.IdKey = newIdKey;
    }
         Type="ICarouselContent";
   
    IdKey = -1;
    TagLineMarkdown="";
    ImageUrl="";

}
export class CompanyWebPageModel implements ICompanyWebPageModel
{

    Type= "ICompanyWebPageModel";
    InjectedItems=[];
    CarouselItems= [new CarouselContent(0)];
    AboutUsMarkdown="";
    AboutUsImageUrl="";
    
}
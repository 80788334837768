import { CompanyWebPageModel,ICompanyWebPageModel } from ".";



export interface ICompanyWebLink {
	companyWebLinkId: number;
	companyId: number;
	urlFriendlyName: string;
	lat: number | null;
	lon: number | null;
	distance: number;
	homeUrl: string|null;
	logoUrl: string|null;
	justLogo: boolean;
	allowInIFrame: boolean;
	templateRoute: string|null;
	bookNowRoute: string|null;
	eventGroupId: number | null;
	googleSiteTag: string | null;
	ud: string|null;
	uu: string|null;
	company:{websiteModuleExp:Date, websiteModuleIsEnabled: boolean}| null;
	webpageContent: ICompanyWebPageModel;
	 readonly Type : string; 
}


export class CompanyWebLink implements ICompanyWebLink {


	Type= "ICompanyWebPageModel";
	companyWebLinkId= -1;
	urlFriendlyName = "";
	companyId=0;
	lat= null;
	lon= null;
	distance= 0;
	homeUrl=null;
	logoUrl=null;
	justLogo=false;
	allowInIFrame=false;
	templateRoute=null;
	bookNowRoute=null;
	eventGroupId= null;
	googleSiteTag=null;
	ud=null;
	uu=null;
	company=null;
	webpageContent= new CompanyWebPageModel();
	
	
	constructor(companyId: number) {
		
	this.companyId= companyId;
	
	}


}
export namespace CompanyWebLink {
		export enum BookNowRoute { Event="event", Boost="boost", Calendar= "eventcalendar" 
	 }
}
import { Locale } from "ares-core/UI";
import {startOfMonth,endOfMonth, startOfWeek, endOfWeek, format} from 'date-fns'
// https://stackoverflow.com/questions/7944460/detect-safari-browser
// var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export class DateTimeUtils {
  static DAY = Locale.day || "day";
  static DAYS = Locale.days /*  */ || "days";
  static MINUTE = Locale.minute || "minute";
  static MINUTES = Locale.minutes || "minutes";
  static LOCALE = Locale.locale || "en-US";

  static eventLocalTime(dateTimeISOString: string) {
    // Build date from string because Safari doesn't follow standards
    // for parsin g dates with times as local time
    const hr = parseInt(dateTimeISOString.substring(11, 13));
    const mn = parseInt(dateTimeISOString.substring(14, 16));
    const dt = new Date(1970, 1, 1, hr, mn);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
    };
    return dt.toLocaleString(this.LOCALE, options);
  }

  // Accommodates Safari Quirk with parsing Date Strings
  static eventLocalDate(dateTimeISOString: string) {
    var a = dateTimeISOString.split(/[^0-9]/);
    let retdate: Date = new Date(+a[0], +a[1] - 1, +a[2], +a[3], +a[4], +a[5]);
    return retdate;
  }

  static stripTime(dateTime: Date) {
    if (!dateTime.setHours) {
      dateTime = new Date(dateTime);
    }
    return new Date(dateTime.setHours(0, 0, 0, 0));
  }

  /**
   * Compare date without time portion
   */
  static compareDates(first: Date, second: Date) {
    return first.setHours(0, 0, 0, 0) - second.setHours(0, 0, 0, 0);
  }

  static inRange(target: Date, start: Date, end: Date) {
    //console.log({target, start, end})
    return (
      this.compareDates(start, target) <= 0 &&
      this.compareDates(target, end) <= 0
    );
  }
  static inRangeStr(target:string, start:string, end:string) {
      const dates = [target, start, end];
      const sorted = dates.sort()
      return sorted[1].slice(0,10) == target.slice(0,10)
  }
  static addDays(date: Date, days: number) {
    const newDate = new Date(date.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  static range(start: Date, end: Date) {
    const dateArray:Date[] = [];
    let currentDate = start;
    while (currentDate < end) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }

  static nowPlusSeconds(seconds: number) {
    return new Date(Date.now() + seconds * 1000);
  }

  static longDate(date: Date) {
    const dt = new Date(date);
    return dt.toLocaleDateString(this.LOCALE, {
      weekday: "long",
      month: "long",
      year: "numeric",
      day: "numeric",
    });
  }

  static longDateString(ds: string) {
    const d = DateTimeUtils.eventLocalDate(ds); // Safari Safe
    return DateTimeUtils.longDate(d);
  }

  static durationFormat(durationInSeconds: number) {
    const days = Math.trunc(durationInSeconds / 86400); // (24 * 60 * 60)
    durationInSeconds -= days * 86400;
    const hours = Math.trunc(durationInSeconds / 3600); // (60 * 60)
    durationInSeconds -= hours * 3600;
    const minutes = Math.trunc(durationInSeconds / 60);
    let fmtString = "";
    if (days > 0) {
      fmtString += `${days} ${days === 1 ? this.DAY : this.DAYS}`;
      if (hours > 0 || minutes > 0) {
        fmtString += " ";
      }
    }
    if (hours > 0) {
      fmtString += `${hours}:`;
    }
    if (minutes === 0 && hours > 0) {
      fmtString += "00";
    }
    if (minutes > 0) {
      if (hours === 0) {
        fmtString += `${minutes} ${minutes === 1 ? this.MINUTE : this.MINUTES}`;
      } else {
        fmtString += `${minutes.toString().padStart(2, "0")}`;
      }
    }
    return fmtString;
  }

  static shortDate(date: Date) {
    const dt = new Date(date);
    return dt.toLocaleDateString(this.LOCALE, {
      weekday: "short",
      month: "numeric",
      //year: "numeric",
      day: "numeric",
    });
  }

  // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
  static toYYYYMMDD(date: Date) {
    return format(date, 'yyyy-MM-dd')
  }

  /**
   * Expects a date string in the form: yyyy-MM-DD and returns a date string in the form yyyy/MM/DD
   * The difference on this formats it's the way that Date object instantiates the timezone
   * i.e. yyyy/MM/DD it's set to midnight (00:00 GMT+x) in the local timezone while yyyy-MM-DD it's set to midnight in GMT+0
   * @see {@link toYYYYMMDD}
   * @param dateIso
   */
  static isoToLocaleString(dateIso: string): string {
    const reg = new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
    if (!reg.test(dateIso)) {
      // If we have a string with a different format we return
      return dateIso;
    }
    return dateIso.replace(/-/g, "/");
  }

  // Expect time string of HH:MM:SS.ssss will convert
  // to HH:MM AM or HH:MM PM
  static toHHMM_AP(timePart: string, isShort? : boolean) {
    let timeParts: string[] = timePart.split(":"); // split HH:MM:SS.ssss
    let hour: number = parseInt(timeParts[0]);
    let extras = timeParts[1].split(" ")
    let min : string = extras[0]
    let meridian : string = extras[1]
    let suffix = !isShort ? meridian : meridian[0].toLowerCase();
    return hour.toString() + ":" + min + suffix;
  }

  static dayToString(weekday: number, condensed?:boolean) {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const weekdaysCondensed = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
    ];
    let temp = weekdays;
    if(condensed) {
      temp = weekdaysCondensed;
    }
    return temp[weekday % 7];
  }
  static getMonthRange(date: Date) {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    return this.range(start, end);
  }

  /**
   * @deprecated Use startOfMonth, endOfMonth from date-fns
   * @param date
   */
  static getFirstAndLastMonth(date: Date) {
    date = this.dateToUTCDatePure(date);
    const start = new Date(date.getFullYear(), date.getUTCMonth(), 1);
    const end = new Date(start.getFullYear(), start.getUTCMonth() + 1, 1);
    return [start, end];
  }

  static getRangeNStartWeek(date: Date, weekday: number, n: number) {
    const currentDay = date.getUTCDate();
    const offset = (weekday + currentDay) % 7;
    const start = new Date(
      date.getFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate() - offset,
    );
    return this.range(start, this.addDays(start, n));
  }
  static getWeekRange(date: Date, weekday: number) {
    const currentDay = date.getUTCDay();
    const offset =
      weekday <= currentDay
        ? currentDay - weekday
        : ((1 - weekday - currentDay) % 7) + 7;
    const start = new Date(
      date.getFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() - offset,
    );
    return this.range(start, this.addDays(start, 7));
  }

  static getUTCToday() {
    return this.dateToUTCDate(new Date());
  }
  static dateToUTCDate(date: Date) {
    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      ),
    );
  }
  /**
   * @deprecated
   * Moving to date-fns, use [startOfMonth]{@link https://date-fns.org/v2.22.1/docs/startOfMonth} instead
   */
  static startOfMonth(monthNum: number, UTC: boolean = false): Date {
    if (UTC) {
      const year = new Date().getUTCFullYear();
      return new Date(Date.UTC(year, monthNum, 1, 0, 0, 0, 0));
    }
    const year = new Date().getFullYear();
    return new Date(year, monthNum, 1, 0, 0, 0, 0);
  }
  /**
   * @deprecated
   * Moving to date-fns, use [endOfMonth]{@link https://date-fns.org/v2.22.1/docs/endOfMonth} instead
   */
  static endOfMonth(monthNum: number, UTC: boolean = false): Date {
    if (UTC) {
      const year = new Date().getUTCFullYear();
      return new Date(Date.UTC(year, monthNum + 1, 0, 0, 0, 0, 0));
    }
    const year = new Date().getFullYear();
    return new Date(year, monthNum + 1, 0, 0, 0, 0, 0);
  }

  static filterPast(dates: Array<string | Date>): Array<string | Date> {
    const now = new Date();
    return dates.filter((date) => (
        this.compareDates(new Date(date), now) >= 0
    ));
  }
  static dateToUTCDatePure(date: Date) {
    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
      ),
    );
  }
  static toFirstOfNextMonth(date: Date): Date {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month > 12) {
      year += 1;
      month = 1;
    }
    return new Date(year, month, 1);
  }

  static inSameMonth(one: Date, two: Date) {
    return (
      one.getMonth() === two.getMonth() &&
      one.getFullYear() == two.getFullYear()
    );
  }
  static getMonthName(date: Date) {
    return date.toLocaleString(this.LOCALE, { month: "long" });
  }
  static getDayName(date: Date) {
    return date.toLocaleDateString(this.LOCALE ,{ weekday: 'short', day: 'numeric',month: 'short'});
  }
  static dateOnly(date: Date): Date {
    return new Date(date.toDateString());
  }
  static dateOnlyToday(): Date {
    return new Date(new Date().toDateString());
  }

  static formatDate(date: string | Date, options?: Intl.DateTimeFormatOptions) {
    const option: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      ...options,
    };
    return new Intl.DateTimeFormat("en-US", option).format(new Date(date));
  }

  // Same as formatDate but no default options.  Caller must provide format options.
  static formatDate2(date: string | Date, options: Intl.DateTimeFormatOptions) {
    return new Intl.DateTimeFormat("en-US", {...options}).format(new Date(date));
  }

  static formatShortDateTime(date: string | Date, options?: Intl.DateTimeFormatOptions) {
    const option: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      ...options,
    };
    return new Intl.DateTimeFormat("en-US", option).format(new Date(date));
  }

  /**
   * @deprecated
   * Moving to date-fns, use [startOfWeek]{@link https://date-fns.org/v2.22.1/docs/startOfWeek} instead
   */
  static firstDayOfWeek(): Date {
    var curr = new Date();
    var firstDay = curr.getDate() - curr.getDay();

    return new Date(curr.setDate(firstDay));
  }
  static toFirstWeekOfNextMonth(date: Date): Date {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month > 12) {
      year += 1;
      month = 1;
    }
    return new Date(year, month, 7);
  }
  static getYearsFromDate = (year : number) =>{
    let years:number[] = [];
    let four_years_before = year - 4;
    let four_years_after = year + 5;
    for (let index = four_years_before; index < four_years_after; index++) {
      years.push(index)
    }
    return years.reverse()
  }
  static formatDateWithLocale (date: string | Date, options?: any){
    return new Intl.DateTimeFormat(this.LOCALE, options).format(new Date(date));
  }
  static formatDateWithoutTime (date: string | Date, options?: any){
    return new Intl.DateTimeFormat(this.LOCALE, options).format(new Date(date).setHours(24,0,0,0));
  }
  static calcCalendarBoundaries = ( _date : Date ) =>{
    const month = _date.getMonth();
    const year = _date.getFullYear();
    const endDate = new Date(endOfWeek(new Date(year, month + 1, 0)));
    const startDate = startOfWeek(
        new Date(_date.getFullYear(), _date.getMonth(), 1),
    );
    return{
        startDate,
        endDate
    }
  }
  static dateToDatePure(date: Date) {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
    );
  }

  /**
   * Returns an ISO string for the given date with unspecified timezone.
   *
   * Useful when we want to send absolute dates and don't deal with calculations due to different timezones.
   * When instantiate the date with the returned string the system will create the date in the local timezone
   * @param date
   */
  static toISOStringWithoutTimezone(date:Date): string {
    // Date on GMT
    let utcDate = new Date(
        Date.UTC(date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    );
    // We delete the "Z" on 2022-05-27T09:35:31.820Z that way the system won't calculate the GMT diff
    return utcDate.toISOString().slice(0,-1);
  }

  /**
   * Working with UTC dates from the server will cause errors since datetime properties will return string like this
   * 2022-05-27T09:35:31.820 and ignore the timezone. If we're sure the date is on UTC we need to concatenate a Z to indicate
   * we're on UTC timezone
   * @param dateStr
   * @constructor
   */
  static UTCStringToLocalDate(dateStr:string): Date {
    const value = dateStr.endsWith('z') ? dateStr : dateStr + 'z';
    return new Date(value);
  }
}

/**
 *
 * @param options optional options object.
 * onlyLanguageCode returns array of browser language(s).
 * getBrowserLocale uses navigator.languages which returns an array of BCP47
 * language tags in order of user preference. These are the same tags used in
 * the Accept-Language HTTP header. In most cases, returns user set languages in
 * the browser.
 *
 */
export function getBrowserLocale(options = {}): readonly string[] {
  const defaultOptions = {
    onlyLanguageCode: false,
    onlyCountryCode: true,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return [""];
  }

  const languageCodes = browserLocales.map((locale) => {
    if (locale.trim().length === 2) {
      return locale.trim();
    }

    return locale.trim().split(/-|_/)[0].toLowerCase();
  });

  const countryCodes = browserLocales
    .filter((code) => code.length > 2)
    .map((locale) => {
      return locale.trim().split(/-|_/)[1].toUpperCase();
    });

  if (opt.onlyLanguageCode) {
    return languageCodes;
  }

  if (opt.onlyCountryCode) {
    return countryCodes;
  }

  return browserLocales;
}

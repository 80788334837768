import React from 'react';
import { RadioGroup, FormGroup } from "@material-ui/core";
import { QuestionSingleChoice } from '../questionTypes';
import SelectionList from '../SelectionList';
import { IBaseQuestionInputProps } from '../Question';

export interface ISingleChoiceQuestionProps extends IBaseQuestionInputProps {
    question: QuestionSingleChoice;
    selected: any;
    onSelectionChange: Function
}
export function SingleChoiceQuestion(props:ISingleChoiceQuestionProps) {
    return (
        <FormGroup>
            <RadioGroup
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
            >
              <SelectionList
                answers={props.question.answers}
                multiselect={false}
                selected={props.selected}
                onSelectionChange={props.onSelectionChange}
              />
            </RadioGroup>
          </FormGroup>
    )
}

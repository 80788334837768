///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import {Action, AnyAction, Dispatch} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import Event from "../EventApi";
import {IEventStore} from "../Types";
import {CoreStatus, ICoreResult} from "../../Types";
import {Reservation} from "../../Reservation";
import Notifications from "../../Notifications/NotificationsApi";
import {EventCalendarItem, EventDateReservationList, EventDateSettings, NotificationsType} from "../../Models";
import {Credentials} from "ares-core/Credentials";
import PortalEventService from "../../Services/PortalEventService";
import EventDateService from "../../Services/EventDateService";
import {IApplicationStore} from "../../../AppState";
import LinkEventService from "../../Services/Link/LinkEventService";

type IEventThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IApplicationStore,
  unknown,
  Action<string>
>;

export const loadEventsAction = (companyId: number): IEventThunk => {
  return async (dispatch: Dispatch) => {
	  Event.Actions.setInProgress(true);
	  const result = await PortalEventService.loadEventSummaries(companyId);
	  if (result.status === CoreStatus.SUCCESS) {
	 	Event.Actions.setEventSummaries(result.payload.events);
	  	Event.Actions.setEventGroups(result.payload.eventGroups);
	  } else if(result.status === CoreStatus.FAILURE) {
		// We should call a logger here
	  }
	  Event.Actions.setInProgress(false);
  };
};

export type ILoadEventCalendarByEvent = (companyId: number, events: Array<number> | number, startDay:Date, endDay: Date, allEvents: boolean) => any;
export const loadEventCalendarByEventAction = (companyId: number, events: Array<number> | number, startDay:Date, endDay: Date, allEvents: boolean): IEventThunk => {
  return async (dispatch: Dispatch, getState) => {
  	// Example of accesing state without accessing APIs
	  const state = getState();
	  const companyId = Credentials.Store().companyId;

	  Event.Actions.setInProgress(true);

	  let result = await PortalEventService.loadCalendarByEvent(companyId,events,startDay,endDay,allEvents);

	  if (result.status === CoreStatus.SUCCESS) {
		  const temp = result.payload as EventCalendarItem[];
		  Event.Actions.setEventCalendarItems(result.payload);
	  }
	  if(result.status === CoreStatus.FAILURE) {
	  	// Call a logger. i.e. Sentry
	  }
	  Event.Actions.setInProgress(false);

  };
};

// Type the Thunk for declaration in Api
export type ILoadEventDetailsAction = (companyId:number, eid: number) => any;

export const loadEventDetailsAction = (companyId:number, eid: number): IEventThunk => {
  return async (dispatch: Dispatch) => {
    	Event.Actions.setInProgress(true);
    	// only fire GET request when eventId is provided. "updates" current event
		let result = await LinkEventService.getEventDetails(companyId,eid);
		if (result.status === CoreStatus.SUCCESS) {
			Event.Actions.setCurrentEvent(result.payload);
		}
		// if (result.status === CoreStatus.FAILURE)
		Event.Actions.setInProgress(false);
	};
};

export type IUpdateEventAction = (companyId:number, eventDateId:number, settings: EventDateSettings) => any;
/**
 * Use only for portal
 * @param companyId
 * @param eventDateId
 * @param settings
 */
export const updateEventDateAction = (
	companyId:number,
	eventDateId: number,
  settings: EventDateSettings,
): IEventThunk => {
  return async (dispatch: Dispatch) => {
	  Event.Actions.setInProgress(true);
	  Reservation.Actions.setInProgress(true);

	  let result: ICoreResult = await EventDateService.updateEventDate(companyId,eventDateId,settings);
	  if (result.status === CoreStatus.SUCCESS) {
	      Notifications.add({
	          type: NotificationsType.success,
	          message: 'Change successful'
	      });
	  } else if (result.status === CoreStatus.FAILURE) {
	      const summary = Reservation.Store().eventDateSummary as EventDateReservationList;
	      Reservation.Actions.setReservationSummary({...summary});
	      Notifications.add({
	          type: NotificationsType.error,
	          message: 'Error updating this event date time'
	      });
	  }
	  Event.Actions.setInProgress(false);
	  Reservation.Actions.setInProgress(false);
  };
};

export interface IEventThunks {
	readonly loadEventDetails: ILoadEventDetailsAction;
	readonly loadEvents: CallableFunction;
	readonly loadEventCalendarByEvent: ILoadEventCalendarByEvent;
	readonly updateEventDate: IUpdateEventAction;
}

const BuildEventThunks = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  const EventThunks: IEventThunks = {
		loadEventDetails: (companyId:number,eventId:number) => 
			dispatch(loadEventDetailsAction(companyId,eventId)),
		loadEvents: (companyId: number) =>
			dispatch(loadEventsAction(companyId)),
		loadEventCalendarByEvent: (companyId: number, eventId: Array<number> | number, start: Date, end: Date, allEvents: boolean) =>
    		dispatch(loadEventCalendarByEventAction(companyId,eventId, start, end, allEvents)),
		updateEventDate: (companyId:number,eventDateId:number,settings:EventDateSettings) =>
			dispatch(updateEventDateAction(companyId,eventDateId,settings))
  };

  return EventThunks;
};

export default BuildEventThunks;

///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import { Action, AnyAction, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { ICredentialStore } from '../Types'
import { CoreStatus, ICoreResult } from "ares-core/Types";
import { getJSON, IQueryParam, BaseURL } from "ares-core/IO/Http";
import { Credentials } from "ares-core/Credentials"
import { IUserCredentials } from "ares-core/Models";

type ICredentialThunk<ReturnType = void> = ThunkAction<ReturnType, ICredentialStore, unknown, Action<string>>;

export const loadUserCredentialsAction = (requestId:number): ICredentialThunk => {
	return async (dispatch: Dispatch) => {
		
		try {
			///
			let headers: IQueryParam[] = [];
			if (requestId !== 0) {
				let header: IQueryParam = { key:"x-indexic-c", value: requestId.toString() };
				headers.push(header);
			}
			
			let result:ICoreResult = await getJSON(BaseURL.PORTAL,"/portal/Creds",[],headers);
			if (result.status === CoreStatus.SUCCESS)
			{
				// Check the result if the user does not Exist
				let credentials: IUserCredentials = result.payload;
				if (result.payload.exists && credentials.companyID > 0)
				{
					let company = credentials.companies.find(el => el.companyId === requestId);
					if (company) {
						credentials = {...credentials, companyID: requestId}
					}
					Credentials.Actions.SetAReservationCredentials(credentials);
				}
			}
		}
		catch (error:any) {
			console.log(error.toString())
		}
	}
};

export interface ICredentialThunks {
	readonly loadUserCredentials: (id:number) => void ;
}

const BuildCredentialThunks = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  const CredentialThunks: ICredentialThunks = {
	loadUserCredentials: (id:number) => dispatch(loadUserCredentialsAction(id))
  };

  return CredentialThunks;
};

export default BuildCredentialThunks;
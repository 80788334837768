import React from "react";

const FourOhFour = () => {
  return (
    <div>
      <h3>The page you&apos;re looking for doesn&apos;t exist</h3>
    </div>
  );
};

export default FourOhFour;

import React, {FunctionComponent, ReactHTMLElement, useEffect, useMemo, useRef, useState} from 'react';
import {Button, ButtonProps,CircularProgress} from "@material-ui/core";

export interface SubmitButtonLoadingProps extends ButtonProps {
    showLoading: boolean
}

type Props = SubmitButtonLoadingProps;

const SubmitButtonLoading: FunctionComponent<Props> = (props) => {
  const {children, showLoading, ...restProps} = props;
    const ref: any = useRef(null);
    const [loadingSize, setLoadingSize] = useState<number>(0);

    useEffect(() => {
        const current: null | HTMLElement = ref?.current;
        setLoadingSize(current ? current?.offsetHeight * 0.6 : 0);
    }, [ref.current]);
  return (
      <Button {...restProps} ref={ref} disabled={props.disabled || showLoading}>
          {
              showLoading && <CircularProgress style={{height: loadingSize, width: loadingSize, marginRight: 5}}/>
          }
          {children}
      </Button>
  );
};

export default SubmitButtonLoading;

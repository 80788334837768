import React from 'react'
import { TextField} from "@material-ui/core";
import { QuestionNumber } from '../questionTypes';
import { IBaseQuestionInputProps } from '../Question';

export interface INumberQuestionProps extends IBaseQuestionInputProps {
    error: any;
    question: QuestionNumber
}
export function NumberQuestion(props: INumberQuestionProps) {
    const { min, max, step = "any" } = props.question;
    let setStep = step;
    if (!isNaN(Number(step)) && Number(step) === -1) {
      setStep = "any";
    }
    return (
      <TextField
        type="number"
        required
        error={props.error}
        inputProps={{ min, max, step: setStep }}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      />
    );
}

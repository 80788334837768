import {Reducer} from "redux";
import {NotificationsActions, NotificationsActionType} from "./Actions";
import {INotificationsStore} from "../Types";
import NotificationsApi from "../NotificationsApi";
import {INotification} from "../../Models/Notifications";


export const NotificationsReducer: Reducer<INotificationsStore, NotificationsActions> = (state: INotificationsStore = NotificationsApi.Store(), action) => {
    switch (action.type) {
        case NotificationsActionType.ADD_NOTIFICATION:
            return addNotification(state, action.notification);
        case NotificationsActionType.SHOW_NOTIFICATION:
            return showNotification(state);
        case NotificationsActionType.SET_NOTIFICATIONS:
            return setNotifications(state, action.notifications)
        case NotificationsActionType.CLEAR_NOTIFICATIONS:
            return clearNotifications(state)
        default:
           return state;
    }

};


function addNotification(state: INotificationsStore, notification: INotification): INotificationsStore {
    const newNotifications = [...state.notifications];
    newNotifications.push(notification);
    return {
        ...state,
        notifications: newNotifications
    };
}

function showNotification(state: INotificationsStore): INotificationsStore {
    const newNotifications = [...state.notifications];
    const lastNotification = newNotifications.shift();
    const current = lastNotification || null;
    return {
        ...state,
        current,
        notifications: newNotifications,
        display: current !== null
    }
}

function setNotifications(state: INotificationsStore, notifications: INotification[]): INotificationsStore {
    return {
        ...state,
        notifications
    };
}

function clearNotifications(state: INotificationsStore): INotificationsStore {
    return {
        ...state,
        notifications: [],
        current: null,
        display: false
    }
}


import { Button, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
  SquarePaymentForm,
  SimpleCard,
  Context
} from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';

import "./SquarePayment.css";

import { SquareGateway } from "ares-core/UI/Checkout/Gateway";

import { IAddressMultiline, IContactInfo } from "../../Models";
import { MathEx } from "../../Utils";
import { Locale } from "../../UI"
import AddressMultiline from "../AddressMultiLine";
import ContactInfo from "../ContactInfo";
import { ICheckoutProps } from './CheckoutProps';



export interface ISquarePaymentProps extends ICheckoutProps {}

const SquarePayment = (props: ISquarePaymentProps) => {
	const [gateway, setGateway] = useState<SquareGateway>(props.gatewayProcessor as SquareGateway);
  const [errorMessages, setErrorMessages] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  function cardNonceResponseReceived(errors: any, nonce: string, cardData: any, buyerVerificationToken?: string) {
    setDisableSubmit(false);
    if (errors) {
      setErrorMessages(errors.map((error: any) => error.message));
      return;
    }

    setErrorMessages([]);
    const token = JSON.stringify({
			nonce: nonce,
			token: buyerVerificationToken
    });
  }


  function createPaymentRequest() {
    // Payment button clicked.
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: props.checkoutData.multilineAddress.country,
      total: {
        label: 'MERCHANT NAME',
        amount: '1',
        pending: false,
      },
      lineItems: [
        {
          label: 'Subtotal',
          amount: '1',
          pending: false,
        },
      ],
    };
  }

  function createVerificationDetails() {
    const {contactInfo, multilineAddress} = props.checkoutData;
    return {
      currencyCode: "USD",
      intent: "STORE",
      billingContact: {
        familyName: contactInfo.lastName || "",
        givenName: contactInfo.firstName || "",
        email: contactInfo.email || "",
        country: multilineAddress.country || "",
        city: multilineAddress.city || "",
        addressLines: [multilineAddress.address1 || "", multilineAddress.address2 || ""],
        postalCode: multilineAddress.zip || "",
        phone: contactInfo.phone,
      },
    };
  }

  const isSandbox = gateway.applicationId.substring(0,7) === "sandbox";
  function postalCode() {
    const postalCode = ''; // your logic here
    return postalCode;
  }

  function focusField() {
    return 'cardNumber';
  }

  return (
    <SquarePaymentForm
      sandbox={isSandbox}
      applicationId={gateway.applicationId}
      locationId={gateway.locationId}
      cardNonceResponseReceived={cardNonceResponseReceived}
      createPaymentRequest={createPaymentRequest}
      createVerificationDetails={createVerificationDetails}
      postalCode={postalCode}
      focusField={focusField}
      formId={"square"}
      apiWrapper="sqPaymentFrm"
    >
      <SimpleCard />
      <SqBuyerInfo
       {...props}
       disableSubmit={disableSubmit}
       onDisableSubmitChange={disable => setDisableSubmit(disable)}
        />
      <div className="sq-error-message">
      {errorMessages.map(errorMessage => (
        <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
      ))}
    </div>

    </SquarePaymentForm>
  );
};


interface ISqBuyerInfoProps extends ISquarePaymentProps {
  disableSubmit: boolean;
  onDisableSubmitChange: (disabled: boolean) => void;
}

function SqBuyerInfo({
  checkoutData, 
  onChange,  
  onDisableSubmitChange, 
  companyEntry = false, 
}: ISqBuyerInfoProps) {
  const sqContext = useContext(Context);
  const [contactInfoIsValid, setContactInfoIsValid] = useState<boolean>(false);
  const [addressIsValid, setAddressIsValid] = useState<boolean>(false);

  const handleContactInfoChange = (contactInfo: IContactInfo, isValid: boolean) => {
    // onChange({ ...checkoutData, contactInfo });
    setContactInfoIsValid(isValid);
  }
  const handleAddressChange = (multiLineAddress: IAddressMultiline, isValid: boolean) => {
    // onChange({ ...checkoutData, multilineAddress: multiLineAddress });
    setAddressIsValid(isValid);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

  }

  return <form onSubmit={handleSubmit}>
    <ContactInfo
      contactInfo={checkoutData.contactInfo}
      onChange={handleContactInfoChange}
      companyEntry={companyEntry}
      country={checkoutData.multilineAddress.country}
    />
    <AddressMultiline
      multiLineAddress={checkoutData.multilineAddress}
      onChange={handleAddressChange}
      onlyRequireZip={companyEntry}
      noZipEntry={true}
    />

			<Grid container spacing={2} alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <Button 
        type="submit" 
        variant={"outlined"} 
        color={"primary"} 
        disabled={false}>
      {Locale.processPayment} {MathEx.formatCurrency(0.0)}
      
      </Button>
      </Grid>
    </Grid>

  </form>
}

export default SquarePayment;

import { ReservationDetail } from 'ares-core/Models/Reservation';
import { TicketValuesValue } from './../../Models/link/Events';
import {Rate, RateEffective} from 'ares-core/Models';
import { ReservationTransaction } from 'ares-core/Models';
import { DateTimeUtils } from './../../Utils/DateTimeUtils';
import { MathEx } from 'ares-core/Utils';
import { Financial } from 'ares-core/Utils/Financials';

/**
 * 
 * @param targetDate String date to compare between the start and end dates
 * @param start_date String
 * @param end_date String
 * @returns boolean If the targetDate is in range it will return true if not false
 */
export const rateIsValid = (targetDate : string, start_date : string, end_date : string): boolean =>{
    
    return DateTimeUtils.inRange(
        new Date(targetDate),
        new Date(start_date),
        new Date(end_date),
    )
}

export const readOnly = ( transactions : ReservationTransaction[] ) =>{
    if(transactions){
        return transactions.length > 0
    }else{
        return false
    }
}

export const hasRequiredFieldsAndQuestions = ( requiredFields : number | null, questions : number | null) =>{
    let disabled = false;
    if(requiredFields !== null && requiredFields > 0){
        disabled = true
    }
    if(questions !== null && questions > 0){
        disabled = true
    }
    return disabled
}

export const buildRates = (rateEffectives: RateEffective[], targetDate : string): number => {
    let rateValid = rateEffectives.find((rateEffective) => {
        if(rateIsValid(targetDate,rateEffective.effectiveFrom, rateEffective.effectiveTo)){
            return rateEffective
        }
    });
    return rateValid?.rate?  Number(rateValid?.rate) : 0;
}

/**
 * Functions for Link
 */

export const RatesDeterminesDuration = ( eventDuration : number, rates : Rate[] ) =>{
    // if event duration is less than 1 the rates will determinate the duration
    if(eventDuration < 1){
        return rates.filter( option  => option.durationInSeconds > 0 )
    }else{
        return rates.filter( option  => option.durationInSeconds < 1 )
    }
}

export const isRadioInput = (maxTickets : number) =>{
    return  maxTickets === 1
}
export const isRadioFlatRate = (maxTickets : number, isFlate : boolean | undefined) =>{
    return isRadioInput(maxTickets) && isFlate
}

export const DisplayableRates = (rates : Rate[]) =>{
    //verify if all the rates has the same duration
    //check if there are radio rates
    let copy_rates = rates;
    if(rates.filter( x => isRadioInput(x.maxTickets)).length > 0){
        if( copy_rates.find(x => x.flatRate === true ) !== undefined ){
            //if there are rates with FlatRate as true, we are going to display all the rates
            copy_rates = rates
        }else{
            copy_rates = rates.filter( x => isRadioInput(x.maxTickets))
        }
    }

    return copy_rates
}
export const GetRatesByRules = (rates : Rate[], selected_rate : Rate) => {
    //if the selected rate is a radio and flatrate true
    if(isRadioFlatRate(selected_rate.maxTickets, selected_rate.flatRate)){
        //return just the selected rate because the rest of the rates do not has relationship with this rate
        return [ selected_rate ]
    }
    return rates.filter((r) => r.durationInSeconds === selected_rate.durationInSeconds && !isRadioFlatRate(r.maxTickets, r.flatRate))

}
export const RateSelectionGetByDuration = (rates: Rate[], duration: number) => {
    return rates.filter((r) => r.durationInSeconds === duration);
}
/**
 * @description this function returns an concatenated sting of rateId!count 
 * @param tickets 
 * @returns string
 */
export const CreateClientTicketList = (tickets : TicketValuesValue[]) => {
    var requestString = "";
    for (let ticket of tickets) {
        if (requestString.length > 0) {
        requestString += ",";
        }
        requestString += `${ticket.rateId}!${ticket.count}`;
    }
    return requestString
}
/**
 * @description This functions helps to compare if a rate has a different amount agains to ticketsPerson
 * in case where the amount of the ticket is different to the amount of the corresponding rate, this function will override the
 * amount of the rate.
 * If there are no amount difference between the rate and the ticket, there is no reason to change it and we will return the original rateEffective array
 * @returns an array of Rates 
 */
export const hasDifferentAmount = (rate : Rate, ticketEdits : Partial<ReservationDetail>) => {
    const rates = rate.rateEffectives.map(internalRate =>{
        //.rate = amount
        if(internalRate.rateId === rate.rateId && internalRate.rate !== ticketEdits.rate){
            internalRate.rate = ticketEdits.rate!
        }
        return internalRate
    })
    return rates
}
/**
 * 
 * @param rate amount of the rate
 * @param rateAdjustmentPercent number that reprecents the adjustment percent for the rate
 * @param rateAdjustmentRoundingUnit number that will fix rounding the amount 
 * @returns amount for the rate - in case that there is no rate adjustments the function will return the base amount of the rate
 */
export const buildDynamicRate = (rate : number, rateAdjustmentPercent : number, rateAdjustmentRoundingUnit : number) => {
    if(rateAdjustmentPercent && rateAdjustmentRoundingUnit){
        return MathEx.round(Financial.CalculateAmountByPercentaje(rate, rateAdjustmentPercent), rateAdjustmentRoundingUnit)
    }
    return rate
}
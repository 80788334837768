import React from "react";
import {useHistory} from "react-router-dom";
import {Theme, withStyles, Badge, IconButton, Tooltip} from "@material-ui/core";
import {ShoppingCart, ShoppingCartOutlined} from "@material-ui/icons";
import {Shopping} from "ares-core/Shopping";
import {MathEx} from "ares-core/Utils";

const StyledBadge = withStyles((theme: Theme) => ({
    badge: {
        top: "50%",
        right: -3,
        // The border color match the background color.
        border: `2px solid ${
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[900]
        }`,
    },
}))(Badge);

interface OpenCartButtonProps {
    btnClasses: any;
    companyName: string;
}

export const OpenCartButton = (props: OpenCartButtonProps) => {
    const history = useHistory();
    const handleCartOpen = () => {
        history.push(`/cart/${props.companyName}`);
    };

    return (
        <Tooltip title={'Total: ' + MathEx.formatCurrency(Shopping.TotalAmount())}>
            <IconButton
                id="open-cart-btn"
                size="small"
                disabled={Shopping.IsEmpty()}
                onClick={handleCartOpen}
                className={props.btnClasses.cartIcon}
            >
                {Shopping.IsEmpty() ? (
                    <ShoppingCartOutlined/>
                ) : (
                        <StyledBadge badgeContent={Shopping.CartItemCount()} color="primary">
                            <ShoppingCart/>
                        </StyledBadge>
                )}
            </IconButton>
        </Tooltip>
    );
};

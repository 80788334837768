import React, { useEffect, MouseEvent } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { ExpandMore, AddPhotoAlternate } from "@material-ui/icons";
import { Locale } from "ares-core/UI";
import { CatalogDetail, ICartItem } from "ares-core/Models";
import { NotificationsType } from "ares-core/Models/Notifications";
import NotificationsApi from "ares-core/Notifications/NotificationsApi";
import CatalogItemsList, {
  CartCount,
} from "link-core/UI/Catalog/components/CatalogItemsList";
import { ICatalogItem } from "link-core/UI/Catalog/components/CatalogItem";
import { IPreGiftCardInformation } from "modules/Catalog";

export interface MerchandisePanelProps {
  expanded: boolean;
  nextStepLabel: string;
  handlePanelChange: CallableFunction;
  handleContinue: CallableFunction;
  addons: Partial<CatalogDetail>[];
  addedAddons: ICartItem[];
  handleMerchandiseUpdate: CallableFunction;
  giftCard?: Array<IPreGiftCardInformation>
}

export const MerchandisePanelComponent = (props: MerchandisePanelProps) => {
  const panelName = "merchandise";

  const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value: currPanel } = e.currentTarget;
    props.handleContinue(null, currPanel);
  };

  const InCart = props.addedAddons.map((i: ICartItem) => ({
    id: i.catalogId,
    count: i.quantity,
  }));
  const AddedItemsCount = InCart.reduce((acc: number, cur: CartCount) => {
    return acc + cur.count;
  }, 0);

  const CatalogListProps = {
    addToCart: (catalogItem: ICatalogItem, quantity: number) => {

      const ItemInCart = InCart.filter((item: CartCount) => item.id === catalogItem.catalogId);
      let add = 1;
      if (ItemInCart.length > 0) {
        add += ItemInCart[0].count;
      }
      props.handleMerchandiseUpdate(catalogItem, add);
    },
    removeFromCart: (id: number, quantity: number) => {
      const ItemInCart = InCart.filter((item: CartCount) => item.id === id);
      if (ItemInCart.length === 0 || ItemInCart[0].count === 0) {
        return;
      }
      let add = -1;
      if (ItemInCart.length > 0) {
        add += ItemInCart[0].count;
      }
      const catalogItem = props.addons.filter(
        (i) => i.catalogId === id,
      )[0] as CatalogDetail;
      props.handleMerchandiseUpdate(catalogItem, add);
    },
    catalogDisplay: props.addons || [],
    cartCount: InCart || [],
    giftCard: props.giftCard
  };

  const AccordionProps = {
    expanded: props.expanded,
    onChange: props.handlePanelChange(panelName),
  };
  const AccordionSummaryProps = {
    expandIcon: <ExpandMore />,
    ["aria-controls"]: "merchandise-panel-content",
    id: "merchandise-panel-header",
  };

  return (
    <Accordion {...AccordionProps}>
      <AccordionSummary {...AccordionSummaryProps}>
        <Grid container>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <AddPhotoAlternate />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {Locale.merchandise}
                {AddedItemsCount > 0
                  ? ` (${Locale.merchandiseQuantityLabel} ${AddedItemsCount})`
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2} justifyContent="center">
            <CatalogItemsList {...CatalogListProps} />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              value="merchandise"
              onClick={onContinue}
            >
              {props.nextStepLabel}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import React from 'react';
import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { PaymentIntentResult, Stripe, StripeElements } from "@stripe/stripe-js";
import { Shopping } from 'ares-core/Shopping';
import { ICheckoutData } from 'ares-core/Models';


interface IStripePaymentCheckoutForm {
  clientSecret: string;
	checkoutData: ICheckoutData;
	proceedWithCheckout: boolean;
	onChange: (valid:boolean) => void;   
	onPaymentReady: (valid: boolean, data: string, errorMessage?: string) => void;
}

const StripePaymentCheckoutForm = (props:IStripePaymentCheckoutForm) => {
  
  const stripe: Stripe|null = useStripe();
  const elements: StripeElements|null = useElements();
  const [elementIsValid, setElementIsValid] = useState<boolean>(false);
  
  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    if(props.proceedWithCheckout && stripe && elements && elementIsValid) {
        processPayment();
    } 
  }, [props.proceedWithCheckout, props.checkoutData]);

  const onElementChange = async (e: any) => {
    setElementIsValid(e.complete);
    props.onChange(e.complete);
  }

  const processPayment = async () => {
    // Call Stripe to confirm payment.
    //
    // Card payment failures (wrong postal code, wrong security code, payment declined)
    // come here as a PaymentIntentResult, not to the return url.
    //
    // The parameter below, redirect: "if_required" means card payment success will return here,
    // not to the return_url.  For other types of payment methods like iDEAL, the result will come
    // to our return_url, /stripe.  That's handled by the StripeRedirect.tsx component.
    if(!stripe || !elements) {
        return props.onPaymentReady(false, "");
    }
    
    const {billingInfo} = Shopping.Store();
    const result: PaymentIntentResult = await stripe.confirmPayment({
      elements,
      redirect: "if_required",   // Card payment results will not redirect.
      confirmParams: {
        return_url: `${window.location.origin}/stripe`,
        // Saving billingInfo in the PaymentIntent for retrieval in our API.
        shipping: {          
          name: `${billingInfo.firstName} ${billingInfo.lastName}`,
          address: {
            line1: billingInfo.address1 ?? 'none',
            line2: billingInfo.address2,
            city: billingInfo.city,
            state: billingInfo.state,
            postal_code: billingInfo.zip
          }
        }
      }
    });

    if(result.error) {
      // Card validation errors and card denials come back immediately
      const error = result.error;

      if (error.type === "card_error" || error.type === "validation_error") {
        return props.onPaymentReady(false, "", error.message);
      } else {
        return props.onPaymentReady(false, "", "Unable to process payment.  Please try again.");
      }
    } else {
      // Payment was successful.  Set Shopping.processorPaymentData the same as Shopping.processorSpecificJson.
      // The value is a IStripeIntentInfo.
      const data = Shopping.Store().processorSpecificJson;
      props.onPaymentReady(true, data);
    }
  };

  return ( 
    <PaymentElement 
        id="payment-element"
        onChange={onElementChange}
    />
  );
}


export default StripePaymentCheckoutForm;
import { Dispatch } from "redux";

import { LinkCompanyStoreReducer } from "./redux-io/Reducers";
import BuildLinkCompanyActions, {
  setLinkCompanyAction,
  setLinkCompanyEventsAction,
  setLinkCurrentEventAction,
  updateReservationAction,
  setInProgressAction,
  LinkCompanyActionType,
  ILinkCompanyActions,
} from "./redux-io/Actions";
import { ILinkCompanyStore } from "../LinkCompany";
import {
  ILinkCompany,
  ICatalogDisplay,
  VwEventDisplay,
  LinkEventDetail,
  ReservationData,
  ReservationDetail,
  TicketPerson,
  ICartItem,
  CatalogDetail,
  IGiftCardInformation,
  SelectedSeats,
} from "../Models";
import BuildLinkCompanyThunks, { ILinkCompanyThunks } from "./redux-io/Thunks";

export class LinkCompanyApi {
  store_: ILinkCompanyStore;
  dispatcher_?: Dispatch;
  Api: ILinkCompanyThunks;
  Actions: ILinkCompanyActions;
  //
  constructor() {
    this.store_ = DefaultCompanyStore;
    //
    this.Api = BuildLinkCompanyThunks(() => {});
    // Need to phase out old action setup in favor of this Build pattern
    this.Actions = BuildLinkCompanyActions(this.dispatcher_!);
  }
  public Store = (s?: ILinkCompanyStore) => {
    if (s) this.store_ = s;
    return this.store_;
  };
  public Reducer = () => {
    return LinkCompanyStoreReducer;
  };
  public Dispatcher = (d?: Dispatch) => {
    if (d) {
      this.dispatcher_ = d;
      this.Api = BuildLinkCompanyThunks(this.dispatcher_);
      this.Actions = BuildLinkCompanyActions(this.dispatcher_!);
    }
    return this.dispatcher_;
  };
  // Set Actions
  public SetLinkCompany = (r: ILinkCompany) => {
    if (this.dispatcher_) this.dispatcher_(setLinkCompanyAction(r));
  };
  public SetLinkCompanyEvents = (cEvents: VwEventDisplay[]) => {
    if (this.dispatcher_) this.dispatcher_(setLinkCompanyEventsAction(cEvents));
  };
  public SetLinkCurrentEvent = (cEvents: LinkEventDetail) => {
    if (this.dispatcher_) this.dispatcher_(setLinkCurrentEventAction(cEvents));
  };
  public UpdateReservation = (res: Partial<ReservationData>) => {
    if (this.dispatcher_) this.dispatcher_(updateReservationAction(res));
  };
  public SetInProgress = (b: boolean, w: LinkCompanyActionType) => {
    if (this.dispatcher_) this.dispatcher_(setInProgressAction(b, w));
  };

  // Reservation Helpers
  public ClearReservation = () => {
    InitialReservation.catalogItems = [];
    InitialReservation.ticketEdits = [];
    this.UpdateReservation(InitialReservation);
  };

  public UpdateReservationRate = (rateId: number, count: number) => {
    var reservation = this.Store().currentReservation;
    var detailList: Partial<ReservationDetail>[] = reservation.ticketEdits
      ? reservation.ticketEdits
      : [];
    // Find the Rate
    var detail: Partial<ReservationDetail> | undefined = detailList.find(
      (d) => d.rateId === rateId,
    );
    if (detail) {
      detail.tickets = count;
    } else {
      detail = {
        rateId: rateId,
        tickets: count,
        reservationDetailId: 0,
        ticketPersons: [],
      };

      detailList.push(detail);
    }
    // Set the array on the current reservation
    this.UpdateReservation({ ticketEdits: detailList });
  };
  // The Rate must exist for the Info update to be successful
  /**
   * Update the ticket person object on a given detail list
   * @param rateId - ID of the ticketEdit array
   * @param index - ID of the ticketPerson array
   * @param info - Ticket person data to be updated
   */
  public UpdateReservationRateInfo = (
    rateId: number,
    ticketPersonId: number,
    info: Partial<TicketPerson>,
  ) => {
    var reservation = this.Store().currentReservation;
    var detailList: Partial<ReservationDetail>[] = reservation.ticketEdits
      ? reservation.ticketEdits
      : [];
    // Find the Rate
    var detail = detailList.find((d) => d.rateId === rateId);
    if (detail) {
      // Find the Ticket
      var ticketList: Partial<TicketPerson>[] = detail.ticketPersons
        ? detail.ticketPersons
        : [];
      // Ensure Consistency
      detail.ticketPersons = ticketList;
      var index = ticketList.findIndex(
        (d) => d.ticketPersonId === ticketPersonId,
      );
      if (index > -1) {
        var ticket: Partial<TicketPerson> = ticketList[index];
        var modTicket = { ...ticket, ...info };
        detail.ticketPersons[index] = modTicket;
      } else {
        // Add the RateInfo
        var newTicket: TicketPerson = {
					ticketPersonId: ticketPersonId,
          participantFirstName: reservation.firstName,
          participantLastName: reservation.lastName,
          ...info,
        };

        detail.ticketPersons.push(newTicket);
      }
      // Set the array on the current reservation
      this.UpdateReservation({ ticketEdits: detailList });
    }
  };
  // The Rate must exist for the Info update to be successful
  // This function will assing the count of catalog items to cart.
  public UpdateReservationCatalogItem = (
    item: ICatalogDisplay | CatalogDetail,
    count: number,
  ) => {
    var catalogList: Partial<CatalogDetail>[] = this.Store().currentEvent
      .addOns; // Find the CatalogItem in the store
    var catalogItem = catalogList.find((c) => c.catalogId === item.catalogId);
    if (catalogItem === undefined) {
      return;
    }

    // All good add to current reservation
    var reservation = this.Store().currentReservation;
    var addons: ICartItem[] = reservation.catalogItems
      ? reservation.catalogItems
      : [];
    // Look for catalogItem in the addon list
    var addon = addons.find((a) => a.catalogId === catalogItem!.catalogId);
    if (addon) {
      addon.quantity = count;
      addon.giftCards = item.giftCards!
    } else {
      addon = {
        catalogId: catalogItem.catalogId || 0,
        quantity: count,
        price: catalogItem.unitPrice || 0,
        description: catalogItem.description || "",
        itemCode: catalogItem.itemCode || "",
        giftCards : item.giftCards || []
      };
      addons.push(addon);
    }
    const setable = addons.filter((i) => i.quantity > 0);
    // Set the array on the current reservation
    this.UpdateReservation({ catalogItems: setable });
  };
  public UpdateGiftCardInformation(catalogId : number, giftCardInformation : IGiftCardInformation[]){
    const catalogItems = this.Store().currentReservation.catalogItems;
    const catalogItemsUpdated = catalogItems?.map(item =>{
      if(item.catalogId === catalogId){
        item.giftCards = giftCardInformation
      }
      return item 
    })
    // Set the array on the current reservation
    this.UpdateReservation({ catalogItems: catalogItemsUpdated });
  }
	//
	// Get Event by URL Friendly Name
	public  EventByUrlName = (name:string) => {
		return (this.store_.events.find((e) => e.urlFriendlyName === name));
	}
  public AddSeatsToTickets = (seats : SelectedSeats[]) =>{
		const tickets = this.Store().currentReservation.ticketEdits
    const rates = this.Store().currentEvent.rates
    const localSeats = [...seats]
    const assignedSeats = tickets?.map(item =>{
      const rate = rates.find(x => x.rateId === item.rateId)
      if(rate){
        let categorySeats = localSeats.find(x => x.category === rate.seatAssingmentCategoryId)
        let seatsIndex = localSeats.findIndex(x => x.category === rate.seatAssingmentCategoryId)
        let seatsToAssign: string[] = [] 
        for (let index = 0; index < item.tickets!; index++) {
          const element = categorySeats?.seats[index]!;
          seatsToAssign.push(element)
        }
        if(seatsIndex !== -1){
          localSeats[seatsIndex].seats = localSeats[seatsIndex].seats.slice(item.tickets!)
        }
        item.assignedSeats = [{
          category : categorySeats?.category!,
          seats : seatsToAssign
        }]
      }
      return item
    })
		this.UpdateReservation({ticketEdits : assignedSeats})
	}
}

// Initialize Default Event
export const DefaultEvent: LinkEventDetail = {
  name: "",
  displayOrder: 0,
  companyId: 0,
  eventId: 0,
  companyName: "",
  description: "",
  durationInSeconds: 0,
  hideDuration: false,
  urlFriendlyName: "",
  excludeFromReseller: false,
  lat: 0,
  lon: 0,
  minRate: 0,
  maxRate: 0,
  restrictions: "",
  reservationInfo: "",
  mapUrl: "",
  maxTickets: 0,
  address: "",
  latestSell: 0,
  earliestSell: 0,
  eventTimeType: 0,
  ticketRequiredFields: 0,
  nextTime: "",
  oneDay: false,
  singleTimeEventId: 0,
  rates: [],
  deposit: 0,
  depositOnly: false,
  delivery: false,
  questionsJson: "",
  rateQuestionGroups: [],
  addOns: [],
  active : false
};

export const InitialReservation: ReservationData = {
  eventDateId: 0,
  eventDateTime: (new Date()).toISOString().slice(0, -5),
  reservationId: 0,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  lat: 0,
  lon: 0,
  answersJson: "",
  ticketEdits: [],
  catalogItems: [],
  thisIsDeposit: false,
};

const DefaultCompanyStore: ILinkCompanyStore = {
  companyID: 0,
  companyFilesBaseUrl: "",
  homeUrl: "",
  justLogo: false,
  allowInFrame: false,
  lat: 0,
  lon: 0,
  logoUrl: "",
  name: "",
  urlFriendlyName: "",
  boosting: false,
  resellOtherEvents: false,
  busy: false,
  events: [],
  currentEvent: DefaultEvent,
  eventDates: [],
  eventTimes: [],
  eventCalendar: {},
  catalogDisplay: [],
  currentReservation: { ...InitialReservation },
  lastReservationDate: new Date().toISOString(),
};

const LinkCompany: LinkCompanyApi = new LinkCompanyApi();
export default LinkCompany;

///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import { Action, AnyAction, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  setInProgressAction,
  setLinkCompanyAction,
  setLinkCompanyEventsAction,
  setLinkCurrentEventAction,
  setLinkEventDatesAction,
  setLinkEventTimesAction,
  setEventCalendarAction,
  setLinkCatalogAction,
} from "./Actions";
import LinkCompany, { DefaultEvent } from "../LinkCompanyApi";
import { ILinkCompanyStore } from "../Types";
import { CoreStatus, ICoreResult } from "../../Types";
import { IQueryParam, getJSON, postJSON, BaseURL } from "../../IO/Http";
import {
  EventDateTimeItem,
  IEventCalendarList,
  EventTicketRequest,
  LinkEventDetail,
  VwEventDisplay
} from "ares-core/Models";
import { isEmpty } from "../../Utils";
import LinkEventService from "ares-core/Services/Link/LinkEventService";


type ILinkCompanyThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ILinkCompanyStore,
  unknown,
  Action<string>
>;

// Type the Thunk for declaration in Api
export type ILoadCompanyAction = (furl: string) => any;

export const loadCompanyAction = (furl: string): ILinkCompanyThunk => {
  return async (
    // ThunkDispatch type copied from IntelliSense
    dispatch: ThunkDispatch<ILinkCompanyStore, unknown, Action<string>>,
  ) => {
    dispatch(setInProgressAction(true));
    try {
      let uri: string = `/api/Company/${furl}`;
      let params: IQueryParam[] = [];
      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCompanyAction(result.payload));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadCompanyEventsAction = (
  id: number,
  coords?: LocationCoords,
) => any;
export interface LocationCoords {
  lat: number;
  lon: number;
  distance: number;
}
export const loadCompanyEventsAction = (
  id: number,
  coords?: LocationCoords,
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    try {
      let uri: string = `/api/${id}/Events`;
      // Coords will be called only on boost
      if (!isEmpty(coords) && coords) {
        uri = `${uri}/${coords.lat}/${coords.lon}/${coords.distance}`;
      }
      let params: IQueryParam[] = [];
      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCompanyEventsAction(result.payload));
        dispatch(setInProgressAction(false));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};
// Type the Thunk for declaration in Api
export type ILoadEventDetailsAction = (eid?: number) => any;

export const loadEventDetailsAction = (eid?: number): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    const cid = LinkCompany.Store().companyID;
    // only fire GET request when eventId is provided. "updates" current event
    if (eid) {
      try {
        let uri: string = `/api/${cid}/Events/${eid}`;
        let params: IQueryParam[] = [];
        let result: ICoreResult = await getJSON(
          BaseURL.LINK,
          uri,
          params,
        );

        if (result.status === CoreStatus.SUCCESS) {
          dispatch(setLinkCurrentEventAction(result.payload));
          dispatch(setInProgressAction(false));
        }
      } catch (error) {
        dispatch(setInProgressAction(false));
      }
    } else {
      // else "empty" current event (used when navigating to Link 'home')
      dispatch(setLinkCurrentEventAction(DefaultEvent));
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadEventDetailsByNameAction = (name?: string) => any;

export const loadEventDetailsByNameAction = (name?: string): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    const cid = LinkCompany.Store().companyID;
    // only fire GET request when eventId is provided. "updates" current event
    if (name) {
      try {
        let uri: string = `/api/${cid}/Events/${name}`;
        let params: IQueryParam[] = [];
        let result: ICoreResult = await getJSON(
          BaseURL.LINK,
          uri,
          params,
        );

        if (result.status === CoreStatus.SUCCESS) {
		  let events = LinkCompany.Store().events;
		  if (isEmpty(events)) {
			  events = new Array<VwEventDisplay>();
		  }
		  let details = result.payload;
		  let eventSummary:VwEventDisplay = { ...details, urlFriendlyName: name };
		  events.push(eventSummary);

		  dispatch(setLinkCompanyEventsAction(events));
          dispatch(setLinkCurrentEventAction(result.payload));
          dispatch(setInProgressAction(false));
        }
      } catch (error) {
        dispatch(setInProgressAction(false));
      }
    } else {
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadEventDatesAction = (
  eid?: number,
  s?: string,
  e?: string,
) => any;

export const loadEventDatesAction = (
  eid?: number,
  s?: string,
  e?: string,
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    const cid = LinkCompany.Store().companyID;
    // only fire GET request when all args are provided. "updates" current event
    if (eid && s && e) {
      try {
        let uri: string = `/api/${cid}/Events/${eid}/Dates`;
        let params: IQueryParam[] = [];
        params.push({ key: "startDate", value: s });
        params.push({ key: "endDate", value: e });
        let result: ICoreResult = await getJSON(
          BaseURL.LINK,
          uri,
          params,
        );

        if (result.status === CoreStatus.SUCCESS) {
          // Marshal out the dates
          const edates = result.payload.map((item: any) => {
            return new Date(item.day).toISOString();
          });

          dispatch(setLinkEventDatesAction(edates));
          dispatch(setInProgressAction(false));
        }
      } catch (error) {
        dispatch(setInProgressAction(false));
      }
    } else {
      // else "empty" current event (used when navigating to Link 'home')
      dispatch(setLinkEventDatesAction([]));
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadEventTimesAction = (
  eventId: number,
  startDate: string,
  request: EventTicketRequest[],
) => any;

export const loadEventTimesAction = (
  eventId: number,
  startDate: string,
  request: EventTicketRequest[],
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    const companyId = LinkCompany.Store().companyID;
    // Build the TicketRequest list
    var requestString = "";
    for (let ticket of request) {
      if (requestString.length > 0) {
        requestString += ",";
      }
      requestString += `${ticket.rateId}!${ticket.count}`;
    }
    try {
      let uri: string = `/api/${companyId}/Events/${eventId}/DateTimes/${startDate}`;
      let params: IQueryParam[] = [];
      params.push({ key: "clientTicketList", value: requestString });

      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        const etimes = result.payload.map((item: any) => {
          const et: EventDateTimeItem = {
            eventDateID: item.eventDateID,
            eventDateTime: item.eventDateTime,
            sellStatus: item.sell_Status,
            publicNote: item.public_Note,
            startTime: item.startTime,
            endTime: item.endTime,
            reason: item.reason,
            rateAdjustmentPercent : item.rateAdjustmentPercent,
            rateAdjustmentRoundingUnit : item.rateAdjustmentRoundingUnit,
            totalTicketAmount : item.totalTicketAmount,
          };
          return et;
        });
        dispatch(setLinkEventTimesAction(etimes));
        dispatch(setInProgressAction(false));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};



export type ILoadEventCalendarAction = (month: string, queryParams?: IQueryParam[] | null) => any;

export const loadEventCalendarAction = (month: string, queryParams?: IQueryParam[] | null): ILinkCompanyThunk => {
  return async (
    // ThunkDispatch type copied from IntelliSense
    dispatch: ThunkDispatch<ILinkCompanyStore, unknown, Action<string>>,
  ) => {
    dispatch(setInProgressAction(true));
    const companyId = LinkCompany.Store().companyID;
    try {
      let uri: string = `/api/${companyId}/events/calendar/${month}`;
      let params: IQueryParam[] = [];

      if (queryParams && queryParams?.length > 0) {
        params = queryParams;
      }

      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        // Convert from C# Dictionary to our normalized definition NEventCalendar

        // let calendar:IEventCalendarList = {
        // 	items: {},
        // 	keys: []
        // };

        // for (const key in result.payload) {
        // 	calendar.items[key] = result.payload[key];
        // 	calendar.keys.push(key);
        // }
        //dispatch(setEventCalendarAction(calendar));
        dispatch(setEventCalendarAction(result.payload));
        dispatch(setInProgressAction(false));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadBoostEventsAction = (
  companyId: number,
  latitude: number,
  longitude: number,
) => any;

export const loadBoostEventsAction = (
  companyId: number,
  latitude: number,
  longitude: number,
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    try {
      let uri: string = `/api/${companyId}/Events/${latitude}/${longitude}/20`;
      let params: IQueryParam[] = [];
      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCompanyEventsAction(result.payload));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};


export type ILoadBoostEventsForSupplierCompany = (
  companyId: number, 
  supplierCompanyFriendlyUrl: string
) => any;

export const loadBoostEventsForSupplierCompany = (
  companyId: number, 
  supplierCompanyFriendlyUrl: string
) : ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await LinkEventService.getEventsForBoostSupplier(companyId,supplierCompanyFriendlyUrl);

      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCompanyEventsAction(result.payload));
      }
    } catch (error) { }
    dispatch(setInProgressAction(false));
  };
};


export type ILoadGroupEventsAction = (
  companyId: number,
  groupId: number,
) => any;

export const loadGroupEventsAction = (
  companyId: number,
  groupId: number,
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    try {
      let uri: string = `/api/${companyId}/Events/Group/${groupId}`;
      let params: IQueryParam[] = [];
      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCompanyEventsAction(result.payload));
        dispatch(setInProgressAction(false));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type ILoadCompanyCatalogAction = (
  companyId: number,
  justGiftCards: boolean,
) => any;

export const loadCompanyCatalogAction = (
  companyId: number,
  justGiftCards: boolean,
): ILinkCompanyThunk => {
  return async (dispatch: Dispatch) => {
    try {
      let uri: string = `/api/${companyId}/Catalog/`;
      let params: IQueryParam[] = [];
      params.push({ key: "justGiftCards", value: `${justGiftCards}` });

      let result: ICoreResult = await getJSON(
        BaseURL.LINK,
        uri,
        params,
      );

      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setLinkCatalogAction(result.payload));
      }
    } catch (error) {
      dispatch(setInProgressAction(false));
    }
  };
};

export interface ILinkCompanyThunks {
  readonly loadCompany: ILoadCompanyAction;
  readonly loadBoostEvents: ILoadBoostEventsAction;
  readonly loadBoostEventsForSupplierCompany: ILoadBoostEventsForSupplierCompany;
  readonly loadGroupEvents: ILoadGroupEventsAction;
  readonly loadCompanyEvents: ILoadCompanyEventsAction;
  readonly loadEventDetails: ILoadEventDetailsAction;
  readonly loadEventDates: ILoadEventDatesAction;
  readonly loadEventTimes: ILoadEventTimesAction;
  readonly loadEventCalendar: ILoadEventCalendarAction;
  readonly loadCompanyCatalog: ILoadCompanyCatalogAction;
  readonly loadEventDetailsByName: ILoadEventDetailsByNameAction;
}

const BuildCompanyThunks = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  const CompanyThunks: ILinkCompanyThunks = {
    loadCompany: (furl: string) => dispatch(loadCompanyAction(furl)),
    loadBoostEvents: (companyId: number, latitude: number, longitude: number) =>
      dispatch(loadBoostEventsAction(companyId, latitude, longitude)),
    loadBoostEventsForSupplierCompany: (companyId: number, supplierCompanyFriendlyUrl: string) =>
      dispatch(loadBoostEventsForSupplierCompany(companyId, supplierCompanyFriendlyUrl)),
    loadCompanyEvents: (companyId: number, coords?: LocationCoords) =>
      dispatch(loadCompanyEventsAction(companyId, coords)),
    loadEventDetails: (eventId?: number) =>
      dispatch(loadEventDetailsAction(eventId)),
    loadEventDates: (eventId?: number, s?: string, e?: string) =>
      dispatch(loadEventDatesAction(eventId, s, e)),
    loadEventTimes: (eventId: number, startDate: string, postObject: any) =>
      dispatch(loadEventTimesAction(eventId, startDate, postObject)),
    loadEventCalendar: (month: string, queryParams?: IQueryParam[] | null) =>
      dispatch(loadEventCalendarAction(month, queryParams)),
    loadGroupEvents: (companyId: number, groupId: number) =>
      dispatch(loadGroupEventsAction(companyId, groupId)),
    loadCompanyCatalog: (companyId: number, justGiftCards: boolean) =>
      dispatch(loadCompanyCatalogAction(companyId, justGiftCards)),
	loadEventDetailsByName: (name?: string) =>
      dispatch(loadEventDetailsByNameAction(name)),
  };

  return CompanyThunks;
};

export default BuildCompanyThunks;

export interface ICountryInfo {
  abbreviation: string;
  name: string;
  zipRegex?: RegExp;
  states?: {
    abbreviation: string;
    name: string;
  }[]
}

let codeLookup: {[id: string]: ICountryInfo} = {};

export function getCountryInfo(abbreviation: string): ICountryInfo {
  if (!codeLookup[abbreviation] ) {
    for( var ci of countryInfo) {
      codeLookup[ci.abbreviation] = ci;
    }
  } 
  return codeLookup[abbreviation];
}

export const countryInfo : ICountryInfo[] =
[
  {
    "abbreviation": "AF",
    "name": "Afghanistan"
  },
  {
    "abbreviation": "AX",
    "name": "Aland Islands",
    "zipRegex": /22\d{3}/
  },
  {
    "abbreviation": "AL",
    "name": "Albania"
  },
  {
    "abbreviation": "DZ",
    "name": "Algeria",
    "zipRegex": /\d{5}/
  },
  {
    "abbreviation": "AS",
    "name": "American Samoa",
    "zipRegex": /96799/
  },
  {
    "abbreviation": "AD",
    "name": "Andorra",
    "zipRegex": /AD\d{3}/
  },
  {
    "abbreviation": "AO",
    "name": "Angola"
  },
  {
    "abbreviation": "AI",
    "name": "Anguilla"
  },
  {
    "abbreviation": "AQ",
    "name": "Antarctica"
  },
  {
    "abbreviation": "AG",
    "name": "Antigua and Barbuda"
  },
  {
    "abbreviation": "AR",
    "name": "Argentina",
    "zipRegex": /^\d{4}|[A-Za-z]\d{4}[a-zA-Z]{3}$/
  },
  {
    "abbreviation": "AM",
    "name": "Armenia",
    "zipRegex": /\d{4}/
  },
  {
    "abbreviation": "AW",
    "name": "Aruba"
  },
  {
    "abbreviation": "AU",
    "name": "Australia",
    "zipRegex": /\d{4}/
  },
  {
    "abbreviation": "AT",
    "name": "Austria",
    "zipRegex": /\d{4}/
  },
  {
    "abbreviation": "AZ",
    "name": "Azerbaijan",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "BS",
    "name": "Bahamas"
  },
  {
    "abbreviation": "BH",
    "name": "Bahrain",
    "zipRegex": /^\d{3,4}$/
  },
  {
    "abbreviation": "BD",
    "name": "Bangladesh",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "BB",
    "name": "Barbados",
    "zipRegex": /^[Aa][Zz]\d{5}$/
  },
  {
    "abbreviation": "BY",
    "name": "Belarus",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "BE",
    "name": "Belgium",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "BZ",
    "name": "Belize"
  },
  {
    "abbreviation": "BJ",
    "name": "Benin"
  },
  {
    "abbreviation": "BM",
    "name": "Bermuda",
    "zipRegex": /^[A-Za-z]{2}\s([A-Za-z]{2}|\d{2})$/
  },
  {
    "abbreviation": "BT",
    "name": "Bhutan"
  },
  {
    "abbreviation": "BO",
    "name": "Bolivia"
  },
  {
    "abbreviation": "BQ",
    "name": "Bonaire, Saint Eustatius and Saba "
  },
  {
    "abbreviation": "BA",
    "name": "Bosnia and Herzegovina",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "BW",
    "name": "Botswana"
  },
  {
    "abbreviation": "BV",
    "name": "Bouvet Island"
  },
  {
    "abbreviation": "BR",
    "name": "Brazil",
    "zipRegex": /^\d{5}-\d{3}$/
  },
  {
    "abbreviation": "IO",
    "name": "British Indian Ocean Territory",
    "zipRegex": /^[Bb]{2}[Nn][Dd]\s{0,1}[1][Zz]{2}$/
  },
  {
    "abbreviation": "VG",
    "name": "British Virgin Islands"
  },
  {
    "abbreviation": "BN",
    "name": "Brunei",
    "zipRegex": /^[A-Za-z]{2}\d{4}$/
  },
  {
    "abbreviation": "BG",
    "name": "Bulgaria",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "BF",
    "name": "Burkina Faso"
  },
  {
    "abbreviation": "BI",
    "name": "Burundi"
  },
  {
    "abbreviation": "KH",
    "name": "Cambodia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "CM",
    "name": "Cameroon"
  },
  {
    "abbreviation": "CA",
    "name": "Canada",
    "states": [
      {
        "abbreviation": "AB",
        "name": "Alberta"
      },
      {
        "abbreviation": "BC",
        "name": "British Columbia"
      },
      {
        "abbreviation": "MB",
        "name": "Manitoba"
      },
      {
        "abbreviation": "NB",
        "name": "New Brunswick"
      },
      {
        "abbreviation": "NL",
        "name": "Newfoundland and Labrador"
      },
      {
        "abbreviation": "NT",
        "name": "Northwest Territories"
      },
      {
        "abbreviation": "NS",
        "name": "Nova Scotia"
      },
      {
        "abbreviation": "NU",
        "name": "Nunavut"
      },
      {
        "abbreviation": "ON",
        "name": "Ontario"
      },
      {
        "abbreviation": "PE",
        "name": "Prince Edward Island"
      },
      {
        "abbreviation": "QC",
        "name": "Quebec"
      },
      {
        "abbreviation": "SK",
        "name": "Saskatchewan"
      },
      {
        "abbreviation": "YT",
        "name": "Yukon Territory"
      }
    ],
    "zipRegex": /^(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\s{0,1}\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d$/
  },
  {
    "abbreviation": "CV",
    "name": "Cape Verde",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "KY",
    "name": "Cayman Islands"
  },
  {
    "abbreviation": "CF",
    "name": "Central African Republic"
  },
  {
    "abbreviation": "TD",
    "name": "Chad"
  },
  {
    "abbreviation": "CL",
    "name": "Chile",
    "zipRegex": /\d{7}/

  },
  {
    "abbreviation": "CN",
    "name": "China",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "CX",
    "name": "Christmas Island",
    "zipRegex": /6798/
  },
  {
    "abbreviation": "CC",
    "name": "Cocos Islands",
    "zipRegex": /6799/
  },
  {
    "abbreviation": "CO",
    "name": "Colombia"
  },
  {
    "abbreviation": "KM",
    "name": "Comoros"
  },
  {
    "abbreviation": "CK",
    "name": "Cook Islands",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "CR",
    "name": "Costa Rica",
    "zipRegex": /^\d{4,5}$/
  },
  {
    "abbreviation": "HR",
    "name": "Croatia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "CU",
    "name": "Cuba"
  },
  {
    "abbreviation": "CW",
    "name": "Curacao"
  },
  {
    "abbreviation": "CY",
    "name": "Cyprus",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "CZ",
    "name": "Czech Republic",
    "zipRegex": /^\d{5}\s\(\d{3}\s\d{2}\)$/
  },
  {
    "abbreviation": "CD",
    "name": "Democratic Republic of the Congo"
  },
  {
    "abbreviation": "DK",
    "name": "Denmark",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "DJ",
    "name": "Djibouti"
  },
  {
    "abbreviation": "DM",
    "name": "Dominica"
  },
  {
    "abbreviation": "DO",
    "name": "Dominican Republic",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "TL",
    "name": "East Timor"
  },
  {
    "abbreviation": "EC",
    "name": "Ecuador",
    "zipRegex": /^\d{6}$/
  },
  {
    "abbreviation": "EG",
    "name": "Egypt",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "SV",
    "name": "El Salvador"
  },
  {
    "abbreviation": "GQ",
    "name": "Equatorial Guinea"
  },
  {
    "abbreviation": "ER",
    "name": "Eritrea"
  },
  {
    "abbreviation": "EE",
    "name": "Estonia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "ET",
    "name": "Ethiopia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "FK",
    "name": "Falkland Islands",
    "zipRegex": /FIQQ 1ZZ/
  },
  {
    "abbreviation": "FO",
    "name": "Faroe Islands",
    "zipRegex": /\d{3}/
  },
  {
    "abbreviation": "FJ",
    "name": "Fiji"
  },
  {
    "abbreviation": "FI",
    "name": "Finland",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "FR",
    "name": "France",
    "zipRegex": /\d{2}[ ]?\d{3}/
  },
  {
    "abbreviation": "GF",
    "name": "French Guiana",
    "zipRegex": /^973\d{2}$/
  },
  {
    "abbreviation": "PF",
    "name": "French Polynesia",
    "zipRegex": /^987\d{2}$/
  },
  {
    "abbreviation": "TF",
    "name": "French Southern Territories"
  },
  {
    "abbreviation": "GA",
    "name": "Gabon"
  },
  {
    "abbreviation": "GM",
    "name": "Gambia"
  },
  {
    "abbreviation": "GE",
    "name": "Georgia",
    "zipRegex": /\d{4}$/

  },
  {
    "abbreviation": "DE",
    "name": "Germany",
    "zipRegex": /\d{5}$/

  },
  {
    "abbreviation": "GH",
    "name": "Ghana"
  },
  {
    "abbreviation": "GI",
    "name": "Gibraltar"
  },
  {
    "abbreviation": "GR",
    "name": "Greece",
    "zipRegex": /\d{3}[ ]?\d{2}$/
  },
  {
    "abbreviation": "GL",
    "name": "Greenland",
    "zipRegex": /39\d{2}$/
  },
  {
    "abbreviation": "GD",
    "name": "Grenada"
  },
  {
    "abbreviation": "GP",
    "name": "Guadeloupe",
    "zipRegex": /^971\d{2}$/
  },
  {
    "abbreviation": "GU",
    "name": "Guam",
    "zipRegex": /\d{5}$/
  },
  {
    "abbreviation": "GT",
    "name": "Guatemala",
    "zipRegex": /\d{5}$/

  },
  {
    "abbreviation": "GG",
    "name": "Guernsey",
    "zipRegex": /^[A-Za-z]{2}\d\s{0,1}\d[A-Za-z]{2}$/
  },
  {
    "abbreviation": "GN",
    "name": "Guinea",
    "zipRegex": /^\d{3}$/
  },
  {
    "abbreviation": "GW",
    "name": "Guinea-Bissau",
    "zipRegex": /^\d{4}$/

  },
  {
    "abbreviation": "GY",
    "name": "Guyana"
  },
  {
    "abbreviation": "HT",
    "name": "Haiti",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "HM",
    "name": "Heard Island and McDonald Islands",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "HN",
    "name": "Honduras",
    "zipRegex": /^\d{5}$/
  },
  {
    "abbreviation": "HK",
    "name": "Hong Kong"
  },
  {
    "abbreviation": "HU",
    "name": "Hungary",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "IS",
    "name": "Iceland",
    "zipRegex": /^\d{3}$/
  },
  {
    "abbreviation": "IN",
    "name": "India",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "ID",
    "name": "Indonesia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "IR",
    "name": "Iran"
  },
  {
    "abbreviation": "IQ",
    "name": "Iraq",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "IE",
    "name": "Ireland"
  },
  {
    "abbreviation": "IM",
    "name": "Isle of Man",
    "zipRegex": /^[Ii[Mm]\d{1,2}\s\d\[A-Z]{2}$/
  },
  {
    "abbreviation": "IL",
    "name": "Israel",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "IT",
    "name": "Italy",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "CI",
    "name": "Ivory Coast"
  },
  {
    "abbreviation": "JM",
    "name": "Jamaica"
  },
  {
    "abbreviation": "JP",
    "name": "Japan",
    "zipRegex": /^\d{7}\s\(\d{3}-\d{4}\)$/
  },
  {
    "abbreviation": "JE",
    "name": "Jersey",
    "zipRegex": /^[Jj][Ee]\d\s{0,1}\d[A-Za-z]{2}$/
  },
  {
    "abbreviation": "JO",
    "name": "Jordan",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "KZ",
    "name": "Kazakhstan",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "KE",
    "name": "Kenya",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "KI",
    "name": "Kiribati"
  },
  {
    "abbreviation": "XK",
    "name": "Kosovo",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "KW",
    "name": "Kuwait",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "KG",
    "name": "Kyrgyzstan",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "LA",
    "name": "Laos",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "LV",
    "name": "Latvia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "LB",
    "name": "Lebanon",
    "zipRegex": /^\d{4}\s{0,1}\d{4}$/
  },
  {
    "abbreviation": "LS",
    "name": "Lesotho",
    "zipRegex": /^\d{3}$/
  },
  {
    "abbreviation": "LR",
    "name": "Liberia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "LY",
    "name": "Libya"
  },
  {
    "abbreviation": "LI",
    "name": "Liechtenstein",
    "zipRegex": /^\d{4}$/
  },
  {
    "abbreviation": "LT",
    "name": "Lithuania",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "LU",
    "name": "Luxembourg",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "MO",
    "name": "Macao"
  },
  {
    "abbreviation": "MK",
    "name": "Macedonia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "MG",
    "name": "Madagascar",
    "zipRegex": /^\d{3}$/
  },
  {
    "abbreviation": "MW",
    "name": "Malawi"
  },
  {
    "abbreviation": "MY",
    "name": "Malaysia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "MV",
    "name": "Maldives",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "ML",
    "name": "Mali"
  },
  {
    "abbreviation": "MT",
    "name": "Malta",
    "zipRegex": /^[A-Za-z]{3}\s{0,1}\d{4}$/
  },
  {
    "abbreviation": "MH",
    "name": "Marshall Islands",
    "zipRegex": /^969\d{2}([ -]d{4})?/
  },
  {
    "abbreviation": "MQ",
    "name": "Martinique",
    "zipRegex": /^9[78]2\d{2}$/
  },
  {
    "abbreviation": "MR",
    "name": "Mauritania"
  },
  {
    "abbreviation": "MU",
    "name": "Mauritius",
    "zipRegex": /(\d{3}[A-Za-z]{2}\d{3})|^$/
  },
  {
    "abbreviation": "YT",
    "name": "Mayotte",
    "zipRegex": /^976\d{2}$/
  },
  {
    "abbreviation": "MX",
    "name": "Mexico",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "FM",
    "name": "Micronesia",
    "zipRegex": /(9694[1-4])([ -]\d{4})?/
  },
  {
    "abbreviation": "MD",
    "name": "Moldova",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "MC",
    "name": "Monaco",
    "zipRegex": /^980\d{2}$/
  },
  {
    "abbreviation": "MN",
    "name": "Mongolia",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "ME",
    "name": "Montenegro",
    "zipRegex": /^8\d{4}$/
  },
  {
    "abbreviation": "MS",
    "name": "Montserrat"
  },
  {
    "abbreviation": "MA",
    "name": "Morocco",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "MZ",
    "name": "Mozambique"
  },
  {
    "abbreviation": "MM",
    "name": "Myanmar"
  },
  {
    "abbreviation": "NA",
    "name": "Namibia"
  },
  {
    "abbreviation": "NR",
    "name": "Nauru"
  },
  {
    "abbreviation": "NP",
    "name": "Nepal",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "NL",
    "name": "Netherlands",
    "zipRegex": /^\d{4}\s{0,1}[A-Za-z]{2}$/
  },
  {
    "abbreviation": "NC",
    "name": "New Caledonia",
    "zipRegex": /^988\d{2}$/
  },
  {
    "abbreviation": "NZ",
    "name": "New Zealand",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "NI",
    "name": "Nicaragua",
    "zipRegex": /^\d{5}$/
  },
  {
    "abbreviation": "NE",
    "name": "Niger",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "NG",
    "name": "Nigeria",
    "zipRegex": /^\d{6}$/
  },
  {
    "abbreviation": "NU",
    "name": "Niue"
  },
  {
    "abbreviation": "NF",
    "name": "Norfolk Island",
    "zipRegex": /2899/
  },
  {
    "abbreviation": "KP",
    "name": "North Korea"
  },
  {
    "abbreviation": "MP",
    "name": "Northern Mariana Islands",
    "zipRegex": /^9695[012]([ -]\d{4})?/
  },
  {
    "abbreviation": "NO",
    "name": "Norway",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "OM",
    "name": "Oman",
    "zipRegex": /(PC )?\d{3}/
  },
  {
    "abbreviation": "PK",
    "name": "Pakistan",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "PW",
    "name": "Palau",
    "zipRegex": /96940/
  },
  {
    "abbreviation": "PS",
    "name": "Palestinian Territory"
  },
  {
    "abbreviation": "PA",
    "name": "Panama"
  },
  {
    "abbreviation": "PG",
    "name": "Papua New Guinea",
    "zipRegex": /^\d{3}$/
  },
  {
    "abbreviation": "PY",
    "name": "Paraguay",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "PE",
    "name": "Peru"
  },
  {
    "abbreviation": "PH",
    "name": "Philippines",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "PN",
    "name": "Pitcairn",
    "zipRegex": /^[Pp][Cc][Rr][Nn]\s{0,1}[1][Zz]{2}$/
  },
  {
    "abbreviation": "PL",
    "name": "Poland",
    "zipRegex": /^\d{2}[- ]{0,1}\d{3}$/
  },
  {
    "abbreviation": "PT",
    "name": "Portugal",
    "zipRegex": /^\d{4}([-]\d{3})?/
  },
  {
    "abbreviation": "PR",
    "name": "Puerto Rico",
    "zipRegex": /^00[679]\d{2}([ -]?\d{4})?/
  },
  {
    "abbreviation": "QA",
    "name": "Qatar"
  },
  {
    "abbreviation": "CG",
    "name": "Republic of the Congo"
  },
  {
    "abbreviation": "RE",
    "name": "Reunion",
    "zipRegex": /^9[78]4\d{2}/
  },
  {
    "abbreviation": "RO",
    "name": "Romania",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "RU",
    "name": "Russia",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "RW",
    "name": "Rwanda"
  },
  {
    "abbreviation": "BL",
    "name": "Saint Barthelemy"
  },
  {
    "abbreviation": "SH",
    "name": "Saint Helena",
    "zipRegex": /^[Ss][Tt][Hh][Ll]\s{0,1}[1][Zz]{2}$/
  },
  {
    "abbreviation": "KN",
    "name": "Saint Kitts and Nevis"
  },
  {
    "abbreviation": "LC",
    "name": "Saint Lucia"
  },
  {
    "abbreviation": "MF",
    "name": "Saint Martin"
  },
  {
    "abbreviation": "PM",
    "name": "Saint Pierre and Miquelon",
    "zipRegex": /9[78]5\d{2}/
  },
  {
    "abbreviation": "VC",
    "name": "Saint Vincent and the Grenadines"
  },
  {
    "abbreviation": "WS",
    "name": "Samoa"
  },
  {
    "abbreviation": "SM",
    "name": "San Marino",
    "zipRegex": /^4789\d$/
  },
  {
    "abbreviation": "ST",
    "name": "Sao Tome and Principe"
  },
  {
    "abbreviation": "SA",
    "name": "Saudi Arabia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "SN",
    "name": "Senegal",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "RS",
    "name": "Serbia",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "SC",
    "name": "Seychelles"
  },
  {
    "abbreviation": "SL",
    "name": "Sierra Leone"
  },
  {
    "abbreviation": "SG",
    "name": "Singapore",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "SX",
    "name": "Sint Maarten"
  },
  {
    "abbreviation": "SK",
    "name": "Slovakia",
    "zipRegex": /^\d{3}[ ]?\d{2}$/
  },
  {
    "abbreviation": "SI",
    "name": "Slovenia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "SB",
    "name": "Solomon Islands"
  },
  {
    "abbreviation": "SO",
    "name": "Somalia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "ZA",
    "name": "South Africa",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "GS",
    "name": "South Georgia and the South Sandwich Islands",
    "zipRegex": /^[Ss][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/
  },
  {
    "abbreviation": "KR",
    "name": "South Korea",
    "zipRegex": /^\d{3}[-]\d{3}$/
  },
  {
    "abbreviation": "SS",
    "name": "South Sudan"
  },
  {
    "abbreviation": "ES",
    "name": "Spain",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "LK",
    "name": "Sri Lanka",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "SD",
    "name": "Sudan"
  },
  {
    "abbreviation": "SR",
    "name": "Suriname"
  },
  {
    "abbreviation": "SJ",
    "name": "Svalbard and Jan Mayen",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "SZ",
    "name": "Swaziland",
    "zipRegex": /^[A-Za-z]\d{3}$/
  },
  {
    "abbreviation": "SE",
    "name": "Sweden",
    "zipRegex": /^\d{3}\s*\d{2}$/
  },
  {
    "abbreviation": "CH",
    "name": "Switzerland",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "SY",
    "name": "Syria"
  },
  {
    "abbreviation": "TW",
    "name": "Taiwan",
    "zipRegex": /^\d{3}(\d{2})?/
  },
  {
    "abbreviation": "TJ",
    "name": "Tajikistan",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "TZ",
    "name": "Tanzania"
  },
  {
    "abbreviation": "TH",
    "name": "Thailand",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "TG",
    "name": "Togo"
  },
  {
    "abbreviation": "TK",
    "name": "Tokelau"
  },
  {
    "abbreviation": "TO",
    "name": "Tonga"
  },
  {
    "abbreviation": "TT",
    "name": "Trinidad and Tobago"
  },
  {
    "abbreviation": "TN",
    "name": "Tunisia",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "TR",
    "name": "Turkey",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "TM",
    "name": "Turkmenistan",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "TC",
    "name": "Turks and Caicos Islands",
    "zipRegex": /^[Tt][Kk][Cc][Aa]\s{0,1}[1][Zz]{2}$/
  },
  {
    "abbreviation": "TV",
    "name": "Tuvalu"
  },
  {
    "abbreviation": "VI",
    "name": "U.S. Virgin Islands",
    "zipRegex": /^008(([0-4]\d)|(5[01]))([ -]?\d{4})?/
  },
  {
    "abbreviation": "UG",
    "name": "Uganda"
  },
  {
    "abbreviation": "UA",
    "name": "Ukraine",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "AE",
    "name": "United Arab Emirates"
  },
  {
    "abbreviation": "GB",
    "name": "United Kingdom",
    "zipRegex": /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/i
  },
  {
    "abbreviation": "US",
    "name": "United States",
    "states": [
      {
        "abbreviation": "AL",
        "name": "Alabama"
      },
      {
        "abbreviation": "AK",
        "name": "Alaska"
      },
      {
        "abbreviation": "AS",
        "name": "American Samoa"
      },
      {
        "abbreviation": "AZ",
        "name": "Arizona"
      },
      {
        "abbreviation": "AR",
        "name": "Arkansas"
      },
      {
        "abbreviation": "CA",
        "name": "California"
      },
      {
        "abbreviation": "CO",
        "name": "Colorado"
      },
      {
        "abbreviation": "CT",
        "name": "Connecticut"
      },
      {
        "abbreviation": "DE",
        "name": "Delaware"
      },
      {
        "abbreviation": "DC",
        "name": "District Of Columbia"
      },
      {
        "abbreviation": "FM",
        "name": "Federated States Of Micronesia"
      },
      {
        "abbreviation": "FL",
        "name": "Florida"
      },
      {
        "abbreviation": "GA",
        "name": "Georgia"
      },
      {
        "abbreviation": "GU",
        "name": "Guam"
      },
      {
        "abbreviation": "HI",
        "name": "Hawaii"
      },
      {
        "abbreviation": "ID",
        "name": "Idaho"
      },
      {
        "abbreviation": "IL",
        "name": "Illinois"
      },
      {
        "abbreviation": "IN",
        "name": "Indiana"
      },
      {
        "abbreviation": "IA",
        "name": "Iowa"
      },
      {
        "abbreviation": "KS",
        "name": "Kansas"
      },
      {
        "abbreviation": "KY",
        "name": "Kentucky"
      },
      {
        "abbreviation": "LA",
        "name": "Louisiana"
      },
      {
        "abbreviation": "ME",
        "name": "Maine"
      },
      {
        "abbreviation": "MH",
        "name": "Marshall Islands"
      },
      {
        "abbreviation": "MD",
        "name": "Maryland"
      },
      {
        "abbreviation": "MA",
        "name": "Massachusetts"
      },
      {
        "abbreviation": "MI",
        "name": "Michigan"
      },
      {
        "abbreviation": "MN",
        "name": "Minnesota"
      },
      {
        "abbreviation": "MS",
        "name": "Mississippi"
      },
      {
        "abbreviation": "MO",
        "name": "Missouri"
      },
      {
        "abbreviation": "MT",
        "name": "Montana"
      },
      {
        "abbreviation": "NE",
        "name": "Nebraska"
      },
      {
        "abbreviation": "NV",
        "name": "Nevada"
      },
      {
        "abbreviation": "NH",
        "name": "New Hampshire"
      },
      {
        "abbreviation": "NJ",
        "name": "New Jersey"
      },
      {
        "abbreviation": "NM",
        "name": "New Mexico"
      },
      {
        "abbreviation": "NY",
        "name": "New York"
      },
      {
        "abbreviation": "NC",
        "name": "North Carolina"
      },
      {
        "abbreviation": "ND",
        "name": "North Dakota"
      },
      {
        "abbreviation": "MP",
        "name": "Northern Mariana Islands"
      },
      {
        "abbreviation": "OH",
        "name": "Ohio"
      },
      {
        "abbreviation": "OK",
        "name": "Oklahoma"
      },
      {
        "abbreviation": "OR",
        "name": "Oregon"
      },
      {
        "abbreviation": "PW",
        "name": "Palau"
      },
      {
        "abbreviation": "PA",
        "name": "Pennsylvania"
      },
      {
        "abbreviation": "PR",
        "name": "Puerto Rico"
      },
      {
        "abbreviation": "RI",
        "name": "Rhode Island"
      },
      {
        "abbreviation": "SC",
        "name": "South Carolina"
      },
      {
        "abbreviation": "SD",
        "name": "South Dakota"
      },
      {
        "abbreviation": "TN",
        "name": "Tennessee"
      },
      {
        "abbreviation": "TX",
        "name": "Texas"
      },
      {
        "abbreviation": "UT",
        "name": "Utah"
      },
      {
        "abbreviation": "VT",
        "name": "Vermont"
      },
      {
        "abbreviation": "VI",
        "name": "Virgin Islands"
      },
      {
        "abbreviation": "VA",
        "name": "Virginia"
      },
      {
        "abbreviation": "WA",
        "name": "Washington"
      },
      {
        "abbreviation": "WV",
        "name": "West Virginia"
      },
      {
        "abbreviation": "WI",
        "name": "Wisconsin"
      },
      {
        "abbreviation": "WY",
        "name": "Wyoming"
      }
    ],
    "zipRegex": /^\d{5}([-]|\s*)?(\d{4})?$/
  },
  {
    "abbreviation": "UM",
    "name": "United States Minor Outlying Islands"
  },
  {
    "abbreviation": "UY",
    "name": "Uruguay",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "UZ",
    "name": "Uzbekistan",
    "zipRegex": /\d{6}/

  },
  {
    "abbreviation": "VU",
    "name": "Vanuatu"
  },
  {
    "abbreviation": "VA",
    "name": "Vatican",
    "zipRegex": /00120/
  },
  {
    "abbreviation": "VE",
    "name": "Venezuela",
    "zipRegex": /\d{4}/

  },
  {
    "abbreviation": "VN",
    "name": "Vietnam"
  },
  {
    "abbreviation": "WF",
    "name": "Wallis and Futuna",
    "zipRegex": /^986\d{2}/
  },
  {
    "abbreviation": "EH",
    "name": "Western Sahara"
  },
  {
    "abbreviation": "YE",
    "name": "Yemen"
  },
  {
    "abbreviation": "ZM",
    "name": "Zambia",
    "zipRegex": /\d{5}/

  },
  {
    "abbreviation": "ZW",
    "name": "Zimbabwe"
  }
];

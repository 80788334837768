import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { EventCard } from "modules/Events";
import { IApplicationStore } from "AppState";
import { VwEventDisplay } from "ares-core/Models";
import { LinkCompany } from "ares-core/LinkCompany";
import { ILinkCompany } from "ares-core/Models";
import {LocationCoords} from "../../../ares-core/LinkCompany/redux-io/Thunks";

interface EventListProps {
  company: ILinkCompany;
  eventList: VwEventDisplay[];
  busy: boolean;
  isBoost: boolean;
  groupId: string | null;
  boostSupplierCompany: string | null;
}



export const EventListComponent = (props: EventListProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const getCoords = (): LocationCoords | null => {
      const {lat: latitude,lon: longitude, distance: dist} = props?.company;
      const lat = Number(latitude);
      const lon = Number(longitude);
      const distance = Number(dist);
      const isCoordsValid = !isNaN(lat) && !isNaN(lon) && !isNaN(distance);
      return isCoordsValid ? ({lat,lon, distance}) : null;
  }

  const locationCoordinates: LocationCoords | null = getCoords();

	useEffect(() => {
		if (props.groupId != null) {
			LinkCompany.Api.loadGroupEvents(props.company.companyID, parseInt(props.groupId));
		} 
    else if(props.isBoost && props.company.resellOtherEvents && props.boostSupplierCompany) {
           LinkCompany.Api.loadBoostEventsForSupplierCompany(props.company.companyID, props.boostSupplierCompany)
    }
    else if(props.isBoost && props.company.resellOtherEvents && locationCoordinates) {
      LinkCompany.Api.loadCompanyEvents(props.company.companyID, locationCoordinates);
    }
    else {
      LinkCompany.Api.loadCompanyEvents(props.company.companyID);
    }
	},[]);

  if (props.busy) {
    return <LinearProgress />;
  }

  return (
    <Grid container>
      {props.eventList.length ? (<>
        {props.eventList.map((event: VwEventDisplay, idx) => (
          <EventCard
            key={`${event.eventId}-${idx}`}
            {...event}
          />
        ))}
        {props.eventList.length % 2 === 0 && !smallScreen ? <EventCard
          key={`${props.eventList[0].eventId}`}
          {...props.eventList[0]}
        /> : null}
      </>) : (
          <NoEventsCard />
        )}
    </Grid>
  );
};

const mapStateToProps = (state: IApplicationStore) => {
  return {
		company: state.company,
    eventList: state.company.events,
    busy: state.company.busy,
  };
};

export default connect(mapStateToProps)(EventListComponent);







const NoEventsCard = () => {
  return (
    <Card style={{ width: "99%", height: "25rem", textAlign: "center" }}>
      <CardContent>
        <Typography variant="h3">No events are currently available</Typography>
      </CardContent>
    </Card>
  );
};


import {GeneralPortalService} from "./GeneralPortalService";
import { ICoreResult } from "../Types";
import {IRefundDTO, IRefundResult, ReservationEditData} from "../Models";
import {RefundTransaction} from "../Models/TransactionsModel";

type refundPayload = ReservationEditData | { data:IRefundResult };

export class RefundService extends GeneralPortalService {
    constructor() {
        super('/portal/transaction');
    }
    async refundReservation(companyId: number, transactionItemId: number, amount: number,refundDTO: IRefundDTO): Promise<ICoreResult<refundPayload>> {
        let uri = this.createUrl(['RefundReservation',transactionItemId.toString(),amount.toString()]);
        return await this.putRequest<IRefundDTO,refundPayload>(uri,companyId.toString(),refundDTO);
    }
    async refundTransaction(companyId: number,refundDTO: RefundTransaction): Promise<ICoreResult<refundPayload>> {
        let uri = this.createUrl(['RefundTransaction',refundDTO.transactionId.toString()]);
        return await this.putRequest<RefundTransaction,refundPayload>(uri,companyId.toString(),refundDTO);
    }

}
const service = new RefundService();
export default service;

import {LinkEventDetail} from "../../Models";
import {isBefore, isValid} from "date-fns";
import { isEmpty } from "../../Utils";

export const isLinkEventAvailable = (event: LinkEventDetail):boolean => {
    // Inactive events doesn't render
    if(!event.active) {
        return false;
    }

    const today = new Date();
    const nextTimeDate = new Date(event.nextTime);
    // Invalid nextTimeDate means that there's no event date or the event doesn't exist
    if(isValid(nextTimeDate)) {
        // True if the nextTimeDate is in the future.
        // On link we can't book a reservation in the past
        // isBefore: Is the first date before the second one?
        return isBefore(today,nextTimeDate) && !isEmpty(event.rates);
    }

    return true;
}

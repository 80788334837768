import {GeneralLinkService} from "../GeneralLinkService";
import {EventDateTimeItem, EventTicketRequest, LinkEventDetail, VwEventDisplay} from "../../Models";
import {IQueryParam} from "../../IO";
import {ICoreResult} from "../../Types";
import {DateTimeUtils} from "../../Utils";
import {formatISO} from "date-fns";

export interface CalendarDate
{
    day: string;
    minTime: string;
    timeCount: number;
}
class LinkEventService extends GeneralLinkService {
    constructor() {
        super('/api');
    }

    getEventDetails(companyId: number,id: number) {
        const uri = this.createUrl([companyId.toString(),'Events',id.toString()]);
        return this.getRequest<LinkEventDetail>(uri);
    }

    getEventDates(companyId:number,eventId:number,startDate: Date, endDate: Date) {
        const uri = this.createUrl([companyId.toString(),'Events',eventId.toString(),'Dates']);
        const params: IQueryParam[] = [];
        params.push({ key: "startDate", value: DateTimeUtils.toYYYYMMDD(startDate) });
        params.push({ key: "endDate", value: DateTimeUtils.toYYYYMMDD(endDate) });
        return this.getRequest<CalendarDate[]>(uri,params);
    }

    getEventTimes(companyId:number,eventId: number, date: Date, tickets: EventTicketRequest[]) {
        let requestString = "";
        for (let ticket of tickets) {
            if (requestString.length > 0) {
                requestString += ",";
            }
            requestString += `${ticket.rateId}!${ticket.count}`;
        }
        const queryDate = formatISO(date, {representation: 'date'});
        const uri = this.createUrl([companyId.toString(),'Events',eventId.toString(),'DateTimes',queryDate]);
        const params: IQueryParam[] = [{ key: "clientTicketList", value: requestString }];
        return this.getRequest<EventDateTimeItem[]>(uri,params);
    }

    getEventsForBoostSupplier(companyId:number, supplierCompanyFriendlyUrl:string) {
        const uri: string = `/api/${companyId}/Events/boostSupplier/${supplierCompanyFriendlyUrl}`;
        const params: IQueryParam[] = [];
        return this.getRequest<VwEventDisplay[]>(uri, params);
    }
}

const service = new LinkEventService();
export default service;

import { ICartDTO } from "../Models";
import { isInIframe } from "../Utils";

export function addToCart(itemDescription: string, itemPrice: number) {
    if (isInIframe()) {
        window.parent.postMessage({ type: "tracking", "event": "add_to_cart", item: itemDescription, price: itemPrice }, "*")
    }
}

export function purchase(cart: ICartDTO, transactionId: number) {
    if (isInIframe()) {
        const cartItems = [];
        if (cart.reservations) {
            for (let i = 0; i < cart.reservations.length; i++) {
                cartItems.push({
                    id: "Res" + cart.reservations[i].reservationId,
                    name: cart.reservations[i].description!.split("\n")[0],
                    price: cart.reservations[i].price,
                    quantity: 1
                });
                if (cart.reservations[i].addOns) {
                    cart.reservations[i].addOns.forEach(item => cartItems.push({
                        id: "Cat" + item.catalogId,
                        name: item.description,
                        quantity: item.quantity,
                        price: item.price
                    }));
                }
            }
            if (cart.catalogItems) {
                cart.catalogItems.forEach(item => cartItems.push({
                    id: "Cat" + item.catalogId,
                    name: item.description,
                    quantity: item.quantity,
                    price: item.price
                }));
            }
            const trackingMsg = {
                "transaction_id": transactionId,
                "affiliation": "Indexic aReservation",
                "value": cart.subTotal,
                "currency": "USD",
                "tax": cart.tax,
                "items": cartItems
            };
            window.parent.postMessage({ type: "tracking", "event": "purchase", details: trackingMsg }, "*")
        }
    }
}

export function selectEvent(eventName: string) {
    if (isInIframe()) {
        window.parent.postMessage({ type: "tracking", "event": "view_event", name: eventName }, "*")
    }
}

import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails } from '@material-ui/core'
import { ExpandMore, AccountCircle } from '@material-ui/icons'
import React from 'react'

interface Props{
    expanded : boolean
    panelName : string
    handlePanelChange : CallableFunction
    panelTitle : string
    children : React.ReactNode
}
const PanelComponent = (props : Props) => {
    const AccordionProps = {
        expanded: props.expanded,
        onChange: props.handlePanelChange(props.panelName),
    };
    return (
        <Accordion {...AccordionProps}>
            <AccordionSummary
                expandIcon={< ExpandMore />}>
                <Grid container>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item>
                            <AccountCircle/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">{props.panelTitle}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default PanelComponent
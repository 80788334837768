import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {Box, Paper} from "@material-ui/core";
import { IApplicationStore } from "AppState";
import { ButtonBottonWrapper } from "modules/Common/GoToBottomButton";
import { Shopping } from "ares-core/Shopping";
import { Locale } from "ares-core/UI/Locale";
import { ICartDTO } from "ares-core/Models";
import CartDisplay from "modules/Cart/components/CartDisplay";
import { GiftCardInput } from "./components/GiftCardsDisplay";
import { GiftCardList } from "ares-core/UI/GiftCard/GiftCardInformation";

export interface ICartDisplay extends ICartDTO {
  clearCart: CallableFunction;
  catalogItemChangeQuantity: CallableFunction;
  catalogItemRemoveFromCart: CallableFunction;
  reservationItemRemove: CallableFunction;
  reservationItemAddOnRemove: CallableFunction;
  reservationItemAddOnChangeQuantity: CallableFunction;
  giftCardApply: CallableFunction;
  giftCardRemove: CallableFunction;
}

export const DisplayCart = (props: any) => {
  const history = useHistory();


  const errorsDisplayed = () => {
    Shopping.Actions.SetCartErrors([])
  }

  const ShoppingCartDisplayProps = {
    clearCart: (cartId: number) => {
      Shopping.Clear();
    },
    catalogItemChangeQuantity: (catalogId: number, quantity: number) => {
      console.warn("Call store and update quantity for catalog item.");
    },
    catalogItemRemoveFromCart: (catalogId: number) => {
      console.warn("Call store catalog item remove from cart.");
      Shopping.Api.removeCatalogItem({ catalogId: catalogId, quantity: 1 });
    },
    reservationItemRemove: (reservationId: number) => {
      Shopping.Api.removeReservation(reservationId);
    },
    reservationItemAddOnRemove: (reservationId: number, catalogId: number) => {
      console.warn(`reservation remove addons`);
    },
    reservationItemAddOnChangeQuantity: (
      reservationId: number,
      catalogId: number,
      quantity: number,
    ) => {
      console.warn(`reseravtion addon change quantity`);
    },
    giftCardApply: (code: string) => {
      console.warn(`apply gift card with code ${code}`);
      Shopping.Api.applyCoupon(code);
    },
    giftCardRemove: (code: string) => {
      console.warn(`remove gift card with code ${code}`);
    },
  };

  const links = [
    {
      label: Locale.makeAnotherReservation,
      enable: true,
      place: (props.boosting ? `/boost/${props.companyFriendlyUrl}` : `/event/${props.companyFriendlyUrl}`),
    },
    {
      label: Locale.checkout,
      enable: !Shopping.IsEmpty(),
      place: () => {
        history.push(`/checkout/${props.companyFriendlyUrl}`);
      },
    },
  ];

  return (
    <>
    <Paper elevation={4} border-radius={20}>
      <Box padding={2}>
      <ButtonBottonWrapper links={links}>
        <CartDisplay cartDto={props} errorsDisplayed={errorsDisplayed}/>      
        <GiftCardInput applyGiftCard={ShoppingCartDisplayProps.giftCardApply} />
        {
          props.catalogItems && props.catalogItems.filter((x : any) => x.giftCards !== null).length > 0 ?
            <GiftCardList catalogItems={props.catalogItems.filter((x : any) => x.giftCards !== null)} />
          : null
        }
      </ButtonBottonWrapper>
      </Box>
    </Paper>

    </> 
  );
};

const mapStateToProps = ({ shopping, company }: IApplicationStore) => ({
  ...shopping.cart,
	boosting: company.boosting,
  companyFriendlyUrl: shopping.companyId,
});

export default connect(mapStateToProps)(DisplayCart);

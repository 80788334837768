import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { RateSelectionItem } from "modules/EventReservation";
import { TicketValues } from "ares-core/Models/link";
import { Rate, ReservationDetail } from "ares-core/Models";
import { DateTimeUtils } from "ares-core/Utils";
import { parseISO } from "date-fns";
import { RatesDeterminesDuration, GetRatesByRules, DisplayableRates } from "ares-core/Business/Reservation/Rates";
import { Locale } from "ares-core";

export interface RateSelectionProps {
  readonly rates: Rate[];
  readonly targetDate: string;
  setTickets: CallableFunction;
  readonly currentTickets?: Partial<ReservationDetail>[];
  eventDuration : number;
  disabled?:boolean;
}
interface DisplayableRates {
  displayable_rates : Rate[] ,
  availableRate : boolean
}
const RateSortFunction = (A: Rate, B: Rate) => A.displayOrder - B.displayOrder;

function TicketValuesToEleavatedTicket(tickets: TicketValues) {
  const returnable =
    Object.values(tickets).filter((v, i, A) => (v.count || 0) > 0) || [];
  return returnable;
}
function TicketValueMinimumGenerator(rates: Rate[]) {
  return rates
    .filter((r) => r.minReservation)
    .reduce(
      (a, r) => ({
        ...a,
        [`${r.rateId}`]: { count: r.minReservation, rateId: r.rateId },
      }),
      {},
    );
}

function CurrentTicketsToTicketValues(tickets: Partial<ReservationDetail>[]) {
  return (tickets.reduce(
    (a, t) => ({
      ...a,
      [`${t.rateId}`]: { rateId: t.rateId, count: t.tickets },
    }),
    {},
  ) || {}) as TicketValues;
}

function RateSelectionGetEffectiveRateSingle(rate: Rate, date: string) {
  const effective_rate = rate.rateEffectives.filter((eff) =>
    DateTimeUtils.inRangeStr(date, eff.effectiveFrom, eff.effectiveTo),
  );
  return effective_rate[0];
}
function RateSelectionGetEffectiveRatesArray(rates: Rate[], date: string) {
  if(rates.length === 0){
    return []
  }
  return rates.reduce(
    (a: { [key: string]: number }, rate: Rate) => ({
      ...a,
      [rate.rateId]: RateSelectionGetEffectiveRateSingle(rate, date),
    }),
    {},
  );
}

function RateSelectionGetDisplayable(rates: Rate[], tickets: TicketValues, eventDuration : number) {
  let availableRate : boolean = true //this variable will be overridden when the rate salected do not exists in the current selected date
  if (rates.length === 0){
    return{
      displayable_rates : [],
      availableRate : !availableRate
    }
  } 
  const ticketsRatesCount = Object.keys(tickets).length;
  let returnable: Rate[] = rates;
  if (ticketsRatesCount > 0) {
    const firstTicket: number = parseInt(Object.keys(tickets)[0]);
    const firstTicketRates = rates.filter(
      (rate) => rate.rateId === firstTicket,
    )[0];
    if(firstTicketRates){

      returnable = GetRatesByRules(rates, firstTicketRates);
    }else{
      //this variable it will prevent and error by having selected a rate that doesn't exists in the current date 
      availableRate = false
    }
  }else{
    //this case is when there is no rate selected by the user or default
    //DisplayableRates will determine which rates have to be displayed
    returnable = DisplayableRates(rates)
  } 
  return {
    displayable_rates : RatesDeterminesDuration( eventDuration , returnable),
    availableRate : availableRate
  } 
}

/**
 *
 * @param props.rates array of event rates
 * @param props.targetDate string value of selected event date, format
 * 'YYYY-MM-DD'
 *
 */
export const RateSelectionComponent = (props: RateSelectionProps) => {
  const [ticketValues, setTicketValues] = useState<TicketValues>({});
  const [ratesToDisplay, setRatesToDisplay] = useState<Rate[]>([]);
  const [effectiveRates, setEffectiveRates] = useState<any>({});

  useEffect(() => {
      if(props.rates.length > 0){
        const effRates = RateSelectionGetEffectiveRatesArray(
        props.rates,
        props.targetDate,
      );
      setEffectiveRates(effRates);
      const { availableRate, displayable_rates } : DisplayableRates = RateSelectionGetDisplayable(props.rates, ticketValues, props.eventDuration)
      if(!availableRate && ticketValues){
        // if availableRate is false the tickets will be removed because they don't belong to this available rates
        setTicketValues({})
        props.setTickets([])
      }
      setRatesToDisplay(displayable_rates);
    }
  }, [props.rates, props.targetDate, props.setTickets, ticketValues]);

  useEffect(() => {
    if ((props.currentTickets || []).length === 0) {
      const minimum = TicketValueMinimumGenerator(props.rates);
      handleSetTickets(minimum, !!minimum);
    } else {
      const setable = CurrentTicketsToTicketValues(props.currentTickets || []);
      handleSetTickets(setable, false);
    }
  }, [props.rates, props.currentTickets]);

  const handleRateSelectionChange = (rateId: number) => (value: any) => {
    value = Number(value);
    const selectedRate = props.rates.filter((i) => i.rateId === rateId)[0];
    const selectedTicketDuration = selectedRate.durationInSeconds;
    const selectedTicketMax = selectedRate.maxTickets;
    const selectedTicketMin = selectedRate.minReservation;
    const setable: TicketValues = { ...ticketValues };
    if (value && value > 0) {
      setable[rateId] = {
        ...ticketValues[rateId],
        rateId: Number(rateId),
        count: Number(value),
      };
    } else {
      // Special Cases to keep rate selected
      if (selectedRate.maxTickets === 1 && Object.keys(setable).length > 1) {
        // With Multi Duration rates and associated rates we cannot allow deselect
        // of the "primary" rate of the duration when quantities exist in its
        // associated rates (Pontoon Tubes). Selecting another "primary" duration rate
        // is ok. Display order is used to identify the "primary" Rate in a duration
        // group so if there is a lower display order in the group then the selected
        // rate is not the primary and therefore can be unselected (Parasailing Observer).
        var displayedRates = Object.keys(setable);
        var durationRates = props.rates.filter(
          (i) => i.durationInSeconds === selectedRate.durationInSeconds,
        );
        // find all displayed rates with lower display order
        var primary = displayedRates.find((rId: string) => {
          var realRateId = parseInt(rId);
          var siblingRate = durationRates.find(
            (dr: Rate) => dr.rateId === realRateId,
          );
          if (siblingRate!.displayOrder < selectedRate.displayOrder) {
            return siblingRate;
          }
          return null;
        });

        if (primary) {
          // The current rate is not the Primary Rate
          delete setable[rateId];
        }
      } else {
        delete setable[rateId];
      }
    }
    Object.keys(setable).map((rId: string) => {
      if (parseInt(rId) === rateId) return;
      const currentRate = props.rates.filter(
        (i) => i.rateId == parseInt(rId),
      )[0];
      const { maxTickets, minReservation, durationInSeconds } = currentRate;
      if (
        durationInSeconds !== selectedTicketDuration ||
        (maxTickets === 1 &&
          minReservation === 0 &&
          selectedTicketMax === 1 &&
          selectedTicketMin === 0)
      )
        delete setable[rId];
    });

    handleSetTickets(setable, true);
  };

  const handleSetTickets = (tickets: TicketValues, propagate: boolean) => {
    setTicketValues(tickets);
    if (props.setTickets && propagate) {
      const elevatedTickets = TicketValuesToEleavatedTicket(tickets);
      props.setTickets(elevatedTickets);
    }
  };
  const allItemsProps = {};
  return (
    <form autoComplete="off">
      <Grid container>
        <Grid item xs={6}>
          <Typography>Quantity</Typography>
        </Grid>
        <Grid item xs={6} style={{display: 'flex', justifyContent : "flex-end"}}>
          <Typography>{Locale.ratesFrom}</Typography>
        </Grid>
      </Grid>
      {ratesToDisplay.length === 0 && <Typography>No Rates</Typography>}
      {ratesToDisplay
        .sort(RateSortFunction)
        .map((rate: Rate) => ({
          rate: rate,
          value: ticketValues[rate.rateId],
          effectiveRate: effectiveRates[rate.rateId],
          handleChange: handleRateSelectionChange(rate.rateId),
        }))
        .map((itemProps: any, i: number, A: Array<any>) => (
          <RateSelectionItem key={i} {...itemProps} {...allItemsProps} disabled={props.disabled} />
        ))}
    </form>
  );
};

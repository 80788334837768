import React, { FunctionComponent } from "react";
import {useHistory} from 'react-router-dom';

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Button } from '@material-ui/core'

const useStyle = makeStyles((theme:Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: "98%",
		justifyContent: "center"
  },
  buttonSection: {
    padding: `${theme.spacing(1)} auto`,
    width: "90%",
    display: 'flex',
    flexDirection: "row",
    justifyContent: "stretch",
    alignContent: "stretch",
    [theme.breakpoints.down('xs')]:{
      flexDirection: "column",
    }
  },
  button: {
    margin: theme.spacing(1),
    width: "95%",
    maxWidth: "80vh",
    padding: `${theme.spacing(1)} auto`,
  }
}))
export interface INavButton {
  place: string | CallableFunction;
  label: string;
  enable: Boolean;
}

export type IButtonWrapper = {
  links: Array<INavButton>
}

export const NavButton: FunctionComponent<INavButton> = (props) => {
  const history = useHistory();
  const classes = useStyle();
  const handleClick = () => {
    if (typeof props.place === 'string') {
      history.push(props.place)
    } else {
      props.place();
    }
  }
  return (
		<Grid item lg={6} xs={12}>
			<Button 
				className={classes.button}
				variant="contained" 
				color="primary" 
				onClick={handleClick} 
				disabled={!props.enable}>{props.label}
			</Button>
		</Grid>
  )
}

export const ButtonBottonWrapper: FunctionComponent<IButtonWrapper> = (props) => {
  const classes = useStyle();
  return (
		<>
		<Grid container>
			<Grid item xs={12}>
				{props.children}
			</Grid>
		</Grid>
		<Grid container xs={12} justifyContent="center">
			{props.links.map((link, i, A) => <NavButton {...link} key={i} />)}
		</Grid>
		</>
  )

}

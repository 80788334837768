import React, { useState, MouseEvent, useEffect } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Divider,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ExpandMore, ContactSupport } from "@material-ui/icons";
import {
  Questions,
  QuestionBase,
  QuestionAnswer,
  QuestionsState,
  RequiredFieldsEnum,
} from "ares-core/UI";
import {
  ReservationDetail,
  RateQuestionGroup,
  IContactInfo,
} from "ares-core/Models";
import { TicketPerson } from "ares-core/Models/Reservation";
import RequiredFields from "link-core/UI/Reservations/RequiredFields";
import { TicketRate, useTicketState } from "../hooks/useTicketState";

const useStyles = makeStyles((theme: Theme) => ({
  rateQuestion: {
    marginBottom: theme.spacing(2),
  },
}));

interface TicketQuestionProps {
  handleUpdateTicket: (ticket: TicketRate) => void;
  ticketRate: TicketRate;
  showError: boolean;
}

const TicketQuestion = (props: TicketQuestionProps) => {
  const classes = useStyles();

  const { ticketRate, handleUpdateTicket } = props;
  const { ticket, answers, questions, requiredFields } = ticketRate;

  const handleTicketAnswer = (ticket: TicketPerson) => {
    // We update ticket prop
    handleUpdateTicket({ ...ticketRate, ticket });
  };
  const handleAnswers = (result: QuestionsState) => {
    handleUpdateTicket({
      ...ticketRate,
      answers: result.questionsWithAnswers,
    });
  };
  if (ticket.ticketPersonId === null || ticket.ticketPersonId === undefined)
    return null;
  return (
    <Grid item xs={12}>
      <Typography>
        {ticketRate.description} # {-1 * ticket.ticketPersonId}
      </Typography>
      <RequiredFields
        showError={props.showError}
        requiredFields={requiredFields}
        handleAnswers={handleTicketAnswer}
        ticketPerson={ticket}
      />
      <Divider variant="middle" className={classes.rateQuestion} />
      <Questions
        questions={questions}
        answers={answers}
        onAnswerChange={handleAnswers}
        showError={props.showError}
      />
    </Grid>
  );
};
export interface QuestionsPanelProps {
  expanded: boolean;
  nextStepLabel: string;
  handlePanelChange: CallableFunction;
  handleContinue: (
    arg: {
      reservationDetails: Partial<ReservationDetail>[];
      eventAnswers: QuestionAnswer[];
    },
    currentName: string,
  ) => void;
  eventQuestions: QuestionBase[];
  ticketEdits?: Partial<ReservationDetail>[];
  ticketPerson?: TicketPerson[];
  ticketRequiredFields: number;
  rateQuestionGroups: RateQuestionGroup[];
  defaultContact: IContactInfo;
}

export const QuestionsPanel = (props: QuestionsPanelProps) => {
  const panelName = "question";
  // Event answers
  const [answers, setAnswers] = useState<QuestionAnswer[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const { tickets, updateTicket, getRates, validateTickets } = useTicketState(
    props.ticketRequiredFields,
    props.ticketEdits,
    props.rateQuestionGroups,
  );

  useEffect(() => {
    if (
      props.defaultContact.firstName &&
      props.defaultContact.lastName &&
      props.defaultContact.phone &&
      tickets.length > 0
    ) {
      const firstTicketRate: TicketRate = tickets[0];
      const ticketObj: TicketPerson = { ...firstTicketRate.ticket };

      if (RequiredFieldsEnum.Phone & props.ticketRequiredFields) {
        ticketObj.participantPhone = props.defaultContact.phone;
      }

      if (RequiredFieldsEnum.Name & props.ticketRequiredFields) {
        ticketObj.participantFirstName = props.defaultContact.firstName;
        ticketObj.participantLastName = props.defaultContact.lastName;
      }

      updateTicket({ ...firstTicketRate, ticket: ticketObj });
    }
  }, [props.defaultContact]);

  const handleEventAnswerChange = (qa: QuestionsState) => {
    setAnswers(qa.questionsWithAnswers);
  };

  const handleTicketUpdate = (ticketRate: TicketRate) => {
    updateTicket(ticketRate);
  };
  const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value: currPanel } = e.currentTarget;
    const eventValid = validateEventQuestions(props.eventQuestions, answers);
    if (!eventValid) {
      setShowError(true);
      return;
    }

    const ticketsValid = validateTickets();
    if (!ticketsValid) {
      setShowError(true);
      return;
    }

    const ticketEdits: Partial<ReservationDetail>[] = getRates();
    const data = {
      reservationDetails: ticketEdits,
      eventAnswers: answers,
    };
    props.handleContinue(data, currPanel);
  };

  const AccordionProps = {
    expanded: props.expanded,
    onChange: props.handlePanelChange(panelName),
  };

  return (
    <Accordion {...AccordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="questions-panel-content"
        id="questions-panel-header"
      >
        <Grid container>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <ContactSupport />
            </Grid>
            <Grid item>
              <Typography variant="h6">Questions</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Questions
              questions={props.eventQuestions}
              answers={answers}
              onAnswerChange={handleEventAnswerChange}
              showError={showError}
            />
          </Grid>
          {tickets.map((ticket: TicketRate, idx: number) => {
            return (
              <TicketQuestion
                ticketRate={ticket}
                handleUpdateTicket={handleTicketUpdate}
                showError={showError}
                key={`${idx}`}
              />
            );
          })}
          <Grid item container xs={12} justify="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              value="questions"
              disabled={false}
              onClick={onContinue}
            >
              {props.nextStepLabel}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionsPanel;

/**
 *  Validate the array of event questions answers
 * @param questions - Array of questions
 * @param answers - Answers of question events
 * @return - True for valid, false for invalid
 */
const validateEventQuestions = (
  questions: QuestionBase[],
  answers: QuestionAnswer[],
): boolean => {
  if (!questions || questions?.length === 0) {
    return true; // Valid when there's no event questions
  }
  if (questions?.length !== answers?.length) {
    return false;
  }
  return answers.every((question) => !question.invalid);
};

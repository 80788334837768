import React, { useEffect, useMemo, useRef, useState } from 'react'
//@ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { Rate, ReservationDetail, SelectedSeats } from 'ares-core/Models'
import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails, Button } from '@material-ui/core'
import { ExpandMore, AccountCircle } from '@material-ui/icons'
import { Locale } from 'ares-core'
/**
 * @description this component will render a chart for picking seats by selected rates
 * @property workspaceKey string value from seats.io account
 * @property chartkey string this represents wich chart of the workspace will be rendered
 * @property event string this represents the event where you will book or see the booked seats
 * @property availableCategories array of numbers, this is needed when we want to only make available some categories and not all
 * @property maxSelectedObjects array of an specific object where we tell to seats.io how many tickets will allow Per category
 */
/**
 * dev values
 * workspaceKey=""
    chartKey=""
    event="1071026"
    availableCategories={[1107,1108]}
            maxSelectedObjects={[
            {
                category: 1107,
                quantity: 2
            },
            {
                category: 1108,
                quantity: 1
            }
        ]}
 */
interface Props {
    workspaceKey: string
    chartKey: string
    event: string
    tickets: Partial<ReservationDetail>[]
    rates: Rate[]
    actionButton: boolean
    getSeats: (selectedSeats: SelectedSeats[]) => void
    clearSeatsSelection: boolean
}
const groupSameCategories = (maxTickets: { category: number, quantity: number }[]) => {
    const summarized = new Map();
    maxTickets.forEach((obj) => {
        let qty = obj.quantity;
        if (summarized.has(obj.category)) {
            const temp = summarized.get(obj.category) ?? 0;
            qty += temp;
        }
        summarized.set(obj.category, qty);
    });
    var newCategories: { category: number, quantity: number }[] = []
    summarized.forEach((value, key) => {
        newCategories.push({ category: key, quantity: value })
    })

    return newCategories
}
export const buildAvailableCategories = (rates: Rate[], tickets: Partial<ReservationDetail>[]) => {
    let maxTicketsPerCategory = tickets.filter(x => rates.find(y => y.rateId === x.rateId)).map(ticket => {
        return {
            category: rates.find(x => x.rateId === ticket!.rateId)!.seatAssingmentCategoryId!,
            quantity: ticket!.tickets!
        }
    })

    return {
        maxSelectedObjects: groupSameCategories(maxTicketsPerCategory),
        availableCategories: [...new Set(maxTicketsPerCategory.map(item => item.category))]
    }
}

let chart: any = null;
const SeatPicker = (props: Props) => {
    const [selectedSeats, setSeats] = useState<SelectedSeats[]>([])
    const [selectedSeat, setSeat] = useState<SelectedSeats | null>(null)
    const [desSelectedSeat, desSelectSeat] = useState<SelectedSeats | null>(null)

    const { maxSelectedObjects, availableCategories } = useMemo(() => {
        const internalTickets = [...props.tickets]
        return buildAvailableCategories(props.rates, internalTickets)
    }, [props.rates, props.tickets])

    const disabled = useMemo(() => {
        if (maxSelectedObjects.length > 0) {
            const maxSeats = maxSelectedObjects.map(item => item.quantity).reduce((prev, current) => prev! + current!)
            const seats = selectedSeats.length === 0 ? 0 : selectedSeats.map(item => item.seats.length).reduce((prev, current) => prev! + current!)
            return maxSeats !== seats
        }
        return false
    }, [maxSelectedObjects, selectedSeats])

    useEffect(() => {
        const fixSeats = async () => {
            let assignedSeats: SelectedSeats[] = []
            try {
                const selectedObjects = await chart.listSelectedObjects()
                selectedObjects.forEach((item: any) => {
                    const indx = assignedSeats.findIndex(x => x.category === item.category.key)
                    if (indx === -1) {
                        assignedSeats.push({ category: item.category.key, seats: [item.label] })
                    } else {
                        assignedSeats[indx].seats = assignedSeats[indx].seats.concat(item.label)
                    }
                })
                setSeats(assignedSeats)
            } catch (error) {

            }
        }
        fixSeats()
    }, [props.tickets])

    useEffect(() => {
        if (selectedSeat !== null) {
            addSeats({ ...selectedSeat })
        }
    }, [selectedSeat])

    useEffect(() => {
        if (desSelectedSeat !== null)
            setSeats(selectedSeats.map(item => {
                if (item.category === desSelectedSeat.category) {
                    return { ...item, seats: item.seats.filter(x => x !== desSelectedSeat.seats[0]) }
                }
                return { ...item }
            }))
    }, [desSelectedSeat])

    useEffect(() => {
        if (props.clearSeatsSelection) {
            setSeats([])
            setSeat(null)
            chart.clearSelection();
        }
    }, [props.clearSeatsSelection])

    const handleOnClicked = (object: Seatsio.BaseObject) => {
        setSeat({ seats: [object.label], category: object.category?.key! })
    }
    const onObjectDeselected = (object: Seatsio.BaseObject) => {
        desSelectSeat({ seats: [object.label], category: object.category?.key! })
    }

    const addSeats = (seat: SelectedSeats) => {
        let exists = selectedSeats.find(x => x.category === seat.category)
        if (exists) {
            setSeats(
                selectedSeats.map(item => {
                    if (item.category === seat.category) {
                        if (!item.seats.find(x => x === seat.seats[0])) {
                            item.seats = item.seats.concat(seat.seats[0])
                        }
                    }
                    return { ...item }
                }))
        } else {
            setSeats(selectedSeats.concat({ category: seat.category, seats: seat.seats }))
        }
    }

    if (maxSelectedObjects.length === 0 && availableCategories.length === 0) {
        return null
    }
    return (
        <>
            <div style={{ 'height': '500px' }}>
                <SeatsioSeatingChart
                    workspaceKey={props.workspaceKey}
                    chartKey={props.chartKey}
                    event={props.event}
                    region="na"
                    availableCategories={availableCategories}
                    maxSelectedObjects={maxSelectedObjects}
                    onObjectSelected={(object: Seatsio.BaseObject) => handleOnClicked(object)}
                    onObjectDeselected={onObjectDeselected}
                    onRenderStarted={(createdChart: any) => { chart = createdChart }}
                />
            </div>
            {
                props.actionButton &&
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button
                        disabled={disabled}
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={() => props.getSeats(selectedSeats)}
                    >
                        {Locale.continue}
                    </Button>
                </Grid>
            }
        </>
    )
}

export default React.memo(SeatPicker)
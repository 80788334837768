
import { ApiRequest } from "../IO/ApiRequests";
import {deleteJSON, getJSON, IQueryParam, BaseURL, postJSON, putJSON} from "../IO";
import {ICoreResult} from "../Types";

export class GeneralLinkService extends ApiRequest{
    readonly providerKey = BaseURL.LINK;
    readonly baseUrl: string

    constructor(baseUrl: string) {
        super()
        this.baseUrl = baseUrl;
        this.setMsalKey(this.providerKey)
    }
    createUrl(route:string[]) {
        const joined = route.join('/');
        return `${this.baseUrl}/${joined}`;
    }
    getCompanyHeaders (): IQueryParam[] {
        return []
    }
    getParams():IQueryParam[] {
        return [];
    }

    getRequest<T>(uri: string, params?: IQueryParam[]): Promise<ICoreResult<T>> {
        const headers = this.getCompanyHeaders();
        const paramsTemp = !params ? this.getParams() : params;
        return getJSON(this.providerKey,uri,params,headers);
    }
    deleteRequest<T>(uri: string, data: T): Promise<ICoreResult> {
        const headers = this.getCompanyHeaders();
        const params = this.getParams();
        return deleteJSON<T>(this.providerKey,uri,params,data,headers);
    }
    postRequest<T>(uri: string, data: T, timeout? : number): Promise<ICoreResult> {
        const headers = this.getCompanyHeaders();
        const params = this.getParams();
        
        this.setConfig({
            url : uri,
            method : 'post',
            timeout
        })
        return this.post<T>(params,data); 
    }
    putRequest<T>(uri: string, data: T): Promise<ICoreResult> {
        const headers = this.getCompanyHeaders();
        const params = this.getParams();
        return putJSON<T>(this.providerKey,uri,params,data,headers);
    }

}

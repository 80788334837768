import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Typography, Toolbar } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { IApplicationStore } from "AppState";
import { ButtonBottonWrapper } from "modules/Common/GoToBottomButton";
import { ILinkCompanyStore, LinkCompany } from "ares-core/LinkCompany";
import { IShoppingStore, Shopping } from "ares-core/Shopping";
import { ICartDTO, ICartItem, IGiftCardInformation, NotificationsType } from "ares-core/Models";

import { Locale } from "ares-core/UI/Locale";

import CatalogItem, { ICatalogItem } from "link-core/UI/Catalog/components/CatalogItem";
import CatalogItemsList from "link-core/UI/Catalog/components/CatalogItemsList";
import { useLocation } from "react-router-dom";
import { getParamsDictionary } from "../../ares-core/Utils/Location";
import { GifCardDialog } from "../../ares-core/UI/GiftCard/GiftCardInformation";
import service from "ares-core/Services/Link/LinkCartService";
import { CoreStatus } from "ares-core/Types";
import { Validation } from "ares-core";
import NotificationsApi from "ares-core/Notifications/NotificationsApi";

interface ICatalogWrapper{
  shopping : IShoppingStore,
  company : ILinkCompanyStore
}
export interface IPreGiftCardInformation{
  catalogId : number
  giftCardInformation : IGiftCardInformation[]
}
const CatalogWrapper = (props: ICatalogWrapper) => {
  const parsedPathName = Object.entries(useParams());
  const { setOpen, BuildGiftCardComponent} = GifCardDialog()
  const [ giftCardSelected, setGiftCardSelected] = useState<{catalogId : number, count:number}>({ catalogId : 0, count : 0})
  const [ giftCardInformation, setGiftCardInformation] = useState<IPreGiftCardInformation[]>([])
  const location = useLocation();
  const checkParameter = (): boolean => {
    const queryParams = getParamsDictionary(location.search);
    if (
      queryParams.hasOwnProperty("justgiftcards") &&
      queryParams["justgiftcards"] == "true"
    )
      return true;
    else return false;
  };
  const justGiftCards: boolean = checkParameter();

  useEffect(() => {
    LinkCompany.Api.loadCompanyCatalog(props.company.companyID, justGiftCards);
  }, [props.company.companyID]);

  useEffect(() => {
    if (props.shopping.cart.catalogItems) {
      const dataGift = props.shopping.cart.catalogItems.map((cartItem) => {
          return { catalogId: cartItem.catalogId, giftCardInformation: cartItem.giftCards!}
      });
      if (dataGift && dataGift.length > 0) {
        setGiftCardInformation(dataGift);
      }
    }
    
  },[props.shopping.cart.catalogItems]);

  const handleAddGiftCard = (catalogItem : ICatalogItem) =>{
    if(!props.company.catalogDisplay.find(x => x.catalogId === catalogItem.catalogId)?.giftCard){
      Shopping.Api.addCatalogItem({ catalogId : catalogItem.catalogId!, quantity: 1 })
      .then(() => {})
      .catch((e: any) => console.error(e));
      return
    }
    let giftCards : IGiftCardInformation[] = []
    let exists = giftCardInformation.find(x=>x.catalogId === catalogItem.catalogId)?.giftCardInformation
    if(exists && exists.length > 0){
      giftCards = exists.concat([{
        giftCardTemplateId : exists.length + 1,
        recipientName : "",
        recipientEmail : "",
        note : ""
      }])
      setGiftCardInformation(giftCardInformation.map(item => { 
        if(item.catalogId === catalogItem.catalogId){
          item.giftCardInformation = giftCards
        }
        return item
      }))
    }else{
      giftCards.push({
        giftCardTemplateId : 1,
        recipientName : "",
        recipientEmail : "",
        note : ""
      })
      setGiftCardInformation([...giftCardInformation, { catalogId : catalogItem.catalogId!, giftCardInformation : giftCards }])
    }
    setOpen(true)
    setGiftCardSelected({catalogId : catalogItem.catalogId!, count : giftCards.length})    
  }
  const handleChangeGiftCardInformation = (name: string, value : string, index : number, catalogId : number) =>{
    let exists = giftCardInformation.find(x => x.catalogId === catalogId)
    let new_data : IGiftCardInformation[] = []
    if(exists){

      new_data = exists.giftCardInformation.map((item : any, indx : number) =>{
        if(indx === index){
          item[name] = value
        }
        return item
      })

      setGiftCardInformation(giftCardInformation.map(item => { 
      if(item.catalogId === catalogId){
        item.giftCardInformation = new_data
      }
      return item
      }))
    }
  }

  const SaveGiftCardInformation = async () =>{
    let information = giftCardInformation.find(x => x.catalogId === giftCardSelected.catalogId)?.giftCardInformation
    // let previousGiftCard = [...giftCardInformation];
    if(information){
      const emailIsValid = information.find(x => Validation.Email(x.recipientEmail) !== '' || Validation.Name(x.recipientName) !== '' )
      if(emailIsValid){
          NotificationsApi.add({
              type: NotificationsType.error,
              message: 'You need to fill all the information before continue'
          })
        return
      }
      let dataToSend = information.filter(o1 => {if (!o1.giftCardRecipientId) { return o1 }});
      const {payload, status} = await service.addGiftCartdInformation( props.company.urlFriendlyName,dataToSend, props.shopping.cart.cartId, giftCardSelected.catalogId)
      if(status === CoreStatus.SUCCESS){
        const cart = payload as ICartDTO
        Shopping.Api.addGiftCard(cart)
        const dataGift = cart.catalogItems.map((cartItem) => {
          return { catalogId: cartItem.catalogId, giftCardInformation: cartItem.giftCards!}
        });
        if (dataGift && dataGift.length > 0) {
          setGiftCardInformation(dataGift);
        }
      }
      setOpen(false)
    }
  }

  const handleRemoveFromCart = async (catalogId: number) => {
    let giftCards : IGiftCardInformation[] = []
    let exists = giftCardInformation.find(x=>x.catalogId === catalogId)?.giftCardInformation
    if(exists && exists.length > 0){
      if (exists[exists.length - 1].giftCardRecipientId) {
        const {payload, status} = await service.deleteGiftCartdInformation(Shopping.Store().companyId,exists[exists.length - 1].giftCardRecipientId!, Shopping.Store().cart.cartId, catalogId)
        if(status === CoreStatus.SUCCESS){
          const cart = payload as ICartDTO
          Shopping.Api.addGiftCard(cart)
          const dataGift = cart.catalogItems.map((cartItem) => {
            return { catalogId: cartItem.catalogId, giftCardInformation: cartItem.giftCards!}
          });
          if (dataGift) {
            setGiftCardInformation(dataGift);
          }
        }
      } else {
        giftCards = exists.slice(0, -1);
        if (giftCards && giftCards.length === 0) {      
          setGiftCardInformation(giftCardInformation.filter((item) => item.catalogId !== catalogId))        
        } else {
          setGiftCardInformation(giftCardInformation.map(item => { 
            if(item.catalogId === catalogId){
              item.giftCardInformation = giftCards
            }
            return item
          }))
        }
      }
    }
    setGiftCardSelected({catalogId : catalogId, count : giftCards.length})    
  }  

  const CatalogItemsListProps = {
    addToCart: (catalogItem: ICatalogItem, quantity: number) => {
      handleAddGiftCard(catalogItem);
    },
    removeFromCart: async (catalogId: number) => {
      handleRemoveFromCart(catalogId)
    },
    catalogDisplay: props.company.catalogDisplay || [],
    giftCard : giftCardInformation
  };

  const links = [
    {
      label: "Events",
      enable: true,
      place: `/event/${props.company.urlFriendlyName}`,
    },
    {
      label: "Cart",
      enable: true,
      place: `/cart/${props.company.urlFriendlyName}`,
    },
  ];

  return (
    <ButtonBottonWrapper links={links}>
      <Toolbar>
        <Typography>Merchandise from : {props.company.name}</Typography>
      </Toolbar>
      <CatalogItemsList {...CatalogItemsListProps} />
      {
        ( giftCardSelected.catalogId !== 0)?
          BuildGiftCardComponent({
            name: props.company.catalogDisplay.find(x => x.catalogId === giftCardSelected.catalogId)!.unitPrice.toString(),
            handleChangeGiftCardInformation: (name : string, value : string, index? : number) => handleChangeGiftCardInformation(name, value, index!, giftCardSelected.catalogId),
            giftCardInformation: giftCardInformation.find(x => x.catalogId === giftCardSelected.catalogId)?.giftCardInformation! ?? [],
            onSuccess:  () => SaveGiftCardInformation(),
          })
        : null
      }
    </ButtonBottonWrapper>
  );
};

const mapStateToProps = ({ company, shopping }: IApplicationStore) => {
  return {
    shopping: shopping,
    company: company,
  };
};

export default connect(mapStateToProps)(CatalogWrapper);

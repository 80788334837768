import {ICartDTO} from "../Models";

export function calculateTaxesAndFees(cart:ICartDTO | null): number {
    const tax: number = cart?.tax || 0 ;
    const buyerServiceCharge: number = cart?.buyerServiceCharge || 0;
    return tax + buyerServiceCharge;
}

export function calculateTotal(cart:ICartDTO | null): number {
    const charges = calculateTaxesAndFees(cart);
    const subtotal = cart?.subTotal || 0;
    return subtotal + charges;
}

export function getNumItems(cart: ICartDTO | null): number {
    if(!cart ) return 0;
    const catalogItems = cart.catalogItems.length;
    const reservations = cart.reservations.length;
    return Number(catalogItems + reservations);
}

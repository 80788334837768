// TASK-2535 - Modify
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

export enum AspectRatio {
	SixteenByNine,
	FourByThree,
	ThreeByTwo,
	EightByFive
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			width: "100%",
			paddingBottom: ({ aspectRatio }: IAspectRatioWrapperProps) => {
				switch (aspectRatio) {
					case AspectRatio.SixteenByNine:
						return "56.25%"
					case AspectRatio.FourByThree:
						return "75%"
					case AspectRatio.ThreeByTwo:
						return "66.66%"
					case AspectRatio.EightByFive:
						return "62.5%"
				}
			},
			position: "relative"
		},
		container: {
			position: "absolute",
			top: "0",
			right: "0",
			bottom: "0",
			left: "0"
		}
	})
);
export interface IAspectRatioWrapperProps {
	aspectRatio: AspectRatio,
	children: React.ReactNode
}
export default function AspectRatioWrapper(props: IAspectRatioWrapperProps) {
	const classes = useStyles(props);
	return (
		<Box className={classes.wrapper}>
			<Box className={classes.container}>
				{props.children}
			</Box>
		</Box>
	);

}

import { GeneralLinkService } from "./GeneralLinkService";
import { CatalogDetail } from "../Models";
import { CoreStatus } from "../Types";

export interface CalendarDate
{
  day: string;
  minTime: string;
  timeCount: number;
}
class CatalogService extends GeneralLinkService {
  constructor() {
    super('/api');
  }

  async getCatalogByEventDate(companyId: number,eventDateId: number) {
    const uri = this.createUrl([companyId.toString(),'Catalog',eventDateId.toString()]);
    const response = await this.getRequest<CatalogDetail[]>(uri);
    if(response.status === CoreStatus.SUCCESS) {
      if(response.payload) {
        return response.payload;
      }
    }
    return null;
  }

}

const service = new CatalogService();
export default service;

import * as https from "https";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { CoreStatus, ICoreResult } from "../Types";
import { Credentials } from "../Credentials";
import AuthService from "../Auth/AuthService";
import { ARES_DEVELOPMENT } from "Config/environment";

// Container for passing HTTP Query Parameters
export interface IQueryParam {
  key: string;
  value: string;
}

export enum BaseURL {
  EBM = "EBM",
  EBMWWW = "EBMWWW",
  LINK = "LINK",
  PORTAL = "PORTAL",
  SIGNALRHUB = "SIGNALRHUB",
  VIATOR = "VIATOR"
}

type BaseUrlMapT = Map<BaseURL,string>;

export const BaseURLMap: BaseUrlMapT = new Map<BaseURL,string>();

/** @description retrieve a JSON Object with HTTP Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @returns Returns an ICoreResult with the status and object
 *
 */
export const getJSON = async (
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
	headers: IQueryParam[] = []
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);
  cfg.headers.Accept = "application/json";
  cfg.responseType = "json";
  cfg.method = "get";

  try {
    return httpRequest(msalProviderKey, cfg, params, headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

/** @description Will send a JSON Object via an HTTP Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @param jobj JSON Object to be posted
 * @returns Returns an ICoreResult with the status and object
 *
 */
export const postJSON = async <T,>(
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
  data: T,
	headers: IQueryParam[] = [],
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);

  cfg.headers["Content-type"] = "application/json";
  cfg.headers.Accept = "application/json";
  cfg.responseType = "json";
  cfg.method = "post";
  cfg.data = JSON.stringify(data);

  try {
    return httpRequest(msalProviderKey, cfg, params, headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

/** @description Will send a JSON Object via an HTTP Put Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @param data JSON Object to be posted
 * @returns Returns an ICoreResult with the status and object
 *
 */
export const putJSON = async<T,> (
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
  data: T,
	headers: IQueryParam[] = [],
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);

  cfg.headers["Content-type"] = "application/json";
  cfg.headers.Accept = "application/json";
  cfg.responseType = "json";
  cfg.method = "put";
  cfg.data = JSON.stringify(data);

  try {
    return httpRequest(msalProviderKey, cfg, params, headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

export const deleteJSON = async<T,> (
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
  jobj: T,
	headers: IQueryParam[] = []
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);

  cfg.headers["Content-type"] = "application/json";
  cfg.headers.Accept = "application/json";
  cfg.responseType = "json";
  cfg.method = "delete";
  cfg.data = JSON.stringify(jobj);

  try {
    return httpRequest(msalProviderKey, cfg, params,headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

/** @description Will send an XML Object via an HTTP Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @param xml XML Object to be posted
 * @returns Returns an ICoreResult with the status and object
 *
 */
 export const postXML = async <T,>(
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
  data: T,
	headers: IQueryParam[] = [],
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);

  cfg.headers["Content-type"] = "application/xml";
  cfg.headers.Accept = "application/xml";
  cfg.responseType = "text";
  cfg.method = "post";
  cfg.data = data;

  try {
    return httpRequest(msalProviderKey, cfg, params, headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

/** @description retrieves text with HTTP Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @returns Returns an ICoreResult with the status and object
 *
 */
export const getText = async (
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
	headers: IQueryParam[] = []
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);
  cfg.headers.Accept = "text/plain";
  cfg.responseType = "text";
  cfg.method = "get";

  try {
    return httpRequest(msalProviderKey, cfg, params,headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};


/** @description Will send a JSON Object via an HTTP Request
 *
 * @param msalProviderKey any MSAL B2C Authority Identifier added to MSALProvider
 * @param url Target endpoint to make the HTTP request
 * @param params Array of IQueryParam that will be added to the request
 * @param jobj JSON Object to be posted
 * @returns Returns an ICoreResult with the status and object
 *
 */
export const postFiles = async <T,>(
  msalProviderKey: BaseURL,
  url: string,
  params: IQueryParam[] = [],
  data: T,
	headers: IQueryParam[] = [],
) => {
  let cfg: AxiosRequestConfig = baseConfig(url);

  //cfg.headers["Content-type"] = "multipart/form-data";
  cfg.headers.Accept = "application/json";
  cfg.responseType = "json";
  cfg.method = "post";
  //cfg.data=JSON.stringify(data);
 cfg.data = data;
  try {
    return httpRequest(msalProviderKey, cfg, params, headers);
  } catch (e) {
    let retval: ICoreResult = {
      status: CoreStatus.FAILURE,
      payload: JSON.stringify(e),
    };

    return retval;
  }
};

const getAuthentication = async(providerKey: BaseURL): Promise<string | null> => {
  let token: string | null = '';
  if (providerKey === BaseURL.PORTAL || providerKey == BaseURL.SIGNALRHUB) {
    token = await AuthService.getAccessToken();
  }

  return token && token?.length > 0 ? token : null
}
// Common Function to renew tokens and add the query parameters
export const httpRequest = async (
  baseUrl: BaseURL,
  cfg: AxiosRequestConfig,
  params: IQueryParam[],
	headers: IQueryParam[] = []
) => {
  const token = await getAuthentication(baseUrl);
  if (token) {
    cfg.headers.Authorization = "Bearer " + token;
  }

  if (
    baseUrl === BaseURL.EBM ||
    baseUrl === BaseURL.EBMWWW
  ) {
    cfg.withCredentials = true;
  }

		// Build the Route
    // In DEV you can use http instead of https
  let url = BaseURLMap.get(baseUrl);
	if (url === undefined) throw 400; // Bad BaseURL key provided

  if ( !ARES_DEVELOPMENT ) {
    url = "https://" + url;
  } 
  else if (!url.startsWith('http:')) {
    url = "https://" + url;
  }
  cfg.baseURL = url;

		// Add Query Parameters if any
		for (let i: number = 0; i < params.length; i++) {
			let param: IQueryParam = params[i];
			cfg.params[param.key] = param.value;
		}

	  // Add Headers if any
		for (let i: number = 0; i < headers.length; i++) {
			let param: IQueryParam = headers[i];
			cfg.headers[param.key] = param.value;
		}

  let retval: ICoreResult = {
    status: CoreStatus.NORESULTS,
    payload: null,
  };

  try {
    let result: ICoreResult | undefined = await axiosRequest(cfg);
    // For JSON return types, if the retrieved data could not be parsed into JSON
    // then the result will be null
    if (result) {
      if (result.payload === null && cfg.responseType === "json") {
        result.msg = "Bad JSON retrieved";
      }
    } else {
      retval.status = CoreStatus.FAILURE;
      retval.msg = "axiosRequest() returned undefined";
      result = retval;
    }

    return result;
  } catch (e) {
    retval.status = CoreStatus.FAILURE;
    retval.payload = JSON.stringify(e);
    return retval;
  }
};

// Common Http Interface using Axios
export const axiosRequest = async (config: AxiosRequestConfig) => {
  let retval: ICoreResult = {
    status: CoreStatus.SUCCESS, // Optimistic
    payload: null,
  };

  try {
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });

    let result: AxiosResponse<JSON> = await instance(config);
    //console.log(result.headers)
    retval.payload = result.data;
    if (result.headers["x-indexic-p"] && retval.payload != null) {
      const role = result.headers["x-indexic-p"]; // ##_##_##
      if (!Credentials.SetRoles(role))
			{
				//console.log("Incoming Roles: " + role);
				Credentials.SetRoles("0_0_0");
			}
    }

    return retval; // config.responseType determines the type of data
  } catch (e:any) {
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Caught Axios Response Error in axiosRequest()");
      console.log("HTTP Status:", e.response.status);
      console.log("Config:", config);
      console.log("Headers:", e.response.headers);
      retval.status = CoreStatus.FAILURE;
      retval.payload = e.response;
      return retval;
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("Caught Request Error in axiosRequest()");
      console.log(e.request, "*** ERROR CATCH ***");
      retval.status = CoreStatus.FAILURE;
      retval.payload = e;
      return retval;
    } else {
      console.log(e.toString(), "*** ERROR CATCH ***");
      retval.status = CoreStatus.FAILURE;
      retval.payload = e;
      retval.msg = "Unknown Error";
    }
  }
};

// Base Config
const baseConfig = (url: string): AxiosRequestConfig => {
  let config: AxiosRequestConfig = {
    url: url,
    method: "get",
    baseURL: "https://",
    headers: {
      Accept: "application/json",
    },
    timeout: 200000,
    withCredentials: false,
    responseType: "json",
    onUploadProgress: (progressEvent: any) => {},
    onDownloadProgress: (progressEvent: any) => {},
    validateStatus: (status: number) => status >= 200 && status < 300,
    maxRedirects: 1,
    params: {},
  };

  return config;
};

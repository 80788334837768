import {IContactInfo, IGiftCardInformation, IRefundResult, ReservationConfirmationNumber} from "ares-core/Models"
export interface ICartDTO {
	readonly companyId: number;
	readonly companySlug?: string | null;
	readonly cartId: string;
	readonly isEmpty: boolean;
	readonly reservations: ICartReservation[];
	readonly catalogItems: ICartItem[];
	readonly clickAgreements: string[];
	readonly errors: string[];
	readonly warnings: string[];
	readonly appliedGiftCards: IGiftCardDetail[];
	readonly tax: number;
	readonly subTotal: number;
	readonly buyerServiceCharge: number;
	readonly depositBalance: number;
	readonly totalCartDiscount: number;
	readonly processorSpecificJson: string;
  }

  // Statuses for PaymentIntent and SetupIntent.
  // SetupIntent does not have 'requires_capture'
export type StripeIntentStatus =
	'requires_payment_method' |
	'requires_confirmation' |
	'requires_action' |
	'processing' |
	'requires_capture' |
	'canceled' |
	'succeeded';

export interface IStripeIntentInfo {
	IntentId: string;
	Secret: string;
	Account?: string;
	Type: StripeIntentType;
}

enum StripeIntentType {
	Setup = 0,
	Payment = 1
}

export interface ICartItem {
	description?: string;
	price?: number;
	tax?: number;
	discount?: number;
	catalogId: number;
	quantity: number;
	itemCode?: string | null;
	giftCards? : IGiftCardInformation[] | null;
}

export interface ICartReservation {
	description?: string;
	price?: number;
	tax?: number;
	discount?: number;
	reservationId: number;
	contact: IContactInfo;
	addOns: ICartItem[];
	rates?:ICartDtoReservationRate[];
	totalCartDiscount: number;
	hasTipping:boolean;
}

export interface IGiftCardDetail {
	companyId: number,
	transactionId: number,
	transactionDate: string,
	code: string;
	originalPrice: number;
	balance: number;
}
export interface ICartDtoReservationRate {
	rateId: number;
    description: string;
    rate: number;
    tickets: number;
}

export interface IReservationTip {
	tipMethod: TipMethod;
	percentTip: number;
	fixedAmount: number;
	reservationIdAddOn?: number;
}

export enum TipMethod {
	Percentage = 0,
	FixedAmount = 1
}

export interface HowHeardAnsItem
{
	howHeardAnswerID: number;
	answer: string;
}
export interface PrecheckoutData
{
	billingInfo: IContactInfo;
	gatewayId: number;
	processorSpecificJson: string;
	cartDTO:ICartDTO;
	howHeardAnswers: HowHeardAnsItem[];
	paymentsOnFile: IPaymentsOnFile[] | null;
}

export interface CheckoutPost
{
	readonly cartId: string;
	billingInfo: IContactInfo;
	shippingInfo: IContactInfo;
	processorPaymentData: string;
	howHeardAnswerID: number;
	refundReason?: number;
	transactionCategoryId?: number;
	sendConfirmation?:boolean;
}
export interface ICheckoutResult
{
	readonly checkoutTransaction: string;  // = obscured transaction for receipt
	readonly cartDTO: ICartDTO;
	readonly transactionId: number;  
	readonly errors: string[];
	readonly refundResults: IRefundResult[] | null
}

export const DefaultCart:ICartDTO = {
	cartId: "",
	companyId: 0,
	isEmpty: true,
	reservations: [],
	catalogItems: [],
	appliedGiftCards: [],
	clickAgreements: [],
	errors: [],
	warnings:[],
	buyerServiceCharge: 0,
	tax: 0,
	subTotal: 0,
	depositBalance: -1,
	totalCartDiscount: 0,
	processorSpecificJson: "",
}

export interface IPaymentsOnFile{
	description : string;
	transactionId : number;
}

export interface SendEmailCartInfo
{
	email: string;
	phone: string;
 	companyFriendlyNames: string[];
}


 export interface SendEmailData
{
	email: string;
	cartId: string;
	companyUrlFriendlyName: string;
	phone?: string;
}

export interface SendCartEmailResponse
{
	success: boolean;
 	errors: string[];
}

export class CheckoutConfiguration {
	public isCash:boolean;
	public transactionCategoryId:number;
	public sendConfirmation:boolean;
	constructor() {
		this.isCash = false;
		this.transactionCategoryId = -1;
		this.sendConfirmation = false;
	}

	setConfig(config: Partial<ICheckoutConfiguration>) {
		this.isCash = config.isCash ?? false;
		this.transactionCategoryId = config.transactionCategoryId ?? -1;
		this.sendConfirmation = config.sendConfirmation ?? false;
	}

}

export interface ICheckoutConfiguration {
	isCash:boolean,
	transactionCategoryId:number,
	sendConfirmation:boolean,
}

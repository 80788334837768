import React, { useState, MouseEvent } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Popover,
  CardActionArea,
  Box,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { MathEx } from "ares-core/Utils/MathEx";
import { VwEventDisplay } from "ares-core/Models";
import { isEmpty, Locale } from "ares-core";
import AspectRatioWrapper, { AspectRatio } from "link-core/UI/AspectRationWrapper";
import * as Tracking from "ares-core/Tracking";
import bookButtonImg from "./Book_Button.png";

const stockImg = "https://picsum.photos/id/1025/450";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "inline-block",
    width: "50%",
    "&:first-of-type": {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  eventCard: {
    height: "100%",
    background: (props: VwEventDisplay) =>
      props.imgUrl
        ? `#cccccc url(${props.imgUrl}) center top/cover no-repeat`
        : `#cccccc url(${stockImg}) center top/cover no-repeat`,
    color: "whitesmoke",
    border: "solid white 2px",
    display: "flex",
    flexDirection: "column"
  },
  action: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gradient: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "0.25rem 1rem !important",
    width: "100%",
  },
  info_icon: {
    color: "whitesmoke",
  },
  popover: {
    maxWidth: "84rem",
  },
  description: {
    padding: theme.spacing(2),
  },
  small: {
    color: "whitesmoke",
    marginLeft: "0.125rem",
    textTransform: "capitalize",
  },
  bookButton: {
    position: "absolute",
    bottom: "0.5em",
    right: "0.5em"
  },
  offeredBy: {
    color: "#ccc",
  }
}));

/**
 * @param props VwEventDisplay properties 
 * 1st Card will take up both columns & be ~2x taller than other events
 * on all screens over 600px. Under 600px, it becomes same size.
 */
export const EventCardComponent = (props: VwEventDisplay) => {
  const classes = useStyles(props);
  const { url } = useRouteMatch();
  const params = useParams<{urlFriendlyCompanyName : string, secondFriendlyCompanyName : string}>()
  const history = useHistory();
  // anchorEl for popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  /**
   * @param minRate minimum price for event
   * @param maxRate max price for event
   * if minRate & maxRate the are same, displays minRate
   * otherwise, displays rate range with 'en' dash in between.
   */
  const renderRateRange = (minRate: number, maxRate: number) => {
    if (minRate === maxRate) {
      return (
        <Typography aria-label="event rate">
          {`${MathEx.formatCurrency(props.minRate)}`}
        </Typography>
      );
    }

    if (maxRate > 0) {
      return (
        <Typography aria-label="event rate range">
          {`${MathEx.formatCurrency(props.minRate)} \u2013 ${MathEx.formatCurrency(props.maxRate)}`}
        </Typography>
      );
    }
  };

  const handleCardClick = () => {
    // possible landing urls .../companyName?GroupID and ../companyName/?GroupID=#
    Tracking.selectEvent(props.name)
    history.push(`/event/${params.urlFriendlyCompanyName}/${props.urlFriendlyName}`);
  };

  const handleInfoClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "description-popover" : undefined;

  return (
    <Box className={classes.root}>
      <AspectRatioWrapper aspectRatio={AspectRatio.SixteenByNine} >
        <Card className={classes.eventCard}>
          <CardActionArea className={classes.action} onClick={handleCardClick}>
            <CardHeader
              title={props.name}
              titleTypographyProps={{ variant: "h6" }}
              subheader={
                isEmpty(props.companyName)? null: <span className={classes.offeredBy}>
                  <sup>{Locale.offeredBy}</sup>
                  {props.companyName}
                </span>
              }
              className={classes.gradient}
              aria-label="event name"
            />
            <img src={bookButtonImg} alt="Book" className={classes.bookButton} />
          </CardActionArea>
          <CardContent className={`${classes.content} ${classes.gradient}`}>
            {renderRateRange(props.minRate, props.maxRate)}
            <Button
              onClick={handleInfoClick}
              aria-label="event information"
              aria-describedby={id}
            >
              <Info className={classes.info_icon} fontSize="small" />
              <Typography className={classes.small}>Details...</Typography>
            </Button>
            <Popover
              id={id}
              open={open}
              className={classes.popover}
              anchorEl={anchorEl}
              onClose={handleInfoClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className={classes.description}>
                <ReactMarkdown source={props.description} />
              </Typography>]
            </Popover>
          </CardContent>
        </Card>
      </AspectRatioWrapper>
    </Box>
  );
};

export default EventCardComponent;

import React from "react";
import { LinearProgress, Typography } from "@material-ui/core";

interface AppLoadingIndicatorProps {
  version?: string;
}

export const AppLoadingIndicator = (props: AppLoadingIndicatorProps) => {
  return (
    <>
      <LinearProgress color={"primary"} variant={"indeterminate"} />
      <Typography align={"center"}>
        {props.version ? `${props.version} /` : ""} Indexic, Inc. &copy; 2020
      </Typography>
      <LinearProgress color={"primary"} variant={"indeterminate"} />
    </>
  );
};

import {createTheme, ThemeOptions} from '@material-ui/core/styles';
import { green} from '@material-ui/core/colors'
export const getTheme = (overrides?: any) => {
  const theme:ThemeOptions = {
    palette: {
      common: {
        black: 'rgba(38, 50, 56, 1)',
        white: '#fff',
      },
      background: {
        paper: 'rgba(230, 230, 230, 1)',
        default: 'rgba(255, 255, 255, 1)',
      },
      primary: {
        light: 'rgba(91, 163, 191, 1)',
        main: 'rgba(5, 105, 126, 1)',
        dark: 'rgba(96, 125, 139, 1)',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(230, 230, 230, 1)',
        main: 'rgba(8, 30, 102, 1)',
        dark: 'rgba(96, 125, 139, 1)',
        contrastText: 'rgba(255, 255, 255, 1)',
      },
      error: {
        light: 'rgb(229, 115, 115, 1)',
        main: 'rgb(244, 67, 54, 1)',
        dark: 'rgb(211, 47, 47, 1)',
        contrastText: 'rgb(255, 255, 255, 1)',
      },
      success: {
        main:green[500],
        contrastText: 'rgb(255, 255, 255, 1)',
      },
      text: {
        primary: 'rgba(38, 50, 56, 1)',
        secondary: 'rgba(74, 97, 109, 1)',
        disabled: 'rgba(121, 155, 175, 1)',
        hint: 'rgba(250, 162, 27, 1)',
      },
    },
    props: {
      MuiTextField: {
        size: 'small' as 'small' | undefined,
        variant: 'outlined' as 'outlined' | undefined,
      },
    },
    overrides: overrides ? overrides : {},
  };
  return createTheme(theme);
};

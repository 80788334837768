import React from 'react';
import SelectionList from '../SelectionList';
import {QuestionMultiChoice} from '../questionTypes';

export interface IMultichoiceQuestionProps {
    question: QuestionMultiChoice,
    selected: any,
    onSelectionChange: Function,
    isEditable?:boolean
}
export function MultiChoiceQuestion(props: IMultichoiceQuestionProps) {
    return (
        <SelectionList
            answers={props.question.answers}
            multiselect={true}
            selected={props.selected}
            onSelectionChange={props.onSelectionChange}
            isEditable={props.isEditable}
          />
    )
}

import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core'
import DialogComponent from 'ares-core/UI/Components/DialogComponent'
import { ICatalogDisplay, IGiftCardInformation } from 'ares-core/Models/Catalog'
import { ICartDTO, ICartItem, NotificationsType } from 'ares-core/Models'
import { IApplicationStore } from 'AppState'
import { Shopping, Validation } from 'ares-core'
import { CoreStatus } from 'ares-core/Types'
import { useSelector, shallowEqual } from 'react-redux'
import service from 'ares-core/Services/Link/LinkCartService'
import Notifications from "ares-core/Notifications/NotificationsApi";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

interface Props{
    handleChangeGiftCardInformation : (name : string, value : string, index? : number) => void
    information? : IGiftCardInformation
    catalogId? : number,
    addNote? : boolean
}

interface IGifCardDialog extends Props{
    name : string
    giftCardInformation : IGiftCardInformation[]
    onSuccess : () => void
    
}
const GiftCardInformation = (props : Props) => {
    const [ giftCardInfo, setInformation] = useState(props.information)
    const [ recipientId, setRecipientId ] = useState(0)
    const handleGetData = (name : string, value : string) =>{
        props.handleChangeGiftCardInformation(name, value)
        setInformation({...giftCardInfo!, [name] : value})
    }

    const getGiftCardInformation = async (recipientId : number) =>{
        if(recipientId && props.catalogId){
            const {payload, status} = await service.getGiftCardInformation(Shopping.Store().companyId, props.catalogId, recipientId, Shopping.Store().cart.cartId)
            if(status === CoreStatus.SUCCESS){
                let gcInfo = payload as IGiftCardInformation
                setInformation(gcInfo)
            }
        }
    }

    const updateInformation = async (catalogId : number) =>{
        const {payload, status} = await service.editGiftCartdInformation( Shopping.Store().companyId,giftCardInfo, Shopping.Store().cart.cartId, catalogId)
        if(status === CoreStatus.SUCCESS){
            const cart = payload as ICartDTO
            Shopping.Api.addGiftCard(cart)
            Notifications.add({
                type: NotificationsType.success,
                message: 'GiftCard updated'
            });
        }
    }

    const removeGiftCard = async(catalogId : number, recipientId : number) =>{
        const {payload, status} = await service.deleteGiftCartdInformation( Shopping.Store().companyId,recipientId, Shopping.Store().cart.cartId, catalogId)
        if(status === CoreStatus.SUCCESS){
            const cart = payload as ICartDTO
            Shopping.Api.addGiftCard(cart)
        }
    }

    const informationIsValid = ( information : IGiftCardInformation ) =>{
        return Validation.Email(information.recipientEmail) === '' && Validation.Name(information.recipientName) === '' && Validation.requiredValue(information.note) === '' 
    }

    const edit = (id : number) =>{
        if(id === recipientId){
            setRecipientId(0)
            return
        }
        setRecipientId(id)
        getGiftCardInformation(id)
    }

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <TextField defaultValue={giftCardInfo?.recipientName} label="Recipient Name" fullWidth name="recipientName" onChange={ev => handleGetData("recipientName",ev.target.value) } variant="outlined"/>
                </Grid>
                <Grid item xs={6}>
                    <TextField defaultValue={giftCardInfo?.recipientEmail} style={{ marginLeft : 2}} label="Recipient Email" fullWidth name="recipientEmail" onChange={ev => handleGetData("recipientEmail",ev.target.value) } variant="outlined" helperText={Validation.Email(giftCardInfo?.recipientEmail)} />
                </Grid>
                {
                    giftCardInfo?.giftCardRecipientId === recipientId || props.addNote ?
                        <Grid item xs={12}>
                            <TextField value={giftCardInfo?.note} style={{ marginTop : 5, marginBottom : 5}} label="Note" fullWidth name="note" onChange={ev => handleGetData("note",ev.target.value) } multiline variant="outlined" InputLabelProps={{ shrink: true }}/>
                        </Grid> : null
                }
            </Grid>
            {
                props.catalogId ?
                <Grid container>
                    <Grid item xs={12}>
                        <Button disabled={!informationIsValid(giftCardInfo!)} size='small' variant="contained" color="primary" onClick={() => updateInformation(props.catalogId!)}>Update information</Button>
                        <IconButton aria-label="edit" style={{color : 'steelblue'}} onClick={() => edit(giftCardInfo?.giftCardRecipientId!)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" style={{color : 'red'}} onClick={() => removeGiftCard(props.catalogId!, giftCardInfo?.giftCardRecipientId!)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                : null
            }
        </>
    )
}

const GifCardDialog = () =>{
    const [open, setOpen] = useState(false)

    const BuildGiftCardComponent = (props : IGifCardDialog) =>{
        return (
            <DialogComponent DialogText='Gift Card Information' open={open} handleClose={() => setOpen(false)} ButtonActions={{
                handleSuccessActionText : 'Save',
                handleSuccessActionFunction : () => props.onSuccess()
            }} >
                {
                    props.giftCardInformation.map((item, indx) =>(
                        <Grid container key={indx}>
                            <Grid item xs={12}>
                                <Typography>  {` ${(indx + 1)}.- $${props.name } Gift Card`} </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GiftCardInformation addNote={true} information={item} key={indx} handleChangeGiftCardInformation={(name: string, value : string) => props.handleChangeGiftCardInformation(name, value, indx)} />
                            </Grid>
                        </Grid>
                    ))
                }
            </DialogComponent>
        )
    }
    return{
        setOpen,
        BuildGiftCardComponent
    }
}
const GiftCardList = ({catalogItems, backgroundColor} : { catalogItems : ICartItem[], backgroundColor? : string}) =>{
    const [updatedInformation, setUpdatedInfo] = useState<IGiftCardInformation | null>(null)
    const { shopping } = useSelector( (state:IApplicationStore) => ({
        shopping : state.shopping,
    }), shallowEqual);
    
    const handleChangeGiftCardInformation = (name : string, value : string, giftCardRecipient : {[key: string]: any}) =>{
        giftCardRecipient[name] = value
        setUpdatedInfo({
            ...updatedInformation, ...giftCardRecipient as IGiftCardInformation
        })
    }

    return (
        <>
        <Paper elevation={1} border-radius={20} style={{ padding : 8, marginBottom : 2, marginTop : 2, backgroundColor}}>
        <Typography> GiftCard Recipient Information </Typography>
        {
            catalogItems.map((item : ICartItem) =>(
                <React.Fragment key={item.catalogId}>
                    {
                    item.giftCards ? item.giftCards.map((information, indx) =>(
                        <React.Fragment key={information.giftCardRecipientId}>
                            <Typography>  {` ${(indx + 1)}.- $${item.price } Gift Card`} </Typography>
                            <Divider style={{marginBottom : 8}} />
                            <GiftCardInformation 
                                information={information}
                                catalogId={item.catalogId}
                                handleChangeGiftCardInformation={(name : string, value : string) => handleChangeGiftCardInformation(name, value, information)} />
                        </React.Fragment>
                    ))
                    : null
                    }
                </React.Fragment>
                ))
            }
            </Paper>
        </>
    )
}
export {
    GiftCardInformation,
    GifCardDialog,
    GiftCardList
}
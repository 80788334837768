import { Reducer } from "redux";
import { EventActionType, EventActions } from "./Actions";
import { IEventStore } from "../Types";
import Event from "../EventApi";

export const EventStoreReducer: Reducer<IEventStore, EventActions> = (
  state: IEventStore = Event.Store(),
  action
) => {
  switch (action.type) {
		case EventActionType.SET_EVENT_CURRENT_EVENT: {
      return {
        ...state,
        currentEvent: action.currentEvent
      };
		}
		case EventActionType.SET_EVENT_SUMMARIES: {
      return {
        ...state,
        events: action.events,
		eventsMemoized: {}
      };
		}
		case EventActionType.SET_EVENT_GROUPS: {
      return {
        ...state,
        eventGroups: action.eventGroups
      };
		}
		case EventActionType.SET_EVENT_CAL_ITEMS: {
      return {
        ...state,
        calendar: action.calendar
      };
		}
    case EventActionType.EVENT_IN_PROGRESS: {
      var changedState = { ...state };
			
      changedState = { ...state, busy: action.bOn };

      return changedState;
		}
    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
		break;
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: EventActions) => {};

import {httpRequest, IQueryParam, BaseURL} from './Http'; //for now this will be the refference
import {AxiosRequestConfig} from 'axios';
import { CoreStatus,ICoreResult } from '../Types';

export class ApiRequest {
    protected config : AxiosRequestConfig
    protected BaseURL? : BaseURL

    constructor() {
        this.config = {
            baseURL: "https://",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                responseType: "json"
            },
            timeout: 200000,
            withCredentials: false,
            responseType: "json",
            onUploadProgress: (progressEvent : any) => {},
            onDownloadProgress: (progressEvent : any) => {},
            validateStatus: (status : number) => status >= 200 && status < 300,
            maxRedirects: 1,
            params: {}
        };
    }
    
    //@set
    protected setConfig( cfg : Partial<AxiosRequestConfig>) {
        if (cfg) {
            this.config = {
                ...this.config,
                ...cfg
            }
        }
    }

    protected setMsalKey(baseURL : BaseURL){
        this.BaseURL = baseURL
    }
    protected setHeaders(headers : IQueryParam[]){
        headers.forEach(item =>{
            this.config = {
                ...this.config,
                headers : {
                    ...this.config.headers,
                    [item.key] : item.value
                }
            }
        })
    }
    protected getConfig() {
        return this.config
    }
    protected async post<T,>(params: IQueryParam[] = [], data: T)  {
        try {
            this.config.data = JSON.stringify(data);
            return httpRequest(this.BaseURL!, this.getConfig()!, params, this.config.headers);
        } catch (e) {
            let retval : ICoreResult = {
                status: CoreStatus.FAILURE,
                payload: JSON.stringify(e)
            };

            return retval;
        }
    }
    protected async get(params: IQueryParam[] = [])  {
        return httpRequest(this.BaseURL!, this.getConfig()!, params, this.config.headers);
    }
    protected async put<T,>(params: IQueryParam[] = [], data: T){
        this.config.data = JSON.stringify(data);
        return await httpRequest(this.BaseURL!, this.getConfig()!, params, this.config.headers);
    }
    protected async delete(params: IQueryParam[] = [])  {
        return httpRequest(this.BaseURL!, this.getConfig()!, params, this.config.headers);
    }
}

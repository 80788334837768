// TASK-2535 - Modify
import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  TextField,
  IconButton,
  Button,
  InputAdornment,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from "@material-ui/icons/Remove";
import { CardGiftcard, ImageOutlined } from "@material-ui/icons";
import ReactMarkdown from "react-markdown";
import { CatalogDetail } from "ares-core/Models";
import { IApplicationStore } from "AppState";
import { ImageGallery } from "ares-core/UI";
import { Locale } from "ares-core/UI/Locale";
import { MathEx } from "ares-core/Utils/MathEx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 250,
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  media: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    flex: "1 0 0",
  },
  actions: {
    alignItems: "unset",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: `${theme.spacing(1)}px`,
  },
  button: {
    gridColumn: "span 1",
  },
  buttonDouble: {
    gridColumn: "span 2",
  },
  formControl: {
    gridColumn: "span 2",
  },
}));

export interface ICartItem {
  addToCart: CallableFunction;
  removeFromCart: CallableFunction;
  disallowRemove?: boolean;
}

export interface ICatalogItem extends Partial<CatalogDetail>, ICartItem {
    quantity: number;
}

type ICatalogItemPreview = {
  giftCard: boolean | Boolean | undefined;
  imgUrls: Array<string> | undefined;
};
const CatalogItemPreview = (props: ICatalogItemPreview) => {
  if (props.giftCard) {
    return <CardGiftcard fontSize="large" color="primary" />;
  }
  if (props.imgUrls?.length) {
    return <ImageGallery images={props.imgUrls} showThumbnails={true} />;
  }
  return <ImageOutlined fontSize="large" color="primary" />;
};

interface ICatalogDisplayPrice {
  unitPrice: number | undefined;
}
const CatalogDisplayPrice = (props: ICatalogDisplayPrice) => {
  let label: string = "Not for Sale";
  if (props.unitPrice) {
    const price: number = props.unitPrice;
    if (price > 0) {
      label = `${MathEx.formatCurrency(price)}`;
    } else if (price < 0) {
      label = `${MathEx.formatCurrency(-1 * price)} ${Locale.discount}`;
    } else if (price == 0) {
      label = Locale.costFree;
    }
  }
  return <Typography variant={"h5"}>{label}</Typography>;
};

interface ICatalogItemActionsCountDisplay {
  count: number;
  availible: number;
  handleCountChange: CallableFunction;
}
const CatalogItemActionsCountDisplay = (
  props: ICatalogItemActionsCountDisplay,
) => {
  const classes = useStyles();
  if (props.availible > 0) {
    return (
      <TextField
        className={classes.button}
        value={props.count}
        label={Locale.merchandiseInCart}
        onChange={(e: any) => props.handleCountChange(e.target.value)}
        InputProps={{
            readOnly: true,
        }}
      />
    );
  }
  return <TextField value={Locale.outOfStock} />;
};
interface ICatalogItemActions {
  count: number;
  availible: number;
  addToCart: CallableFunction;
  removeFromCart: CallableFunction;
  disallowRemove?: boolean;
}
const CatalogItemActions = (props: ICatalogItemActions) => {
  const classes = useStyles();
  //const [count, setCount] = useState<number>(0);
  const handleCountChange = (e: any) => {
    //const cnt = parseInt(e);
    //cnt > 0 && cnt <= props.availible && setCount(cnt);
  };
  const handleRemoveFromCart = () => props.removeFromCart(0);
  const handleAddToCart = () => props.addToCart(0);
  return (
    <CardActions disableSpacing className={classes.actions}>
      <CatalogItemActionsCountDisplay
        availible={props.availible}
        handleCountChange={handleCountChange}
        count={props.count}
      />
      {props.disallowRemove ? (
        <Button
          className={classes.button}
          onClick={handleRemoveFromCart}
          color="primary"
          variant="contained"
          aria-label="Remove from cart."
        >
            <RemoveIcon/>
        </Button>
      ) : null}
      <Button
        className={
          classes.button +
          (!props.disallowRemove ? " " + classes.buttonDouble : null)
        }
        onClick={handleAddToCart}
        color="primary"
        variant="contained"
        aria-label="Add to cart."
      >
          <AddIcon/>
      </Button>
    </CardActions>
  );
};

const CatalogItem = (props: ICatalogItem) => {
  const classes = useStyles();
  const actionProps: ICatalogItemActions = {
    addToCart: props.addToCart,
    removeFromCart: props.removeFromCart,
    availible: props.available || 0,
    disallowRemove: !props.disallowRemove,
    count: props.quantity
  };
	const { description, longDescription } = props;
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media}>
        <CatalogItemPreview giftCard={props.giftCard} imgUrls={props.imgUrls} />
      </CardMedia>
      <CardContent className={classes.content}>
        <Typography variant={"h6"}>
          {props.giftCard ? (
            Locale.giftCard
          ) : (
            <ReactMarkdown>{description ?? ''}</ReactMarkdown>
          )}
        </Typography>
        <CatalogDisplayPrice unitPrice={props.unitPrice} />
        <Typography align="left">
          {props.giftCard
            ? `${Locale.giftCardDescription} ${props.description}`
            : null}
        </Typography>
          {/**props.giftCard ? <br /> : null*/}
        <ReactMarkdown>{longDescription ?? ''}</ReactMarkdown>
      </CardContent>
      <CatalogItemActions {...actionProps} />
    </Card>
  );
};

export default CatalogItem;

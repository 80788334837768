import React, { useState, MouseEvent, useEffect, useRef } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { ExpandMore, Email } from "@material-ui/icons";

import { Address, Locale } from "ares-core/UI";
import { IAddressValue } from "ares-core/Models";

export interface DeliveryAddressPanelProps {
  expanded: boolean;
  nextStepLabel: string;
  handlePanelChange: CallableFunction;
  handleContinue: CallableFunction;
  lat: number;
  lon: number;
}

const DeliveryAddressPanelComponent = (props: DeliveryAddressPanelProps) => {
  const panelName = "delivery";
  const [address, setAddress] = useState<IAddressValue | null>();

  const handleChange = (newAddress: IAddressValue | null) => {
    setAddress(newAddress);
  };

  const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value: currPanel } = e.currentTarget;
    const newResAddress = {
      address: address?.addressText,
      lat: address?.lat,
      lon: address?.lon,
    };
    props.handleContinue(newResAddress, currPanel);
  };

  const AccordionProps = {
    expanded: props.expanded,
    onChange: props.handlePanelChange(panelName),
  };

  return (
    <Accordion {...AccordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="delivery-panel-content"
        id="delivery-panel-header"
      >
        <Grid container>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <Email />
            </Grid>
            <Grid item>
              <Typography variant="h6">Delivery/Pickup Address</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Address
              azureMapsKey={"Ygq_BxrmbqGl8T_bbldVTZgwgijkoXPIeGhvtS6AfmQ"}
              onChange={handleChange}
              value={address}
              nearbyLat={props.lat}
              nearbyLon={props.lon}
              label={Locale.pickupDelivery}
              required
              fullWidth
            />
          </Grid>
          <Grid item container xs={12} justify="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              value="delivery"
              disabled={!address?.addressText ?? true}
              onClick={onContinue}
            >
              {props.nextStepLabel}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeliveryAddressPanelComponent;

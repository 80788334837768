//
// This Class attempts to Emulate the MDN Web Storage Object such that
// Node based or non browser code can utilize a common interface. The
// current implementation is built to only support browser functionality
// and unit tests for the common library. However if the need arises to
// utilize the class to run windowless the WebStorage class can be
// adapted to read from a User's home directory, /var/tmp, /tmp etc...
//
export enum WebStorageType {
	SESSION = "SESSION",
	LOCAL = "LOCAL",
	CACHE = "CACHE"
}

export class WebStorageApi {
	private static instance?: WebStorageApi = undefined;
	private _localStorage:Storage;
	private _sessionStorage:Storage;
	private _cacheStorage:Storage;
	//
	private constructor() {
		this._localStorage = (new WebStore() as unknown) as Storage;
		this._sessionStorage = (new WebStore() as unknown) as Storage;
		this._cacheStorage = (new WebStore() as unknown) as Storage;
	}

	static Instance() {
		if (WebStorageApi.instance === undefined) {
			WebStorageApi.instance = new WebStorageApi();
		}

		if (typeof window === 'undefined') {
			// Running in Node
		} else {
			WebStorageApi.instance._localStorage = window.localStorage;
			WebStorageApi.instance._sessionStorage = window.sessionStorage;
		}
		
		return WebStorageApi.instance;
	}
	///////////////////////////
	// Storage API
	public getItem = (mode:WebStorageType,id:string) => {
		let thestore = this._cacheStorage;

		if (mode === WebStorageType.SESSION)
			thestore = this._sessionStorage;
		else if (mode === WebStorageType.LOCAL)
			thestore = this._localStorage;

		return thestore.getItem(id);
	}

	public setItem = (mode:WebStorageType,id:string, obj:any) => {
		let thestore = this._cacheStorage;

		if (mode === WebStorageType.SESSION)
			thestore = this._sessionStorage;
		else if (mode === WebStorageType.LOCAL)
			thestore = this._localStorage;

		return thestore.setItem(id,obj);
	}

	public removeItem = (mode:WebStorageType,id:string) => {
		let thestore = this._cacheStorage;

		if (mode === WebStorageType.SESSION)
			thestore = this._sessionStorage;
		else if (mode === WebStorageType.LOCAL)
			thestore = this._localStorage;

		return thestore.removeItem(id);
	}

	public clear = (mode:WebStorageType) => {
		let thestore = this._cacheStorage;
	
		if (mode === WebStorageType.SESSION)
			thestore = this._sessionStorage;
		else if (mode === WebStorageType.LOCAL)
			thestore = this._localStorage;
	
		return thestore.clear();
	}

	public key = (mode:WebStorageType, n:number) => {
		let thestore = this._cacheStorage;
	
		if (mode === WebStorageType.SESSION)
			thestore = this._sessionStorage;
		else if (mode === WebStorageType.LOCAL)
			thestore = this._localStorage;
	
		return thestore.key(n);
	}
}


// Implement the MDN (Mozilla) Storage Object Interface
class WebStore {
	private _storage:Map<string,any>;
	//
	public constructor() {
		this._storage = new Map<string,any>();
	}

	public getItem = (s:string) => {
		return this._storage.get(s);
	}

	public setItem = (s:string, obj:any) => {
		return this._storage.set(s,obj);
	}

	public removeItem = (s:string) => {
		let retval:any = this._storage.get(s);
		this._storage.delete(s);
		return retval;
	}

	public clear = () => {
		return this._storage.clear();
	}

	public key = (n: number) => {
		if (n < 0 || this._storage.size -1 < n) return null;
		let retval:any = null;
		const itr = this._storage.keys();
		for (let i=0; i < n; i++) {
			retval = itr.next();
		}
		return retval;
	}
}

const WebStorage:WebStorageApi = WebStorageApi.Instance();
export default WebStorage;

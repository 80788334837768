import React, { useState, useEffect, MouseEvent } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { DateTimeUtils, MathEx } from "ares-core/Utils";
import { IApplicationStore } from "AppState";
import { EventDateTimeItem } from "ares-core/Models";
import { Locale } from "ares-core/UI";
import { createStyles, Theme } from "@material-ui/core/styles";
import { ValidateSellingWindow, ValidateSellStatus } from "../Utis/EventTimeValidations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledText: {
      fontWeight: "bold",
      color: "#E97E30",
    },
    buttonLabelText: {
      fontWeight: "bold",
      color: "#FFFFFF",
    },
  }),
);
export interface EventTimePickerProps {
  times: EventDateTimeItem[];
  eventEarliestSell: number;
  eventLatestSell: number;
  setSelectedTime: (t: number) => void;
  selectedTime: number;
  selectedDate: Date;
}

export const EventTimePickerComponent = (props: EventTimePickerProps) => {
  const classes = useStyles();

  const handleTimeSelect = (event: MouseEvent<HTMLButtonElement>) => {
    const value = parseInt(event.currentTarget.value);
    if (value != props.selectedTime) {
      props.setSelectedTime(value);
    }
  };

  const renderAvailableEventTimes = () => {
    if (!props.times.length) {
      return (
        <Grid item container xs={12} md={12} justifyContent="center">
          <Typography variant="h6">No Times Available</Typography>
        </Grid>
      );
    }

    const timeItems = props.times.map((eTime: EventDateTimeItem) => {
      let timeString = DateTimeUtils.eventLocalTime(eTime.startTime);
      let btnText: string = "";
      let btnDisabled: boolean = true;
      let btnClass = classes.disabledText;

      let response = ValidateSellStatus(eTime);
      if(response.valid) {
          btnDisabled = false;
          btnClass = classes.buttonLabelText;
          if (eTime.startTime !== eTime.eventDateTime) {
            timeString +=
              " - " + DateTimeUtils.eventLocalTime(eTime.eventDateTime);
          }
      } else {
          btnText = response.message;
          btnClass = classes.disabledText;
      }
       response = ValidateSellingWindow(eTime.eventDateTime,props.eventEarliestSell,props.eventLatestSell);
      if(!response.valid) {
        btnText = response.message;
        btnClass = classes.disabledText;
        btnDisabled = true;
      }
      // Wrap Typography in a div to make multiline button text
      return (
        <Grid item xs={12} md={12} key={eTime.eventDateID}>
          <Button
            id={eTime.eventDateID.toString()}
            value={eTime.eventDateID}
            color="primary"
            size="large"
            variant={
              `${eTime.eventDateID}` === `${props.selectedTime}`
                ? "outlined"
                : "contained"
            }
            onClick={handleTimeSelect}
            disabled={btnDisabled}
            fullWidth
          >
            <div>
              <Typography
                align="center"
                color={btnDisabled ? "primary" : "inherit"}
                className={btnClass}
                variant="body1"
              >
                {  eTime.sellStatus != 'U' && eTime.sellStatus != 'V' ? `${ MathEx.formatCurrency(eTime.totalTicketAmount)} ${Locale.at} ${timeString}` : timeString }
              </Typography>
              <Typography
                align="center"
                variant="body2"
                color={btnDisabled ? "primary" : "inherit"}
              >
                {eTime.publicNote}
              </Typography>
              <Typography className={btnClass} align="center" variant="body2">
                {btnText}
              </Typography>
            </div>
          </Button>
        </Grid>
      );
    });

    const fromNow =
      (props.selectedDate &&
        Math.trunc(
          (new Date(props.selectedDate).getTime() -
            DateTimeUtils.stripTime(new Date()).getTime()) /
            86400000,
        )) ||
      Math.trunc(
        (new Date().getTime() - DateTimeUtils.stripTime(new Date()).getTime()) /
          86400000,
      );
    const localeFromNow = "from now";
    return (
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} md={12} key="title">
          <div>
            <Typography align="center" variant="h5">
              {DateTimeUtils.longDate(props.selectedDate)}
            </Typography>
            {fromNow > 0 ? (
              <Typography align="center" variant="h5" color="primary">
                {DateTimeUtils.durationFormat(fromNow * 86400)} {localeFromNow}
              </Typography>
            ) : null}
          </div>
        </Grid>
        {timeItems}
      </Grid>
    );
  };

  return (
    <Grid item container>
      {renderAvailableEventTimes()}
    </Grid>
  );
};

import { Button, FormControl, FormHelperText, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Alert } from "@material-ui/lab";
import { roundTo } from "ares-core";
import { ICartReservation, IReservationTip, TipMethod } from "ares-core/Models";
import { NewLineToBr } from "ares-core/Utils/NewLineToBr";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import './ReservationTipPanel.css'


interface ITipPanelProps {
    handleAddTip:(tip: IReservationTip) => void;
    reservations: ICartReservation[];
}

interface IPriceDescriptionId {
    price: number;
    description: ReactNode;
    id: number;
}

const ReservationTipPanel: FunctionComponent<ITipPanelProps> = (props) => {

    const [reservations, setReservations] = useState<IPriceDescriptionId[]>([]);
    const [selectedReservationId, setSelectectReservationId] = useState(-9999);
    const [reservationPrice, setReservationPrice] = useState(0);
    const [display, setDisplay] = useState({displayTitle: false, displaySelect: false, displayError: false});
    const [customPercentage, setCustomPercentage] = useState(0);
    const [customFixedAmount, setCustomFixedAmount] = useState(0);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

    useEffect(() => {
        const newReservations: IPriceDescriptionId[] = props.reservations.map((r) => {
            const parts = r.description?.split("\n") ?? [];
            let description = parts[0] ?? '';
            if(parts[1]) {
                description += '\n' + parts[1];
            }
            return { price: r.price!, id: r.reservationId, description: <NewLineToBr>{description ?? ''}</NewLineToBr> };
        });
        setReservations(newReservations);

        if (newReservations.length === 0) {
            setDisplay({displayTitle: false, displaySelect: false, displayError: true});

        } else if(newReservations.length === 1) {
            setDisplay({displayTitle: true, displaySelect: false, displayError: false});
            setReservationPrice(newReservations[0].price ?? 0);
            setSelectectReservationId(newReservations[0].id);
        } else {
            setDisplay({displayTitle: false, displaySelect: true, displayError: false});
        }
    }, []);


    const handleSelectReservation = (event: any) => {
        const value = parseInt(event.target.value);
        setSelectectReservationId(value);
        setDisplayErrorMessage(false);
        if(value !== -9999) {
            const selected = reservations.find(r => r.id === value);
            setReservationPrice(selected?.price ?? 0);    
        }
    }

    const addPercentTip = (percent:number) => {
        const value = roundTo(percent, 2);
        addTip({tipMethod: TipMethod.Percentage, percentTip: value, fixedAmount: 0});
    }

    const addFixedAmountTip = (amount:number) => {
        const value = roundTo(amount, 2);
        addTip({tipMethod: TipMethod.FixedAmount, percentTip: 0, fixedAmount: value});
    }

    const addTip = (tip:IReservationTip) => {
        if(reservations.length === 0) {
            return;
        } else if(reservations.length > 1 && selectedReservationId === -9999) {
            setDisplayErrorMessage(true);
            return;
        }
        tip.reservationIdAddOn = selectedReservationId;
        props.handleAddTip(tip);
    }

    
    return (
        <div className='tip-panel-container'>

            {
                display.displayError &&
                    <div style={{marginBottom: '15px'}}>
                        <Alert severity="error">There is no reservation in the shopping cart.</Alert>
                    </div>
            }

            {
                display.displayTitle && reservations[0]?.description &&
                    <Typography style={{fontWeight: '700', marginBottom: '15px'}}>
                        { reservations[0].description }
                    </Typography>
            }

            {
                display.displaySelect &&
                <FormControl style={{marginBottom: '10px'}} error={displayErrorMessage} fullWidth>
                    <Select
                        name='selectReservation'
                        onChange={handleSelectReservation}
                        variant='outlined'
                        value={selectedReservationId}
                    >
                        <MenuItem value={-9999} disabled selected>
                            <em>Select reservation</em>
                        </MenuItem>
                        {
                            reservations.map((res, idx) =>
                                <MenuItem key={idx} value={res.id}>{res.description}</MenuItem>
                            )
                        }
                    </Select>
                    <FormHelperText>Select reservation</FormHelperText>
                </FormControl>
            }

            <PercentTip tipPercent={10} handleAddTip={addPercentTip} price={reservationPrice}/>
            <PercentTip tipPercent={15} handleAddTip={addPercentTip} price={reservationPrice}/>
            <PercentTip tipPercent={18} handleAddTip={addPercentTip} price={reservationPrice}/>
            <PercentTip tipPercent={20} handleAddTip={addPercentTip} price={reservationPrice}/>
            <div className='custom-tip-row'>
                <Button
                    size="small" 
                    color="primary"
                    variant='outlined'
                    onClick={() => addPercentTip(customPercentage)}
                >
                    <ShoppingCartIcon/>
                </Button>
                <div className='flex align-items-center justify-content-end'>
                    <TextField
                        size="small"
                        type='number'
                        value={customPercentage}
                        onChange={e => setCustomPercentage(Number(e.target.value))}
                        onFocus={e => {e.target.select()}}
                        InputProps={{ inputProps: { min: 0, step: 'any' } }}
                    />
                </div>
                <div className='label'>Custom % tip</div>
                <div className='price-label'>
                    { `= $${ parseFloat(roundTo(reservationPrice * customPercentage / 100, 2).toString()).toFixed(2)}` }
                </div>
            </div>
            <div className='custom-tip-row'>
                <Button color="primary"
                    variant='outlined'
                    size="small"
                    onClick={() => addFixedAmountTip(customFixedAmount)}
                >
                    <ShoppingCartIcon fontSize={'small'}/>
                </Button>
                <div className='flex align-items-center justify-content-end'>
                    <TextField
                        size="small"
                        type='number'
                        value={customFixedAmount}
                        onChange={e => setCustomFixedAmount(Number(e.target.value))}
                        onFocus={e => {e.target.select()}}
                        InputProps={{ inputProps: { min: 0, step: 'any' } }}
                    />
                </div>
                <div className='label'>Custom $ tip</div>
                <div className='price-label'>
                    { `= $${ parseFloat(roundTo(customFixedAmount, 2).toString()).toFixed(2)}` }
                </div>
            </div>
        </div>
    );
}

export default ReservationTipPanel;






interface PercentTipProps {
    tipPercent: number;
    price: number;
    handleAddTip:(percent:number) => void;
}

const PercentTip: FunctionComponent<PercentTipProps> = (props) => {
    const tipAmount = Math.round(props.price * props.tipPercent) / 100;
    const stringAmount = parseFloat(tipAmount.toString()).toFixed(2);

    return (
    <div className='tip-row'>
        <Button color={"primary"}
            size="small"
            variant={'outlined'}
            onClick={() => props.handleAddTip(props.tipPercent)}
        >
        <ShoppingCartIcon/>
        </Button>
        <div className='label'>{props.tipPercent}% tip</div>
        <div className='price-label'>{`= $${stringAmount}`}</div>
    </div>
    );
}
import React, { useState, useEffect } from "react";
import { Grid, TextField, InputAdornment, Typography } from "@material-ui/core";
import { AccountCircle, MailOutline } from "@material-ui/icons";
import PhoneNumber from "material-ui-phone-number";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Validation } from "ares-core/Utils";
import { Locale } from "ares-core/UI";
import {
  IContactInfo,
  IContactInfoTemplate,
} from "ares-core/Models/ContactInfo";

export interface IContactInfoProps {
  contactInfo?: IContactInfo;
  onChange: (contactInfo: IContactInfo, isValid: boolean) => void;
  companyEntry: boolean;
  country: string;
  validate?: boolean;
}
export function allValid(
  fieldValidity: IContactInfoTemplate<boolean>,
  onlyRequireLastName: boolean,
): boolean {
  if (onlyRequireLastName) {
    return fieldValidity.lastName ?? false;
  }
  for (let field in fieldValidity) {
    if ((fieldValidity as any)[field] !== true) {
      return false;
    }
  }
  return true;
}
export const generateValidityObj =
  (contactInfo: IContactInfo) =>
  (validityObj: IContactInfoTemplate<boolean>) => {
    const validity = { ...validityObj };
    if (contactInfo.firstName) {
      validity.firstName =
        contactInfo.firstName !== "" && contactInfo?.firstName?.length > 0;
    }
    if (contactInfo.lastName) {
      validity.lastName =
        contactInfo.lastName !== "" && contactInfo?.lastName?.length > 0;
    }
    if (contactInfo.email) {
      validity.email = Validation.Email(contactInfo.email).length === 0;
    }
    if (contactInfo.phone) {
      validity.phone = Validation.PhoneNumber(contactInfo.phone).length === 0;
    }

    return validity;
  };
export default function ContactInfo({
  contactInfo = {},
  companyEntry = false,
  country = "US",
  onChange,
  ...props
}: IContactInfoProps) {
  const [isValid, setIsValid] = useState<IContactInfoTemplate<boolean>>({
    email: false,
    lastName: false,
    phone: false,
    firstName: false,
  } as IContactInfoTemplate<boolean>);

  const [hasBlurred, setHasBlurred] = useState<IContactInfoTemplate<boolean>>({
    email: false,
    lastName: false,
    phone: false,
    firstName: false,
  } as IContactInfoTemplate<boolean>);

  const [defaultCountry, setDefaultCountry] = useState<string>(
    country.toLowerCase(),
  );

  useEffect(() => {
    setIsValid(generateValidityObj(contactInfo));
  }, [contactInfo]);

  useEffect(() => {
    const emailValid: boolean =
      Validation.Email(contactInfo.email || "").length === 0;
    const phoneValid: boolean =
      Validation.PhoneNumber(contactInfo.phone).length === 0;
    const newValidity = {
      ...isValid,
      phone: phoneValid,
      email: emailValid,
    };
    onChange(contactInfo, allValid(newValidity, companyEntry));
  }, [props.validate]);

  useEffect(() => {
    setIsValid(generateValidityObj(contactInfo));
  }, []);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = { ...hasBlurred };
    (newValue as any)[event.target.name] = true;
    setHasBlurred(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { ...contactInfo };
    (newValue as any)[event.target.name] = event.target.value;
    const newValidity = { ...isValid };
    (newValidity as any)[event.target.name] = event.target.validity.valid;
    onChange(newValue, allValid(newValidity, companyEntry));
    setIsValid(newValidity);
  };

  const isRequired = !companyEntry;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValid = Validation.Email(event.target.value).length === 0;
    const newValidity = {
      ...isValid,
      email: emailValid,
    };
    setIsValid(newValidity);
    onChange(
      { ...contactInfo, email: event.target.value },
      allValid(newValidity, companyEntry),
    );
  };

  const handlePhoneChange = (phone: string, country: any) => {
    const phoneNum = parsePhoneNumberFromString(phone, country.countryCode);
    const phoneValid: boolean = Validation.PhoneNumber(phone).length === 0;
    if (phoneNum) {
      const newValidity = { ...isValid, phone: phoneValid };
      onChange(
        { ...contactInfo, phone: phoneNum?.number as string },
        allValid(newValidity, companyEntry),
      );
      setIsValid(newValidity);
    }
  };

  useEffect(() => {
    if (!contactInfo.phone || contactInfo.phone.length < 5) {
      setDefaultCountry(country.toLowerCase());
    }
  }, [country]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={2} style={{ paddingTop: 4, paddingBottom: 4 }}>
      <Grid item xs={12} sm={6} style={{ paddingTop: 4, paddingBottom: 4 }}>
        <TextField
          id="firstName"
          name="firstName"
          value={contactInfo.firstName || ""}
          label={Locale.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isRequired && !isValid.firstName}
          required={isRequired}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingTop: 4, paddingBottom: 4 }}>
        <TextField
          id="lastName"
          name="lastName"
          value={contactInfo.lastName || ""}
          label={Locale.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isRequired && !isValid.lastName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingTop: 4, paddingBottom: 4 }}>
        <TextField
          id="email"
          name="email"
          type="email"
          value={contactInfo.email || ""}
          label={Locale.email}
          onChange={handleEmailChange}
          onBlur={handleBlur}
          error={isRequired && !isValid.email}
          required={isRequired}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutline />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingTop: 4, paddingBottom: 4 }}>
        <PhoneNumber
          defaultCountry={defaultCountry}
          disableAreaCodes={true}
          value={contactInfo.phone || ""}
          label={Locale.phone}
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          error={isRequired && !isValid.phone}
          required={isRequired}
          variant="outlined"
          fullWidth
          countryCodeEditable={false}
          InputProps={{
            autoComplete: "off",
          }}
        />
        <Typography variant="subtitle2">
          By entering your phone number you are agreeing to receive text
          messages and/or phone calls with reservation updates and status
          information.
        </Typography>
      </Grid>
    </Grid>
  );
}

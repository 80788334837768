// TASK-2535 - Remove
import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Checkbox,
  Radio,
  Select,
  TextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { IRateEffective } from "ares-core/Models/link";
import { Rate, EventTicket } from "ares-core/Models";
import { MathEx } from "ares-core";

const useStyles = makeStyles({
  input: {
    flex: 1,
    maxWidth: 60,
  },
  description: {
    flex: 1,
    paddingLeft: 10,
  },
});

/**
 * @param duration durationInSeconds from Rate object. Values:
 *   -1: quantity inputs and some legacy checkboxes,
 *  <=0: Checkboxes,
 *   >0: Radio selection inputs with positive durations.
 * @param maxTickets maximum number of tickets that can be purchased for event.
 * @param minReservation minimum required number of tickets for event.
 * @param isSingleInput prop used to keep checkbox/radio checked when it's the only input available.
 * When isSingleInput is true, checked will always be true, regardless of value.
 */
export interface IRateItemInputField {
  duration: number;
  effectiveRate?: IRateEffective;
  handleChange: CallableFunction;
  maxTickets: number;
  minReservation: number;
  rateId: number;
  value: any;
  disabled?: boolean;
  ticketIncrement: number;
  edit?: boolean;
}

export const RateItemInputFieldNumber = (props: IRateItemInputField) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    let value = event.target.value;
    if (!props.edit && value >= props.maxTickets) {
      value = props.maxTickets;
    }
    if (!props.edit && value <= props.minReservation) {
      value = props.minReservation;
    }
    props.handleChange(Number(value));
  };
  return (
    <TextField
      onFocus={(e) => e.target.select()}
      name={`${props.rateId}`}
      value={props.value}
      onChange={handleChange}
      className={classes.input}
      type="number"
      inputProps={{ "aria-label": "rate-input" }}
      disabled={!props.effectiveRate || props.disabled}
    />
  );
};
export const RateItemInputFieldNumberSelect = (props: IRateItemInputField) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    let value = event.target.value;
    if (value >= props.maxTickets) {
      value = props.maxTickets;
    }
    if (value <= props.minReservation) {
      value = props.minReservation;
    }
    props.handleChange(value);
  };
  const options = Array.from({
    length: props.maxTickets - props.minReservation + 1,
  }).map((e, i, A) => (
    <option key={i} value={i + props.minReservation}>
      {i + props.minReservation}
    </option>
  ));
  return (
    <Select
      native
      value={props.value || props.minReservation}
      onChange={handleChange}
      className={classes.input}
      inputProps={{
        name: `${props.rateId}`,
        "aria-label": "rate-input",
      }}
      disabled={!props.effectiveRate || props.disabled}
    >
      {options}
    </Select>
  );
};
export const RateItemInputFieldCheckbox = (props: IRateItemInputField) => {
  const handleChange = (event: any) => {
    const checked = event.target.checked;
    props.handleChange(checked);
  };
  return (
    <Checkbox
      name={`${props.rateId}`}
      onChange={handleChange}
      disabled={!props.effectiveRate || props.disabled}
      inputProps={{
        "aria-label": "rate-checkbox",
      }}
    />
  );
};
export const RateItemInputFieldRadio = (props: IRateItemInputField) => {
  const handleChange = (event: any) => {
     props.handleChange(!props.value || props.minReservation);
  }
  return (
    <Radio
      name={`${props.rateId}`}
      value={`${props.rateId}`}
      onChange={(e: any) => {}}
      onClick={handleChange}
      checked={!!props.value}
      disabled={!props.effectiveRate || props.disabled}
      inputProps={{
        "aria-label": "rate-radio-input",
      }}
    />
  );
};
export const RateItemInputField = (props: IRateItemInputField) => {
  let returnable = null;
  if (props.edit) {
    returnable = <RateItemInputFieldNumber {...props} />;
  } else if (props.maxTickets === 1) {
    returnable = <RateItemInputFieldRadio {...props} />;
  } else {
    returnable = <RateItemInputFieldNumberSelect {...props} />;
  }
  return returnable;
};

/**
 *
 * @param rateData Object containing data for a single event rate.
 * @param onchange function to pass up input values to parent.
 * @param value value passed down from parent state.
 * Uses Intl.NumberFormat for formatting. Could pass locale string & currency
 * code if need be later on.
 * InputValue & onchange functions will pull from & update redux store.
 */
export interface IRateSelectionItemProps {
  rate: Rate;
  handleChange: CallableFunction;
  value: any;
  effectiveRate?: IRateEffective;
  edit?: boolean;
  disabled?:boolean;
}

export const RateSelectionItem = (props: IRateSelectionItemProps) => {
  const classes = useStyles();
  const RateInputItemFieldProps: IRateItemInputField = {
    edit: props.edit,
    disabled:props.disabled,
    duration: props.rate.durationInSeconds,
    effectiveRate: props.effectiveRate,
    handleChange: (value: any) => {
      props.handleChange(value);
    },
    maxTickets: props.rate.maxTickets,
    minReservation: props.rate.minReservation,
    rateId: props.rate.rateId,
    value: props.value?.count || 0,
    ticketIncrement:
      props.rate.ticketIncrement &&
      props.rate.ticketIncrement > 0 &&
      !props.edit
        ? props.rate.ticketIncrement
        : 1,
  };
  return (
    <FormControl fullWidth>
      <Box display="flex" flexDirection="row" alignItems="center" p={1}>
        <RateItemInputField {...RateInputItemFieldProps} />
        <Typography
          align="left"
          className={classes.description}
          aria-label="rate-description"
        >
          {props.rate.description}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          textAlign="right"
        >
          {props.effectiveRate ? (
            <Typography aria-label="rate-price">
              {MathEx.formatCurrency(props.effectiveRate.rate)}
            </Typography>
          ) : (
            <FormHelperText error aria-label="rate-price">
              No Rate Available
            </FormHelperText>
          )}
        </Box>
      </Box>
    </FormControl>
  );
};

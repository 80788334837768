import { Action, ActionCreator, Dispatch } from "redux";
import { VwEventTimeSummary, EventGroupItem,
	EventSummary, LinkEventDetail } from '../../Models'

export enum EventActionType {
  SET_EVENT_CURRENT_EVENT = "[EVENT] SET_CURRENT_EVENT",
  SET_EVENT_SUMMARIES = "[EVENT] SET_SUMMARIES",
  SET_EVENT_GROUPS = "[EVENT] SET_GROUPS",
  SET_EVENT_CAL_ITEMS = "[EVENT] SET_CAL_ITEMS",
  EVENT_IN_PROGRESS = "[EVENT] IN_PROGRESS"
}

// Loading Data
export interface ISetEventInProgressAction extends Action<EventActionType.EVENT_IN_PROGRESS> {
  bOn: boolean;
}
export const setInProgressAction: ActionCreator<ISetEventInProgressAction> = (t) => {
  return { bOn: t, type: EventActionType.EVENT_IN_PROGRESS };
};

// Event Summaries
export interface ISetEventSummariesAction extends Action<EventActionType.SET_EVENT_SUMMARIES> {
  events: Array<EventSummary>;
}

export const setEventSummariesAction: ActionCreator<ISetEventSummariesAction> = eventSummaries => {
	// Now it is set up for the reducer
  return { events: eventSummaries, type: EventActionType.SET_EVENT_SUMMARIES };
};

// Event Groups
export interface ISetEventGroupsAction extends Action<EventActionType.SET_EVENT_GROUPS> {
  eventGroups: Array<EventGroupItem>;
}
export const setEventGroupsAction: ActionCreator<ISetEventGroupsAction> = g => {
  return { eventGroups: g, type: EventActionType.SET_EVENT_GROUPS };
};

// EventCalendarItems
export interface ISetEventCalendarItemsAction extends Action<EventActionType.SET_EVENT_CAL_ITEMS> {
  calendar: VwEventTimeSummary[];
}
export const setEventCalendarItemsAction: ActionCreator<ISetEventCalendarItemsAction> = c => {
  return { calendar: c, type: EventActionType.SET_EVENT_CAL_ITEMS };
};

// Current Event
export interface ISetEventCurrentEventAction
  extends Action<EventActionType.SET_EVENT_CURRENT_EVENT> {
  currentEvent: LinkEventDetail;
}
export const setEventCurrentEventAction: ActionCreator<ISetEventCurrentEventAction> = (
  ce: LinkEventDetail,
) => {
  return {
    currentEvent: ce,
    type: EventActionType.SET_EVENT_CURRENT_EVENT,
  };
};

export type EventActions =
	| ISetEventSummariesAction
	| ISetEventGroupsAction
	| ISetEventCalendarItemsAction
	| ISetEventCurrentEventAction
	| ISetEventInProgressAction;

	export interface IEventActions {
		readonly setCurrentEvent: CallableFunction;
		readonly setEventSummaries: CallableFunction;
		readonly setEventGroups: CallableFunction;
		readonly setEventCalendarItems: CallableFunction;
		readonly setInProgress: CallableFunction;
	}

		// Convenience Function to Portal Action Dispatcher
		const BuildEventActions = (dispatcher: Dispatch) => {
			const EventActions: IEventActions = {
				setCurrentEvent: (event: LinkEventDetail) => {
					dispatcher(setEventCurrentEventAction(event));
				},
				setEventSummaries: (summaries: Array<EventSummary>) => {
					dispatcher(setEventSummariesAction(summaries));
				},
				setEventGroups: (groups: Array<EventGroupItem>) => {
					dispatcher(setEventGroupsAction(groups));
				},
				setEventCalendarItems: (calendarItems: VwEventTimeSummary[]) => {
					dispatcher(setEventCalendarItemsAction(calendarItems));
				},
				setInProgress: (status:boolean) => {
					dispatcher(setInProgressAction(status));
				},
			};
			
			return EventActions;
		};
		
		export default BuildEventActions;

import { GeneralLinkService } from "ares-core/Services/GeneralLinkService";

class UploadLinkService extends GeneralLinkService {
    constructor() {
        super(`/api`)
    }
    async getUploadDetails(companyId: number, obscuredTransactionId: string) {
        let uri = this.createUrl([`${companyId}`, 'upload', 'GetUploadDetails', obscuredTransactionId])
        return await this.getRequest(uri)
    }

    async updateImageCount(companyId: number, reservationId: number) {
        let uri = this.createUrl([`${companyId}`, 'upload', 'UpdateImageCount', `${reservationId}`])
        return await this.putRequest(uri, {})
    }
}
const uploadService = new UploadLinkService()
export default uploadService
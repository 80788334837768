export class QuestionAnswer {
  questionId: number = -1;
  questionText: string = "";
  questionType: QuestionType = -1;
  answer: string[] = []; // Maybe this should be any
  invalid: boolean = true;
  touched: boolean = false;
}

export enum QuestionType {
  Number = 0, // Summable
  Boolean, // Countable
  SingleChoice, // Countable
  MultiChoice, // Countable
  Text,
  Date,
  Phone,
  MultiChoiceEditable,
  SingleChoiceEditable
}

export interface QuestionBase {
  id: number;
  questionText: string;
  questionType: QuestionType;
}

export interface QuestionGroup {
  questionGroupId: number;
  groupName: string;
  questions: QuestionBase[];
}
export interface QuestionNumber extends QuestionBase {
  min: string;
  max: string;
  step: string;
}

export interface QuestionDate extends QuestionBase {
  min: string;
  max: string;
}

export interface QuestionSingleChoice extends QuestionBase {
  answers: string[];
}

export interface QuestionMultiChoice extends QuestionBase {
  answers: string[];
}

// TASK-2535 - Modify
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import {Button, SnackbarOrigin, Theme} from "@material-ui/core";
import {IApplicationStore} from "../../../AppState";
import {connect} from "react-redux";
import {INotificationConfig, NotificationsType} from "ares-core/Models/Notifications";
import {INotificationsStore} from "ares-core/Notifications/Types";
import NotificationsApi from "ares-core/Notifications/NotificationsApi";

const useStyles = makeStyles((theme: Theme) => ({
    errorAlert: {
        top : '4rem'
    },
}));


export const NotificationsComponent = (props: INotificationsStore & {config?: INotificationConfig}) => {
    const classes = useStyles()
    const notification = props.current;
    if (!notification) return null;

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        NotificationsApi.next();
    };

    const getSeverity = (type?: NotificationsType): "success" | "info" | "warning" | "error" | undefined => {
        switch (type) {
            case NotificationsType.success:
                return 'success';
            case NotificationsType.error:
                return 'error';
            case NotificationsType.info:
                return 'info';
            case NotificationsType.warning:
                return 'warning';
        }
    }

    const action = (description = 'close') => (
            <Button color="inherit" size="small" onClick={() => handleCloseAlert()}>
                {description}
            </Button>
        );

    const snackBarProps = () => {
        // const anchor = notification.config?.anchorOrigin || {vertical: "bottom", horizontal: "center"} as SnackbarOrigin;
        const anchor = notification.config?.anchorOrigin || props.config?.anchorOrigin as SnackbarOrigin;
        const customClassNames = notification.config?.anchorOrigin ? '' : classes.errorAlert;
        const autoHide = notification.config?.autoHideDuration || props.config?.autoHideDuration;
        const defaultProps =  {
            open:props.display,
            anchorOrigin:anchor,
            className:customClassNames,
            autoHideDuration: autoHide
        }
        // When type is default we don't have the close icon so we need to send and action
        if (notification.type === NotificationsType.default) {
           return {...defaultProps, action: action() }
        }
        // When a description for the callback exists we change the close icon to a text button
        // If we add an autoHideDuration we don't pass an action to the Snack or the autoHide will be disabled
        if (notification?.callbackDescription && !autoHide) {
            return {
                ...defaultProps,
                action: action(notification?.callbackDescription)
            }
        }
        return {...defaultProps, onClose: handleCloseAlert}
    }

    const alertProps = () => {
        if (notification?.callbackDescription) {
            return { action: action(notification?.callbackDescription) }
        }
        return { onClose: handleCloseAlert }
    }

    if (notification.type === NotificationsType.default) {
        return (
            <Snackbar {...snackBarProps()} message={notification?.message}/>
        )
    }

    return (
        <Snackbar {...snackBarProps()} >
            <MuiAlert elevation={6} variant="filled" severity={getSeverity(notification?.type)} {...alertProps()}>
                {notification?.message}
            </MuiAlert>
        </Snackbar>
    )
}

const defaultConfig: INotificationConfig = {
    autoHideDuration: 2000,
    anchorOrigin: {vertical: "bottom", horizontal: "center"}
}

NotificationsComponent.defaultProps = {config: defaultConfig};

const mapStateToProps = ({notifications}: IApplicationStore) => {
    return {...notifications};
};
export default connect(mapStateToProps)(NotificationsComponent);

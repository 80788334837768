import { Reducer } from "redux";
import { CredentialActionType, CredentialActions } from "./Actions";
import { ICredentialStore } from "../Types";
import Credentials from "../CredentialsApi";

export const CredentialStoreReducer: Reducer<ICredentialStore, CredentialActions> = (
  state: ICredentialStore = Credentials.Store(),
  action
) => {
  switch (action.type) {
	case CredentialActionType.SET_CRED_ROLES: {
		return {
			...state,
			systemAdmin: action.sysAdminRole,
			companyRoles: action.companyRoles,
			reportsRoles: action.reportsRoles,
		};
	}
    case CredentialActionType.SET_COMPANY_ID : {
		return {
			...state,
			companyId : action.companyId
		};
    }
	case CredentialActionType.SET_ARESERVATION_CREDENTIALS : {
		return {
			...state,
			aReservationUser : action.credentials
		};
	}
	case CredentialActionType.SET_AWAIVER_CREDENTIALS : {
		return {
			...state,
			aWaiverUser : action.credentials
		};
	}
    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: CredentialActions) => {};

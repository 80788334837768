export enum CoreStatus { SUCCESS, NORESULTS, FAILURE }

// Apps will attempt to always return ICoreResult
export interface ICoreResult<T = any> {
  status: CoreStatus
  payload?: T
  msg?: string
}

export interface IActionResult {
	type: any;
  status: CoreStatus;
  payload?: any;
  msg?: string;
}
//
// Derive from the MDN Base Error object
// The Base has a message:string member
// Prototype Munging https://github.com/Microsoft/TypeScript/issues/13965
// 
export class CoreException extends Error {
  location: string;
  info: string;
  originalErr?: Error;
  __proto__: Error; 
  constructor(msg:string, loc:string, info:string) {
    const trueProto = new.target.prototype;
    super(msg);
    // Capture Our Stack. The originalErr may have stack
    Error.captureStackTrace(this, CoreException);
    this.location = loc;
    this.info = info;
    this.__proto__ = trueProto;
  }
  toString() {
    return ("\n****************************"
      + "\nException: " + this.message
      + "\nLocation: " + this.location 
      + "\nInfo: " + this.info
      + "\n*****************************\n");
  }
}

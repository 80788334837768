// TASK-2535 - Modify
import React from "react";
import { Grid, Typography } from "@material-ui/core";

import CatalogItem, { ICatalogItem } from "./CatalogItem";
import { CatalogDetail, IGiftCardInformation } from "ares-core/Models";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { IPreGiftCardInformation } from "modules/Catalog/DisplayCatalog";

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    border: "1px solid black",
  },
  catalogContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}));
export interface CartCount {
  count: number;
  id: number;
}

type ICatalog = {
  urlFriendlyCompanyName?: string;
  addToCart: CallableFunction;
  removeFromCart: CallableFunction;
  catalogDisplay: Array<Partial<ICatalogItem>>;
  cartCount?: Array<CartCount>;
  giftCard?: Array<IPreGiftCardInformation>
};

const CatalogItemsList = (props: ICatalog) => {
  const classes = useStyles();
  const CatalogItemActions = (catalogItem: any) => ({
    addToCart: (quantity: number) => {
      props.addToCart(catalogItem, quantity);
    },
    removeFromCart: (quantity: number) => {
      props.removeFromCart(catalogItem.catalogId, quantity);
    },
  });
  return (
    <Grid container justifyContent="center" alignItems="flex-start" alignContent="stretch">
      <Grid item container xs={12} spacing={2} justifyContent="center">
        {props.catalogDisplay.length === 0 ? (
          <Grid item container xs={12} spacing={2} justifyContent="center">
            <Typography variant="h5">No Items</Typography>
          </Grid>
        ) : (
          props.catalogDisplay
            .map((catalogItem: any) => {
              catalogItem.quantity = 0;
              if (props.cartCount) {
                const countObj = props.cartCount.filter((x) => x.id === catalogItem.catalogId);
                if (countObj && countObj.length > 0) {
                  catalogItem.quantity = countObj[0].count;
                }
              }
              if (props.giftCard && props.giftCard.find(x => x.catalogId === catalogItem.catalogId)?.giftCardInformation) {
                catalogItem.quantity = props.giftCard.find(x => x.catalogId === catalogItem.catalogId)?.giftCardInformation.length
              }
              return catalogItem;
            })
            .map((catalogItem: any, i: number, A: any) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={i} >
                  <CatalogItem {...catalogItem} {...CatalogItemActions(catalogItem)} />
                </Grid>
              );
            })
        )}
      </Grid>
    </Grid>
  );
};

export default CatalogItemsList;

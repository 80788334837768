import { EventDateTimeItem } from "../../../ares-core/Models";
import { DateTimeUtils, Locale } from "../../../ares-core";

export interface EventTimeValidationResponse {
  valid: boolean;
  message: string;
}

export const ValidateSellStatus = (eTime: EventDateTimeItem): EventTimeValidationResponse => {
  let response: EventTimeValidationResponse = {
    valid: false,
    message:''
  }
  if (eTime.sellStatus === "O" || eTime.sellStatus === "Open") {
    response.valid = true;
    if (eTime.startTime !== eTime.eventDateTime) {
      response.message = DateTimeUtils.eventLocalTime(eTime.startTime) +
        " - " + DateTimeUtils.eventLocalTime(eTime.eventDateTime);
    }
  } else if (eTime.sellStatus === "S" || eTime.sellStatus === "SoldOut") {
    response.message = Locale.soldOut;
    response.valid = false;
  }
  return response;
}

/**
 * Also known as booking window
 */
export const ValidateSellingWindow= (eventDate: string, earliestSell: number, latestSell: number): EventTimeValidationResponse => {
  let response: EventTimeValidationResponse = {
    valid: true,
    message:''
  }

  const now = new Date();
  const eventDateTime = new Date(eventDate);
  const latestSellTime = new Date(eventDateTime.getTime() - latestSell * 1000);
  const earliestSellTime = new Date(eventDateTime.getTime() - earliestSell * 1000);

  if (now.getTime() < earliestSellTime.getTime()) {
    response.message = Locale.tooEarly + ` (${DateTimeUtils.longDate(earliestSellTime)})`;
    response.valid = false;
  } else if ( now.getTime() >= latestSellTime.getTime()) {
    response.message = Locale.tooLate;
    response.valid = false;
  }
  return response;
}
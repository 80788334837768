import { Locale } from "ares-core/UI";
import {CountryCode, isValidPhoneNumber, parsePhoneNumberFromString} from "libphonenumber-js";

export class Validation {
  //now checks for at least 2 names that are at least 2 letters each
  static Name(value: string='') {
    let errorMessage: string = ``;
    let singleName = (value: string) => value.split(" "); //split(" ", trim(value));
    let gtNameLength = (value: string) => value.length >= 2; //gte(length(value), 2); //length(value)
    // let nameLength = value => length(value) < 2;

    if (!value) {
      errorMessage = Locale.nameRequired;
    } else if (value.length < 2) {
      errorMessage = Locale.tooShort;
    // } else if (value.length > 50) {
    //   errorMessage = Locale.tooLong;
      //   } else if (value.trim().length < 5) {
      // errorMessage = currentLocale.bothNames;
      // } else if (!isEmpty(filter(nameLength, singleName(value)))) {
      //   errorMessage = currentLocale.tooShort;
      //   } else if (gte(1, length(filter(gtNameLength, singleName(value))))) {
      //     errorMessage = currentLocale.tooShort;
    }
    return errorMessage;
  }

  static Email = (value: string = '') => {
    let errorMessage: string = ``;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = Locale.emailError;
    }
    return errorMessage;
  };

  static PhoneNumber = (value: string = '', countryCode?: CountryCode): string => {
    let errorMessage: string = ``;
    if(value && !isValidPhoneNumber(value, countryCode)) {
      errorMessage = Locale.phoneNumberError;
    }
    return errorMessage;
  }

  // Used for credit card validation.  All credit
  // card numbers are Luhn numbers.  The number
  // must be in string format with no spaces.
  static isValidLuhn(numberString: string): boolean {
    let digit, n, sum, _len, _ref;
    sum = 0;
    _ref = numberString.split("").reverse().join("");
    for (n = 0, _len = _ref.length; n < _len; n++) {
      digit = _ref[n];
      digit = +digit;
      if (n % 2) {
        digit *= 2;
        if (digit < 10) {
          sum += digit;
        } else {
          sum += digit - 9;
        }
      } else {
        sum += digit;
      }
    }
    return sum % 10 === 0;
  }

  static validateName(value: string) {
    let errorMessage: string = ``;
    let singleNameTrim: string[] = value.trim().split(" ");
    let gtNameLength = (value: string) => value.length >= 2;
    let singleNameLength = singleNameTrim.length >= 2;

    if (!value) {
      errorMessage = Locale.nameRequired;
    } else if (value.length < 5) {
      errorMessage = Locale.tooShort;
    } else if (value.length > 50) {
      errorMessage = Locale.tooLong;
    } else if (value.trim().match(/ /) === null) {
      errorMessage = Locale.fullName;
    } else if (singleNameTrim.filter((r: any) => gtNameLength(r)).length <= 1) {
      errorMessage = Locale.tooShort;
    }
    return errorMessage;
  }

  static validateZip(value: string) {
    // Several countries don't have zip codes.
    // When we add country list to checkout we can
    // validate for the country.
    let errorMessage: string = "";
    return errorMessage;
  }

  static validateUSZip(value: string) {
    let errorMessage: string = "";
    if (!value) {
      errorMessage = Locale.zipRequired;
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
      errorMessage = Locale.zipUSInvalid;
    }
    return errorMessage;
  }

  static requiredValue(value: string='') {
    let errorMessage: string = "";
    if (!value || value?.length < 2) {
      errorMessage = Locale.required;
    }
    return errorMessage;
  }

  static validateAddress(value: string) {
    let errorMessage: string = "";
    if (value && value.trim().split(/ /).length < 3) {
      errorMessage = Locale.streetAddress;
    }
    return errorMessage;
  }

  static isGiftCard(code: string): boolean {
    // Codes are separated by blank space
    let strArray: string[] = code.split(" ");
    let segCount: number = 3;
    if (strArray.length !== segCount) return false;

    // Each code segment is 5 charatcers long
    let good: boolean = true;
    let i: number = 0;
    while (good && i < segCount) {
      if (strArray[i].length !== 5) good = false;
      i++;
    }

    return good;
  }
  static isNumericValue = (value : string) =>{
    const regx = /^-?\d+\.?\d*$/
    return regx.test( value )
  }
}

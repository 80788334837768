// TASK-2535 - Remove
import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import creditCardType from "credit-card-type";

import { Locale } from "../../UI";
import { Validation } from "../../Utils";
import { ICheckoutProps } from "./CheckoutProps";


export interface IDirectCCEntryProps extends ICheckoutProps {
}

export default function DirectCCEntry(props: IDirectCCEntryProps) {
  const classes = useStyles();
  useEffect(()=> {
      if(props.proceedWithCheckout) {
        const _isValid = panValid && expValid && cvvValid;
        handleReady(_isValid);
      }
  }, [props.proceedWithCheckout])
  const [pan, setPan] = useState<string>("");
  const [panTouched, setPanTouched] = useState(false);
  const [panValid, setPanValid] = useState(false);
  const [cvvLen, setCvvLen] = useState(5);
  const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberString = stripNonDigits(event.target.value);
    let isValid = panValid;
    const ccTypes = creditCardType(numberString);
    if (ccTypes && ccTypes.length === 1) {
      const ccType = creditCardType.getTypeInfo(ccTypes[0].type as string);
      setCvvLen(ccType.code!.size as number);
      isValid =
        ccType.lengths!.includes(numberString.length) &&
        Validation.isValidLuhn(numberString);
      let offsets = [0].concat(ccType.gaps as number[], numberString.length);
      let components: string[] = [];
      for (var i = 0; offsets[i] < numberString.length; i++) {
        let start = offsets[i];
        let end = Math.min(offsets[i + 1], numberString.length);
        components.push(numberString.substring(start, end));
      }
      setPan(components.join(" "));
    } else {
      isValid = false;
      setPan(numberString);
    }
    setPanValid(isValid);
    const _isValid = isValid && expValid && cvvValid;
    props.onChange(_isValid, {});
  };

  const [exp, setExp] = useState<string>("");
  const [expTouched, setExpTouched] = useState(false);
  const [expValid, setExpValid] = useState(false);
  const handleExpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputExp = event.target.value;
    const backspace = inputExp.length < (exp ? exp!.length : 0);
    const addedSlash = inputExp.includes("/");
    let expString = stripNonDigits(inputExp);
    let m = Number(expString.substring(0, 2));
    let newExp = expString;
    let isValid = false;
    if (
      expString[0] !== "0" &&
      expString.length === 1 &&
      (m > 1 || addedSlash)
    ) {
      expString = "0" + expString;
      m = Number(expString.substring(0, 2));
    }
    if (expString.length > 1) {
      newExp = (m < 10 ? "0" : "") + `${m}`;
      if (expString.length > 2 || (!backspace && newExp.length >= 2)) {
        newExp += " / ";
      }
    }
    if (expString.length > 2) {
      const y = Number(expString.substring(expString.length > 4 ? 3 : 2));
      newExp += `${y}`;
      isValid = new Date(y + 2000, m, 1) > new Date();
    }
    setExpValid(isValid)
    setExp(newExp);
    const _isValid = panValid && isValid && cvvValid;
    props.onChange(_isValid, {});
  };

  const [cvv, setCvv] = useState<string>("");
  const [cvvTouched, setCvvTouched] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const handleCvvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCvv = stripNonDigits(event.target.value);
    setCvv(newCvv);
    let isValid = event.target.validity.valid;
    setCvvValid(isValid);
    const _isValid = panValid && expValid && isValid;
    props.onChange(_isValid, {});
  };

  const stripNonDigits = (input: string): string =>
    input ? input.replace(/[^0-9]+/g, "") : "";

  const handleReady = (isValid:boolean) => {
    if (isValid) {
      const token = `${stripNonDigits(pan)}|${stripNonDigits(exp)}|${stripNonDigits(cvv)}`;
      props.onPaymentReady(isValid, token);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              error={panTouched && !panValid}
              onChange={handlePanChange}
              onBlur={() => setPanTouched(true)}
              name="pan"
              value={pan}
              label={Locale.cardNumber}
              required
              helperText={panTouched && !panValid ? Locale.invalidCC : null}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth={true}
              error={expTouched && !expValid}
              onChange={handleExpChange}
              onBlur={() => setExpTouched(true)}
              name="exp"
              value={exp}
              label={Locale.expirationCC}
              required
              helperText={expTouched && !expValid ? Locale.invalid : null}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth={true}
              error={cvvTouched && !cvvValid}
              onChange={handleCvvChange}
              onBlur={() => setCvvTouched(true)}
              name="cvv"
              value={cvv}
              label={Locale.cvv}
              required
              helperText={cvvTouched && !cvvValid ? Locale.invalid : null}
              inputProps={{ maxLength: cvvLen, minLength: cvvLen }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: "1px",
      padding: "0.5em",
      borderStyle: "solid",
    },
    hasFocus: {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
    hasError: {
      borderColor: theme.palette.error.main,
    },
    submit: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {ScheduleOutlined} from "@material-ui/icons";
//const formatter = new Intl.DateTimeFormat(undefined, {dateStyle: "full", timeStyle: "short"} as any);
const formatter = new Intl.DateTimeFormat(undefined, {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: 'numeric',
    minute: '2-digit',
} as any);

export default function SimpleClock() {
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    useEffect(() => {
        var clockInterval = setInterval(() => setCurrentTime(new Date()), 10_000);
        return (() => clearInterval(clockInterval));
    }, []);
    return <Box >
        <Typography style={{display:'flex', lineHeight: 1.1}} >
            <ScheduleOutlined style={{marginRight:'8px'}}/>
            {formatter.format(currentTime)}
        </Typography>
    </Box>
}

import {SnackbarOrigin} from "@material-ui/core";

export enum NotificationsType {
    success ='success',
    error ='error',
    warning ='warning',
    info = 'info',
    default = 'default'
}
export interface INotificationConfig {
    autoHideDuration?: number | null;
    anchorOrigin?: SnackbarOrigin | undefined;
}
export interface INotification {
    message: string;
    id?: number;
    type: NotificationsType;
    callback?: () => void;
    callbackDescription?: string;
    config?: INotificationConfig
}

export interface SmsQM
{
	companyId: number;
	reservationIds?: Array<number>;	// required if PhoneNumber is null
	messageBody?: string;			// required if CannedMessageName is null
	phone?: string;					// ITU E.164 format. Required if ReservationIds empty
	cannedMessageName?: string;		// required if MessageBody is null
	includeRescheduleLink: boolean;	// required
}
import React from 'react'
import PhoneNumber from "material-ui-phone-number";

// This interface doesn't extends from IBaseQuestionInputProps since onChange and value props are different
export interface IPhoneNumberQuestionProps {
    error:  any;
    value: any;
    onChange: (value: string, country: any) => void
    onBlur: () => void
}

export function PhoneNumberQuestion(props: IPhoneNumberQuestionProps) {
    return (
        <PhoneNumber
            disableAreaCodes={true}
            value={props.value}
            defaultCountry="us"
            error={props.error}
            onChange={props.onChange}
            onBlur={props.onBlur}
            variant="outlined"
          />
    )
}

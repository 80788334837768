import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { EventList } from "modules/Events";
import {getParamsDictionary} from "../../ares-core/Utils/Location";

export const DisplayEvents = (props:any) => {

  const location = useLocation();

	const groupID:string | null  = (() => {
		const queryParams = getParamsDictionary(location.search);
		return queryParams.hasOwnProperty('groupid') ? queryParams['groupid'] :null;
	})();

	// props.path is not a Router path. It is a an interface
	// member value of routes set in AppsRoutes.tsx
  const isBoost: boolean = props.path.includes('/boost/:urlFriendlyCompanyName');

  let boostSupplier: string | null = null;

	// props.path is not a Router path. It is a an interface
	// member value of routes set in AppsRoutes.tsx
  if(props.path.includes('/boost/:urlFriendlyCompanyName/:secondFriendlyCompanyName')) {
    const path: string[] = location.pathname.split('/')
    boostSupplier = path[3];
  }


  return (
    <section>
      <EventList groupId={groupID} 
        isBoost={isBoost} 
        boostSupplierCompany={boostSupplier}
      />
    </section>
  );
};

export default DisplayEvents;

import { Action, ActionCreator, Dispatch } from "redux";
import {
	ReservationData,
	ReservationEditData,
	EventDateReservationList,
	ReservationListItem,
	VwManagementCalendar,
	ICheckInCheckOut
} from '../../Models/Reservation';
import { IAddressValue } from '../../Models/Address';
import { IRefundResult } from "../../Models";

export enum ReservationActionType {
	SET_RES_CURRENT = "[RES] SET_CURRENT",
	SET_RES_EDIT_DATA = "[RES] SET_EDIT_DATA",
	SET_RES_ITEM = "[RES] SET_ITEM",
	SET_RES_SUMMARY = "[RES] SET_SUMMARY",
	SET_RES_DELIVERY_ADDRESS = "[RES] SET_DELIVERY_ADDRESS",
	SET_RES_ANSWERS = "[RES] SET_ANSWERS",
  	RESERVATION_IN_PROGRESS = "[RES] IN_PROGRESS",
  	SET_PUBLIC_NOTE = "[RES] SET_PUBLIC_NOTE",
	SET_RES_CALENDAR = "SET_RES_CALENDAR",
	SET_REFUND_RESULT = "SET_REFUND_RESULT",
	UPDATE_RES_SUMMARY = "UPDATE_RES_SUMMARY",
	UPDATE_CHECK_IN_OUT = "[RES] CHECK_IN_OUT"
}

// Loading Data
export interface ISetReservationInProgressAction extends Action<ReservationActionType.RESERVATION_IN_PROGRESS> {
  bOn: boolean;
}
export const setInProgressAction: ActionCreator<ISetReservationInProgressAction> = (t) => {
  return { bOn: t, type: ReservationActionType.RESERVATION_IN_PROGRESS };
};

// Reservation
export interface ISetCurrentReservationAction extends Action<ReservationActionType.SET_RES_CURRENT> {
  currentRes: ReservationData;
}
export const setCurrentReservationAction: ActionCreator<ISetCurrentReservationAction> = r => {
  return { currentRes: r, type: ReservationActionType.SET_RES_CURRENT };
};

// Reservation Edit Data
export interface ISetReservationEditDataAction extends Action<ReservationActionType.SET_RES_EDIT_DATA> {
  reservationEditData: ReservationEditData;
}
export const setReservationEditDataAction: ActionCreator<ISetReservationEditDataAction> = r => {
  return { reservationEditData: r, type: ReservationActionType.SET_RES_EDIT_DATA };
};

export interface ISetReservationItemAction extends Action<ReservationActionType.SET_RES_ITEM> {
	resListItem: ReservationListItem | null;
}
export const setReservationItemAction: ActionCreator<ISetReservationItemAction> = r => {
  return { resListItem: r, type: ReservationActionType.SET_RES_ITEM };
};
export interface ISetReservationSummaryAction extends Action<ReservationActionType.SET_RES_SUMMARY> {
  data: EventDateReservationList | null;
}
export const setReservationSummaryAction: ActionCreator<ISetReservationSummaryAction> = r => {
  return { data: r, type: ReservationActionType.SET_RES_SUMMARY };
};

export interface IUpdateReservationSummaryAction extends Action<ReservationActionType.UPDATE_RES_SUMMARY> {
	reservation: ReservationData;
}
export const updateReservationSummaryAction: ActionCreator<IUpdateReservationSummaryAction> = r => {
return { reservation: r, type: ReservationActionType.UPDATE_RES_SUMMARY };
};

// Reservation Delivery Address
export interface ISetDeliveryAddressAction extends Action<ReservationActionType.SET_RES_DELIVERY_ADDRESS> {
  address: IAddressValue;
}
export const setDeliveryAddressAction: ActionCreator<ISetDeliveryAddressAction> = a => {
  return { address: a, type: ReservationActionType.SET_RES_DELIVERY_ADDRESS };
};

// Reservation Answers to questions
export interface ISetAnswersAction extends Action<ReservationActionType.SET_RES_ANSWERS> {
  answers: string;
}
export const setAnswersAction: ActionCreator<ISetAnswersAction> = a => {
  return { answers: a, type: ReservationActionType.SET_RES_ANSWERS };
};
export interface ISetPublicNoteAction extends Action<ReservationActionType.SET_PUBLIC_NOTE> {
  publicNote: string;
}
export const setPublicNoteAction: ActionCreator<ISetPublicNoteAction> = a => {
  return { publicNote: a, type: ReservationActionType.SET_PUBLIC_NOTE };
};

// Reservation Calendar of Events Data
export interface ISetCalendarAction extends Action<ReservationActionType.SET_RES_CALENDAR> {
  calendarEvents: VwManagementCalendar[];
}
export const setCalendarAction: ActionCreator<ISetCalendarAction> = events => {
  return { calendarEvents: events, type: ReservationActionType.SET_RES_CALENDAR };
};

// Reservation Calendar of Events Data
export interface ISetRefundResultsAction extends Action<ReservationActionType.SET_REFUND_RESULT> {
	result: IRefundResult;
  }
export const setRefundResultAction: ActionCreator<ISetRefundResultsAction> = (results:IRefundResult) => {
	return { result: results, type: ReservationActionType.SET_REFUND_RESULT };
};

export interface ICheckInCheckOutAction extends Action<ReservationActionType.UPDATE_CHECK_IN_OUT>{
	result : ICheckInCheckOut
}
export const setCheckInCheckoutAction : ActionCreator<ICheckInCheckOutAction> = (results : ICheckInCheckOut) =>{
	return { result : results, type : ReservationActionType.UPDATE_CHECK_IN_OUT }
}

export type ReservationActions =
	| ISetCurrentReservationAction
	| ISetReservationEditDataAction
	| ISetReservationItemAction
	| ISetDeliveryAddressAction
	| ISetAnswersAction
	| ISetReservationInProgressAction
	| ISetReservationSummaryAction
	| IUpdateReservationSummaryAction
	| ISetPublicNoteAction
	| ISetCalendarAction
	| ISetRefundResultsAction
	| ICheckInCheckOutAction;

	export interface IReservationActions {
		readonly setCurrentReservation: CallableFunction;
		readonly setReservationEditData: CallableFunction;
		readonly setReservationItem: CallableFunction;
		readonly setReservationSummary: (summary: EventDateReservationList | null) => void;
		readonly updateReservationSummary: (reservation: ReservationData) => void;
		readonly setDeliveryAddress: CallableFunction;
		readonly setAnswers: CallableFunction;
		readonly setCalendarEvents: CallableFunction;
		readonly setInProgress: CallableFunction;
		readonly setPublicNote: (publicNote: string) => void
		readonly setRefundResult: (result:IRefundResult) => void;
		readonly setUpdateCheckInCheckOut : (result : ICheckInCheckOut) => void
	}

// Convenience Function to Portal Action Dispatcher
const BuildReservationActions = (dispatcher: Dispatch) => {
	const ReservationActions: IReservationActions = {
		setCurrentReservation: (r:ReservationData) => {
			dispatcher(setCurrentReservationAction(r))
		},
		setReservationEditData: (r:ReservationEditData) => {
			dispatcher(setReservationEditDataAction(r))
		},
		setReservationItem: (r:ReservationData) => {
			dispatcher(setReservationItemAction(r))
		},
		setReservationSummary: (r) => {
			dispatcher(setReservationSummaryAction(r))
		},
		updateReservationSummary: (r:ReservationData) => {
			dispatcher(updateReservationSummaryAction(r))
		},
		setDeliveryAddress: (r:ReservationData) => {
			dispatcher(setDeliveryAddressAction(r))
		},
		setAnswers: (r:ReservationData) => {
			dispatcher(setAnswersAction(r))
		},
		setCalendarEvents: (e:VwManagementCalendar[]) => {
			dispatcher(setCalendarAction(e))
		},
		setInProgress: (status:boolean) => {
			dispatcher(setInProgressAction(status));
		},
		setPublicNote: (publicNote:string) => {
			dispatcher(setPublicNoteAction(publicNote));
		},
		setRefundResult: (result:IRefundResult) => {
			dispatcher(setRefundResultAction(result));
		},
		setUpdateCheckInCheckOut(result : ICheckInCheckOut) {
			dispatcher(setCheckInCheckoutAction(result))
		},
	};
	
	return ReservationActions;
};

export default BuildReservationActions;

import {GeneralLinkService} from "../../ares-core/Services/GeneralLinkService";
import {CoreStatus, ICoreResult} from "../../ares-core/Types";

export type RescheduleResponse = {success: boolean,errors: string[]};
class RescheduleLinkService extends GeneralLinkService {
    constructor() {
        super('/api/reschedule');
    }
    async reschedule(companyId:number,rescheduleKey:string,newEventDateId: number): Promise<RescheduleResponse | null> {
        let uri = this.createUrl([companyId.toString(),rescheduleKey,newEventDateId.toString()]);
        const response = await this.postRequest(uri,{}) as ICoreResult<RescheduleResponse>;
        if(response.status === CoreStatus.SUCCESS) {
            if(response.payload) return response.payload;
        }
        return null;
    }
}


const service = new RescheduleLinkService();
export default service;

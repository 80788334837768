import {GeneralPortalService} from "./GeneralPortalService";
import {CoreStatus} from "../Types";
import {CreateEventDateSettings, EventDateReservationSummary, EventDateSettings} from "../Models";
import {formatISO} from "date-fns";
import {DateTimeUtils, isEmpty} from "../Utils";

class EventDateService extends GeneralPortalService{
    constructor() {
        super('/portal/EventDate');
    }
    async createEventDate(companyId: number,  eventId: number, eventDateTime: Date, startDate?: Date): Promise<EventDateReservationSummary | null> {
        let uri = this.createUrl(['Create']);
        eventDateTime.setSeconds(0);
        eventDateTime.setMilliseconds(0);
        const data: CreateEventDateSettings = {
            eventId:eventId.toString(),
            eventDateTime: DateTimeUtils.toISOStringWithoutTimezone(eventDateTime)
        }
        if(!isEmpty(startDate)) {
            data.startDate = DateTimeUtils.toISOStringWithoutTimezone(startDate!);
        }
        const result = await this.postRequest<CreateEventDateSettings>(uri,companyId.toString(),data);
        if (result.status === CoreStatus.SUCCESS) {
            return result.payload as EventDateReservationSummary;
        }
        return null
    }
    updateEventDate(companyId:number,eventDateId:number,data:EventDateSettings) {
        let uri = this.createUrl([eventDateId.toString()]);
        return this.putRequest<EventDateSettings>(uri,companyId.toString(),data);
    }
}
const service = new EventDateService();
export default service;

import React, {useEffect, useState} from "react";
import {IconButton, Button, Box, Typography, TextField, Paper } from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ClearIcon from '@material-ui/icons/Clear';
import { MathEx } from "ares-core/Utils";
import {IGiftCardDetail} from 'ares-core/Models';
import {Locale} from 'ares-core/UI/Locale';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: `${theme.spacing(2)}px 0`,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    title: {
        alignSelf: "flex-start",
    },
    empty: {alignSelf:"center",},
    item: {
        display: "grid",
        gridTemplateAreas: `
            "title title title price" 
        `,
        gridTemplateColumns: `${theme.spacing(3)}px 2fr 1fr 1fr`,
        justifyItems: "stretch",
        alignItems: "left",
    },
    item_title: {
        alignSelf: "center",
        paddingLeft: theme.spacing(2),
        gridArea: "title",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    item_price: {
        gridArea: "price", 
        justifySelf: "stretch",
        alignSelf: "stretch",
        display: "flex", 
        flexDirection: "column",
        alignItems: "space-between"
    },
    item_price_row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    item_input: {
        marginRight: theme.spacing(6),
    }
}));

export interface IGiftCardInput {
    applyGiftCard: CallableFunction;
}
export const GiftCardInput = (props:IGiftCardInput) => {
    const classes = useStyles();
    const [code, setCode] = useState<string>('');
    const handleChange = (e:any) => {
        let value = e.target.value;
        setCode(value);
    }
    const handleApplyCode = () => {
        if (code.length > 0) {
            props.applyGiftCard(code);
        }
    }
    return (
		<>
		{/* <Paper elevation={0}  border-radius={20}> */}
			<Box display="flex" >
					<Box p={1} width="100%" >
							<TextField fullWidth 
									value={code} 
									onChange={handleChange}
									placeholder={Locale.giftCardCode} />
					</Box>
					<Box p={1} flexShrink={1} >
						<Button
								variant="contained" 
								color="primary"
								onClick={handleApplyCode}
						>
							{Locale.apply} 
						</Button>
					</Box>
			</Box>
		{/* </Paper> */}
		</>
    )
}

export interface IGiftCard extends IGiftCardDetail{
    removeGiftCard: CallableFunction;
}
export const GiftCard = (props:IGiftCard) => {
    const classes = useStyles();
    const handleRemove = () => {
        props.removeGiftCard();
    }
    return (
        <Box className={classes.item}>
            <Box className={classes.item_title}>
                <TextField disabled value={props.code} label={Locale.giftCardCode}/>
                <IconButton color="primary" onClick={handleRemove}>
                    <ClearIcon/>
                </IconButton>
            </Box>
            <Box className={classes.item_price}>
            <Box className={classes.item_price_row}>
                <Typography variant="overline">{Locale.balance}</Typography>
                <Typography variant="h6">
                    {MathEx.formatCurrency(props.balance)}
                </Typography>
            </Box>
            <Box className={classes.item_price_row}>
                <Typography variant="overline">{Locale.originalPrice}</Typography>
                <Typography variant="h6">
                    {MathEx.formatCurrency(props.originalPrice)}
                </Typography>
            </Box>
            </Box>
        </Box>
    );
}

export interface IGiftCardDisplay {
    giftCards: Array<IGiftCardDetail>,
    removeGiftCard: CallableFunction,
    applyGiftCard: CallableFunction
}

const GiftCardsDisplay = (props:any) => {
    const classes = useStyles();
    const handleItems = (code:string) => ({
        removeGiftCard: () => {
            props.removeGiftCard(code);
        },
    })
    const applyGiftCard = (code:string) => {
        props.applyGiftCard(code);
    }
    return (
        <Box className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                <CardGiftcardIcon />
                {Locale.giftCards}
            </Typography>
            {
            props.giftCards.map((giftCard:IGiftCardDetail) => ({
                ...giftCard, ...handleItems(giftCard.code)
            })).map((item:any, k:number, A:any) => (<GiftCard key={k} {...item} />))
            }
            <GiftCardInput applyGiftCard={applyGiftCard} />
        </Box>
    );
}

export default GiftCardsDisplay;
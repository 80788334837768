// TASK-2535 - Remove
import React from 'react';
import { 
Button,
Grid,
Box,
TextField,
MenuItem,
InputLabel,
Select,
FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Locale} from 'ares-core/UI/Locale';
import {HowHeardAnsItem} from 'ares-core/Models/Cart';


const useStyles = makeStyles((theme) => ({
  root: {
      minWidth: "100%",
  },
}));

export interface IHowDidYouFindUsQuestion {
    onChange: CallableFunction;
    answer: number;
    answers: HowHeardAnsItem[];
    portalMode?: boolean;
}

export const HowDidYouFindUsQuestion = (props:IHowDidYouFindUsQuestion) => {
    const classes = useStyles();
    const handleOnChange = (e:any) => {
        const value = parseInt(e.target.value);
        props.onChange(value);
    }
    const answers = props.answers || [];
    const required: boolean = !props.portalMode // only required if no portal
    return (
      answers.length > 0 ? 
	<Grid container spacing={2}>
		<Grid item xs={12}>
          <TextField
            name="howDidYouFindUs"
            value={props.answer || ""}
            label={Locale.HowDidYouFindOutAboutUs}
            onChange={handleOnChange}
            fullWidth
            required={required}
            select
            SelectProps={{ native: true }}
            inputProps={{ "data-testid": "howDidYouFindUs-input" }}
          >
            <option key={"null"} value={0} data-testid={"howDidYouFindUs-option"} ></option>
            {answers.map((e, i) => (
              <option key={i} value={e.howHeardAnswerID} data-testid={"howDidYouFindUs-option"}> {e.answer} </option>
            ))}
          </TextField>
        </Grid>
    </Grid>
    : null
    ); 
};

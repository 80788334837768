export const Locale = {
  locale: "en-US",
  currency: "USD",
  isMetric: false,
  metric: "Metric",
  imperial: "Imperial",
  next: "Next",
  back: "Back",
  day: "day",
  days: "days",
  minute: "minute",
  minutes: "minutes",
  getTimes: "Get times",
  tooLate: "Too Late to Purchase Online - Please Call",
  tooEarly: "Too Early to Purchase Tickets",
  canPurchase: "Can be purchased after:",
  fromNow: "from now",
  loading: "Loading...",
  notRunning: "This event is not currently running",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  country: "Country",
  state: "State",
  invalidEmail: "Invalid email address",
  mobileNumber: "Mobile number",
  invalidPhone: "Invalid phone number",
  required: "Required",
  yes: "Yes",
  no: "No",
  ftIn: "ft/inches",
  meters: "meters",
  height: "Height",
  weight: "Weight",
  pounds: "pounds",
  kilos: "kilograms",
  invalidHeight: "Invalid height",
  invalidWeight: "Invalid weight",
  ordinalSuffixOne: "st",
  ordinalSuffixTwo: "nd",
  ordinalSuffixFew: "rd",
  ordinalSuffixOther: "th",
  invalidQuestions: "You entered an invalid answer",
  invalidMissingQuestions: "You have not answered all questions",
  ticketing: "Ticketing by Indexic.net",
  reservationHolder: "Reservation Holder Information",
  tickets: "Tickets",
  type: "Type",
  rate: "Rate",
  feet: "feet",
  kg: "kg",
  lb: "lb",
  lbs: "lbs",
  m: "m",
  cm: "cm",
  in: "in",
  inches: "inches",
  ft: "ft",
  agree: "Agree",
  disagree: "Disagree",
  billingInfo: "Billing Information",
  phone: "Phone Number",
  emailAddress: "Email Address",
  tooShort: "Too short.",
  tooLong: "Too Long.",
  nameRequired: "Name is Required.",
  bothNames: "Please Enter First and Last Name.",
  emailError: "Invalid Email Address.",
  eventDates: "Event Dates",
  checkAvail: "Check Availability",
  createCart: "Create/Get Shopping Cart",
  cart: 'Cart',
  shoppingCart: "Shopping Cart",
  groupQuestions: "Group Questions",
  ticketQuestions: "Ticket Questions",
  rememberBilling: "Remember reservation holder data for credit card payment",
  mainMenu: "Main Menu",
  reset: "Reset",
  clear: "Clear",
  rateList: "Ticket Rates",
  merchandise: "Merchandise",
  merchandiseQuantityLabel: "Added Items:",
  merchandiseAddedToReservation: "Merchandise added to reservation.",
  merchandiseInCart: "In Cart",
  complete: "Complete",
  done: "Done",
  availTimes: "Available Times",
  continue: "Continue",
  addEvents: "Add More Events",
  duration: "Duration:",
  participant: "Participant",
  cancel: "Cancel",
  noEvents: "No events are currently available",
  noEvent: 'This event is not currently available',
  questions: "Questions",
  maxTickets: "Maximum # Tickets Available",
  noRatesAvailable:
    "There are currently no available rates, please select another date.",
  viewCart: "View Cart",
  checkout: "Checkout",
  justMetric: "metric",
  cardNumber: "Card number",
  invalidCC: "Invalid card number",
  expirationCC: "Expiration MM/YY",
  invalid: "Invalid",
  cvv: "CVV Code",
  selectDate: "Please select a date!",
  availableOn: "Available on ",
  remove: "Remove",
  ticketDetails: "Ticket Details",
  isEmpty: "Is Empty",
  noReservation: "Could not find reservation",
  fees: "Fees",
  subTotal: "Subtotal",
  tax: "Tax",
  taxAndFees: "Taxes & Fees",
  total: "Total due today",
  remainingBalance: 'Remaining balance',
  beginningBalance: "Beginning balance",
  amountApplied: "Amount applied",
  giftCard: "Gift card",
  giftCards: "Gift cards",
  giftCardCode: "Coupon / Giftcard Code",
  giftCardDescription: "Gift card to be redeemed at:",
  completeCheckout: "Complete Checkout",
  completeReservation: "Complete Reservation",
  close: "Close",
  receipt: "Receipt",
  paymentInformation: "Payment Information",
  zip: "Zip / Postal Code",
  street: "Street Address",
  apt: "Apartment, suite, unit etc. (optional)",
  city: "City",
  cardHolder: "Name on Card",
  zipNumber: "Zip Code must be a number",
  zipRequired: "Zip Code is required.",
  phoneNumberError: "Invalid Phone Number",
  invalidCreditCard:
    "Invalid Card - Checkout button will remain disabled until credit card is valid.",
  fullName: "Must include first and last name.",
  fullAddress: "Please include full street address.",
  streetAddress: "Please include full street address.",
  zipUSInvalid: "Invalid. US Zip codes must be numbers.",
  clickConfirm: "Your reservation is not complete until you have Clicked the agreements and pressed Process Payment",
  clickAgreements: "You must agree to ALL accepted terms in order to checkout",
  summary: "Summary",
  discount: "Discount",
  apply: "Apply",
  makeAnotherReservation: "Make Another Reservation",
  validCC:
    "This button will stay disabled until valid credit card, name and zip entry",
  invalidCardHolder: "Invalid Card Holder Name",
  invalidUSZip: "Invalid US Zip",
  restrictions: "Restrictions",
  iAgree: "I Agree",
  processPayment: "Process Payment",
  checkingOut: "Checking Out",
  noTimes: "No Times Available for Selected Date",
  soldOut: "Sold out, not enough tickets available",
  getAvailTimes: "Get Available Times",
  offeredBy: "offered by",
  descriptionOf: "description of",
  due: "Due",
  is: "Is",
  useMetric: "Use Metric",
  useImperial: "Use Imperial",
  comment: "Comment",
  comments: "Comments (DO NOT enter credit card numbers here)",
  reservation: "Reservation",
  quantity: "Quantity",
  pickupDelivery: "Delivery / Pickup Address",
  outOfStock: "Out of Stock",
  costFree: "Free",
  price: "Price",
  catalog: "Catalog",
  catalogItem: "Catalog Item",
  catalogItems: "Catalog Items",
  catalogItemsEmpty: "No Catalog Items",
  catalogItemAdd: "Add To Cart",
  catalogItemRemove: "Remove From Cart",
  reservationAddOns: "Reservation AddOns",
  reservationAddOnsEmpty: "No Reservation AddOns",
  reservationsEmpty: "No reservations in list",
  balance: "Balance",
  originalPrice: "Original Price",
  HowDidYouFindOutAboutUs: "How did you find out about us?",
  dateFormat: "MM/dd/yyyy",
  rescheduleConfirmButton: 'Confirm reschedule',
  rescheduleConfirmDialog: 'Click confirm to reschedule your {0} for {1}',
  home: "Home",
  invalidContactInfo: "Invalid Contact Information",
  invalidAddressInfo: "Invalid Address Information",
  invalidPaymentInfo: "Invalid Payment Information",
  invalidPostalCode: "Postal code is required",
  invalidHowHeardSelection: "Please tell us how you found out about us.",
  ratesFrom: "Rates From",
  at: 'At',
  noRateSelected: 'Select the quantities for this reservation on {0}. Then click "Get Available Times" or select another date.',
  uploadDocumentTitle: "Upload a document",
  uploadDocumentMessage: " Your reservation for {0} {1} requires you to submit additional documentation. Please follow the instructions below.",
  cameraSuccessUpload: "Photo uploaded successfully",
  cameraErrorUpload: "There was an error trying to upload your photo",
  cameraAccess: "Please connect or give access to your camera.",
  cameraRetake: "Retake",
  cameraSave: "Save",
};

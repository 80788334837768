///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import {Action, AnyAction, Dispatch} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import Reservation from "../ReservationApi"
import { Credentials } from "ares-core/Credentials"
import {IReservationStore} from "../Types";
import {CoreStatus, ICoreResult} from "ares-core/Types";
import {getJSON, IQueryParam, BaseURL, putJSON, postJSON } from "ares-core/IO/Http";
import {ICheckInCheckOut, IRefundResult, ReservationData, ReservationEditData} from "ares-core/Models/Reservation";
import {EventDateReservationList, VwManagementCalendar, IRefundDTO } from "../../Models";
import {DateTimeUtils} from "ares-core/Utils";
import NotificationsApi from "../../Notifications/NotificationsApi";
import {NotificationsType} from "../../Models/Notifications";
import RefundService from "../../Services/RefundService";

export type IReservationThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IReservationStore,
  unknown,
  Action<string>
>;

export type ILoadReservationDetailsAction = (companyId:number, id: number) => any;

export const loadReservationDetailsAction = (companyId:number, id: number): IReservationThunk => {
  return async (dispatch: Dispatch) => {
    Reservation.Actions.setInProgress(true);

    const uri: string = "/portal/Reservations";
    const params: IQueryParam[] = [{key: "reservationId", value: id.toString()}];
    const headers: IQueryParam[] = [{key: "X-Indexic-C", value: companyId.toString()}];

    Reservation.Actions.setInProgress(false);
    const result: ICoreResult = await getJSON(BaseURL.PORTAL, uri, params, headers);  
    Reservation.Actions.setInProgress(false);

    if (result.status === CoreStatus.SUCCESS) {			
      const rdata:ReservationEditData = result.payload;
      Reservation.Actions.setReservationEditData(rdata);
      Reservation.Actions.setCurrentReservation(rdata.reservationData);
    } else if (result.payload.status === 400 || result.payload.status ===404) {
      // 400 comes from requesting a non-numeric reservation ID.  Treat as Not Found.
      throw 404;
    } else if (result.payload.status === 401 || result.payload.status === 403) {
      // user can't view the reservation
      throw 403;
    }
  };
};


export type ILoadReservationSummaryAction = (companyId:number, id: number) => any;
export const loadReservationSummaryAction = (companyId:number, id: number): IReservationThunk => {
  return async (dispatch: Dispatch) => {
      Reservation.Actions.setInProgress(true);
      let uri: string = "/portal/Reservations/List/" + id.toString();
      let params: IQueryParam[] = [];
			let headers: IQueryParam[] = [{key: "X-Indexic-C", value: companyId.toString()}];

      let result: ICoreResult = await getJSON(BaseURL.PORTAL, uri, params,headers);
      if (result.status === CoreStatus.SUCCESS) {
        const data = result.payload as EventDateReservationList;
        if (data) {
          // const capitalizedSummary = capitalizeObjectKeys<VwEventTimeSummary>(data.eventTimeSummary,['eventDateId']);
          Reservation.Actions.setReservationSummary(data);
        }
      } else if (result.status === CoreStatus.FAILURE) {
          Reservation.Actions.setReservationSummary(null)
      }
      Reservation.Actions.setInProgress(false);
  };
};

export type IUpdateReservationAction = (companyId:number, r: ReservationData) => any;
/**
 * @deprecated Use PortalReservationService.updateReservation
 * @param companyId
 * @param res
 */
export const updateReservationAction = (
	companyId:number, 
  res: ReservationData,
): IReservationThunk => {
  return async (dispatch: Dispatch) => {
    Reservation.Actions.setInProgress(true);
      let uri: string = "/portal/Reservations";
      let params: IQueryParam[] = [];
			let headers: IQueryParam[] = [];
			let header: IQueryParam = {
				key: "X-Indexic-C",
				value: companyId.toString()
			};
			headers.push(header);

      let result: ICoreResult = await putJSON(BaseURL.PORTAL, uri, params, res,headers);
      if (result.status === CoreStatus.SUCCESS) {
				var rdata:ReservationEditData = result.payload;
				Reservation.Actions.setReservationEditData(rdata);
        Reservation.Actions.setCurrentReservation(rdata.reservationData);
        Reservation.Actions.setInProgress(false);
      }else{
        Reservation.Actions.setInProgress(false);
        throw Error(result.msg) //handle a bad response from the server
      }
  };
};

export type ICheckInCheckOutAction = (result : ICheckInCheckOut) => any;

export const checkInCheckOutAction = (result : ICheckInCheckOut): IReservationThunk => {
  return async (dispatch: Dispatch) => {
    Reservation.Actions.setUpdateCheckInCheckOut(result)
  };
};

export type IGetTransactionsAction = (companyId:number, id: number) => any;

export const getTransactionsAction = (companyId:number, id: number): IReservationThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(Reservation.Actions.setInProgress(true));
    try {
      let uri: string = "/portal/Reservations/Transactions/" + id.toString();

      let params: IQueryParam[] = [];
			let headers: IQueryParam[] = [];
			let header: IQueryParam = {
				key: "X-Indexic-C",
				value: companyId.toString()
			};
			headers.push(header);

      let result: ICoreResult = await getJSON(BaseURL.PORTAL, uri, params,headers);
      if (result.status === CoreStatus.SUCCESS) {
				// Returns in a list of size 1
				//const items:ReservationListItem[] = result.payload;
				//if (items.length === 1) {
					//dispatch(setReservationItemAction(result.payload[0]));
				//}
        Reservation.Actions.setInProgress(false);
      }
    } catch (error) {
      Reservation.Actions.setInProgress(false);
    }
  };
};

export type ILoadReservationCalendarAction = (companyId:number, day?:Date) => any;

export const loadReservationCalendarAction = (companyId:number, day?:Date): IReservationThunk => {
  return async (dispatch: Dispatch) => {
    Reservation.Actions.setInProgress(true);
    try {
      let uri: string = "/portal/Reservations/Calendar/";
			if (day) {
				uri += DateTimeUtils.toYYYYMMDD(day);
			}

      let params: IQueryParam[] = [];
			let headers: IQueryParam[] = [];
			let header: IQueryParam = {
				key: "X-Indexic-C",
				value: companyId.toString()
			};
			headers.push(header);

      let result: ICoreResult = await getJSON(BaseURL.PORTAL, uri, params, headers);
      if (result.status === CoreStatus.SUCCESS) {
				var rdata:VwManagementCalendar[] = result.payload;
				Reservation.Actions.setCalendarEvents(rdata);
      }
    } catch (error) {
    } finally {
        Reservation.Actions.setInProgress(false);
    }
  };
};
//
// Move a list of reservations to the given eventDateId
//
export type IMoveReservationsAction = (companyId:number,reservations:Array<number>, eventDateId: number, sendEmail : boolean) => any;
export const moveReservationsAction = (companyId: number,reservations:Array<number>, eventDateId: number, sendEmail : boolean): IReservationThunk => {
	return async (dispatch: Dispatch) => {
		Reservation.Actions.setInProgress(true);
		let uri: string = `/portal/reservations/${eventDateId.toString()}/move/${reservations.toString()}/${sendEmail}`;
        let params: IQueryParam[] = [];
        let headers: IQueryParam[] = [{
          key: "X-Indexic-C",
          value: companyId.toString()
        }];
		let result: ICoreResult = await putJSON(BaseURL.PORTAL, uri, params,{},headers);
		if (result.status === CoreStatus.SUCCESS) {
			const data = result.payload;
			if ( data && data.length > 0 && Array.isArray(data)) {
                const notifications = data.map(el => ({message: el, type: NotificationsType.error}));
                NotificationsApi.addMany(notifications);
			}
			else {
                const message = `Reservation${reservations.length > 0 ? 's' : null} moved`
                NotificationsApi.add({message, type: NotificationsType.success})
			}
		}
		Reservation.Actions.setInProgress(false);
	};
};

export type IItemReturnAction = (transactionItemId: number, quantity: number) => any;
export const itemReturnAction = (transactionItemId: number, quantity: number): IReservationThunk => {
    return async (dispatch: Dispatch) => {

        let uri: string = `/portal/transaction/ItemReturn/${transactionItemId}/${quantity}`;
        let params: IQueryParam[] = [];
		let headers: IQueryParam[] = [];
		let header: IQueryParam = { key:"x-indexic-c", value: Credentials.Store().companyId.toString() }
		headers.push(header);

        let result: ICoreResult = await putJSON(BaseURL.PORTAL, uri, params, {}, headers);
        if (result.status === CoreStatus.SUCCESS) {
            // result could have errors
            Reservation.Actions.setRefundResult(result.payload);
        } else {
            NotificationsApi.add({message: 'Error refunding item', type: NotificationsType.error})
        }
    };
};

export type IRefundReservationAction = (transactionItemId: number, amount: number, refundDTO: IRefundDTO) => any;
export const refundReservationAction = (transactionItemId: number, amount: number, refundDTO: IRefundDTO): IReservationThunk => {
    return async (dispatch: Dispatch) => {
        let result = await RefundService.refundReservation(Credentials.Store().companyId,transactionItemId,amount,refundDTO);
        if (result.status === CoreStatus.SUCCESS) {
            const payload = result.payload as ReservationEditData;
            Reservation.Actions.setReservationEditData(payload);
            NotificationsApi.add({message: 'Transaction refunded', type: NotificationsType.success});
        } else {
            const payload = result.payload as {data: IRefundResult};
            const data = payload.data;
            NotificationsApi.add({message: 'Error refunding reservation', type: NotificationsType.error});
            if(!data?.paymentGatewayResult?.success) {
                data.paymentGatewayResult.errors.forEach(message => {
                    NotificationsApi.add({message, type: NotificationsType.error});
                });
            }
        }
		
    };
};

export interface IReservationThunks {
	readonly loadReservationDetails: ILoadReservationDetailsAction;
	// readonly loadReservationItem: ILoadReservationItemAction;
	readonly loadReservationSummary: ILoadReservationSummaryAction;
	// readonly updateReservation: IUpdateReservationAction;
	readonly checkInCheckOut: ICheckInCheckOutAction;
	readonly getTransactions: IGetTransactionsAction;
	readonly loadReservationCalendar: ILoadReservationCalendarAction;
	readonly moveReservations: IMoveReservationsAction;
	readonly itemReturn: IItemReturnAction;
	readonly refundReservation: IRefundReservationAction;
}

const BuildReservationThunks = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) => {
  const ReservationThunks: IReservationThunks = {
    loadReservationDetails: (companyId:number, id: number) =>
			dispatch(loadReservationDetailsAction(companyId,id)),
		// loadReservationItem: (companyId:number, id: number) =>
      // dispatch(loadReservationItemAction(companyId,id)),
    loadReservationSummary: (companyId:number, id: number) =>
		  dispatch(loadReservationSummaryAction(companyId, id)),
    checkInCheckOut: (result : ICheckInCheckOut) =>
        dispatch(checkInCheckOutAction(result)),
    getTransactions: (companyId:number, r:number) =>
        dispatch(getTransactionsAction(companyId,r)),
    loadReservationCalendar: (companyId:number, day?:Date) =>
        dispatch(loadReservationCalendarAction(companyId,day)),
	moveReservations: (companyId: number,reservations:Array<number>, eventDateId: number, sendEmail : boolean) =>
		dispatch(moveReservationsAction(companyId,reservations,eventDateId, sendEmail)),
	itemReturn: (transactionItemId: number, quantity: number) =>
		dispatch(itemReturnAction(transactionItemId, quantity)),
	refundReservation: (transactionItemId: number, amount: number, refundDTO: IRefundDTO) =>
		dispatch(refundReservationAction(transactionItemId, amount, refundDTO)),
  };

  return ReservationThunks;
};

export default BuildReservationThunks;

import React, { Component, ReactNode } from "react";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
import { IconButton, Typography, Button, Box } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    btnBack: {},
    btnForward: {},
    calendarMonth: {
      flex: "auto",
      alignSelf: "center",
    },
    calDayName: {},
    calDayCell: {
      width: "14.28%",
      minHeight: "2em",
      padding: "0.1em",
      textAlign: "center",
    },
    calNav: {
      display: "flex",
      width: "100%",
      position: "relative",
      textAlign: "center",
    },
    calDayButton: {
      maxWidth: "100%",
      minWidth: "2.1em",
      padding: "0.2em 0.4em",
    },
    selectedDay: {
      backgroundColor: theme.palette.common.white,
    },
    past: {
      color: theme.palette.text.disabled,
    },
    today: {
      backgroundColor: theme.palette.action.selected,
    },
    content: {
      fontSize: "0.7em",
    },
  });

// Calendar is driven by props. The parent is responsible for setting the
// current month, selected date, start date, and date array in the props.
// Callbacks basically request calendar navigation and parent gives approval
// by changing the props accordingly.
interface ISimpleCalendarProps extends WithStyles<typeof styles> {
  firstDayOfWeek: number;
  locales: string;
  min: Date;
  max: Date;
  startDate: Date;
  selectedDate?: Date;
  btnBack?: ReactNode;
  btnForward?: ReactNode;
  selectableDates?: Date[];
  onNavigate?: (newDate: Date) => void;
  onSelectDate?: (selectedDate: Date) => void;
}

interface ISimpleCalendarState {}

class SimpleCalendar extends Component<
  ISimpleCalendarProps,
  ISimpleCalendarState
> {
  classes = this.props.classes;

  static defaultProps: Partial<ISimpleCalendarProps> = {
    firstDayOfWeek: 0,
    locales: "en-US",
    btnBack: "<",
    btnForward: ">",
    min: new Date(0, 0, 1),
    max: new Date(9999, 11, 31),
    startDate: new Date(),
  };

  startOfWeek = (startDate: Date): number => {
    const someDate = new Date(startDate.setHours(0, 0, 0, 0));
    const offset = (7 - (this.props.firstDayOfWeek - someDate.getDay())) % 7;
    return someDate.setDate(someDate.getDate() - offset);
  };
  endOfWeek = (someDate: Date): number => {
    const offset = (6 - (someDate.getDay() - this.props.firstDayOfWeek)) % 7;
    return someDate.setDate(someDate.getDate() + offset);
  };
  onBackClick = () => {
    if (this.canGoBack()) {
      this.navigate(true);
    }
  };
  onForwardClick = () => {
    if (this.canGoForward()) {
      this.navigate();
    }
  };

  navigate = (back = false) => {
    const monthToView = this.props.startDate; // View is driven by props
    const newMonth = new Date(
      monthToView.getFullYear(),
      monthToView.getMonth() + (back ? -1 : 1),
      1,
    );
    if (this.props.onNavigate) {
      this.props.onNavigate(newMonth);
    }
  };
  canGoBack = () => {
    return this.props.startDate > this.props.min;
  };
  canGoForward = () => {
    return this.props.startDate < this.props.max;
  };
  renderNav = () => {
    const monthToView = this.props.startDate;
    const monthName = monthToView.toLocaleString(this.props.locales, {
      month: "long",
    });
    return (
      <Box className={this.classes.calNav}>
        <IconButton
          className={this.classes.btnBack}
          onClick={this.onBackClick}
          disabled={!this.canGoBack()}
        >
          {this.props.btnBack}
        </IconButton>
        <Typography className={this.classes.calendarMonth}>
          {monthName} {monthToView.getFullYear()}
        </Typography>
        <IconButton
          className={this.classes.btnForward}
          onClick={this.onForwardClick}
          disabled={!this.canGoForward()}
        >
          {this.props.btnForward}
        </IconButton>
      </Box>
    );
  };

  renderDayNames = () => {
    const days = [];
    for (var day = 0; day < 7; day++) {
      days.push(
        new Date(1970, 0, day + 4 + this.props.firstDayOfWeek).toLocaleString(
          this.props.locales,
          {
            weekday: "short" /* or 'long' */,
          },
        ),
      );
    }
    return days.map((dayName, i) => {
      return (
        <Box
          className={`${this.classes.calDayCell} ${this.classes.calDayName}`}
          key={i}
        >
          <span>{dayName}</span>
        </Box>
      );
    });
  };

  onDayClick = (clickedDay: Date) => {
    if (
      !(
        this.props.selectedDate &&
        clickedDay.getTime() === this.props.selectedDate.getTime()
      )
    ) {
      if (this.props.onSelectDate) {
        this.props.onSelectDate(clickedDay);
      }
    }
  };

  renderDays = (selectableDates? : Date[]) => {
    const monthToView = this.props.startDate;
    const now = new Date().setHours(0, 0, 0, 0);
    const month = monthToView.getMonth();
    const year = monthToView.getFullYear();
    const nextMonth = new Date(this.endOfWeek(new Date(year, month + 1, 0)));
    const start = this.startOfWeek(
      new Date(monthToView.getFullYear(), monthToView.getMonth(), 1),
    );
    const calDays = [];
    for (
      let curDay = new Date(start);
      curDay <= nextMonth;
      curDay.setDate(curDay.getDate() + 1)
    ) {
      calDays.push(new Date(curDay));
    }
    return calDays.map((day,index) => {
      let content = <div></div>;
      const dayTime = day.getTime();
      const isCurrentSelectedDate =
        this.props.selectedDate &&
        this.props.selectedDate.getUTCDate() === day.getUTCDate() &&
        this.props.selectedDate.getUTCMonth() === day.getUTCMonth() &&
        this.props.selectedDate.getUTCFullYear() === day.getUTCFullYear();
      let dowClass = `${this.classes.calDayCell} ${
        dayTime < now ? this.classes.past : ""
      }`;
      if (day.getMonth() === this.props.startDate.getMonth()) {
        dowClass += ` ${dayTime === now ? this.classes.today : ""}`;
        content = <span>{day.getDate().toString()}</span>;
        if (selectableDates) {
          let sd = selectableDates.find(
            (d) =>
              d.getUTCDate() === day.getUTCDate() &&
              d.getUTCMonth() === day.getUTCMonth() &&
              d.getUTCFullYear() === day.getUTCFullYear(),
          );

          if (sd) {
            content = (
              <React.Fragment key={index}>
                <Button
                  variant={isCurrentSelectedDate ? "outlined" : "contained"}
                  classes={
                    isCurrentSelectedDate
                      ? { root: this.classes.selectedDay }
                      : undefined
                  }
                  className={this.classes.calDayButton}
                  color="primary"
                  onClick={() => this.onDayClick(day)}
                >
                  {day.getDate()}
                </Button>
              </React.Fragment>
            );
          }
        }
      }
      return (
        <Box className={dowClass} key={day.getTime()}>
          {" "}
          {content}{" "}
        </Box>
      );
    });
  };
  render() {
    return (
      <Box className={this.classes.container}>
        {this.renderNav()}
        {this.renderDayNames()}
        {this.renderDays(this.props.selectableDates)}
      </Box>
    );
  }
}

export default withStyles(styles)(SimpleCalendar);

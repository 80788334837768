import { Grid, IconButton } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { MathEx } from "ares-core";
import { CatalogIDCode, ICartItem, ICartReservation } from "ares-core/Models";


interface CartItemProps {
	cartItem: ICartItem;
	condensed: boolean;
	classes: any;
	res: ICartReservation | null;
	handleDelete(reservation: ICartReservation | null, catalogItem: ICartItem | null): void;
}


function CartItem(props: CartItemProps) {
	const { cartItem, condensed, classes, res } = props;
	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.commandCol} hidden={condensed}>
				{
					cartItem.catalogId !== CatalogIDCode.Surcharge && cartItem.catalogId !== CatalogIDCode.CartSurcharge ? (
						<IconButton size="small" onClick={() => props.handleDelete(res, cartItem)}>
							<DeleteForeverIcon />
						</IconButton>
					) : null
				}
			</Grid>
			<Grid item className={classes.descriptionCol}>
				{cartItem.description}
			</Grid>

			<Grid item className={classes.unitPriceCol}>
				{cartItem.catalogId > 0 ? <>
					{cartItem.quantity}<span className={classes.unitPrice}> x {MathEx.formatCurrency(cartItem.price ?? 0)}</span>
				</>
					: null
				}
			</Grid>
			<Grid item className={classes.currency}>
				{
					(cartItem.catalogId > 0
						|| cartItem.catalogId === CatalogIDCode.Surcharge
						|| cartItem.catalogId === CatalogIDCode.CartSurcharge
						|| cartItem.catalogId === CatalogIDCode.Tip) ?
						MathEx.formatCurrency(cartItem.quantity * (cartItem.price ?? 0)) 
						: null
				}
			</Grid>
		</Grid>
	)
}

export default CartItem;
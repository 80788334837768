import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  makeStyles,
  createStyles,
  DialogProps,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import ReactMarkdown from "react-markdown";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.warning.light,
    },
  }),
);

interface RestrictionsDialogProps extends DialogProps {
  open: boolean;
  handleClose: any;
  restrictions: string;
}

const RestrictionsDialog = (props: RestrictionsDialogProps) => {
  const classes = useStyles();
  const { open, handleClose, restrictions, ...dialogProps } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose as DialogProps["onClose"]}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      classes={classes}
      {...dialogProps}
    >
      <DialogContent>
        <ReactMarkdown>{restrictions}</ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestrictionsDialog;

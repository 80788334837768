import {Dispatch} from "redux";
import {NotificationsReducer} from "./redux-io/Reducers";
import BuildActions, {
    addNotification, clearNotifications,
    INotificationsActions,
    setNotifications,
    showNotification
} from "./redux-io/Actions";
import {INotification} from "../Models/Notifications";
import {INotificationsStore} from "./Types";


export class NotificationsApi {
    store_: INotificationsStore;
    dispatcher_?: Dispatch;
    Actions: INotificationsActions;

    //
    constructor() {
        this.store_ = defaultStore;
        //
        // Need to phase out old action setup in favor of this Build pattern
        this.Actions = BuildActions(this.dispatcher_!);
    }

    public Store = (s?: INotificationsStore) => {
        if (s) this.store_ = s;
        return this.store_;
    };
    public Reducer = () => {
        return NotificationsReducer;
    };
    public Dispatcher = (d?: Dispatch) => {
        if (d) {
            this.dispatcher_ = d;
            this.Actions = BuildActions(this.dispatcher_!);
        }
        return this.dispatcher_;
    };
    /**
     * Set new Notifications array, doesn't update current and display properties
     * @param r
     */
    public set = (r:INotification[]) => {
        if (this.dispatcher_) this.dispatcher_(setNotifications(r));
    };
    /**
     * Executes notification callback and show the next notifications.
     * This function is called on the dismiss of the current notification
     */
    public next = () => {
        // When we dismiss the current notification we execute the callback
        if (this.store_.current?.callback) {
            this.store_?.current?.callback();
        }
        if (this.dispatcher_) this.dispatcher_(showNotification());
    }
    /**
     * Adds and array of notifications, calls one action per notification
     * @param payload
     */
    public addMany = (payload: INotification[]) => {
            payload.forEach(this.add);
    };
    /**
     * Add a single notification, if there's no current notification then we show the first notification
     * @param notification
     */
    public add = (notification:INotification) => {
            if (this.dispatcher_) {
                this.dispatcher_(addNotification(notification));
                if (this.store_.current !== null) {
                    this.next();
                }
            }
    };
    /**
     * Clears the notifications store
     */
    public clear = () => {
            if (this.dispatcher_) this.dispatcher_(clearNotifications())
    };
}

// Initialize Default Event

const defaultStore:INotificationsStore = {
    notifications: [],
    current: null,
    display: false,
};

const api: NotificationsApi = new NotificationsApi();
export default api;

import { Action, ActionCreator, Dispatch } from "redux";
import { ICartDTO, IContactInfo, IAddressMultiline, PrecheckoutData, StripeIntentStatus } from '../../Models'

export enum ShoppingActionType {
	SET_SHOPPING_CART = "[SHOPPING] SET_CART",
	SET_SHOPPING_MODE = "[SHOPPING] SET_MODE",
	SET_SHOPPING_COMPANY = "[SHOPPING] SET_COMPANY",
	SET_SHOPPING_BILLING_INFO = "[SHOPPING] SET_BILLING_INFO",
	SET_SHOPPING_SHIPPING_INFO = "[SHOPPING] SET_SHIPPING_INFO",
	SET_SHOPPING_BILLING_ADDRESS = "[SHOPPING] SET_BILLING_ADDRESS",
	SET_SHOPPING_PROCESSOR_DATA = "[SHOPPING] SET_PROCESSOR_DATA",
	SET_SHOPPING_PRECHECKOUT = "[SHOPPING] SET_PRECHECKOUT",
	SET_SHOPPING_RECEIPT = "[SHOPPING] SET_RECEIPT",
	SET_SHOPPING_HOWHEARD = "[SHOPPING] SET_HOWHEARD",
	// SET_SHOPPING_STRIPE_PAYMENT_STATUS = "[SHOPPING] SET_STRIPE_PAYMENT_STATUS",
	SET_CART_ERRORS = "[SHOPPING] SET_CART_ERRORS",
  	SHOPPING_IN_PROGRESS = "[SHOPPING] IN_PROGRESS",
}

export interface ISetPortalModeAction extends Action<ShoppingActionType.SET_SHOPPING_MODE> {
  portalMode: boolean;
}
export const setPortalModeAction: ActionCreator<ISetPortalModeAction> = (b) => {
  return { portalMode: b, type: ShoppingActionType.SET_SHOPPING_MODE };
};

// Loading Data
export interface ISetShoppingInProgressAction extends Action<ShoppingActionType.SHOPPING_IN_PROGRESS> {
  bOn: boolean;
}
export const setInProgressAction: ActionCreator<ISetShoppingInProgressAction> = (t) => {
  return { bOn: t, type: ShoppingActionType.SHOPPING_IN_PROGRESS };
};

// Shopping
export interface ISetShoppingCartAction extends Action<ShoppingActionType.SET_SHOPPING_CART> {
  cart: ICartDTO;
}
export const setShoppingCartAction: ActionCreator<ISetShoppingCartAction> = (c:ICartDTO) => {
  return { cart: c, type: ShoppingActionType.SET_SHOPPING_CART };
};

// Shopping Company
export interface ISetShoppingCompanyAction extends Action<ShoppingActionType.SET_SHOPPING_COMPANY> {
  companyId: string;
}
export const setShoppingCompanyAction: ActionCreator<ISetShoppingCompanyAction> = (c:string) => {
  return { companyId: c, type: ShoppingActionType.SET_SHOPPING_COMPANY };
};

// PreCheckout Action
export interface ISetPreCheckoutAction extends Action<ShoppingActionType.SET_SHOPPING_PRECHECKOUT> {
  preCheckout: PrecheckoutData;
}
export const setPreCheckoutAction: ActionCreator<ISetPreCheckoutAction> = (c:PrecheckoutData) => {
  return { preCheckout: c, type: ShoppingActionType.SET_SHOPPING_PRECHECKOUT };
};

// Billing Info
export interface ISetShoppingBillingInfoAction extends Action<ShoppingActionType.SET_SHOPPING_BILLING_INFO> {
  billingInfo: IContactInfo;
}
export const setShoppingBillingInfoAction: ActionCreator<ISetShoppingBillingInfoAction> = (c:IContactInfo) => {
  return { billingInfo: c, type: ShoppingActionType.SET_SHOPPING_BILLING_INFO };
};

// Billing Address
export interface ISetShoppingBillingAddressAction extends Action<ShoppingActionType.SET_SHOPPING_BILLING_ADDRESS> {
  billingAddress: IAddressMultiline;
}
export const setShoppingBillingAddressAction: ActionCreator<ISetShoppingBillingAddressAction> = (address:IAddressMultiline) => {
  return { billingAddress: address, type: ShoppingActionType.SET_SHOPPING_BILLING_ADDRESS };
};

// Shipping Info
export interface ISetShoppingShippingInfoAction extends Action<ShoppingActionType.SET_SHOPPING_SHIPPING_INFO> {
  shippingInfo: IContactInfo;
}
export const setShoppingShippingInfoAction: ActionCreator<ISetShoppingShippingInfoAction> = (c:IContactInfo) => {
  return { shippingInfo: c, type: ShoppingActionType.SET_SHOPPING_SHIPPING_INFO };
};

// Shopping Receipt
export interface ISetShoppingReceiptAction extends Action<ShoppingActionType.SET_SHOPPING_RECEIPT> {
  checkoutTransaction: string;
}
export const setShoppingReceiptAction: ActionCreator<ISetShoppingReceiptAction> = (receipt:string) => {
  return { checkoutTransaction: receipt, type: ShoppingActionType.SET_SHOPPING_RECEIPT };
};

// Shopping HowHeard Answer
export interface ISetShoppingHowHeardAction extends Action<ShoppingActionType.SET_SHOPPING_HOWHEARD> {
  howHeardAnswerID: number;
}
export const setShoppingHowHeardAction: ActionCreator<ISetShoppingHowHeardAction> = (answer:number) => {
  return { howHeardAnswerID: answer, type: ShoppingActionType.SET_SHOPPING_HOWHEARD };
};

// Processor Info Action
export interface ISetShoppingProcessorDataAction extends Action<ShoppingActionType.SET_SHOPPING_PROCESSOR_DATA> {
  processorData: string;
}
export const setShoppingProcessorDataAction: ActionCreator<ISetShoppingProcessorDataAction> = (data:string) => {
  return { processorData: data, type: ShoppingActionType.SET_SHOPPING_PROCESSOR_DATA };
};

// Set/Update Cart Errors
export interface ISetCartErrorsAction extends Action<ShoppingActionType.SET_CART_ERRORS> {
  errors: string[];
}
export const setCartErrorsAction: ActionCreator<ISetCartErrorsAction> = (errorList:string[]) => {
  return { errors: errorList, type: ShoppingActionType.SET_CART_ERRORS };
};

// export interface ISetCartPaymentStatusAction extends Action<ShoppingActionType.SET_SHOPPING_STRIPE_PAYMENT_STATUS> {
// 	stripeStatus: StripeIntentStatus;
// }
// export const setShoppingStripePaymentStatusAction: ActionCreator<ISetCartPaymentStatusAction> = (data:StripeIntentStatus) => {
// 	return { stripeStatus: data, type: ShoppingActionType.SET_SHOPPING_STRIPE_PAYMENT_STATUS};
// }

export type ShoppingAction =
	| ISetPortalModeAction
	| ISetShoppingCompanyAction
	| ISetShoppingCartAction
	| ISetShoppingBillingInfoAction
	| ISetShoppingBillingAddressAction
	| ISetShoppingShippingInfoAction
	| ISetShoppingProcessorDataAction
	| ISetPreCheckoutAction
	| ISetShoppingReceiptAction
	| ISetShoppingHowHeardAction
	| ISetCartErrorsAction
  	| ISetShoppingInProgressAction

	export interface IShoppingActions {
		readonly SetPortalMode: CallableFunction;
		readonly SetShoppingCompany: CallableFunction;
		readonly SetShippingInfo: CallableFunction;
		readonly SetBillingInfo: CallableFunction;
		readonly SetBillingAddress: CallableFunction;
		readonly SetProcessorData: CallableFunction;
		readonly SetShoppingCart: CallableFunction;
		readonly SetShoppingReceipt: CallableFunction;
		readonly SetShoppingHowHeard: CallableFunction;
		readonly SetCartErrors: CallableFunction;
		readonly SetInProgress: CallableFunction;
		readonly SetPreCheckoutAction: (data: PrecheckoutData) => void;
	}

	// Convenience Function to link Action Dispatcher
	const BuildShoppingActions = (dispatcher: Dispatch) => {
		const ShoppingActions: IShoppingActions = {
			SetPortalMode: (b:boolean) => {
				dispatcher(setPortalModeAction(b));
			},
			SetShoppingCompany: (c: string) => {
				dispatcher(setShoppingCompanyAction(c));
			},
			SetShoppingCart: (r: ICartDTO) => {
    		dispatcher(setShoppingCartAction(r));
			},
			SetBillingInfo: (c:IContactInfo) => {
				dispatcher(setShoppingBillingInfoAction(c));
			},
			SetBillingAddress: (address:IAddressMultiline) => {
				dispatcher(setShoppingBillingAddressAction(address));
			},
			SetShippingInfo: (c:IContactInfo) => {
				dispatcher(setShoppingShippingInfoAction(c));
			},
			SetProcessorData: (d:string) => {
				dispatcher(setShoppingProcessorDataAction(d));
			},
  			SetInProgress: (b: boolean, w: ShoppingActionType) => {
    			dispatcher(setInProgressAction(b, w));
			},
			SetShoppingReceipt: (r: string) => {
    			dispatcher(setShoppingReceiptAction(r));
			},
			SetShoppingHowHeard: (answer: number) => {
    			dispatcher(setShoppingHowHeardAction(answer));
			},
			SetCartErrors: (errors: string[]) => {
    			dispatcher(setCartErrorsAction(errors));
  			},
			SetPreCheckoutAction: (data) => {
				dispatcher(setPreCheckoutAction(data));
			}
		};
	
		return ShoppingActions;
	};
	
	export default BuildShoppingActions;

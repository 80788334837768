import React from "react";
import { Grid, Button, makeStyles, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { IApplicationStore } from "AppState";

const useStyles = makeStyles((theme: Theme) => ({
  spaceTop: {
    marginTop: "0.2rem",
  },
  noDecoration: {
    textDecoration: "none",
  },
}));

interface WizardFooterProps {
  urlFriendlyName: string;
}
// This component is used to navigate away from the Reservation Wizard
export const WizardFooterComponent = (props: WizardFooterProps) => {
  const classes = useStyles();

  const buildUrl = (dest: string): string => {
    let url: string = "";

    if (dest === "cart") {
      url = "/cart/";
    }

    if (dest === "MoreEvents") {
      url = "/event/";
    }

    if (dest === "checkout") {
      url = "/checkout/";
    }

    url += props.urlFriendlyName;
    return url;
  };

  return (
    <Grid
      container
      classes={{ root: classes.spaceTop }}
      spacing={6}
      justify="center"
      alignItems="center"
    >
      <Grid item container xs={12} md={5} justify="center" alignItems="center">
        <Link to={buildUrl("cart")} className={classes.noDecoration}>
          <Button color="primary" variant="contained">
            Apply Coupon / Giftcard
          </Button>
        </Link>
      </Grid>
      <Grid item container xs={12} md={4} justify="center" alignItems="center">
        <Link to={buildUrl("MoreEvents")} className={classes.noDecoration}>
          <Button color="primary" variant="contained">
            Add More Events
          </Button>
        </Link>
      </Grid>
      <Grid item container xs={12} md={3} justify="center" alignItems="center">
        <Link to={buildUrl("checkout")} className={classes.noDecoration}>
          <Button color="primary" variant="contained">
            Checkout
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

import {ILinkCompanyStore, LinkCompany} from "ares-core/LinkCompany";

import {IApplicationStore} from "AppState";
import {EventHeader} from "modules/EventReservation";

import {IShoppingStore} from "ares-core/Shopping";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimeUtils, Locale} from "ares-core";
import {EventHeaderProps} from "../EventReservation/components/EventHeader";
import {EventTicketRequest, INotification, NotificationsType, Rate} from "../../ares-core/Models";
import {DateRatePanelComponent} from "../EventReservation/components/DateRatePanel";
import {add, endOfMonth, format, startOfMonth} from "date-fns";
import DialogComponent, {DialogHooks} from "../../link-core/UI/Components/DialogComponent";
import RescheduleLinkService from "../../link-core/Services/RescheduleLinkService";
import {formatString} from 'ares-core/Utils/Common'
import {Alert} from "@material-ui/lab";
import {Grow} from "@material-ui/core";

interface EventReservationProps {
    company: ILinkCompanyStore;
    shopping: IShoppingStore;
}

/**
 * DisplayEventReservation only renders event header & reservation wizard,
 * loads specific event details into LinkCompany.currentEvent, & keeps state
 * for restrictions dialog acknowledgement.
 *
 */
const Reschedule = (props: EventReservationProps) => {
    const [loading, setLoading] = useState(true);
    const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);
    const [requestedTickets, setRequestedTickets] = useState<EventTicketRequest[]>([]);
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const validTrigger = useRef(false);
    const validContinue = useRef(null);
    const [eventDate, setEventDate] = useState('');
    const [waitingResponse, setWaitingResponse] = useState(false);
    const {
        urlFriendlyCompanyName,
        urlFriendlyEventName,
        urlEventDate,
        rescheduleKey,
        selectedRates
    }: { [key: string]: string } = useParams();
    const [messages, setMessages] = useState<INotification[]>([]);
    const history = useHistory();
    // Set the Selected Date for initialization
    useEffect(() => {
        if (urlEventDate) {
            LinkCompany.Actions.SetLastReservationDate(urlEventDate);
        } else {
            const today = DateTimeUtils.toYYYYMMDD(new Date());
            LinkCompany.Actions.SetLastReservationDate(today);
        }
    }, [urlEventDate]);

    useEffect(() => {
        const rates:EventTicketRequest[] = [];
        selectedRates.split(',').forEach(el => {
            const data =el.split('!');
            if(data.length === 2) {
                rates.push({rateId:Number(data[0]),count:Number(data[1])});
            }
        })
        setRequestedTickets(rates);
    }, [selectedRates]);

    const navigateHome = () =>  history.push(`/event/${urlFriendlyCompanyName}`)

    // Landing URL is /CompanyName/EventName/:EventDate . At this point the Company Data has been
    // retrieved but the events / event details may or may not have been retrieved. MapStateToProps
    // maps the events and currentEvent. We need both.
    useEffect(() => {
        if (props.company.events && props.company.events.length > 0) {
            // Find the event by its name from the url
            const selectedEvents = props.company.events.filter(
                (event) =>
                    event.urlFriendlyName.toUpperCase() ===
                    urlFriendlyEventName.toUpperCase(),
            );
            if (selectedEvents.length !== 1) {
                // Try Getting Boost Events
                if(props.company.currentEvent.eventId < 1) {
                    LinkCompany.Api.loadEventDetailsByName(urlFriendlyEventName);
                } else {
                   navigateHome();
                }
            } else {
                if (selectedEvents[0].eventId !== props.company.currentEvent.eventId) {
                    LinkCompany.Api.loadEventDetails(selectedEvents[0].eventId); // Now get the Event Details
                }
                setLoading(
                    selectedEvents[0].eventId !== props.company.currentEvent.eventId,
                );
            }
        } else if (props.company.events === null) {
            // Load Company Events and come back again
            LinkCompany.Api.loadCompanyEvents(props.company.companyID);
        }
        else if (props.company.events && props.company.events.length === 0) {
            LinkCompany.Api.loadEventDetailsByName(urlFriendlyEventName);
        }
    }, [
        history,
        props.company.events,
        props.company.currentEvent,
        urlFriendlyEventName,
        urlFriendlyCompanyName,
    ]);

    const EventHeaderComponentProps = {
        ...props.company.events.filter(
            (ev) => ev.eventId === props.company.currentEvent.eventId,
        )[0],
        restrictions: props.company.currentEvent.restrictions,
        handleAcknowledge: setAcknowledgeDialog,
    } as EventHeaderProps;
    const rates = props.company.currentEvent.rates.filter(({ internal }: Rate) => !internal);
    const handleSetDate = (newDate: Date) => {
        LinkCompany.Actions.SetLastReservationDate(format(newDate, "yyyy-MM-dd"));
    }
    const handleGetEventDates = (startDate: Date, endDate?: Date) => {
        // date-fns functions
        let start = startOfMonth(startDate);
        let end = startOfMonth(add(startDate, { months: 1 }));
        if (endDate) {
            end = endOfMonth(endDate);
        }
        LinkCompany.Api.loadEventDates(
            props.company.currentEvent.eventId,
            DateTimeUtils.toYYYYMMDD(start),
            DateTimeUtils.toYYYYMMDD(end),
        );
    }
    const handleValidTrigger = (valid: boolean) => {
        setMessages([]);
        validTrigger.current = valid;
        validContinue.current = null;
    }
    const handleContinue = (data: any) => {
        if(!data || !validTrigger.current) return;
        if(validContinue.current !== null && validTrigger.current && !waitingResponse) {
            handleClickOpen();
            const eventDateId = (validContinue.current as any)['eventDateID'];
            const eventDateEntry = props.company.eventTimes.find( el => el.eventDateID == eventDateId);
            if(eventDateEntry) {
                setEventDate(eventDateEntry.startTime);
            }
        } else {
            validContinue.current = data;
        }
    }
    const handleReschedule = async () => {
        const eventDateId = (validContinue.current as any)['eventDateID'];
        setWaitingResponse(true);
        if(!isNaN(Number(eventDateId))) {
            handleClose();
            const response = await RescheduleLinkService.reschedule(props.company.companyID,rescheduleKey,eventDateId);
            setWaitingResponse(false);
            if(!response) {
                setMessages([{message: 'Error rescheduling reservation',type: NotificationsType.error}]);
                return;
            }
            if(!response.success || response.errors?.length > 0) {
                const notifications:INotification[] = response.errors.map(error => ({message:error,type:NotificationsType.error}));
                setMessages(notifications);
                return;
            }
            //Success
            setTimeout(navigateHome,1000);
            setMessages([{message: 'Reschedule complete', type: NotificationsType.success}]);
        }
    }
    const Actions = {
        handleSuccessActionText :  "Confirm",
        handleSuccessActionFunction : handleReschedule,
        handleCancelActionText :  "Cancel",
        handleCancelActionFunction : () => handleClose()
    }
    return loading ? null : (
        <section>
            <EventHeader {...EventHeaderComponentProps} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateRatePanelComponent
                    lastReservationDate = {props.company.lastReservationDate}
                    currentEvent = {props.company.currentEvent}
                    currentReservation = {props.company.currentReservation}
                    eventDates ={DateTimeUtils.filterPast(props.company.eventDates) as string[]}
                    eventId ={props.company.currentEvent.eventId}
                    eventTimes ={props.company.eventTimes}
                    requestedTickets ={requestedTickets}
                    defaultDate={urlEventDate || undefined}
                    expanded={true}
                    handleGetEventDates={handleGetEventDates}
                    handleSetDate={handleSetDate}
                    rates={rates}
                    handleContinue={handleContinue}
                    handlePanelChange={() => {}}
                    validTrigger={handleValidTrigger}
                    isReschedule={true}
                />
                <DialogComponent open={open} handleClose={handleClose} ButtonActions={Actions}>
                    <div>
                        {formatString(Locale.rescheduleConfirmDialog,urlFriendlyEventName,`${DateTimeUtils.longDateString(eventDate)}, ${DateTimeUtils.eventLocalTime(eventDate)}`)}
                    </div>
                </DialogComponent>
                <Grow in={messages.length > 0}>
                    <div style={{display:'flex', justifyContent:'center', padding:'16px'}}>
                        {
                            messages.map( (err,id) => <Alert key={id} severity={err.type as any}>{err.message}</Alert>)
                        }
                    </div>
                </Grow>
            </MuiPickersUtilsProvider>
        </section>
    );
};

/**
 *
 * @param company (destructured from state)
 * @param ownProps copy of other props received by component. In this case, Route props (history, location, match).
 * This mapStateToProps uses urlFriendlyEventName to single out event because
 * LinkCompany.LoadEventDetails requires eventID found on Event.
 */
const mapStateToProps = (
    { company, shopping }: IApplicationStore,
    ownProps: any,
) => {
    return {
        company: company,
        shopping: shopping,
    };
};

export default connect(mapStateToProps)(Reschedule);

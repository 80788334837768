///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import { Action, AnyAction, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { setAppInProgressAction } from "./Actions";
import { IAppStateStore, IUserCredentials } from "../Types";
import { getJSON, postJSON } from "ares-core/IO/Http";
import { CoreStatus, ICoreResult } from "ares-core/Types";
import AppState from "../AppStateApi";

type IAppStateThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IAppStateStore,
  unknown,
  Action<string>
>;

// Provide a convenience Interface for Api to set the interface
export interface IAppStateThunks {

}

// Provide a convenience function to map/hook the dispatcher to the thunks
const BuildAppStateThunks = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  const AppStateThunks: IAppStateThunks = {
  };

  return AppStateThunks;
};

export default BuildAppStateThunks;

import { Reducer } from "redux";
import { ShoppingActionType, ShoppingAction } from "./Actions";
import Shopping from "../ShoppingApi";
import { IShoppingStore } from "../Types";
import { ICartDTO } from "../../Models";
import { getGatewayById} from 'ares-core/UI/Checkout/Gateway';

export const ShoppingStoreReducer: Reducer<IShoppingStore, ShoppingAction> = (
  state: IShoppingStore = Shopping.Store(),
  action,
) => {
  switch (action.type) {
    case ShoppingActionType.SET_SHOPPING_CART: {
      return {
        ...state,
        cart: action.cart,
      };
    }
    case ShoppingActionType.SHOPPING_IN_PROGRESS: {
      return {
        ...state,
        busy: action.bOn,
      };
    }
    case ShoppingActionType.SET_SHOPPING_SHIPPING_INFO: {
      return {
        ...state,
        shippingInfo: action.shippingInfo,
      };
    }
    case ShoppingActionType.SET_SHOPPING_BILLING_INFO: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...action.billingInfo,
        },
      };
    }
    case ShoppingActionType.SET_SHOPPING_BILLING_ADDRESS: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...action.billingAddress,
          address: action.billingAddress.address1!,
        },
      };
    }
    case ShoppingActionType.SET_SHOPPING_PROCESSOR_DATA: {
      return {
        ...state,
        processorPaymentData: action.processorData,
      };
    }
    case ShoppingActionType.SET_SHOPPING_PRECHECKOUT: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...action.preCheckout.billingInfo
        },
        cart: action.preCheckout.cartDTO,
        gatewayId: action.preCheckout.gatewayId,
        gatewayProcessor: getGatewayById(action.preCheckout.gatewayId, action.preCheckout.cartDTO.processorSpecificJson),
        processorSpecificJson: action.preCheckout.cartDTO.processorSpecificJson,
        howHeardAnswers: action.preCheckout.howHeardAnswers,
        paymentsOnFile : action.preCheckout.paymentsOnFile
      };
    }
    case ShoppingActionType.SET_SHOPPING_RECEIPT: {
      return {
        ...state,
        checkoutTransaction: action.checkoutTransaction,
      };
    }
    case ShoppingActionType.SET_SHOPPING_HOWHEARD: {
      return {
        ...state,
        howHeardAnswerID: action.howHeardAnswerID,
      };
    }
    case ShoppingActionType.SET_CART_ERRORS: {
      const cart: ICartDTO = {
        ...state.cart,
        errors: action.errors,
      };
      return {
        ...state,
        cart,
      };
    }
    case ShoppingActionType.SET_SHOPPING_MODE: {
      return {
        ...state,
        portalMode: action.portalMode,
      };
    }
    case ShoppingActionType.SET_SHOPPING_COMPANY: {
      return {
        ...state,
        companyId: action.companyId,
      };
    }

    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: ShoppingAction) => {};

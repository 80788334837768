import { Reducer } from "redux";
import { AppStateActionType, AppStateActions } from "AppState/redux-io/Actions";
import { IAppStateStore } from "AppState/Types";
import AppState from "AppState/AppStateApi";

export const AppStateStoreReducer: Reducer<IAppStateStore, AppStateActions> = (
  state: IAppStateStore = AppState.Store(),
  action,
) => {
  switch (action.type) {
    case AppStateActionType.SET_APP_ENDPOINT:
      return {
        ...state,
        endpoint: action.endpoint,
      };
	  case AppStateActionType.SET_APP_LANDING_URL:
		return {
		  ...state,
		  landingUrl: action.landingUrl,
		};
	  case AppStateActionType.SET_APP_COMPANY_URL:
      return {
        ...state,
        companyUrl: action.companyUrl,
      };
    case AppStateActionType.SET_APP_IN_PROGRESS:
      var changedState = { ...state };

      changedState = { ...state, busy: action.bOn };

      return changedState;
    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
      break;
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: AppStateActions) => {};

import {useDebugValue, useEffect, useState} from "react";
import {RateQuestionGroup, ReservationDetail, TicketPerson} from "ares-core/Models";
import {isEmpty, QuestionAnswer, QuestionBase, RequiredFieldsEnum, TicketPersonValidator} from "ares-core";
// Interface that makes the relation between a ticket and a rate
export interface TicketRate {
    ticket: TicketPerson,
    answers: QuestionAnswer[],
    questions: QuestionBase []
    rateId?: number,
    invalid: boolean,
    requiredFields: number,
    description?: string
}

/**
 * Main Hook to handle TicketPerson lists
 * @param requiredFields
 * @param rates
 * @param rateQuestionGroups
 */
export function useTicketState(requiredFields: number, rates: Partial<ReservationDetail>[]  = [], rateQuestionGroups: RateQuestionGroup[] = []){
    const [tickets, setState] = useState(() =>
        ratesToTickets(rates, requiredFields, rateQuestionGroups)
    );
    // Updates state
    const updateTicket = (payload:TicketRate): void => {
        setState(state => state.map(ticketRate => {
            // Check both rateId and ticketPersonId
            if (ticketRate.rateId === payload.rateId &&
                ticketRate.ticket.ticketPersonId === payload.ticket.ticketPersonId) {
                const isRequiredValid = validateRequiredFields(payload.ticket, requiredFields);
                const isAnswersValid = validateQuestions(payload.answers, payload.questions);
                return {...payload, invalid: !(isRequiredValid && isAnswersValid)}
            }
            return ticketRate;
            })
        );
    }
    // Returns formatted data
    const getRates = () :Partial<ReservationDetail>[] => {
        return rates.map(rate => {
            const newTickets = tickets.filter(ticket => ticket.rateId === rate.rateId).map(buildTicketPerson);
            return {...rate,ticketPersons: newTickets }
        })
    }
    const validateTickets = (): boolean => {
        return tickets.every(ticketRate => !ticketRate.invalid);
    }
    // Updates the state if something changes
    useEffect(() => {
        // If rates changes, update the inner state
        setState(ratesToTickets(rates, requiredFields, rateQuestionGroups));
    },[rates,requiredFields, rateQuestionGroups]);

    // TODO: Adds a label, remove when approved
    useDebugValue(tickets, tickets =>
        tickets?.length > 0 ? "Tickets set: " : "No Tickets "
    );

    return {
        tickets,
        updateTicket,
        getRates,
        validateTickets
    };
}


// Utils
/**
 * Given a rates array returns a one-dimension array of all tickets
 * @param rates
 * @param requiredFields
 * @param questionsGroups
 */
const ratesToTickets = (rates:Partial<ReservationDetail>[],requiredFields: number, questionsGroups: RateQuestionGroup[]): TicketRate[] => {
    let flattenedArray:TicketRate[] = [];
    flattenedArray = rates.reduce((acc: TicketRate[],rate) => {
        let tempTickets: TicketRate[];
        if (!isEmpty(questionsGroups)) {
            const tempQuestions = questionsGroups.find(element => element.questionGroupId === rate.questionGroupId);
            tempTickets = generateTicketsPerRate(rate, requiredFields,tempQuestions );
        } else {
            tempTickets = generateTicketsPerRate(rate, requiredFields);
        }
        if (tempTickets?.length > 0) {  acc.push(...tempTickets); }
        return acc;
    },[])
    return flattenedArray;
};
/**
 * Generates TicketPerson objects given tickets count on ReservationDetail object
 * @param rate - ReservationDetail of the event
 * @param requiredFields - Bitwise number of RequiredFieldsEnum
 * @param questionsGroup - RateQuestion object
 */
const generateTicketsPerRate = (rate: Partial<ReservationDetail>, requiredFields: number, questionsGroup?: RateQuestionGroup):TicketRate[] => {
        let tickets:TicketRate[] = [];

        if(!rate.tickets || rate.tickets === 0) {
            return [];
        }
        // Don't generate tickets if both required fields and questions are empty
        if (!hasRequiredFields(requiredFields) && isEmpty(questionsGroup)) {
            return [];
        }

        let questions: QuestionBase[] = [];
         if(!isEmpty(questionsGroup)) {
             questions = parseObject<QuestionBase[]>(questionsGroup!.questionsJson);
         }

        for(let i = 0; i < rate.tickets; i++){
            // Generates default with negative index
            const ticket: TicketPerson = generateTicketDefault(-1 - i);
            tickets.push({
                ticket,
                answers: [],
                questions,
                rateId: rate.rateId,
                invalid: true,
                description: rate.description,
                requiredFields: requiredFields
            });
        }

        return tickets;
}
const parseObject = <T,>(data: string) => { // @ts-ignore
        if (data && data?.length > 2) {
            try {
                // In case string is malformed
                return JSON.parse(data);
            } catch (e) {
                console.warn(e);
            }
        }
}
/**
 * Reads answersJson and returns the correct parsed answer
 * @param ticket
 */
const parseAnswers = (ticket: TicketPerson): QuestionAnswer[] => {
    let answers: QuestionAnswer[] = [];
    if (ticket.answersJson && ticket.answersJson?.length > 2) {
        try {
            // In case answersJson string is malformed
            answers = JSON.parse(ticket.answersJson);
        } catch (e) {
            console.warn(e);
        }
    }
    return answers;
}

/**
 * Generates the TicketPerson object, if there's no answers it wont be added to the object
 * @param ticketRate
 * @return Updated TicketPerson object with answersJson
 */
const buildTicketPerson = (ticketRate: TicketRate): TicketPerson => {
    if(isEmpty(ticketRate.answers)) {
        return ticketRate.ticket;
    }
    let answersJson: string = '';
    try {
        answersJson = JSON.stringify(ticketRate.answers);
    } catch (e) {
        console.warn(e);
    }

    if (answersJson?.length <= 2) {
        return ticketRate.ticket;
    }

    return { ...ticketRate.ticket,  answersJson };
}

/**
 * Function to check that the tickets has at least one required field
 * @param requiredFields
 */
const hasRequiredFields = (requiredFields: number): boolean => {
    return (
        (requiredFields & RequiredFieldsEnum.Name) > 0 ||
        (requiredFields & RequiredFieldsEnum.Phone) > 0 ||
        (requiredFields & RequiredFieldsEnum.Height) > 0 ||
        (requiredFields & RequiredFieldsEnum.Weight) > 0
    );
}

const generateTicketDefault = (id: number = 0):TicketPerson => {
    return {
        ticketPersonId: id,
        participantFirstName: '',
        participantLastName: '',
        participantPhone: '',
        height: 0,
        weight: 0,
    }
}
const validateQuestions = (answers:QuestionAnswer[], questions: QuestionBase[]): boolean => {
    if (questions?.length === 0) {
        return true;
    }
    if (answers?.length < questions?.length) {
        return false;
    }
  return answers.every(answer => !answer.invalid)
}

const validateRequiredFields = (ticket: TicketPerson, requiredFields: number):boolean => {
    const validator = new TicketPersonValidator(ticket, requiredFields);
    return validator.validate();
}

import { Action, ActionCreator, Dispatch } from "redux";
import {
  ILinkCompany,
  VwEventDisplay,
  LinkEventDetail,
  ReservationData,
  EventDateTimeItem,
  IEventCalendarList,
  ICatalogDisplay,
} from "../../Models";

export enum LinkCompanyActionType {
  LINK_COMPANY_IN_PROGRESS = "LINK_COMPANY_IN_PROGRESS",
  SET_LINK_COMPANY = "SET_LINK_COMPANY",
  SET_LINK_COMPANY_EVENTS = "SET_LINK_COMPANY_EVENTS",
  SET_LINK_CURRENT_EVENT = "SET_LINK_CURRENT_EVENT",
  SET_LINK_EVENT_DATETIME = "SET_LINK_EVENT_DATETIME",
  SET_LINK_EVENT_DATES = "SET_LINK_EVENT_DATES",
  SET_LINK_EVENT_TIMES = "SET_LINK_EVENT_TIMES",
  UPDATE_LINK_RESERVATION = "BUILD_LINK_RESERVATION",
  SET_LINK_EVENT_CAL = "SET_LINK_EVENT_CAL",
  SET_LINK_CATALOG = "SET_LINK_CATALOG",
  SET_LAST_RESERVATION_DATE = "SET_LAST_RESERVATION_DATE",
	SET_LINK_COMPANY_BOOSTING = "SET_LINK_COMPANY_BOOSTING",
}

// Loading Data
export interface ISetLinkCompanyInProgressAction
  extends Action<LinkCompanyActionType.LINK_COMPANY_IN_PROGRESS> {
  bOn: boolean;
}
export const setInProgressAction: ActionCreator<ISetLinkCompanyInProgressAction> = (
  t,
) => {
  return { bOn: t, type: LinkCompanyActionType.LINK_COMPANY_IN_PROGRESS };
};

// LinkCompany
export interface ISetLinkCompanyAction
  extends Action<LinkCompanyActionType.SET_LINK_COMPANY> {
  company: ILinkCompany;
}
export const setLinkCompanyAction: ActionCreator<ISetLinkCompanyAction> = (
  c: ILinkCompany,
) => {
  return { company: c, type: LinkCompanyActionType.SET_LINK_COMPANY };
};

// Link Company Events
export interface ISetLinkCompanyEventsAction
  extends Action<LinkCompanyActionType.SET_LINK_COMPANY_EVENTS> {
  companyEvents: VwEventDisplay[];
}
export const setLinkCompanyEventsAction: ActionCreator<ISetLinkCompanyEventsAction> = (
  e: VwEventDisplay[],
) => {
  return {
    companyEvents: e,
    type: LinkCompanyActionType.SET_LINK_COMPANY_EVENTS,
  };
};

// Current Event
export interface ISetLinkCurrentEventAction
  extends Action<LinkCompanyActionType.SET_LINK_CURRENT_EVENT> {
  currentEvent: LinkEventDetail;
}
export const setLinkCurrentEventAction: ActionCreator<ISetLinkCurrentEventAction> = (
  ce: LinkEventDetail,
) => {
  return {
    currentEvent: ce,
    type: LinkCompanyActionType.SET_LINK_CURRENT_EVENT,
  };
};

export interface ISetLinkEventDatesAction
  extends Action<LinkCompanyActionType.SET_LINK_EVENT_DATES> {
  eventDates: string[];
}
export const setLinkEventDatesAction: ActionCreator<ISetLinkEventDatesAction> = (
  d,
) => {
  return {
    eventDates: d,
    type: LinkCompanyActionType.SET_LINK_EVENT_DATES,
  };
};

export interface ISetLinkEventTimesAction
  extends Action<LinkCompanyActionType.SET_LINK_EVENT_TIMES> {
  eventTimes: EventDateTimeItem[];
}
export const setLinkEventTimesAction: ActionCreator<ISetLinkEventTimesAction> = (
  d,
) => {
  return {
    eventTimes: d,
    type: LinkCompanyActionType.SET_LINK_EVENT_TIMES,
  };
};

export interface IUpdateReservationAction
  extends Action<LinkCompanyActionType.UPDATE_LINK_RESERVATION> {
  res: Partial<ReservationData>;
}
export const updateReservationAction: ActionCreator<IUpdateReservationAction> = (
  r: Partial<ReservationData>,
) => {
  return {
    res: r,
    type: LinkCompanyActionType.UPDATE_LINK_RESERVATION,
  };
};

// Event Calendar
export interface ISetEventCalendarAction
  extends Action<LinkCompanyActionType.SET_LINK_EVENT_CAL> {
  eventCalendar: IEventCalendarList;
}
export const setEventCalendarAction: ActionCreator<ISetEventCalendarAction> = (
  ev,
) => {
  return { eventCalendar: ev, type: LinkCompanyActionType.SET_LINK_EVENT_CAL };
};

// Company Catalog
export interface ISetLinkCatalogAction
  extends Action<LinkCompanyActionType.SET_LINK_CATALOG> {
  catalogDisplay: ICatalogDisplay[];
}
export const setLinkCatalogAction: ActionCreator<ISetLinkCatalogAction> = (
  cd,
) => {
  return { catalogDisplay: cd, type: LinkCompanyActionType.SET_LINK_CATALOG };
};

// Company Catalog
export interface ISetLastReservationDateAction
  extends Action<LinkCompanyActionType.SET_LAST_RESERVATION_DATE> {
  lastReservationDate: string;
}
export const setLastReservationDateAction: ActionCreator<ISetLastReservationDateAction> = (
  lastDate,
) => {
  return {
    lastReservationDate: lastDate,
    type: LinkCompanyActionType.SET_LAST_RESERVATION_DATE,
  };
};

// Company Catalog
export interface ISetBoostingAction
  extends Action<LinkCompanyActionType.SET_LINK_COMPANY_BOOSTING> {
  boosting: boolean;
}
export const setBoostingAction: ActionCreator<ISetBoostingAction> = (
  boosting,
) => {
  return {
    boosting: boosting,
    type: LinkCompanyActionType.SET_LINK_COMPANY_BOOSTING,
  };
};

export type LinkCompanyActions =
  | ISetLinkCompanyAction
  | ISetLinkCompanyEventsAction
  | ISetLinkCurrentEventAction
  | ISetLinkEventDatesAction
  | ISetLinkEventTimesAction
  | IUpdateReservationAction
  | ISetEventCalendarAction
  | ISetLinkCatalogAction
  | ISetLastReservationDateAction
  | ISetLinkCompanyInProgressAction
	| ISetBoostingAction;

export interface ILinkCompanyActions {
  readonly SetLinkCompany: CallableFunction;
  readonly SetLinkCompanyEvents: CallableFunction;
  readonly SetLinkCurrentEvent: CallableFunction;
  readonly SetLinkEventDates: CallableFunction;
  readonly SetLinkEventTimes: CallableFunction;
  readonly UpdateReservation: CallableFunction;
  readonly SetEventCalendar: CallableFunction;
  readonly SetLinkCatalog: CallableFunction;
  readonly SetLastReservationDate: CallableFunction;
  readonly SetInProgress: CallableFunction;
	readonly SetBoosting: CallableFunction;
}

// Convenience Function to link Action Dispatcher
const BuildLinkCompanyActions = (dispatcher: Dispatch) => {
  const LinkCompanyActions: ILinkCompanyActions = {
    SetLinkCompany: (company: ILinkCompany) => {
      dispatcher(setLinkCompanyAction(company));
    },
    SetLinkCompanyEvents: (events: VwEventDisplay[]) => {
      dispatcher(setLinkCompanyEventsAction(events));
    },
    SetLinkCurrentEvent: (event: LinkEventDetail) => {
      dispatcher(setLinkCurrentEventAction(event));
    },
    SetLinkEventDates: (eventDates: string[]) => {
      dispatcher(setLinkEventDatesAction(eventDates));
    },
    SetLinkEventTimes: (eventDates: EventDateTimeItem[]) => {
      dispatcher(setLinkEventTimesAction(eventDates));
    },
    UpdateReservation: (eventDates: Partial<ReservationData>) => {
      dispatcher(updateReservationAction(eventDates));
    },
    SetEventCalendar: (eventDates: IEventCalendarList) => {
      dispatcher(setEventCalendarAction(eventDates));
    },
    SetLinkCatalog: (eventDates: ICatalogDisplay[]) => {
      dispatcher(setLinkCatalogAction(eventDates));
    },
    SetLastReservationDate: (reservationDate: string) => {
      dispatcher(setLastReservationDateAction(reservationDate));
    },
		SetBoosting: (boosting: boolean) => {
      dispatcher(setBoostingAction(boosting));
    },
    SetInProgress: (status: boolean) => {
      dispatcher(setInProgressAction(status));
    },
  };

  return LinkCompanyActions;
};

export default BuildLinkCompanyActions;

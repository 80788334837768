import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  RouteComponentProps,
  useLocation, useHistory,
} from "react-router-dom";
import { makeStyles, Paper, Box, Theme } from "@material-ui/core";
import { LinearProgress, CircularProgress } from "@material-ui/core";
import { AppRoutes, route } from "AppRoutes";
import { FourOhFour, LinkHeader } from "modules/Common";
import { ApplicationStore, AppState, IApplicationStore } from "AppState";

import { BaseURLMap, BaseURL } from "ares-core/IO/Http";
import { isInIframe } from "utils";
import { LinkCompany } from "ares-core/LinkCompany";
import { Shopping } from "ares-core/Shopping";

import "./App.css";
import NotificationsComponent from "link-core/Notifications/components/NotificationsComponent";
import * as ShoppingUtils from "ares-core/Shopping/HelperFunctions";
const useStyles = makeStyles((theme: Theme) => ({
  app: {
    "&::after": {
      content: "''",
      height: "100%",
      width: "100%",
      margin: 0,
      zIndex: -1,
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  root: {
    margin: "2rem auto",
    width: "100%",
    maxWidth: "800px",
    ["@media(max-width: 800px)"]: {
      margin: "auto",
      overflowY: "hidden",
      minHeight: "100vh",
    },
  },
  navList: {
    display: "flex",
    width: "80%",
    justifyContent: "space-evenly",
  },
  iframe: {
    width: "100%",
    height: "100%",
    zIndex: -2,
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    ["@media(max-width: 800px)"]: {
      display: "none",
    },
  },
  spinnerContainer: {
    position: "absolute",
    top: "calc(50vh - 50px)",
    left: "calc(50vw - 50px)",
    width: "100px!important",
    height: "100px!important",
    zIndex: 1,
    padding: theme.spacing(1),
  },
}));

interface AppProps extends RouteComponentProps, IApplicationStore {}

const App = (props: AppProps) => {
  const classes = useStyles();
  const [inIframe] = useState(isInIframe());
  const { pathname } = useLocation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [isBoost,setBoost] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // All Link Routes will be /<topic>/<friendlyUrlName>/...
    var urlParts: string[] = props.location.pathname.split("/");
		if (urlParts.length > 2) {
			const companyName = urlParts[2];
			LinkCompany.Api.loadCompany(companyName);
			let cartId = ShoppingUtils.ReadCartId(companyName);
        if(urlParts[1] === 'cart' && urlParts.length === 4) {
          cartId = urlParts[3];
          ShoppingUtils.SaveCartId(cartId,companyName);
          history.push(`/cart/${companyName}`);
        }
			Shopping.Initialize(companyName, false, cartId);
			var dataEndpoint = BaseURLMap.get(BaseURL.LINK);
			if (dataEndpoint === undefined) throw Error('HTTPS/400');
			AppState.Actions.SetEndpoint(dataEndpoint);
			var url = props.location.pathname.replace(/^https?\:\/\//i, "");
			if(props.location.search !== ''){ //this means if there exists any param on the URL will get that and parce the url
				url = `${url}${props.location.search}`
			}
			AppState.Actions.SetLandingUrl(url);
			AppState.Actions.SetCompanyUrl("/event/" + companyName);
    }
  }, []);
  useEffect(() => {
    if (!props.shopping.busy) {
      setTimeout(() => {
        setShowSpinner(props.shopping.busy);
      }, 500);
    } else {
      setShowSpinner(props.shopping.busy);
    }
  }, [props.shopping.busy]);

  // exit to company home page
  const handleAppClose = () => {
    if (inIframe) {
      window.parent.postMessage("CLOSE", "*");
    } else {
      window.location.replace(props.company.homeUrl!);
    }
  };

  // scroll to top on view change
  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  const renderIframe = () => {
    return !inIframe ? (
      <iframe
        title="background filler iframe"
        id="backgroundIframe"
        scrolling="no"
        src={props.company.homeUrl}
        className={classes.iframe}
      />
    ) : null;
  };

  const renderLoading = () => {
    if (props.company) {
      return <LinearProgress color={"primary"} variant={"query"} />;
    }
  };

  const renderLandingPage = () => {
    if (props.company) {
      return (
        <>
          <NotificationsComponent />
          <Box component="main" className={classes.root}>
            <Paper square elevation={0}>
              <LinkHeader handleAppClose={handleAppClose} />
              {showSpinner && (
                <CircularProgress
                  color={"primary"}
                  className={classes.spinnerContainer}
                />
              )}
              <Switch>
                {AppRoutes.map((route: route, i: number) => (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                      <route.component {...route} {...props} />
                    )}
                  />
                ))}
                <Route component={FourOhFour} />
              </Switch>
            </Paper>
          </Box>
        </>
      );
    }
  };

  // Load the Landing Page once the Company Data is Loaded
  return (
    <div className={classes.app}>
      {(!process.env.REACT_APP_HIDE_IFRAME && renderIframe()) || null}
      {props.company.companyID > 0 ? renderLandingPage() : renderLoading()}
    </div>
  );
};

const connector = connect(ApplicationStore);
export default connector<any>(App);

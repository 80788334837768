import {MutableRefObject, useLayoutEffect, useState} from 'react';

export const useElementHasOverflow = (ref: MutableRefObject<HTMLElement | null>,...renderArguments:Array<any>) => {
    const [isOverflow, setIsOverflow] = useState(false);

    useLayoutEffect(() => {
        const { current } = ref;
        if (current) {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            setIsOverflow(hasOverflow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref,...renderArguments]);

    return isOverflow;
};

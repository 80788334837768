import React, { useState, useEffect, MouseEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { AccountCircle, ExpandMore } from "@material-ui/icons";
import { Locale, ContactInfo } from "ares-core/UI";
import { getBrowserLocale } from "utils";
import { IContactInfo } from "ares-core/Models";

const useStyles = makeStyles({
  button: {
    marginTop: "0.75rem",
  },
});

export interface ContactInfoProps {
  defaultContact: IContactInfo;
  expanded: boolean;
  nextStepLabel: string;
  handlePanelChange: CallableFunction;
  handleContinue: CallableFunction;
}

export const ContactInfoPanelComponent = (props: ContactInfoProps) => {
  const panelName = "contact";
  const classes = useStyles();
  const [contactInfo, setContactInfo] = useState(props.defaultContact);
  const [allValid, setAllValid] = useState(false);
  const [performValidation, setPerformValidation] = useState(false);

  // Constructor / Destructor
  const now = new Date();
  useEffect(() => {
    /*
    const { firstName, lastName, email, phone } = props.defaultContact;
    const fields = [firstName, lastName, email, phone];
    if (fields.every((field) => field && field !== "")) {
      setAllValid(false);
    }
  */
    // return destructor function
    setPerformValidation(true);
  }, [props.defaultContact]);

  const handleContactChange = (contactInfo: IContactInfo, isValid: boolean) => {
    setContactInfo((prevInfo) => {
      return { ...prevInfo, ...contactInfo };
    });
    setAllValid(isValid);
    setPerformValidation(false);
  };

  const browserLocale = getBrowserLocale()[0];

  const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value: currPanel } = e.currentTarget;
    props.handleContinue(contactInfo, currPanel);
  };

  const AccordionProps = {
    expanded: props.expanded,
    onChange: props.handlePanelChange(panelName),
  };

  return (
    <Accordion {...AccordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="contact-panel-content"
        id="contact-panel-header"
      >
        <Grid container>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              <Typography variant="h6">Contact Details</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <form>
          <Grid container>
            <ContactInfo
              contactInfo={contactInfo}
              onChange={handleContactChange}
              companyEntry={false}
              country={browserLocale}
              validate={performValidation}
            />
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              className={classes.button}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                value="contact"
                disabled={!allValid}
                onClick={onContinue}
              >
                {props.nextStepLabel}
              </Button>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default ContactInfoPanelComponent;

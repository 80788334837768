import React, { useState, useRef, useMemo, useEffect } from "react";
import {
    Grid,
    LinearProgress
} from "@material-ui/core";
import Webcam from "react-webcam";
import "./Cam.css";
import { Alert } from "@material-ui/lab";
import CameraIcon from "@material-ui/icons/Camera";
import SaveIcon from "@material-ui/icons/Save";
import { BlobServiceClient } from "@azure/storage-blob";
import CameraFrontTwoToneIcon from "@material-ui/icons/CameraFrontTwoTone";
import CameraRearTwoToneIcon from "@material-ui/icons/CameraRearTwoTone";
import NotificationsApi from "ares-core/Notifications/NotificationsApi";
import { NotificationsType } from "ares-core/Models";
import { Locale } from "ares-core";

interface Props extends IUploadPictureSas {
    reservationId: number
    closeComponent?: () => void
}
interface IUploadPictureSas {
    serviceUri: string;
    sas: string;
    container: string;
    fileName: string;
}

enum FacingMode {
    USER = "user",
    ENVIROMENT = "environment",
}
const WebCamComponent = (props: Props) => {
    const [camIsAvailable, setAvailable] = useState(false);
    const [image, setImage] = useState<string | null>(null);
    const [switchCamera, setSwitchCamera] = useState<FacingMode>(
        FacingMode.USER,
    );
    const [multipleCameras, setMultipleCameras] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploaded, setUploaded] = useState<boolean | null>(null);

    useEffect(() => {
        verifyCameraAccess();
    }, []);

    const videoConstraints = useMemo(() => {
        return {
            video: {
                width: { min: 576, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 },
            },
            facingMode: switchCamera,
        };
    }, [switchCamera]);

    const verifyCameraAccess = async () => {
        //@ts-ignore
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                const cameras = devices
                    .filter(
                        (d) => d.label.length > 0 && d.kind === "videoinput",
                    )
                    .map((d, i) => d.deviceId);
                if (
                    cameras.length === 0 &&
                    devices.filter((d) => d.kind === "videoinput").length > 0
                ) {
                    navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then((stream) => {
                            stream.getTracks().forEach((track) => track.stop());
                            setAvailable(false);
                            verifyCameraAccess();
                        });
                } else {
                    setMultipleCameras(cameras.length > 1);
                    setAvailable(true);
                }
            })
            .catch((error) => {
                setAvailable(false);
            });
    };

    const retrievedImage = (image: string | null) => {
        setImage(image);
    };

    const converToBlob = async () => {
        const canvas: any = document.getElementById("webcam_image");
        const canvasURL = canvas.toDataURL();
        const base64Response = await fetch(`${canvasURL}`);
        return await base64Response.blob();
    };

    const createWaterMark = async (imageUrl: string) => {
        const img = new Image();
        const canvas: any = document.getElementById("webcam_image");
        const ctx = canvas.getContext("2d");
        img.src = imageUrl;
        canvas.style = "display : none";
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

        const ts = new Date().toUTCString();
        ctx.font = "18px arial, helvetica";
        ctx.fillStyle = "#FFF";
        ctx.globalCompositeOperation = "difference";
        ctx.fillText(ts, 5, 25);
    };
    const save = async () => {
        setUploading(true);
        if (image !== null) {
            let blobInfo = props
            await createWaterMark(image!);
            const blob: any = await converToBlob();
            if (blob) {
                const blobService = new BlobServiceClient(
                    blobInfo!.serviceUri + "/?" + blobInfo!.sas,
                );
                const customBlockSize =
                    image.length > 1024 * 1024 * 32
                        ? 1024 * 1024 * 4
                        : 1024 * 512;
                const containerClient = blobService.getContainerClient(
                    blobInfo!.container,
                );
                const blockBlobClient = containerClient.getBlockBlobClient(
                    blobInfo!.fileName,
                );

                const options = {
                    blockSize: customBlockSize,
                    blobHTTPHeaders: { blobContentType: "image/jpeg" },
                };
                await blockBlobClient
                    .uploadData(blob, options)
                    .then(() => {
                        NotificationsApi.add({
                            message: "Image uploaded",
                            type: NotificationsType.success,
                        });
                        setUploading(false);
                        setUploaded(true);
                        setTimeout(() => {
                            if (props.closeComponent) {
                                props.closeComponent()
                            }
                        }, 2000)
                    })
                    .catch((error) => {
                        console.log(error);
                        NotificationsApi.add({
                            message:
                                "There was an error uploading the image",
                            type: NotificationsType.error,
                        });
                        setUploading(false);
                        setUploaded(false);
                    }).finally(() => {

                    });
            } else {
                NotificationsApi.add({
                    message:
                        "There was an error trying to get the image from the camera",
                    type: NotificationsType.error,
                });
                setUploading(false);
            }
        }
    };

    const toggleSwitchCamera = () => {
        if (switchCamera === FacingMode.USER) {
            setSwitchCamera(FacingMode.ENVIROMENT);
        } else {
            setSwitchCamera(FacingMode.USER);
        }
    };
    return (
        <Grid container>
            <Grid item md={12}>
                {!camIsAvailable ? (
                    <Alert
                        variant="outlined"
                        title="We are trying to connect to your cammera."
                        severity="warning"
                    >
                        {Locale.cameraAccess}
                    </Alert>
                ) : image === null ? (
                    <Webcam
                        audio={false}
                        height="100%"
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={videoConstraints}
                    >
                        {({ getScreenshot }) => (
                            <div className="camera-actions">
                                <div
                                    className="photo-button"
                                    onClick={() => {
                                        retrievedImage(getScreenshot());
                                    }}
                                >
                                    <div className="circle"></div>
                                    <div className="ring"></div>
                                </div>
                                {multipleCameras ? (
                                    <div
                                        className="switch-camera"
                                        onClick={() => toggleSwitchCamera()}
                                    >
                                        {switchCamera === FacingMode.USER ? (
                                            <CameraRearTwoToneIcon
                                                fontSize="large"
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <CameraFrontTwoToneIcon
                                                fontSize="large"
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </Webcam>
                ) : (
                    <React.Fragment>
                        {uploading && <LinearProgress />}
                        <img src={image} className="preview" />
                        <div>
                            {
                                uploaded !== null &&
                                <>
                                    {uploaded && (
                                        <Alert
                                            variant="outlined"
                                            title="Success"
                                            severity="success"
                                        >
                                            {Locale.cameraSuccessUpload}
                                        </Alert>
                                    )
                                    }
                                    {!uploaded && (
                                        <Alert
                                            variant="outlined"
                                            title="error"
                                            severity="error"
                                        >
                                            {Locale.cameraErrorUpload}
                                        </Alert>
                                    )
                                    }
                                </>
                            }
                            <div
                                className="btnControlls"
                                onClick={() => setImage(null)}
                            >
                                <p className="btnText">{Locale.cameraRetake}</p>
                                <div className="secondaryButton retake">
                                    <p className="btnText2">
                                        <CameraIcon />{" "}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="btnControlls"
                                onClick={() => save()}
                            >
                                <p className="btnText">{Locale.cameraSave}</p>
                                <div className="secondaryButton save">
                                    <p className="btnText2">
                                        <SaveIcon />{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <canvas id="webcam_image"></canvas>
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
};

export default WebCamComponent;

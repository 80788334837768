import React, { useState, useEffect, PropsWithChildren } from "react";

// Based on CacheBuster component
// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
//
export interface CacheBusterProps {
  version: string;
}
// If the props version is less than the meta file then reload
export const CacheBuster = (props: PropsWithChildren<CacheBusterProps>) => {
  const [metaVersion, setMetaVersion] = useState<string>(props.version);

  // Fetch the latest meta.json to allow version check
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        setMetaVersion(meta.version);
      });
  }, []);

  // Watch for metaFile change then check versions
  useEffect(() => {
    if (semverGreaterThan(metaVersion, props.version)) {
      refreshCacheAndReload();
    }
  }, [metaVersion, props.version]);

  /**
   *
   * @param latestVersion app version from meta.json file
   * @param currentVersion app version from packageJSON file
   */
  const semverGreaterThan = (latestVersion: string, currentVersion: string) => {
    const latestVersionParts = latestVersion.split(/\./g);
    const currentVersionParts = currentVersion.split(/\./g);

    while (latestVersionParts.length || currentVersionParts.length) {
      const latest = Number(latestVersionParts.shift());

      const current = Number(currentVersionParts.shift());

      if (latest === current) {
        continue;
      }

      return latest > current || Number.isNaN(current);
    }
    return false;
  };

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
      // delete browser cache and hard reload
      window.location.reload();
    }
  };

  return <>{props.children}</>;
};

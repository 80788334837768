import {TicketPerson} from 'ares-core/Models/Reservation'
import { RequiredFieldsEnum } from 'ares-core/UI/Reservations/RequiredFields';
import {parsePhoneNumberFromString} from "libphonenumber-js";

export class TicketPersonValidator {
    
    constructor(public person: TicketPerson,readonly requiredFields: number) {}

    private isValidString = (str: string | undefined): boolean => (!!str?.trim() && str?.trim() !== '')

    private minValueNumber = (limit: number) => (num: number | undefined): boolean => (!!num && num > limit)

    private maxValueNumber = (limit: number) => (num: number | undefined): boolean => (!!num && num < limit)

    validateName():boolean {
        return (this.isValidString(this.person.participantFirstName) && this.isValidString(this.person.participantLastName));
    }

    validateHeight():boolean {
        return this.minValueNumber(0.1)(this.person.height) && this.maxValueNumber(3)(this.person.height);
    }
    
    validateWeight():boolean {
        return this.minValueNumber(1)(this.person.weight) && this.maxValueNumber(250)(this.person.weight);
    }
    
    validatePhone():boolean {
        if (this.person.participantPhone) {
            const phoneNum = parsePhoneNumberFromString(this.person.participantPhone);
            return !!phoneNum && phoneNum.isValid();
        }
        return  false;
    }

    validate(): boolean {
        const validations:boolean[] = [];
        if((this.requiredFields & RequiredFieldsEnum.Name) > 0) {
            validations.push(this.validateName());
        }
        if((this.requiredFields & RequiredFieldsEnum.Height) > 0) {
            validations.push(this.validateHeight());
        }
        if((this.requiredFields & RequiredFieldsEnum.Weight) > 0) {
            validations.push(this.validateWeight());
        }
        if((this.requiredFields & RequiredFieldsEnum.Phone) > 0) {
            validations.push(this.validatePhone());
        }
        return validations.every(element => element === true);
    }
}

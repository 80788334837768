import {getJSON, IQueryParam, BaseURL, postJSON} from "../IO";
import {ICoreResult} from "ares-core/Types";
import { ApiRequest } from "ares-core/IO/ApiRequests";

export class GeneralPortalService extends ApiRequest{
    protected readonly providerKey = BaseURL.PORTAL;
    protected readonly baseUrl: string
    protected params : IQueryParam[] = []
    constructor(baseUrl: string) {
        super()
        this.baseUrl = baseUrl;
        this.setMsalKey(this.providerKey)
    }

    protected getCompanyHeaders (companyID: string): IQueryParam[] {
        return [
            { key:"x-indexic-c", value: companyID }
        ]
    }
    protected getParams():IQueryParam[] {
        return this.params
    }
    protected setParms( params : IQueryParam[] ){
        this.params = params
    }

    protected getRequest<T=any>(uri: string, companyID: string): Promise<ICoreResult<T>> {
        const params = this.getParams();
        this.setConfig({
            url : uri,
            method : 'get'})
        if(companyID !== "0"){
            this.setHeaders(this.getCompanyHeaders(companyID))
        }
        return this.get(params)
    }
    protected postRequest<T>(uri: string, companyID: string, data: T): Promise<ICoreResult> {
        const params = this.getParams();
        this.setConfig({
            url : uri,
            method : 'post'})
        if(companyID !== "0"){
            this.setHeaders(this.getCompanyHeaders(companyID))
        }
        return this.post<T>(params,data);
    }
    protected putRequest<T,K = any>(uri: string, companyID: string, data: T): Promise<ICoreResult<K>> {
        const params = this.getParams();
        this.setConfig({
            url : uri,
            method : 'put'})
        if(companyID !== "0"){
            this.setHeaders(this.getCompanyHeaders(companyID))
        }
        return this.put<T>(params,data);
    }
    protected deleteRequest(uri: string, companyID: string): Promise<ICoreResult> {
        const params = this.getParams();
        this.setConfig({
            url : uri,
            method : 'delete'})
        if(companyID !== "0"){
            this.setHeaders(this.getCompanyHeaders(companyID))
        }
        return this.delete(params)
    }
    protected createUrl(route:string[]) {
        const joined = route.join('/');
        return `${this.baseUrl}/${joined}`;
    }
}

export function isEmpty(obj: any): boolean {
	if (
		obj === null ||
		obj === undefined ||
		(obj.length !== undefined && obj.length === 0) ||
		Object.keys(obj).length === 0
	) {
		return true;
	} else {
		return false;
	}
}

export function toLower(str: string): string {
	return str.toLowerCase();
}

export function booleanFromString(str: string): boolean {
	switch ((str || "").trim().toLowerCase()) {
		case "true":
		case "yes":
		case "1":
		case "checked":
			return true;
		default:
			return false;
	}
}

export function capitalizeEnding(key: string, ending: string): string {
	if(key.endsWith(ending)){
		const splitted = key.split(ending)
		return splitted[0] + ending.toUpperCase()
	}
	return key
}

export function capitalize(str: string): string {
	return str[0].toUpperCase() + str.slice(1)
}

export function capitalizeObjectKeys<T>(data: T, keys2Capitalize: Array<string> | null = null , endingCapitalize: string = "Id"):T {
		const newItem: any = {};
		if(!data) return data;
		Object.keys(data).forEach((key) => {
			if(keys2Capitalize && Array.isArray(keys2Capitalize)) {
				// If we pass a null value to the keys2Capitalize all keys ending with _endingCapitalize_ will be capitalized
				// otherwise will search for keys included
				if(keys2Capitalize.includes(key)) {
					const newKey = capitalizeEnding(key,endingCapitalize);
					newItem[newKey] = (data as any)[key];
				} else {
					newItem[key] = (data as any)[key];
				}
			} else {
				const newKey = capitalizeEnding(key,endingCapitalize);
				newItem[newKey] = (data as any)[key];
			}
		});
		return newItem as T
	}

export const sortByField = <T,>(field: keyof T) => (a: T,b: T) =>{
	if(a[field] === b[field]) return 0;
	return a[field] > b[field] ? 1 : -1;
}

export const formatString = (str:string,...args: string[]) =>
	str.replace(/{([0-9]+)}/g,  (match, index) => !args[index] ? match : args[index]);

import React, { useState } from "react";
import { Appearance, StripeElementsOptions, loadStripe, StripeConstructorOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


import StripePaymentCheckoutForm from "./StripePaymentCheckoutForm"
import './StripePaymentElement.css'

import { GatewayID, StripeProcessor } from "ares-core/UI/Checkout/Gateway"
import { Shopping } from "ares-core/Shopping";
import { ICheckoutData } from "ares-core/Models";
import { isEmpty } from "ares-core";


interface IStripePaymentElementProps {
  gatewayProcessor: StripeProcessor;
	checkoutData: ICheckoutData;
	proceedWithCheckout: boolean;
	onChange: (valid:boolean) => void;    
	onPaymentReady: (valid: boolean, data: string, errorMessage?: string) => void;
}


const StripePaymentElement = (props:IStripePaymentElementProps) => { 
  const shopping = Shopping.Store();

  const getClientSecret = () => {
    const json = shopping.cart.processorSpecificJson;
    if(!json) {
      return null;
    }
    const parsed = JSON.parse(json);
    if(!parsed) {
      return null;
    }
    if(parsed.hasOwnProperty('Secret')) {
      return (parsed as any)['Secret'];
    }
    return null;
  };

  const gateway = shopping.gatewayProcessor;
  if(!gateway || gateway instanceof StripeProcessor === false) {
    return (<></>);
  }
  const stripeGateway = gateway as StripeProcessor;

  const clientSecret = getClientSecret();
  if(!clientSecret) {
    return (<></>);
  }

  const appearance: Appearance = { theme: 'stripe' };
  const elementOptions: StripeElementsOptions = { clientSecret , appearance };

  return (
    <div className="stripePaymentElement"> 
      {
        clientSecret && 
        <Elements options={elementOptions} stripe={stripeGateway.stripePromise} key={clientSecret}>   
          <StripePaymentCheckoutForm {...props} clientSecret= {clientSecret}/>
        </Elements>
      }
    </div>
  );
};

export default StripePaymentElement;
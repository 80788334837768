import React from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      padding: theme.spacing(2),
    },
    name: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
    },
    indexic: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
    },
    logo: {
      height: "5rem",
      width: "auto",
    },
    image: {
      height: "100%",
      paddingBottom: theme.spacing(2),
      objectFit: "contain",
    },
  }),
);

interface CompanyLogoProps {
  logo?: string;
  showLogo?: boolean;
  showName?: boolean;
  companyName: string;
}

export const CompanyLogo = (props: CompanyLogoProps) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      {props.logo && props.showLogo && (
        <Box className={classes.logo}>
          <img
            aria-label="company logo"
            className={classes.image}
            src={props.logo}
            alt={props.companyName}
          />
        </Box>
      )}
      {props.showName && (
        <Box className={classes.name}>
          <Typography id="company-name" style={{ fontSize: "28px" }} aria-label="company name">
            {props.companyName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

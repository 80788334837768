import React from "react";
import { Box }  from '@material-ui/core';
//import { Locale } from "ares-core/UI/Locale";
import YaiFrame from "./YaiFrame"

export interface IReceiptDisplayProps {
	url: string
}

export const ReceiptDisplay = (props:IReceiptDisplayProps) => {

  return (
    <Box display="flex" style={{
			width: "100%",
			maxWidth: "720px",
			margin: "0 auto",
			height: "90vh",
		}}>
			<YaiFrame url={props.url} />
    </Box>
  );
};

export default ReceiptDisplay;


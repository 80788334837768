import { CredentialStoreReducer } from "./redux-io/Reducers";
import { Dispatch } from "redux";

import { ICredentialStore } from "./Types";
import BuildCredentialActions, { ICredentialActions } from "./redux-io/Actions";
import BuildCredentialThunks,{ ICredentialThunks } from "./redux-io/Thunks";
import { IUserCredentials, SystemAccess } from "ares-core/Models"

export class CredentialApi {
	store_: ICredentialStore;
	dispatcher_?: Dispatch;
	Api: ICredentialThunks;
	Actions: ICredentialActions;
	// class cache for items not in store and/or avoid async dispatch
	constructor() {
		this.store_ = DefaultCredentialsStore;
		//
		this.Actions = BuildCredentialActions(this.dispatcher_!);
		this.Api = BuildCredentialThunks(()=>{});
	}
	public Store = (s?: ICredentialStore) => {
		if (s) this.store_ = s;
		return this.store_;
	};
		public Reducer = () => {
		return CredentialStoreReducer;
	};
	public Dispatcher = (d?: Dispatch) => {
		if (d) {
			this.dispatcher_ = d;
			this.Actions = BuildCredentialActions(this.dispatcher_);
			this.Api = BuildCredentialThunks(this.dispatcher_);
		}
		return this.dispatcher_;
	};

  // Set Roles
	// Expects SysAdmin, Company, and Reports Roles as #_#_#
  public SetRoles = (roles: string) : boolean => {
		let sr,cr,rr = 0;
		var roleArray = roles.split('_');
		if (roleArray.length > 2) {
			try {
				sr = parseInt(roleArray[0]);
				cr = parseInt(roleArray[1]);
				rr = parseInt(roleArray[2]);
			} catch {
				console.log("Received Bad Role String from Https Header");
				return false;
			}
		}
    this.Actions.SetRoles((sr === -1), cr, rr );
    // backwards compatability for roles with 1 << 32 bit set
    if (this.HasCompanyRole(SystemAccess.CompanyAdmin)) {
      cr = -1; // Server Side Bit Set uses signed int32 give all access
      this.Actions.SetRoles((sr === -1), cr, rr );
    }
		return true;
  };
  // Role Accessors
	public HasSysAdminRole = () => {
		return this.Store().systemAdmin;
	}
	public HasCompanyRole = (role: number): boolean => {
		return (this.Store().companyRoles & role) === role;
	};
	public HasReportsRole = (role: number): boolean => {
		return (this.Store().companyRoles & role) === role;
	};

	public SetCompanyId = ( company : number ) =>{
		// Keep from unnecessary Updates. This func is called by endpoint
		// code with HTTP header "x-indexic-c" value
		if (this.store_.companyId !== company) {
			this.Actions.SetCompanyId(company);
		}
		return true;
	}

	public ChangeCompany = async(companyID: number) => {
		if (this.dispatcher_) {
			const companies = this.store_.aReservationUser?.companies;
			let newCompany = companies.find(el => el.companyId === companyID);
		let newCredentials: IUserCredentials = this.store_.aReservationUser;
		
		// validate SysAdmin
		if(this.HasSysAdminRole()){
			newCredentials = {...newCredentials, companyID: companyID}
			this.Actions.SetAReservationCredentials(newCredentials);
		}
			if (newCompany) {
				newCredentials = {...newCredentials, companyID: newCompany.companyId }
				this.Actions.SetAReservationCredentials(newCredentials);
			}
		}
	};
}

const DefaultCredentialsStore:ICredentialStore = {
	systemAdmin: false,
	companyRoles: 0,
	reportsRoles: 0,
	companyId: 0,
	aReservationUser : {
		companies: [],
		oid: "",
		companyID: 0,
		firstName: "",
		lastName: "",
		preferences: {
			defaultCalendarView:0,
			showZeroTicketTimes:false,
			showInactiveEvents:false,
			user24HourTime:false,
			firstDayOfWeek:0,
		}
	},
	aWaiverUser : {
		companies: [],
		oid: "",
		companyID: 0,
		firstName: "",
		lastName: "",
		preferences: {
			defaultCalendarView:0,
			showZeroTicketTimes:false,
			showInactiveEvents:false,
			user24HourTime:false,
			firstDayOfWeek:0,
		}
	}
}

const Credentials: CredentialApi = new CredentialApi()

export default Credentials;

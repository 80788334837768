import {RequiredFieldsEnum} from "../UI";

export function cmToFeetInches(
  heightCm: number,
): { feet: number; inches: number } {
  let feet = 0;
  let inches = 0;
  if (heightCm && !isNaN(heightCm)) {
    // Rounds by half inch
    inches = roundToSteps(heightCm / 2.54,2);
    if (inches >= 12.0) {
      feet = Math.floor(inches / 12);
      inches -= feet * 12;
    }
  }
  return { feet, inches };
}

export function mToFeetInches(
  heightM: number,
): { feet: number; inches: number } {
  return cmToFeetInches(heightM * 100);
}

export function feetInchesToCm(feet: number, inches: number): number {
  let cm = 0;
  if (inches && !isNaN(inches)) {
    cm = inches * 2.54;
  }
  if (feet && !isNaN(feet)) {
    cm += feet * 12 * 2.54;
  }
  return cm;
}

export function feetInchesToM(feet: number, inches: number): number {
  return feetInchesToCm(feet, inches) / 100;
}

export function poundsToKg(lbs: number): number {
  let kg = 0;
  if (lbs && !isNaN(lbs)) {
    kg = lbs * 0.45359237;
  }
  return kg;
}

export function kgToPounds(kgs: number): number {
  var lbs = 0;
  if (kgs && !isNaN(kgs)) {
    lbs = kgs / 0.45359237;
  }
  return lbs;
}

/**
 * Use for round to a certain decimals
 * @param num
 * @param decimals
 */
export function roundTo(num: number, decimals: number) {
  let steps = 1;
  if (decimals && !isNaN(decimals)) {
    steps = Math.pow(10, decimals);
  }
  return roundToSteps(num,steps);
}

/**
 * Divides the integer into steps to be rounded. You can use it to include decimals passing a power of 10 or a certain step like 2 (0 - 0.5 - 1).
 * This function divides the integer into the given step so it's possible to return several digits after the decimal point.
 * Be careful when used
 * @param num
 * @param steps
 */
export function roundToSteps(num: number,steps:number = 1) {
  return Math.round(num * steps) / steps;
}

export function requiredFieldToNum(...keys :Array<RequiredFieldsEnum>): number {
  return keys.reduce((acc,count)=> acc + count,0);
}
export function allRequiredFieldsToNum(): number {
  const fields = [RequiredFieldsEnum.Height,RequiredFieldsEnum.Weight, RequiredFieldsEnum.Name, RequiredFieldsEnum.Phone];
  return requiredFieldToNum(...fields);
}

import React, {useState, useEffect, useRef} from "react";
import { makeStyles, Link, Typography, Button, useMediaQuery,} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import ReactMarkdown from "react-markdown";
import { VwEventDisplay } from "ares-core/Models";
import { RestrictionsDialog } from "modules/EventReservation";
import { isEmpty, Locale } from "ares-core";
import '../EventReservation.css';
import clsx from "clsx";
import {useElementHasOverflow} from "ares-core/Utils/hooks/useElementHasOverflow";
const useStyles = makeStyles((theme) => ({
  title_wrapper: {
    padding: "0 1.25rem",
  },
  title_text: {
    fontWeight: "bold",
		wordBreak: "break-word"
  },
  restrictionsBtnText: {
    color: theme.palette.warning.light,
    height:'35px'
  },
  offeredBy: {
    fontSize: "0.8em",
  }
}));

export interface EventHeaderProps extends VwEventDisplay {
  restrictions: string;
  handleAcknowledge: CallableFunction;
}

const EventHeaderComponent = (props: EventHeaderProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const hasOverflow = useElementHasOverflow(ref,props.description);
  const smallDevice = useMediaQuery('(max-width:374px)');

  const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (props.handleAcknowledge) {
        props.handleAcknowledge(true);
        setOpen(false);
    }
  };

  useEffect(() => {
    // dialog appears when restrictions load
    if (props.restrictions && props.restrictions.length > 0) {
      setOpen(true);
    }
  }, [props.restrictions]);

  if(props.name === undefined){
    return null
  }
  let headerComponent = (
      <header className={clsx('event-header event-header-container')}>
        <div className={clsx('event-header event-header-inner', { truncate:!expand })} ref={ref}>
          <div className={'event-header__img-container'}>
            <div className={'image-holder'}>
              {
                props.imgUrl ? (
                    <img
                        src={props.imgUrl}
                        alt={props.name || "event image"}
                    />
                ) : <Skeleton variant="rect" width="100%" height="100%" />
              }
            </div>
            {
              props.restrictions ? (
                  <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.restrictionsBtnText}
                      onClick={handleClickOpen}
                      endIcon={<Warning />}
                      startIcon={<Warning />}
                      style={{width:'100%'}}
                  >
                    {Locale.restrictions}
                  </Button>
              ) : null
            }
          </div>
          <Typography variant="h6" className={classes.title_text}>
            {props.name || Locale.loading}
            {
              isEmpty(props.companyName)? null: <div className={classes.offeredBy}>
                <sup>{Locale.offeredBy}</sup>
                {props.companyName}
              </div>
            }
          </Typography>
          <div>
            <ReactMarkdown source={props.description || ""} />
          </div>
        </div>
        {
          hasOverflow ? (
              <div className={'show-more'}>
                <Link onClick={() => setExpand(t => !t)}>Show {expand ? 'less' : 'more'} ...</Link>
              </div>
          ) : null
        }
      </header>
  );
  if(smallDevice) {
    headerComponent = (
        <header className={clsx('event-header event-header-container')}>
          <div className={'image-holder'}>
            {
              props.imgUrl ? (
                  <img
                      src={props.imgUrl}
                      alt={props.name || "event image"}
                  />
              ) : <Skeleton variant="rect" width="100%" height="100%" />
            }
          </div>
          {
            props.restrictions ? (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.restrictionsBtnText}
                    onClick={handleClickOpen}
                    endIcon={<Warning />}
                    startIcon={<Warning />}
                    style={{width:'100%'}}
                >
                  {Locale.restrictions}
                </Button>
            ) : null
          }
          <div className={clsx('event-header event-header-inner', { truncate:!expand })} ref={ref}>

            <Typography variant="h6" className={classes.title_text}>
              {props.name || Locale.loading}
              {
                isEmpty(props.companyName)? null: <div className={classes.offeredBy}>
                  <sup>{Locale.offeredBy}</sup>
                  {props.companyName}
                </div>
              }
            </Typography>
            <div>
              <ReactMarkdown source={props.description || ""} />
            </div>
          </div>
          {
            hasOverflow ? (
                <div className={'show-more'}>
                  <Link onClick={() => setExpand(t => !t)}>Show {expand ? 'less' : 'more'} ...</Link>
                </div>
            ) : null
          }
        </header>
    )
  }
  return (
      <>
        { headerComponent }
        <RestrictionsDialog
            open={open}
            handleClose={handleClose}
            restrictions={props.restrictions || ""}
            disableBackdropClick
            disableEscapeKeyDown
        />
      </>
  );
};

export default EventHeaderComponent;

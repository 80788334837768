import React, {useState} from 'react'
import {QuestionDate} from '../questionTypes';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateTimeUtils } from 'ares-core/Utils';
import {Locale} from "../../Locale";
export interface IDateQuestionProps {
    error: any,
    question: QuestionDate,
    value: string,
    label?: string | null,
    onChange: (arg: Date, arg2: string) => void
}
/**
 *  Renders date question control
 *
 * _Important: If the date picker doesn't shows, double check that
 * MuiPickersUtilsProvider is present at the highest level of your components tree.
 * For more information see: [docs](https://material-ui-pickers.dev/getting-started/installation)_
 * @param props
 */
export function DateQuestion(props: IDateQuestionProps) {

    const [state, setState] = useState({
        error: props.error,
        value: parseDate(props.value)
    });
    const {min, max} = props.question;
    // const value = props.value === '' ? null : DateTimeUtils.isoToLocaleString(props.value);

    const onChangeDate = (date: Date | null, value: string | null | undefined) => {
        if (date && !isNaN(date.getDate())) {
                props.onChange(date, value!);
            setState({value: value!,error: false});
        } else {
            setState({value: value!,error: true});
        }
    }
    return (
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={Locale.dateFormat}
            value={state.value}
            minDate={min}
            maxDate={max}
            onChange={onChangeDate}
            autoOk
            error={state.error}
            invalidDateMessage="Invalid Date"
            label={props.label}
        />
    )
}

const parseDate = (value: string): string | null => {
    return value === '' ? null : DateTimeUtils.isoToLocaleString(value);
}

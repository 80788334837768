import { Reducer } from "redux";
import { LinkCompanyActionType, LinkCompanyActions } from "./Actions";
import { ILinkCompanyStore } from "../Types";
import LinkCompany from "../LinkCompanyApi";

export const LinkCompanyStoreReducer: Reducer<
  ILinkCompanyStore,
  LinkCompanyActions
> = (state: ILinkCompanyStore = LinkCompany.Store(), action) => {
  switch (action.type) {
    case LinkCompanyActionType.SET_LINK_COMPANY:
      return {
        ...state,
        ...action.company,
      };
    case LinkCompanyActionType.SET_LINK_COMPANY_EVENTS:
      return {
        ...state,
        events: action.companyEvents,
      };
    case LinkCompanyActionType.SET_LINK_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: action.currentEvent,
			};
		case LinkCompanyActionType.SET_LINK_EVENT_DATES:
			return {
				...state,
				eventDates: action.eventDates,
			};
		case LinkCompanyActionType.SET_LINK_EVENT_TIMES:
			return {
				...state,
				eventTimes: action.eventTimes,
			};
    case LinkCompanyActionType.UPDATE_LINK_RESERVATION:
      return {
        ...state,
        currentReservation: {
          ...state.currentReservation,
          ...action.res,
        },
			};
			case LinkCompanyActionType.SET_LINK_EVENT_CAL: {
				return {
					...state,
					eventCalendar: action.eventCalendar,
					busy: false
				};
			}
			case LinkCompanyActionType.SET_LINK_CATALOG: {
				return {
					...state,
					catalogDisplay: action.catalogDisplay,
					busy: false
				};
			}
			case LinkCompanyActionType.SET_LAST_RESERVATION_DATE: {
				return {
					...state,
					lastReservationDate: action.lastReservationDate,
					busy: false
				};
			}
			case LinkCompanyActionType.SET_LINK_COMPANY_BOOSTING: {
				return {
					...state,
					boosting: action.boosting,
					busy: false
				};
			}
			case LinkCompanyActionType.LINK_COMPANY_IN_PROGRESS:
				var changedState = { ...state };
				changedState = { ...state, busy: action.bOn };
				return changedState;
			default:
				neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
				break;
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (action: LinkCompanyActions) => {};

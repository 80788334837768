import {
    AuthenticationResult,
    Configuration,
    LogLevel,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest
} from "@azure/msal-browser";
import JwtDecode from "jwt-decode";
const createLogger = (printLogger: boolean) => (level: LogLevel, message: string, containsPii: boolean): void => {
    if (containsPii) return;

    if (!printLogger) return;

    switch (level) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
    }
}
class AuthService {
    instance: PublicClientApplication | null = null;
    configuration: Configuration | null = null;
    public loginRequest: RedirectRequest | null = null;
    lastAccessToken: string | null = null;
    lastAccessTime: Date | null = null;
    oid: any | null = null;
    private _endpointHost:string | null = null;
    createInstance() {
        if(this.configuration)
        this.instance = new PublicClientApplication(this.configuration)
    }

    /**
     * Creates a configuration object for the PublicClientApplication object.
     * Call this function in the environments
     * @param applicationId
     * @param endpointHost
     * @param authorityUri
     * @param redirectUri
     * @param knownAuthorities
     */
    configureInstance(applicationId: string, endpointHost:string,authorityUri: string, redirectUri: string, knownAuthorities: string[]) {
        this._endpointHost = endpointHost;
        this.configuration = {
            auth: {
                clientId: applicationId,
                authority: authorityUri,
                knownAuthorities: knownAuthorities,
                redirectUri: redirectUri,
                postLogoutRedirectUri: "http://www.indexic.net",
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
            system: {
                loggerOptions: {
                    loggerCallback: createLogger(false),
                    piiLoggingEnabled: true,
                }
            },
        }
    }

    /**
     * Configures loginRequest
     * Call this in the environments
     * @param scopes
     * @param redirectStartPage
     */
    configureLoginRequest(scopes: string[]) {
       const login: RedirectRequest = {
            scopes: scopes
        }
        this.loginRequest = login;
    }
    updateLoginRequest(request: Partial<RedirectRequest>) {
        this.loginRequest = {
            ...this.loginRequest,
            ...request as RedirectRequest
        }
    }

    async getAccessToken(): Promise<string | null> {
        let token: string = "";
        if(this.isTokenValid){
            return this.lastAccessToken;
        }
        const response: AuthenticationResult | null = await this.acquireTokenSilent();
        if(response) {
            token = response.idToken;
            let asciiToken:any = JwtDecode(token);
            this.oid = asciiToken.oid;
            if (token !== this.lastAccessToken) {
                this.lastAccessTime = new Date(); // Successful acquireTokenSilent() reset timer
                this.lastAccessToken = token;
                console.log("[" + this.lastAccessTime.toTimeString() + "] New token acquired: " + token);
            }
        } else {
            // this.instance?.loginRedirect(this.loginRequest!)
        }
        return token;
    }

    private async acquireTokenSilent(): Promise<AuthenticationResult | null> {
        let response: AuthenticationResult | null = null;
        const scopes = this.loginRequest?.scopes;
        if(!this.instance || !scopes) {
            console.error('NO instance')
            return null;
        }
        const account = this.instance.getAllAccounts()[0];
        let tokenRequest: SilentRequest = { scopes, account: account };
        try {
            if(this.instance) {
                 response = await this.instance.acquireTokenSilent(tokenRequest);
            }
        } catch (error) { // Lower typescript version wont allow typed catch
            console.error(error);
            if(this.loginRequest) {
               await this.instance.loginRedirect(this.loginRequest)
            }
        }
        return response;
    }

    private get isTokenValid():boolean {
        let isValid = false;
        if (this.lastAccessTime && this.lastAccessToken) {
            let now: Date = new Date();
            const milliseconds = now.getTime() - this.lastAccessTime.getTime();
            // force acquire if 6 minutes has elapsed
            isValid = milliseconds < (1000 * 60 * 6);
        }
        return isValid;
    }
    get endpointHost() {
        return this._endpointHost
    }

    logout() {
        return this.instance?.logoutRedirect();
    }

}

const service = new AuthService();
export default service;

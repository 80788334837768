import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationStore } from "AppState";
import { ButtonBottonWrapper } from "modules/Common/GoToBottomButton";
import ReceiptDisplay from "./ReceiptDisplay";
//import { Shopping } from "ares-core/Shopping";
import { AppState } from "../../AppState";
import { Locale } from "ares-core/UI/Locale";
import { useParams } from "react-router";
export interface IReceiptPage {
  location: any;
}

export const ReceiptPage = (props: any) => {
  const [url, setUrl] = useState<string>("");
  // 040b000e040c090d07060102e9fd1820
  const { checkoutTransaction } = useParams<{ checkoutTransaction : string }>()

  useEffect(() => {
    if ( checkoutTransaction !== undefined ) {
      var baseurl = `https://${AppState.Store().endpoint}/Receipt/${checkoutTransaction}`;
      setUrl(baseurl);
    }
  }, [props.location]);

  const links = [
    {
      label: Locale.makeAnotherReservation,
      enable: true,
      place: AppState.Store().companyUrl,
    },
    {
      label: Locale.home,
      enable: true,
      place: AppState.Store().landingUrl,
    },
  ];
  return (
    <ButtonBottonWrapper links={links}>
      <ReceiptDisplay url={url} />
    </ButtonBottonWrapper>
  );
};

const mapStateToProps = (state: IApplicationStore) => {
  return {
    ...state.shopping,
		boosting: state.company.boosting,
  };
};

export default connect(mapStateToProps)(ReceiptPage);

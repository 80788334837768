import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Button, Card, CardContent, createStyles, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {ICartDTO, ICartItem, ICartReservation, IReservationTip} from "ares-core/Models";
import { MathEx, isEmpty } from "ares-core/Utils";
import { Locale } from "ares-core/UI/Locale";
import { Shopping } from "ares-core/Shopping";
import * as CheckoutUtils from 'ares-core/Utils/CheckoutUtils';
import CartItem from "./CartItem";
import DialogComponent, { DialogHooks } from "link-core/UI/Components/DialogComponent";
import ReservationTipPanel from "./ReservationTipPanel";
import { ARES_DEVELOPMENT } from "Config/environment";
export interface ICartDisplayProps {
	cartDto: ICartDTO | null;
	condensed?: boolean;
	errorsDisplayed?: () => void
}



export default function CartDisplay({ cartDto, condensed = false,...props }: ICartDisplayProps) {
	const classes = useStyles();
	const [cartErrors, setCartErrors] = useState<string[]>([]);
	const [dialogIsOpen, handleOpenDialog, handleCloseDialog] = DialogHooks();
	const DialogActions = { handleSuccessActionText: "Close", handleSuccessActionFunction: () => handleCloseDialog() }
  
	const openDialog = () => {
	  handleOpenDialog();
	}
  
	const handleAddTip = (tip: IReservationTip) => {
	  handleCloseDialog();
	  if(tip) {
		  const {companyId} = Shopping.Store();
		  Shopping.Api.addLinkTipItem(tip, companyId);
	  }
   
	}

	useEffect(()=>{
		if(!isEmpty(cartDto?.errors)) {
			setCartErrors(cartDto?.errors!);
			if(props.errorsDisplayed) {
				props.errorsDisplayed()
			}
		}
	},[cartDto])

	const handleDelete = (reservation: ICartReservation | null, catalogItem: ICartItem | null) => {
		// TODO: Confirmation before actual delete.
		if (reservation) {
			if (catalogItem) {
				Shopping.Api.removeReservation(reservation.reservationId, catalogItem.catalogId)		}
			else {
				Shopping.Api.removeReservation(reservation.reservationId);
			}
		} else
			if (catalogItem) {
				Shopping.Api.removeCatalogItem(catalogItem);
			}
	};

	const taxAndFees = CheckoutUtils.calculateTaxesAndFees(cartDto);
	let total = taxAndFees + (cartDto?.subTotal ?? 0);
	let appliedGiftCards = Shopping.GiftCardTotal();
	total -= appliedGiftCards;
	let discount = 0;
	cartDto?.catalogItems.forEach(catalogItem => discount += catalogItem.discount ?? 0);
	cartDto?.reservations.forEach(reservation => {
		discount += reservation.discount ?? 0;
		reservation.addOns.forEach(addOn => discount += addOn.discount ?? 0);
	});
	const remainingBalance = (cartDto?.depositBalance && cartDto?.depositBalance >= 0) ? cartDto?.depositBalance : -1;

	// Tipping is only for reservations with price > 0
	const reservationsForTipping = cartDto?.reservations.filter(res => res.price && res.price > 0 && res.hasTipping);
	
	return (
		<>
		{
			cartDto?.reservations.map((res) => 
			<Fragment key={res.reservationId}>
				<Grid container className={classes.root}>
					<Grid item className={classes.commandCol} hidden={condensed}>
						<IconButton size="small" onClick={() => handleDelete(res, null)}>
							<DeleteForeverIcon/>
						</IconButton>
					</Grid>
					<Grid item className={classes.descriptionCol}>
						{res.description?.split(/(?:\r\n|\r|\n)/g).map((part, i) =>
							<Fragment key={i}>
								{(condensed && i > 0) ? null : <div>{part}</div>}
							</Fragment>)}
					</Grid>
					<Grid item className={classes.currency}>
						{MathEx.formatCurrency(res.price ?? 0)}
					</Grid>
				</Grid>
				{
					res.addOns.map((addOn, i) => (
						<CartItem key={addOn.catalogId} cartItem={addOn} handleDelete={handleDelete} condensed={condensed} classes={classes} res={res}/>
					)
					)
				}
			</Fragment>)
		}

		{
			cartDto?.catalogItems.map((cat, i) =>
					<CartItem key={cat.catalogId} cartItem={cat} handleDelete={handleDelete} condensed={condensed} classes={classes} res={null}/>
				)
		}

		{ 
			discount != 0 &&
			<Grid container>
				<Grid item className={classes.labelCol}>{Locale.discount}</Grid>
				<Grid item className={classes.currency}>{MathEx.formatCurrency(discount)}</Grid>
			</Grid> 
		}



		<div style={{display:'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-start', marginTop: 10}}>
			<div>
			{
				reservationsForTipping && reservationsForTipping?.length > 0 && !condensed &&
				<Button
					color={'primary'}
					variant={'contained'}
					onClick={openDialog}
					size={'small'}
					style={{margin: '15px 5px 0 10px'}}
				>ADD TIP</Button>
			}
			</div>
			
			<div style={{flex: 2}}>
			<Grid container>
				<Grid item className={classes.labelCol}>{Locale.subTotal}</Grid>
				<Grid item className={classes.currency}>{MathEx.formatCurrency(cartDto?.subTotal ?? 0)}</Grid>
			</Grid>
			<Grid container>
				<Grid item className={classes.labelCol}>{Locale.taxAndFees}</Grid>
				<Grid item className={classes.currency}>{MathEx.formatCurrency(taxAndFees)}</Grid>
			</Grid>
			{
				appliedGiftCards > 0 &&
				<Grid container>
					<Grid item className={classes.labelCol}>{Locale.giftCards}</Grid>
					<Grid item className={classes.currency}>{MathEx.formatCurrency(appliedGiftCards * -1)}</Grid>
				</Grid>
			}
			<Grid container>
				<Grid item className={classes.labelCol}>{Locale.total}</Grid>
				<Grid item className={classes.currency}>{MathEx.formatCurrency(total)}</Grid>
			</Grid>

			{
				remainingBalance >= 0 &&
				<Grid container>
					<Grid item className={classes.labelCol}>{Locale.remainingBalance}</Grid>
					<Grid item className={classes.currency}>{MathEx.formatCurrency(remainingBalance)}</Grid>
				</Grid>
			}
			</div>
		</div>

		{
			!condensed && cartDto?.appliedGiftCards && cartDto?.appliedGiftCards.length > 0 && 
			<Card className={classes.giftCards}>
				<CardContent>
					<Grid container className={classes.root}>
						<Grid item className={classes.giftCardCodeCol}>{Locale.giftCards}</Grid>
						<Grid item className={classes.giftCardValueCol}>{Locale.beginningBalance}</Grid>
						<Grid item className={classes.giftCardBalanceCol}>{Locale.amountApplied}</Grid>
					</Grid>
					{cartDto?.appliedGiftCards.map(giftCard => <Grid container className={classes.root} key={giftCard.code}>
						<Grid item className={classes.giftCardCodeCol}>{giftCard.code}</Grid>
						<Grid item className={classes.giftCardValueCol}>{MathEx.formatCurrency(giftCard.originalPrice)}</Grid>
						<Grid item className={classes.giftCardBalanceCol}>{MathEx.formatCurrency(giftCard.balance)}</Grid>
					</Grid>)}
				</CardContent>
			</Card> 
		}

		{ 
			!isEmpty(cartDto?.warnings) && 
			<Card className={classes.warningMsgCol}>
				<CardContent>
					{ cartDto?.warnings.map((warnMsg, i) => <div key={i} >{warnMsg}</div>) }
				</CardContent>
			</Card> 
		}
		{ 
			!isEmpty(cartErrors) && 
			<Card className={classes.errorMsgCol}>
				<CardContent>
					{ cartErrors.map((errMsg, i) => <div key={i} >{errMsg}</div>) }
				</CardContent>
			</Card> 
		}
			<DialogComponent open={dialogIsOpen} handleClose={handleCloseDialog} ButtonActions={DialogActions}>
      		<ReservationTipPanel reservations={reservationsForTipping!} handleAddTip={handleAddTip} />
    	</DialogComponent>
		</>
	);
}



const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexWrap: "nowrap",
			marginTop: "0.3em",
            padding: "0",
			[theme.breakpoints.down("xs")]: {
				fontSize: "0.7em"
			}
		},
		commandCol: {
			width: "2em",
		},
		giftCards: {
			marginTop: "0.5em",
		},
		warningMsgCol: {
			marginTop: "0.5em",
			color: theme.palette.warning.contrastText,
			backgroundColor: theme.palette.warning.main
		},
		errorMsgCol: {
			marginTop: "0.5em",
			color: theme.palette.error.contrastText,
			backgroundColor: theme.palette.error.main
		},
		labelCol: {
			flexGrow: 1,
			textAlign: "right"
		},
		unitPrice: {
			[theme.breakpoints.down("xs")]: {
				display: "none"
			}			
		},
		currency: {
			width: "5em",
			textAlign: "right",
			marginLeft: "0.3em",
            paddingRight: "0.3em",
		},
		unitPriceCol: {
			width: "6em",
			textAlign: "right",
			marginLeft: "0.3em"
		},
		descriptionCol: {
			flexGrow: 1,
			marginLeft: "1em",
			// textIndent: "-1em"
		},
		giftCardCodeCol: {
			flexGrow: 1,
		},
		giftCardValueCol: {
			width: "6em",
			textAlign: "right",
			marginLeft: "0.3em",
		},
		giftCardBalanceCol: {
			width: "6em",
			textAlign: "right",
			marginLeft: "0.3em",
		}

	})
);


// TASK-2535 - Remove
import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Box,
} from "@material-ui/core";

import {
  CardElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";

import {
  CreatePaymentMethodData,
  CreateTokenCardData,
  Stripe,
  StripeCardElement,
  StripeError,
} from "@stripe/stripe-js";


import { ICheckoutData } from "ares-core/Models";
import { StripeGateway } from "ares-core/UI/Checkout/Gateway";
import { ICheckoutProps } from "./CheckoutProps";

export interface IStripePaymentProps extends ICheckoutProps {}

export default function StripePayment(props: IStripePaymentProps) {
  const [gateway, setGateway] = useState<StripeGateway>(props.gatewayProcessor as StripeGateway);

  return (
    <Elements stripe={gateway.stripePromise}>
      <StripePaymentContent {...props} />
    </Elements>
  );
}

const StripePaymentContent = (props:IStripePaymentProps) => {
  	const classes = useStyles();
	const [hasFocus, setHasFocus] = useState(false);
	const [hasError, setHasError] = useState("");
  	const [stripeElement, setStripeElement] = useState<StripeCardElement>();
  	const stripe: any | null = useStripe();

	const getToken = (checkoutData: ICheckoutData, stripeElement: StripeCardElement, stripe:Stripe)=>{
		const createTokenCardData = {
			name: `${checkoutData.contactInfo.firstName} ${props.checkoutData.contactInfo.lastName}`,
			address_city: checkoutData.multilineAddress.city,
			address_state: checkoutData.multilineAddress.state,
			address_line1: checkoutData.multilineAddress.address1,
			address_line2: checkoutData.multilineAddress.address2,
			address_zip: checkoutData.multilineAddress.zip,
			address_country: checkoutData.multilineAddress.country,
		} as CreateTokenCardData;

		stripe
			.createToken(stripeElement, createTokenCardData)
			.then((result: any) => {
				handleTokenReceived(
					result.token?.id || null,
					result.error === undefined,
				);
			})
			.catch((reason: StripeError) => {
				setHasError(reason.message || "");
			});
			
		};

		const getTokenWithIntent = (checkoutData: ICheckoutData, paymentIntentCode: string,
			stripeElement: StripeCardElement, stripe:Stripe) =>
		{
			const paymentMethodData = {
				type: "card",
				card: stripeElement,
				billing_details: {
					name: `${checkoutData.contactInfo.firstName} ${checkoutData.contactInfo.lastName}`,
					address: {
						city: checkoutData.multilineAddress.city,
						state: checkoutData.multilineAddress.state,
						line1: checkoutData.multilineAddress.address1,
						line2: checkoutData.multilineAddress.address2,
						postal_code: checkoutData.multilineAddress.zip,
						country: checkoutData.multilineAddress.country,
					},
					email: checkoutData.contactInfo.email,
					phone: checkoutData.contactInfo.phone,
				},
			} as CreatePaymentMethodData;

			stripe
				.confirmCardPayment(paymentIntentCode, paymentMethodData)
				.then((result: any) => {
					handleTokenReceived(
						result.paymentIntent?.id || null,
						result.error === undefined,
					);
				})
				.catch((reason: StripeError) => {
					setHasError(reason.message || "");
				});
		};

		useEffect(() => {
			if (props.proceedWithCheckout && stripeElement) {
				if (props.paymentIntentCode && props.paymentIntentCode.length > 0) {
					getTokenWithIntent(props.checkoutData,props.paymentIntentCode,stripeElement,stripe);
				}
				else {
					getToken(props.checkoutData,stripeElement,stripe);
				}
			}
		}, [props.proceedWithCheckout, props.checkoutData, stripeElement, stripe]);

		const handleReady = (stripeEl: StripeCardElement) => {
			setStripeElement(stripeEl);
		};

		const handleChange = (change: any) => {
				setHasError(change.error ? change.error.message : "");
				if (!change.isEmpty && change.complete) {
					props.onChange(true,change);
				}
				else {
					props.onChange(false,change);
				}
		};

		const handleTokenReceived = (token: string | null, isValid: boolean) => {
			if (isValid) {
					props.onPaymentReady(isValid,token);
				}
				else { // Maybe Error Msg instead of token
					props.onPaymentReady(isValid,token);
				}
		};

  		return (
			<Box className={`${classes.root} ${hasFocus ? classes.hasFocus : ""} ${
							hasError.length > 0 ? classes.hasError : ""
						}`}
					>
				<CardElement
					onReady={handleReady}
					onFocus={() => setHasFocus(true)}
					onBlur={() => setHasFocus(false)}
					onChange={handleChange}
					options={{ hidePostalCode: true }}
				/>
			</Box>
  		);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: "1px",
      padding: "0.5em",
      borderStyle: "solid",
    },
    hasFocus: {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
    hasError: {
      borderColor: theme.palette.error.main,
    },
    submit: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);

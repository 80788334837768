import React, {Fragment, ReactNode} from "react";

export const NewLineToBr = ({children = ''}) => {
    if(!children) return null;
    return (
        <Fragment>
            {
                children.split('\n')
                    .reduce((arr, line, index) => arr.concat(
                        <Fragment key={index}>
                            {line}
                            <br/>
                        </Fragment>
                    ), [] as ReactNode[])
            }
        </Fragment>
    )
}

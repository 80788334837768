import { Action, ActionCreator, Dispatch } from "redux";
import { IUserCredentials } from "AppState/Types";

export enum AppStateActionType {
	SET_APP_ENDPOINT = "SET_APP_ENDPOINT",
	SET_APP_LANDING_URL = "SET_APP_LANDING_URL",
	SET_APP_COMPANY_URL = "SET_APP_COMPANY_URL",
	SET_APP_IN_PROGRESS = "SET_APP_IN_PROGRESS"
}

// In Progress
export interface ISetAppInProgressAction
  extends Action<AppStateActionType.SET_APP_IN_PROGRESS> {
  bOn: boolean;
}
export const setAppInProgressAction: ActionCreator<ISetAppInProgressAction> = (
  t,
) => {
  return { bOn: t, type: AppStateActionType.SET_APP_IN_PROGRESS };
};

// Endpoint
export interface ISetEndpointAction
  extends Action<AppStateActionType.SET_APP_ENDPOINT> {
  endpoint: string;
}
export const setEndpointAction: ActionCreator<ISetEndpointAction> = (e:string) => {
  return { endpoint: e, type: AppStateActionType.SET_APP_ENDPOINT };
};

// Landing URL
export interface ISetLandingUrlAction
  extends Action<AppStateActionType.SET_APP_LANDING_URL> {
	landingUrl: string;
}
export const setLandingUrlAction: ActionCreator<ISetLandingUrlAction> = (url:string) => {
  return { landingUrl: url, type: AppStateActionType.SET_APP_LANDING_URL };
};

// Company URL
export interface ISetCompanyUrlAction
  extends Action<AppStateActionType.SET_APP_COMPANY_URL> {
	companyUrl: string;
}
export const setCompanyUrlAction: ActionCreator<ISetCompanyUrlAction> = (url:string) => {
  return { companyUrl: url, type: AppStateActionType.SET_APP_COMPANY_URL };
};

export type AppStateActions =
  | ISetEndpointAction
  | ISetLandingUrlAction
  | ISetCompanyUrlAction
  | ISetAppInProgressAction;

	export interface IAppStateActions {
		readonly SetEndpoint: (endpoint:string) => void;
		readonly SetLandingUrl: (url:string) => void;
		readonly SetCompanyUrl: (url:string) => void;
	}

	// Convenience Function to link Action Dispatcher
	const BuildAppStateActions = (dispatcher: Dispatch) => {
		const AppStateActions: IAppStateActions = {
			SetEndpoint: (host:string) => { dispatcher(setEndpointAction(host)); },
			SetLandingUrl: (url:string) => { dispatcher(setLandingUrlAction(url)); },
			SetCompanyUrl: (url:string) => { dispatcher(setCompanyUrlAction(url)); },
		};

	return AppStateActions;
}

export default BuildAppStateActions;
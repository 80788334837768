import { IQueryParam } from "ares-core/IO";

/**
 *  Returns an object with query params as keys.
 * Note: this function is case insensitive and will return first result in the string
 * i.e. "?GroupID=1&GroupId=2&groupid=3&groupId=4" will return {groupid: '1'}
 * @param url string to search for params
 */
export const getParamsDictionary = (url: string):  {[key: string] : string } => {
    let params = new URLSearchParams(url);
    let dictionary:  {[key: string] : string }  = {};
    for (const [queryParam, value] of params) {
        const key = queryParam.toLowerCase()
        if(!dictionary.hasOwnProperty(key)) {
            dictionary[key] = value
        }
    }
    return dictionary
}

export const getQueryParams = (url: string): IQueryParam[] => {
    let valueParamGroup: any = [];
    new URLSearchParams(url).forEach((v, k) => {
        valueParamGroup.push({key: k.toLocaleLowerCase(), value: v.toLocaleLowerCase()});
    });
    return valueParamGroup;
  };

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationStore } from "../../AppState";
import CalendarContainer, {
  CalendarView,
} from "ares-core/UI/Calendar/CalendarContainer/CalendarContainer";
import { ILinkCompanyStore, LinkCompany } from "ares-core/LinkCompany";
import "ares-core/UI/EventColors/colors.css";
import EventCalendarItem from "./components/EventCalendarItem";
import { getParamsDictionary, getQueryParams } from "ares-core/Utils/Location";

// This Page Sets up the initial Link Reservation process flow
// it will query the endpoints for the initial set of data, it
// will instantiate the state machine and register for state changes

export interface EventCalendarProps {
  history: any;
  companyName: string;
  boost: boolean;
  groupId: string;
  company: ILinkCompanyStore;
}

// Need to track the EventName. If names change then query for details
// if not check for Reservation State if need to Start over
const DisplayEventCalendar = (props: any) => {
  const today = new Date();
  const queryParams = getQueryParams(window.location.search);

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [eventMonth, setEventMonth] = React.useState<Date>(
    new Date(1990, 1, 1),
  );
  const [initialStartDay, setInitialStartDay] = React.useState<Date>(today);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  // Constructor / Destructor
  useEffect(() => {
    // Await on the initial data load to determine the lowest chronological day
    // in the EventCalendar data. Use a loaded bool so that the Calendar object
    // does not render until we get the date and can set initialStartDay, otherwise
    // the Calendar will default to today.
    const asyncFunc = async () => {
      await LinkCompany.Api.loadEventCalendar(
        eventMonth.toISOString(),
        queryParams,
      );
      var eventCal = LinkCompany.Store().eventCalendar;
      var keyArray = Object.keys(eventCal).sort();
      var key = keyArray[0];
      setInitialStartDay(new Date(key));
      setLoaded(true);
    };

    asyncFunc();
    return () => {};
  }, []);

  // Watch the month to change
  useEffect(() => {
    // This check keeps the data load only when navigation occurs
    // otherwise it would load on the initial construction
    if (eventMonth >= thisMonth) {
      LinkCompany.Api.loadEventCalendar(eventMonth.toISOString(),queryParams);
    }
  }, [eventMonth]);

  // Watch for Data to change
  useEffect(() => {}, [eventMonth, props.company.eventCalendar]);

  const BuildCalendar = () => {
    var eventCal = LinkCompany.Store().eventCalendar;
    return (
      <CalendarContainer
        dayContentsPropsDictionary={eventCal}
        min={today}
        viewStartDate={initialStartDay}
        onNavigate={handleNavigate}
      >
        <EventCalendarItem {...props} />
      </CalendarContainer>
    );
  };

  const handleNavigate = (
    viewStartDate: Date,
    view: CalendarView,
    backward: boolean,
  ) => {
    // Creates a new Reference to the Date so the UseEffect() function
    // watching the eventMonth will trigger and load the calendar
    var copiedDate = new Date(viewStartDate.getTime());
    setEventMonth(copiedDate);
  };

  return <>{loaded ? BuildCalendar() : null}</>;
};

// Get notified of any changes to the props. This is where you should
// transform any data needed by the component. The state is the root
// of the Store as known by Redux
const mapStateToProps = ({ company, shopping }: IApplicationStore) => {
  return {
    company: company,
  };
};

export default connect(mapStateToProps)(DisplayEventCalendar);

import React, { Component, ReactNode } from 'react';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Typography, IconButton } from '@material-ui/core';
import {DateTimeUtils} from "../../../Utils";

const styles =
	(theme: Theme) => createStyles({
		calBody: {
			display: "flex",
			flexWrap: "wrap",
		},
		calHeader: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		dayNamesRow: {
			display: "flex",
			[theme.breakpoints.down("xs")]: {
				display: "none",
			},
			position: "relative",
		},
		calDayName: {
			position: "relative",
			overflow: "hidden",
			textAlign: "center",
		},
		calDayCell: {
			boxSizing: "border-box",
			position: "relative",
			width: "14.28%",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: theme.palette.divider,
		},
		calDayCellNumber: {
			fontSize: "0.9em",
			[theme.breakpoints.down("xs")]: {
				display: "none",
			},
		},
		calDayCellHeader: {
			display: "none",
			[theme.breakpoints.down("xs")]: {
				display: "block",
			},
			fontSize: "1.3em",
			fontWeight: "bold"
		},
		noContent: {
			[theme.breakpoints.down("xs")]: {
				display: "none",
			},
		},
		past: {
			"& div:first": {
				opacity: 0.6,
			},
		},
		otherMonth: {
			[theme.breakpoints.down("xs")]: {
				display: "none",
			},
			"& div:first": {
				opacity: 0.2,
			},
		},
		today: {
			fontWeight: "bold",
		},
	});

export enum CalendarView {
	Month = 0,
	Week,
	Day
}
interface ICalendarContainerProps extends WithStyles<typeof styles> {
	firstDayOfWeek: number;
	locales: string;
	viewStartDate: Date;
	max: Date;
	min: Date;
	view: CalendarView;
	leftHeaderComponent?: ReactNode;
	rightHeaderComponent?: ReactNode;
	dayContentsPropsDictionary?: ICalendarDayContent;
	onNavigate?: (viewStartDate: Date, view: CalendarView, backward: boolean) => void;
	children: any
}
interface ICalendarContainerState {
	viewStartDate: Date;
}

export interface ICalendarDayContent {
	// key needs to be in the format of YYYY-MM-DD, the first 10 characters of ISO Date
	// The data portion will be passed a properties to the child component
	[key: string]: any;
}

/**
 * Displays a traditional calendar view (Month), Week or Day views.  A onNavigation handler
 * can be specified to request data.  Data can be displayed on the calendar in the day cells.
 * The content of the day cell is a combination of the child node(s) and the data specified 
 * in the dictionary dayContentsPropsDictionary.  The dictionary is key by YYYY-MM-DD. When 
 * the calendar is rendered any data that has a key matching the day being rendered is passed
 * in as props to the child nodes.  Otherwise the child nodes will net be rendered.
 */
class CalendarContainer extends Component<ICalendarContainerProps, ICalendarContainerState> {
	constructor(props: ICalendarContainerProps) {
		super(props);
		// Create and array of localized day names ie (Monday, Tuesday, ...)
		this.dayNames = [];
		for (var day = 0; day < 7; day++) {
			this.dayNames.push(new Date(1970, 0, day + 4 + this.props.firstDayOfWeek)
				.toLocaleString(this.props.locales, { weekday: "long" })
			);
		}
	}
	classes = this.props.classes;
	state = {
		viewStartDate: this.props.viewStartDate,
	}
	dayNames: string[];
	static defaultProps: Partial<ICalendarContainerProps> = {
		firstDayOfWeek: 0,
		locales: "en-US",
		min: new Date(0, 0, 1),
		max: new Date(9999, 11, 31),
		viewStartDate: new Date(),
		view: CalendarView.Month,
	};
	longDateFormater = new Intl.DateTimeFormat(this.props.locales, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
	startOfWeek = (startDate: Date): number => {
		const someDate = new Date(startDate.setHours(0, 0, 0, 0));
		const offset = (7 - (this.props.firstDayOfWeek - someDate.getDay())) % 7;
		return someDate.setDate(someDate.getDate() - offset);
	};

	endOfWeek = (someDate: Date): number => {
		const offset = (6 - (someDate.getDay() - this.props.firstDayOfWeek)) % 7;
		return someDate.setDate(someDate.getDate() + offset);
	};

	setCalendarRange = (): Date[] => {
		if (this.props.view === CalendarView.Day) {
			return [this.state.viewStartDate];
		} else {
			const month = this.state.viewStartDate.getMonth();
			const year = this.state.viewStartDate.getFullYear();
			const end = new Date(this.endOfWeek(this.props.view === CalendarView.Week ?
				this.state.viewStartDate : new Date(year, month + 1, 0)));
			const start = this.startOfWeek(this.props.view === CalendarView.Week ?
				this.state.viewStartDate : new Date(year, month, 1));
			const calDays = [];
			for (let curDay = new Date(start); curDay <= end; curDay.setDate(curDay.getDate() + 1)) {
				calDays.push(new Date(curDay));
			}
			return calDays;
		}
	};
	calendarTitle = (): string => {
		const month = this.state.viewStartDate.toLocaleString(this.props.locales, { month: "long" });
		let dayText = "";
		switch (this.props.view) {
			case CalendarView.Day:
				dayText = `${this.state.viewStartDate.getDate()} `;
				break;
			case CalendarView.Week:
				const day = new Date(this.startOfWeek(this.state.viewStartDate)).getDate()
				dayText = `${day}-${day + 6} `;
				break;
		}
		return `${month} ${dayText}${this.state.viewStartDate.getFullYear()}`;
	};

	canGoBack = (): boolean => {
		return this.state.viewStartDate > this.props.min;
	}
	canGoForward = (): boolean => {
		return this.state.viewStartDate < this.props.max;
	}
	handleBackClick = () => {
		if (this.canGoBack()) {
			this.changeDate(true);
		}
	};
	handleForwardClick = () => {
		if (this.canGoForward()) {
			this.changeDate();
		}
	};
	changeDate = (backward: boolean = false): void => {
		const currentViewDate = this.state.viewStartDate;
		const direction = backward ? -1 : 1;
		switch (this.props.view) {
			case CalendarView.Day:
				currentViewDate.setDate(currentViewDate.getDate() + direction);
				break;
			case CalendarView.Week:
				currentViewDate.setDate(currentViewDate.getDate() + (direction * 7));
				break;
			case CalendarView.Month:
				currentViewDate.setDate(1);
				currentViewDate.setMonth(currentViewDate.getMonth() +  direction);
				break;
		}
		this.setState({ viewStartDate: currentViewDate });
		if (this.props.onNavigate) {
			this.props.onNavigate(currentViewDate, this.props.view, backward);
		}
	}
	render() {
		const now = new Date().setHours(0, 0, 0, 0);
		const calendarRange = this.setCalendarRange();
		return (
			<Box>
				<Box className={this.classes.calHeader}>
					<IconButton onClick={this.handleBackClick} disabled={!this.canGoBack()} >
						<ArrowBackIosIcon />
					</IconButton>
					<Box>
						{this.props.leftHeaderComponent}
					</Box>
					<Box>
						<Typography variant="h5">
						{calendarRange.length > 0 ? this.calendarTitle() : "No events, rentals or tours are currently scheduled."}
						</Typography>
					</Box>
					<Box>
						{this.props.rightHeaderComponent}
					</Box>
					<IconButton onClick={this.handleForwardClick} disabled={!this.canGoForward()} >
						<ArrowForwardIosIcon />
					</IconButton>
			</Box>
				{calendarRange.length > 1 ?
					<Box className={this.classes.dayNamesRow}>
						{this.dayNames.map((dayName: string, i: number) =>
							<div className={`${this.classes.calDayCell} ${this.classes.calDayName}`} key={i}>
								<Typography color="primary" >{dayName}</Typography>
							</div>
						)}
					</Box> : null}
				<Box className={this.classes.calBody}>
					{calendarRange.map((day: Date, i: number) => {
						const contentData = this.props.dayContentsPropsDictionary ? this.props.dayContentsPropsDictionary[DateTimeUtils.toYYYYMMDD(day)] : null;
						const dayTime = day.getTime();
						const otherMonth = day.getMonth() !== this.state.viewStartDate.getMonth();
						const dowClass = `${this.classes.calDayCell} ${dayTime < now ? this.classes.past : ''}`
							+ ` ${otherMonth ? this.classes.otherMonth : ''}`
							+ ` ${dayTime === now ? this.classes.today : ''}`
							+ ` ${contentData ? '' : this.classes.noContent}`;
						return (
							<div className={dowClass} key={i} style={this.props.view === CalendarView.Day ? { width: "100%" } : {}}>
								<Box className={this.classes.calDayCellNumber}>
									{day.getDate()}
								</Box>
								<Box className={this.classes.calDayCellHeader}>
									{this.longDateFormater.format(day)}
								</Box>
								<Box>
									{contentData && this.props.children ?
										React.Children.map(this.props.children, child =>
											React.cloneElement(child as React.ReactElement<any>, {data: contentData}))
										: null}
								</Box>
							</div>);
					})}
				</Box>
			</Box>
		);
	}
}

export default withStyles(styles)(CalendarContainer);

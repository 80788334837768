import React from "react";

export interface YaiFrameProps {
	url: string
}

interface YaiFrameState {
}

class YaiFrame extends React.Component<
  YaiFrameProps,
  YaiFrameState
  > {
		static defaultProps: Partial<YaiFrameProps> = {
		};

  constructor(prop: YaiFrameProps) {
    super(prop);
    // You can Initialize State but Don't call this.setState() here!
    var stayte: YaiFrameState = {
		};
		
    this.state = stayte;
  }

  render() {
    return (
			<iframe src={this.props.url} style={{width: "100%", height: "100%"}} title={this.props.url}>
			</iframe>
    );
  }
}

export default YaiFrame;

import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import uploadService from 'link-core/Services/UploadLinkService';
import WebcamComponent from 'link-core/UI/WebCam';
import { DateTimeUtils, Locale, formatString } from 'ares-core';
import { shallowEqual, useSelector } from 'react-redux';
import { IApplicationStore } from 'AppState/store';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    input: {
        display: 'none'
    }
}));
enum UploadDocumentType {
    Image = 1
}
interface IDocumentsToUpload {
    type: UploadDocumentType
    description: string
}
interface IUploadResponse {
    sasData: {
        serviceUri: string,
        sas: string
        container: string,
        fileName: null
    },
    resDocuments: {
        reservationId: number,
        documentsToUpload: IDocumentsToUpload[],
        documentsUrl: string,
        eventName: string,
        eventDate: string
    }[]
}
interface UploadProps {
    reservationId: number
    serviceUri: string
    sas: string
    container: string
    fileName: string
    document: IDocumentsToUpload
    companyId: number
    refreshApiData: () => void
}

const UpdloadRowComponent = (props: UploadProps) => {
    const classes = useStyles();
    const [openCamera, showCamera] = useState(false);

    const closeAfterSuccess = async () => {
        await uploadService.updateImageCount(props.companyId, props.reservationId)
        showCamera(false)
        props.refreshApiData()
    }
    return (
        <div className={classes.root}>
            {/* In the future we must have to add upload files */}
            <p>{props.document.description}</p>
            {
                props.document.type === UploadDocumentType.Image &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showCamera(!openCamera)}
                    startIcon={!openCamera ? <PhotoCamera /> : <CancelPresentationIcon />}
                >
                    {!openCamera ? "Use camera" : "Close camera"}
                </Button>
            }
            {
                openCamera &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative'
                }}>
                    <WebcamComponent
                        reservationId={props.reservationId}
                        serviceUri={props.serviceUri}
                        sas={props.sas}
                        container={props.container}
                        fileName={props.fileName}
                        closeComponent={() => closeAfterSuccess()}
                    />
                </div>
            }
        </div>
    )
}
const UploadDocuments = () => {
    const { obscuredTransactionId } = useParams<{ obscuredTransactionId: string }>()
    const [uploadData, setData] = useState<null | IUploadResponse>(null)
    const { companyId } = useSelector((state: IApplicationStore) => ({
        companyId: state.company.companyID
    }), shallowEqual);

    const getData = useCallback(async () => {
        const { payload } = await uploadService.getUploadDetails(companyId, obscuredTransactionId)
        if (payload !== null) {
            return setData(payload as IUploadResponse)
        }
        setData(null)
    }, [obscuredTransactionId]);

    useEffect(() => {
        getData()
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{
                    padding: 15,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h5" gutterBottom>
                        {Locale.uploadDocumentTitle}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                {
                    uploadData && uploadData.resDocuments.map(item => (
                        <div key={item.reservationId} style={{
                            padding: 20
                        }}>
                            <Card>
                                <CardHeader title={
                                    <Typography variant="body1" component="p">
                                        {formatString(Locale.uploadDocumentMessage, item.eventName, `${DateTimeUtils.longDateString(item.eventDate)}`)}
                                    </Typography>
                                } />
                                <CardContent>
                                    {
                                        item.documentsToUpload.map((document, index) => (
                                            <UpdloadRowComponent
                                                key={index}
                                                reservationId={item.reservationId}
                                                serviceUri={uploadData.sasData.serviceUri}
                                                sas={uploadData.sasData.sas}
                                                container={uploadData.sasData.container}
                                                fileName={item.documentsUrl}
                                                document={document}
                                                companyId={companyId}
                                                refreshApiData={() => getData()} />
                                        ))
                                    }
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default UploadDocuments
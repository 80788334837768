import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import { IApplicationStore } from "AppState";
export const RedirectHomeComponent = (props:any) => {
    useEffect(() => {
        if (props.homeUrl) {
            window.location.href = props.homeUrl;
        }
    }, props.homeUrl)
    return (
        <>
            <span>Redirect...</span>
        </>
    );
}

const mapStateToProps = ({company}: IApplicationStore) => ({
  homeUrl: company.homeUrl,
});

export default connect(mapStateToProps)(RedirectHomeComponent);

import React, { useState, useEffect, MouseEvent } from 'react';
import Grid from '@material-ui/core/Grid'

import { EventCalItem, ILinkCompany } from "ares-core/Models"
import { DateTimeUtils } from "ares-core/Utils";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { LinkCompany } from 'ares-core';
import { useLocation } from 'react-router-dom';
import * as Tracking from "ares-core/Tracking";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		eventBox: {
			width: "100%",
			padding: "0.2em",
		},
		calendarButton: {
			width: "100%",
			padding: "0.5em 1em",
			border: "none",
			borderRadius: "6px",
			textTransform: "uppercase",
			"&:hover": {
				opacity: "0.5"
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: "0.75em",
			},
		},
		timeText: {
			fontWeight: "normal",
			fontSize: "0.9em", 
			padding: "0.2em"
		}
	}),
);

export interface EventCalendarItemProps {
	history: any,
	company: ILinkCompany;
	data?: any
}

// Need to track the EventName. If names change then query for details
// if not check for Reservation State if need to Start over
const EventCalendarItem = (props: EventCalendarItemProps) => {
	const classes = useStyles();
	const location = useLocation();
	// Constructor / Destructor
	useEffect(() => {
		// return destructor function
		return (() => { })
	}, []);

	// Check to see if Company Events have been loaded before navigating
	const asyncLoad = async (companyId:number, target:string) => {
		if (LinkCompany.Store().events.length < 1) {
			await LinkCompany.Api.loadCompanyEvents(companyId);
		}
		// Navigate to Event Reservation
		props.history.push(target);
	};

	const handleEventSelected = (event: MouseEvent<HTMLElement>, eventName : string) => {
		Tracking.selectEvent(eventName)
		asyncLoad(props.company.companyID, event.currentTarget.id);
	}
	// Watch the props for change
	useEffect(() => {
	}, [props.data]);

	const buildTimes = (csv: string) => {
		let timeArray = csv.split(',');
		let timeItems = timeArray.map((t: string) => {
			const dateTime = "1970-01-01T" + t;
			let shortTime = DateTimeUtils.eventLocalTime(dateTime);
			return (
				<Grid item className={classes.timeText}>
					{shortTime}
				</Grid>
			);
		});
		return timeItems;
	}

	const buildSubItem = (item: EventCalItem) => {
		let day: string = DateTimeUtils.toYYYYMMDD(new Date(item.date));

		let times = buildTimes(item.timesCSV);

		return (
			<Box className={classes.eventBox}>
				<button className={classes.calendarButton + " " + item.colorClass}
					onClick={(event) =>handleEventSelected(event,item.name)}
					id={"/event/" + props.company.urlFriendlyName + "/" + item.urlFriendlyName + "/" + day}
				>
					<Grid container alignItems={"center"} direction={"column"} spacing={1}>
						<Grid item>
							{item.name}
						</Grid>
					</Grid>
					<Grid container justify={"center"} alignItems={"center"} >
						{times}
					</Grid>
				</button>
			</Box>
		);
	}

	const CalendarDayItems = () => {
		var CalendarItems = props.data.map((event: EventCalItem) => {
			// let subItem = BuildSubItem(event);
			return (<>
				{ buildSubItem(event)}
			</>
			)
		})

		return (
			<>
				{ CalendarItems}
			</>
		);
	}

	return (
		<React.Fragment>
			{ CalendarDayItems()}
		</React.Fragment>
	);
}

export default EventCalendarItem;
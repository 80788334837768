import { Dispatch } from 'redux'

import { IEventStore } from "../Event";
import { EventStoreReducer } from "./redux-io/Reducers";
import BuildEventActions, { IEventActions } from "./redux-io/Actions";
import BuildEventThunks, { IEventThunks } from "./redux-io/Thunks";
import { LinkEventDetail } from "../Models"

export class EventApi {
	store_:IEventStore;
	dispatcher_?:Dispatch;
	Api: IEventThunks;
	Actions: IEventActions;
	//
	constructor() {
		this.store_ = {
			currentEvent: DefaultEvenData,
			events: [],
			eventsMemoized: {},
			eventGroups: [],
			calendar: [],
			busy: false
		};
		//
		this.Api = BuildEventThunks(()=>{});
		this.Actions = BuildEventActions(this.dispatcher_!);
	}
	public Store = (s?:IEventStore) => {
		if (s)
			this.store_ = s;
		return this.store_;
	}
	public Reducer = () => {
		return EventStoreReducer;
	}
	public Dispatcher = (d?:Dispatch) => {
		if (d) {
			this.dispatcher_ = d;
			this.Api = BuildEventThunks(this.dispatcher_);
			this.Actions = BuildEventActions(this.dispatcher_!);
		}
		return this.dispatcher_;
	}
	// Memoized access to EventSummary
	public GetEventSummary = (eventId:number) => {
		var id = eventId.toString();
		if (this.store_.events && this.store_.eventsMemoized[id] === undefined) {
			var event = this.store_.events.find( e => e.eventId === eventId);
			// Do not add Undefined Objects to memoized object
			if (event !== undefined) {
				this.store_.eventsMemoized[id] = event;
			}
			return event;
		}
		return this.store_.eventsMemoized[id];
	}
}

// Initialize Default Event
export const DefaultEvenData: LinkEventDetail = {
  name: "",
  displayOrder: 0,
  companyId: 0,
  eventId: 0,
  companyName: "",
  description: "",
  durationInSeconds: 0,
  hideDuration: false,
  urlFriendlyName: "",
  excludeFromReseller: false,
  lat: 0,
  lon: 0,
  minRate: 0,
  maxRate: 0,
  restrictions: "",
  reservationInfo: "",
  mapUrl: "",
  maxTickets: 0,
  address: "",
  latestSell: 0,
  earliestSell: 0,
  eventTimeType: 0,
  ticketRequiredFields: 0,
  nextTime: "",
  oneDay: false,
  singleTimeEventId: 0,
  rates: [],
  deposit: 0,
  depositOnly: false,
  delivery: false,
  questionsJson: "",
  rateQuestionGroups: [],
  addOns: [],
  active : false
};

const Event:EventApi = new EventApi();
export default Event;

import { CircularProgress } from "@material-ui/core";
import DirectCCEntry from "./DirectCCEntry";
import SquarePayment from "./SquarePayment";
import { GatewayID } from "./Gateway";
import StripePayment from "./StripePayment";
import { ICheckoutProps } from "./CheckoutProps";



export default function CheckoutUI(props: ICheckoutProps) {
    switch (props.gatewayProcessor?.gatewayID()) {
        case GatewayID.MPPG:
            return <DirectCCEntry {...props} />
        case GatewayID.Square:
            return <SquarePayment {...props} />
        case GatewayID.Stripe:
        case GatewayID.StripeConnect:
            return <StripePayment {...props} />
        default:
            return <CircularProgress />
    }
}

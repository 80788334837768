import {Shopping} from "ares-core/Shopping";
import {isEmpty} from "../Utils";

// CompanyId is the UrlFriendlyName when called by Link
export const EstablishCart = (companyId: string) => {
    // Clear any residual
    Shopping.Actions.SetShoppingCart(null);

    // Load the Cart Info from the Cache and act accordingly
}
const LogError = (data: any) => {
    if (process.env.NODE_ENV === "development") {
        console.error({
            message: 'Error trying to save local storage, empty property',
            data
        });
    }
}
export const ReadCartId = (companyName: string): string | null => {
    return localStorage.getItem(`cartId-${companyName}`);
}
export const ClearCartId = (companyName: string) => {
    if(!isEmpty(companyName)) {
        const key = `cartId-${companyName}`;
        localStorage.removeItem(key);
    } else {
        LogError({companyName});
    }
}
export const SaveCartId = (cartId: string, companyName: string): void => {
    if (!isEmpty(cartId) || !isEmpty(companyName)) {
        const key = `cartId-${companyName}`;
        localStorage.setItem(key, cartId);
    } else {
        LogError({companyName,cartId})
    }
}
//if the discount of a added reservation into the shopping cart is greater than 0 it means tha is a refounded
export const isRefund = ( discount : number ) =>{
    return discount > 0
}
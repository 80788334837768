import { Dispatch } from "redux";

import { ShoppingStoreReducer } from "./redux-io/Reducers";
import { IShoppingStore } from "../Shopping";
import {DefaultCart, ICartDTO} from "../Models";
import BuildShoppingThunks, { IShoppingThunks } from "./redux-io/Thunks";
import BuildShoppingActions, { IShoppingActions } from "./redux-io/Actions";

// import { EstablishCart } from "./HelperFunctions"
export class ShoppingApi {
  store_: IShoppingStore;
  dispatcher_?: Dispatch;
	// Thunk Placeholders
	Api: IShoppingThunks;
	Actions: IShoppingActions;
  //
  constructor() {

    this.store_ = {
			cart: DefaultCart,
			portalMode:false,
			companyId: "",
			billingInfo: { country: "US" },
			shippingInfo: {},
			processorPaymentData: "",
			processorSpecificJson: "",
			checkoutTransaction: "",
			howHeardAnswerID: 0,
			howHeardAnswers: [],
			paymentsOnFile : null,
      busy: false,
    };
		// Default Initialization
		this.Api = BuildShoppingThunks(this.dispatcher_!);
		this.Actions = BuildShoppingActions(this.dispatcher_!);
  }
  public Store = (s?: IShoppingStore) => {
    if (s) this.store_ = s;
    return this.store_;
  };
  public Reducer = () => {
    return ShoppingStoreReducer;
  };
  public Dispatcher = (d?: Dispatch) => {
    if (d) {
      this.dispatcher_ = d;
			this.Api = BuildShoppingThunks(this.dispatcher_);
			this.Actions = BuildShoppingActions(this.dispatcher_!);
    }
    return this.dispatcher_;
	};

	// Utility Functions
	public Initialize = (slugOrCompanyId:string,isPortal:boolean, cartId:string | null): void => {
		this.Actions.SetShoppingCompany(slugOrCompanyId);
		this.Actions.SetPortalMode(isPortal);
	    // Call the server when the cartId already exists on the localStorage
		if (cartId) {
			this.Api.loadShoppingCart(cartId);
		}
	}
	public TotalAmount = (): number => {
		return ( 0 +
			(this.Store().cart.subTotal || 0 ) +
			(this.Store().cart.tax || 0 ) +
			(this.Store().cart.buyerServiceCharge || 0)
		);
	}
	public TotalFees = (): number => {
		return ( 0 + 
			(this.Store().cart.tax || 0 ) +
			(this.Store().cart.buyerServiceCharge || 0)
		);
	}
	public GiftCardTotal = (): number => {
		let appliedGiftCards = 0;
		this.Store().cart.appliedGiftCards.forEach(giftCard => appliedGiftCards += giftCard.balance);
		return appliedGiftCards;
	}
	public IsEmpty = ():boolean => { return (this.Store().cart.isEmpty); }
	public Clear = ():void => {
		this.Actions.SetBillingInfo({});
		this.Actions.SetShippingInfo({});
		this.Actions.SetProcessorData("");
		this.Actions.SetShoppingCart(DefaultCart);
	}
  	public CartItemCount = (): number => {
		const cart:ICartDTO = this.Store().cart;
		let items = 0;
		if (cart.reservations) {
			items += cart.reservations.length;
			cart.reservations.forEach(res => items += res.addOns ? res.addOns.length : 0);
		}
		return (items + this.CatalogItemCount());
	};
	public CatalogItemCount = (): number => {
		var items = Shopping.Store().cart.catalogItems;
		var count = 0;
		for (let item of items){
			count += item.quantity;
		}
    return (count);
	};
	public GetAgreements = () => {
		// This function should return the click agreements
		// corresponding to the events that are in the shopping cart.
		return this.Store().cart.clickAgreements;
	}
}

const Shopping: ShoppingApi = new ShoppingApi();
export default Shopping;

export interface CatalogDetail {
  catalogId: number;
  companyId: number;
  itemCode: string;
  description: string;
  longDescription: string;
  requiresShipping: boolean;
  unitPrice: number;
  wholesalePrice: number;
  tax_Rate: number;
  taxable: boolean;
  active: boolean;
  available: number;
  giftCard: boolean;
  ud: Date;
  uu: string;
  imgUrls?: string[];
  giftCards? : IGiftCardInformation[];
}

export interface ICatalogDisplay {
	companyId: number;
	catalogId: number;
  itemCode: string;
  description: string;
  longDescription: string;
	requiresShipping: boolean;
	available: number;
	unitPrice: number;
  giftCard: boolean;
  imgUrls?: string[];
  giftCards? : IGiftCardInformation[];
}

export interface IGiftCardInformation{
  giftCardRecipientId? : number
  giftCardTemplateId : number
  recipientName : string
  recipientEmail : string
  note : string
}

export interface CatalogItem {
	catalogId: number;
	quantity: number;
  reservationIdAddOn?:number;
}

export interface IMiscellaneousItem {
	description: string;
	taxable: boolean;
	unitPrice: number;
	quantity: number;
    reservationIdAddOn?: number;
}

export interface GiftCardDetail
{
	companyId: number;
 	cardCode: string;
	transactionId:number;
	transactionDate: string;
	originalPrice: number;
	balance: number;
}
// CatalogID codes > 0 have corresponding entries in the TBLCatalog table.
// CatalogID codes < -100 in the Cart are MiscellaneousItems that need to be
// converted to -2 on saving the TblTransactionItem.
export enum CatalogIDCode
{
        TicketServiceCharge = 0,
        NotSet = -1,
        MiscellaneousItem = -2,
        Tip = -3,
        Reservation = -4,
        Refund = -5,
        Deposit = -6,
        Coupon = -7,
        CatalogAdjustment = -8,
        ReservationAdjustment = -9,
        GiftCard = -10,
        DepositTSC = -11,
        ReservedItemPayment = -12,
        Surcharge = -13,
        CartSurcharge = -14
}

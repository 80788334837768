import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box } from '@material-ui/core';
import { IApplicationStore } from "AppState";
import { Shopping } from 'ares-core/Shopping';
import {Checkout} from './components/Checkout';
import * as ShoppingUtils from "ares-core/Shopping/HelperFunctions";
import {isEmpty} from "../../ares-core";
import {useHistory} from 'react-router-dom';
import { CoreStatus, ICoreResult } from "../../ares-core/Types";
import LinkCartService from "../../ares-core/Services/Link/LinkCartService";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IDisplayCheckout {}

export const DisplayCheckout = (props:any) => {
  // const parsedPathName = Object.entries(useParams());
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // When we load on checkout setCart actions isn't dispatched yet so we read from localStorage
    // otherwise we can have an Empty id from the DefaultCart object.
    // The cartId is only saved on the localStorage when a cartId is valid (i.e. we make a reservation)

    const cartId = ShoppingUtils.ReadCartId(Shopping.Store().companyId) ?? "";
    if (!isEmpty(cartId)) {
      setLoading(true);
      preCheckout(cartId).then(() => {
        setLoading(false);
      });
    } else {
      // If we don't have a cartId we redirect from the checkout page
      history.push(`/event/${Shopping.Store().companyId}`);
    }
    }, []);

  const preCheckout = async (cartId: string) => {
    let result: ICoreResult = await LinkCartService.preCheckout(cartId);
    if (result.status === CoreStatus.SUCCESS) {
      Shopping.Actions.SetPreCheckoutAction(result.payload)
    }
  }
  return (
    <Box p={2}>
      {
        loading ? <Loading/> : <Checkout {...props}/>
      }

    </Box>
  );
};

const mapStateToProps = (state: IApplicationStore) => {
  return {
    homeUrl: state.company.homeUrl,
    ...state.shopping,
  };
}

export default connect(mapStateToProps)(DisplayCheckout);

const Loading = () => {
  return (
    <div style={{
      height: "300px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <CircularProgress/>
    </div>
  )
}
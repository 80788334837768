import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { Locale } from "ares-core/UI/Locale";
import ReactMarkdown from "react-markdown";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {CheckCircle} from "@material-ui/icons";
import {IconButton, useMediaQuery, useTheme} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      display: "flex",
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1),
    },
    checkbox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    agreementBox:{
      borderWidth:'2px',
      borderStyle:'solid',
      borderRadius:'10px',
      borderColor:theme.palette.background.paper,
      marginBottom:theme.spacing(1),
      marginTop:theme.spacing(1),
      paddingLeft:theme.spacing(1),
      paddingRight:theme.spacing(1),
      position:'relative',
      transition:'0.2s all ease-in'
    },
    boxChecked: {
      borderColor:theme.palette.primary.main,
    },
    boxCheckedIcon: {
      position:'absolute',
      top:0,
      right:0
    },
      actionsSmallHolder: {
        width:100,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifySelf:'center',
        flexGrow:1
      }
  }),
);

export interface IValidAgreements {
  checked: boolean;
  name: number;
  statements: string;
  handleChange?: (event: any) => void;
}

export interface ClickAgreementsProps {
  agreements: string[];
  open: boolean;
  handleClose: (event?: any) => void;
  handleCancel: (event?: any) => void;
}

export interface AgreementsCheckBoxProps {
  checked: boolean;
  name: number;
  statement: string;
  onChange: CallableFunction;
}

export const AgreementsCheckBox = (props: AgreementsCheckBoxProps) => {
  const classes = useStyles();
  const handleChange = (e: any) => {
    const value = e.target.checked;
    props.onChange(value);
  };
  return (
      <div className={clsx(classes.agreementBox,{[classes.boxChecked]: props.checked})}>
        {
          props.checked ? (
              <div className={classes.boxCheckedIcon}>
                <CheckCircle color={'primary'}/>
              </div>
          ) : null
        }
        <ReactMarkdown
            escapeHtml={false}
            linkTarget="_blank"
            source={props.statement}
        />
        <Checkbox
            color="primary"
            checked={props.checked}
            onChange={handleChange}
            value={props.name}
        />
        <Typography variant="caption">{Locale.iAgree}</Typography>
      </div>
  )
};

export default function ClickAgreements(props: ClickAgreementsProps) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [state, setState] = useState<boolean[]>(
    props.agreements.map((i: string) => false),
  );
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(true);

  useEffect(() => {
    setState(props.agreements.map((i: string) => false));
  }, [props.agreements, props.open]);

  const values = Object.values(state);
  //function used to validate Complete Button
  useEffect(() => {
    if (values.filter((f:boolean) => f).length === props.agreements.length) {
      setValid(true); setError(false);
    } else {
      setValid(false); setError(true);
    }
  }, [state]);

  const onChange = (name: number) => (value: boolean) => {
    setState((prevState) => {
      const newState = Array.from(prevState);
      newState[name] = value;
      return newState;
    });
  };

  const onCancel = () => {
    setState(props.agreements.map((i: string) => false));
    props.handleCancel();
  };

  const onContinue = (e: any) => {
    if (!valid) { return; }
    setValid(false); // Dimm the Button to prevent double checkout
    setError(false);
    props.handleClose();
    setState(props.agreements.map((i: string) => false));
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onContinue} disableBackdropClick disableEscapeKeyDown >
        {
            fullScreen ? (
                <DialogTitle >
                    <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    Agreements
                </DialogTitle>
            ) : null
        }
          <DialogContent dividers style={{
              padding:theme.spacing(1),
              paddingTop:theme.spacing(2),
              paddingBottom:theme.spacing(2)
          }}>
              <FormControl required={error} error={error} component="fieldset" className={classes.formControl} >
                  <FormLabel component="legend">{Locale.clickConfirm}</FormLabel>
                  <FormGroup>
                      {state
                          .map( (value: boolean, i: number, S: boolean[]) => props.agreements[i],)
                          .map((a: string, i: number, A: string[]) => ({ statement: a, name: i, checked: state[i], onChange: onChange(i), }))
                          .map((a: AgreementsCheckBoxProps, j: number) => ( <AgreementsCheckBox key={j} {...a} />))
                      }
                  </FormGroup>
                  <FormHelperText>{Locale.clickAgreements}</FormHelperText>
              </FormControl>
          </DialogContent>
        <DialogActions>
            {
                smallMobile ? (
                    <div className={classes.actionsSmallHolder}>
                        <Button disabled={!valid} variant="contained" color="primary" onClick={props.handleClose} >
                            {/* Continue Checking Out */}
                            {Locale.processPayment}
                        </Button>
                        <Button onClick={onCancel}>{Locale.cancel}</Button>
                    </div>
                ) : (
                    <>
                        <Button onClick={onCancel}>{Locale.cancel}</Button>
                        <Button disabled={!valid} variant="contained" color="primary" onClick={props.handleClose} >
                            {/* Continue Checking Out */}
                            {Locale.processPayment}
                        </Button>
                    </>
                )
            }

        </DialogActions>
    </Dialog>
  );
}

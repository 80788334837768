///////////////////////////////////////////////////////////////////////
// Thunks
///////////////////////////////////////////////////////////////////////
import { Action, AnyAction, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { setInProgressAction, setShoppingCartAction,
	setPreCheckoutAction, setShoppingBillingInfoAction } from "./Actions";
import {Shopping, IShoppingStore, TicketsAdjustment} from "ares-core/Shopping";
import { CoreStatus, ICoreResult } from "../../Types";
import { IQueryParam, getJSON, postJSON, putJSON, deleteJSON, BaseURL } from "ares-core/IO/Http";
import {
    ReservationData,
    CatalogItem,
    ICheckoutResult,
    CheckoutPost,
    ICartDTO,
    IMiscellaneousItem, IReservationTip
} from "ares-core/Models"
import * as ShoppingUtils from 'ares-core/Shopping/HelperFunctions';
import * as Tracking from "ares-core/Tracking";
import NotificationsApi from "../../Notifications/NotificationsApi";
import {NotificationsType} from "../../Models/Notifications";
import { Credentials } from "ares-core/Credentials";
import LinkCartService from "ares-core/Services/Link/LinkCartService";
import PortalCartService from "../../Services/PortalCartService";
import { LinkAddTipRequest } from "ares-core/Services/Link/LinkTypes";

type IShoppingThunk<ReturnType = void> = ThunkAction<
  ReturnType,
	IShoppingStore,
  unknown,
  Action<string>
>;

// Type the Thunk for declaration in Api
export type ILoadShoppingCartAction = (cartId: string) => any;

export const setStorage = (cartId: string) => {
    const key = `cartId-${Shopping.Store().companyId}`;
    localStorage.setItem(key, cartId);
}
export const loadShoppingCartAction = (cartId: string): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		Shopping.Dispatcher(dispatch);

		// Prevent generating unnecessary carts
		if (cartId === null || cartId.length === 0) return;
		Shopping.Actions.SetInProgress(true);
    
    try {

      let uri: string = `/api/cart/${Shopping.Store().companyId}/${cartId}`;
			let params: IQueryParam[] = [];
			
      let result: ICoreResult = await getJSON(BaseURL.LINK, uri, params);
      if (result.status === CoreStatus.SUCCESS) {
				let cart:ICartDTO = result.payload;
				// If cart is Empty clear erroneous errors
				if (cart.isEmpty) result.payload.errors = [];
				Shopping.Actions.SetShoppingCart(cart)
				Shopping.Actions.SetInProgress(false);
      }
    } catch (error) {
      console.error(error);
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type IAddReservationAction = (r:ReservationData) => any;

export const addReservationAction = (r:ReservationData): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		
		dispatch(setInProgressAction(true));
    try {
			let uri: string = `/api/cart/${Shopping.Store().companyId}/reservation/${Shopping.Store().cart.cartId}`;
			let params: IQueryParam[] = [];
			
      let result: ICoreResult = await postJSON(BaseURL.LINK, uri, params,r);
      if (result.status === CoreStatus.SUCCESS) {
          const cart = result.payload as ICartDTO;
          ShoppingUtils.SaveCartId(cart.cartId,Shopping.Store().companyId);
        dispatch(setShoppingCartAction(result.payload));
        dispatch(setInProgressAction(false));
        const cartRes = cart.reservations[cart.reservations.length -1];
		if (cartRes !== undefined) {
        	Tracking.addToCart(cartRes.description!, cartRes.price || 0);
		}
      }
    } catch (error) {
      console.error(error);
      dispatch(setInProgressAction(false));
    }
  };
};
export type IAddExistingReservationAction = (reservationId: number) => any;

export const addExistingReservationAction = (reservationId: number): IShoppingThunk => {
    return async (dispatch: Dispatch) => {
        dispatch(setInProgressAction(true));
        let cartId = Shopping.Store().cart.cartId;
        if (cartId === "Empty") {
            cartId = "";
        }

        let uri: string = `/portal/cart/Reservation/${reservationId}/${cartId}`;
        // let uri: string = `/portal/cart/${Shopping.Store().companyId}/Reservation/${reservationId}/${}`;
        let params: IQueryParam[] = [];
		let headers: IQueryParam[] = [];
		let header: IQueryParam = { key:"x-indexic-c", value: Credentials.Store().companyId.toString() }
		headers.push(header);

        let result: ICoreResult = await putJSON(BaseURL.PORTAL, uri, params, {}, headers);
        if (result.status === CoreStatus.SUCCESS) {
            const cart = result.payload as ICartDTO;
            ShoppingUtils.SaveCartId(cart.cartId,Shopping.Store().companyId);
            dispatch(setShoppingCartAction(result.payload));
            dispatch(setInProgressAction(false));
            if(Shopping.Store().portalMode) {
                NotificationsApi.add({message: 'Reservation added to cart', type: NotificationsType.success})
            }
        } else {
            dispatch(setInProgressAction(false));
            // throw new Error(result.msg);
            if(Shopping.Store().portalMode) {
                NotificationsApi.add({message: 'Error adding reservation', type: NotificationsType.error})
            }
        }
    };
};

export type IAddReservationRefundAction = (reservationId: number) => any;

export const addReservationRefundAction = (reservationId: number): IShoppingThunk => {
    return async (dispatch: Dispatch) => {
        dispatch(setInProgressAction(true));
        let cartId = Shopping.Store().cart.cartId;
        if (cartId === "Empty") {
            cartId = "";
        }

        let uri: string = `/portal/cart/ReservationRefund/${reservationId}/${cartId}`;
        let params: IQueryParam[] = [];
		let headers: IQueryParam[] = [];
		let header: IQueryParam = { key:"x-indexic-c", value: Credentials.Store().companyId.toString() }
		headers.push(header);

        let result: ICoreResult = await postJSON(BaseURL.PORTAL, uri, params, {}, headers);
        if (result.status === CoreStatus.SUCCESS) {
            const cart = result.payload as ICartDTO;
            ShoppingUtils.SaveCartId(cart.cartId,Shopping.Store().companyId);
            dispatch(setShoppingCartAction(result.payload));
            dispatch(setInProgressAction(false));
            if(Shopping.Store().portalMode) {
                NotificationsApi.add({message: 'Reservation refund added to cart', type: NotificationsType.success})
            }
        } else {
            dispatch(setInProgressAction(false));
            // throw new Error(result.msg);
            if(Shopping.Store().portalMode) {
                NotificationsApi.add({message: 'Error refund reservation', type: NotificationsType.error})
            }
        }
    };
};

// Type the Thunk for declaration in Api
export type IAddCatalogItemAction = (cd:CatalogItem) => any;

export const addCatalogItemAction = (cd:CatalogItem): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
			let uri: string = `/api/cart/${Shopping.Store().companyId}/catalog/${Shopping.Store().cart.cartId}`;
			let params: IQueryParam[] = [];

      let result: ICoreResult = await postJSON(BaseURL.LINK, uri, params,cd);
      if (result.status === CoreStatus.SUCCESS) {
          const cart = result.payload as ICartDTO;
          setStorage(cart.cartId);
		  // May not be part of a reservation
          const cartCatItem = cart.catalogItems[cart.reservations.length -1];
		  if (cartCatItem !== undefined) {
			Tracking.addToCart(cartCatItem.description!, (cartCatItem.price || 0)  * (cartCatItem.quantity || 1));
		  }
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Catalog added to cart', type: NotificationsType.success})
          }
          dispatch(setShoppingCartAction(result.payload));
          dispatch(setInProgressAction(false));
      } else {
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Error adding catalog', type: NotificationsType.error})
          }
        dispatch(setInProgressAction(false));

      }
  };
};

// Type the Thunk for declaration in Api
export type IRemoveReservationAction = (reservationId: number, catalogId?: number) => any;

export const removeReservationAction = (reservationId: number, catalogId?: number): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    try {
			let store:IShoppingStore = Shopping.Store();
      let uri: string = `/api/cart/${store.companyId}/reservation/${store.cart.cartId}/${reservationId}`;
      if (catalogId) {
        uri += `/${catalogId}`;
      }
			let params: IQueryParam[] = [];
			
      let result: ICoreResult = await deleteJSON(BaseURL.LINK, uri, params,null);
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
        dispatch(setInProgressAction(false));
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Reservation removed from the cart', type: NotificationsType.success})
          }
      } else {
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Error removing Reservation from the cart', type: NotificationsType.success})
          }
      }
    } catch (error) {
      console.error(error);
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type IRemoveCatalogItemAction = (item:CatalogItem,reservationId?: number) => any;

export const removeCatalogItemAction = (item:CatalogItem, reservationId = -1): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
    dispatch(setInProgressAction(true));
    try {
        let uri: string = `/api/cart/${Shopping.Store().companyId}/catalog/${Shopping.Store().cart.cartId}/${item.catalogId}`;
        if(reservationId > -1) {
            uri = uri + '/' + reservationId.toString();
        }
			let params: IQueryParam[] = [];
			
      let result: ICoreResult = await deleteJSON(BaseURL.LINK, uri, params,item);
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
        dispatch(setInProgressAction(false));
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Catalog item removed from the cart', type: NotificationsType.success})
          }
      } else {
          if(Shopping.Store().portalMode) {
              NotificationsApi.add({message: 'Error removing Catalog item from the cart', type: NotificationsType.success})
          }
      }
    } catch (error) {
      console.error(error);
      dispatch(setInProgressAction(false));
    }
  };
};

// Type the Thunk for declaration in Api
export type IDeleteCartAction = () => any;

export const deleteCartAction = (): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
	if (Shopping.IsEmpty())  { return; }
    const {companyId,cart} = Shopping.Store();
	dispatch(setInProgressAction(true));

	const result = await LinkCartService.emptyCart(companyId,cart.cartId);
    if (result.status === CoreStatus.SUCCESS) {
        Shopping.Clear();
    }

    dispatch(setInProgressAction(false));
  };
};

// Type the Thunk for declaration in Api
export type IPreCheckoutAction = (cartId: string) => any;


export const preCheckoutAction = (cartId: string): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
	    dispatch(setInProgressAction(true));
        let result: ICoreResult = await LinkCartService.preCheckout(cartId);
        if (result.status === CoreStatus.SUCCESS) {
          dispatch(setPreCheckoutAction(result.payload));
          if (Shopping.Store().portalMode === true) {
            dispatch(setShoppingBillingInfoAction(result.payload.billingInfo));
          }
        }
        dispatch(setInProgressAction(false));
  };
};

// Type the Thunk for declaration in Api
export type ICheckoutAction = (cash?: boolean, transactionCategoryId?:number) => any;
/**
 * Thunk only used on LINK
 * @param cash
 * @param transactionCategoryId
 */
export const checkoutAction = (cash: boolean = false,transactionCategoryId:number = -1): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		Shopping.Dispatcher(dispatch);
		Shopping.Actions.SetInProgress(true);
		const store:IShoppingStore = Shopping.Store();
		const cartId = store.cart.cartId;
    let checkoutDTO: CheckoutPost;
		if(cash) {
       checkoutDTO = LinkCartService.createCheckoutCashDTO(store, transactionCategoryId);
    } else {
		   checkoutDTO = LinkCartService.createCheckoutDTO(store);
    }
        let result: ICoreResult = await LinkCartService.checkout(cartId,checkoutDTO)
      if (result.status === CoreStatus.SUCCESS) {
				const checkOutResult: ICheckoutResult = result.payload;
				if (checkOutResult.cartDTO.errors.length > 0) { 
					Shopping.Actions.SetShoppingCart(checkOutResult.cartDTO);
					Shopping.Actions.SetShoppingReceipt(checkOutResult.checkoutTransaction);
				}
				else {
          Tracking.purchase(store.cart, checkOutResult.transactionId )
					Shopping.Clear();
					Shopping.Actions.SetShoppingReceipt(checkOutResult.checkoutTransaction);
				}
      }
      else{
        Shopping.Actions.SetInProgress(false);
        NotificationsApi.add({message: result.msg ?? "Payment can't be processed" , type: NotificationsType.error})
        throw Error(result.msg ?? "Payment can't be processed")
      }
      Shopping.Actions.SetInProgress(false);
  };
};

// Type the Thunk for declaration in Api
export type ICheckoutWithRefundAction = (reason:number) => any;

export const checkoutWithRefundAction = (reason:number): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		Shopping.Dispatcher(dispatch);
		Shopping.Actions.SetInProgress(true);
		var store:IShoppingStore = Shopping.Store();
        const cartId = store.cart.cartId;
        const companyId = Credentials.Store().companyId.toString();
		const refundDTO = PortalCartService.createCheckoutRefundDTO(store,reason);
        let result: ICoreResult = await PortalCartService.checkoutRefund(cartId,companyId,refundDTO);

		if (result.status === CoreStatus.SUCCESS) {
			const checkOutResult: ICheckoutResult = result.payload;
			if (checkOutResult.cartDTO.errors.length > 0) { 
				Shopping.Actions.SetShoppingCart(checkOutResult.cartDTO);
				Shopping.Actions.SetShoppingReceipt(checkOutResult.checkoutTransaction);
		}
		else {
      		Tracking.purchase(store.cart, checkOutResult.transactionId )
					Shopping.Clear();
					Shopping.Actions.SetShoppingReceipt(checkOutResult.checkoutTransaction);
			}
		}
		Shopping.Actions.SetInProgress(false);
  };
};

// Type the Thunk for declaration in Api
export type IApplyCouponAction = (c:string) => any;

export const applyCouponAction = (coupon:string): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		dispatch(setInProgressAction(true));
		const {companyId,cart} = Shopping.Store();
		const service = Shopping.Store().portalMode ? PortalCartService : LinkCartService;

        const result:ICoreResult = await service.applyCoupon(companyId,cart.cartId,coupon);
        if (result.status === CoreStatus.SUCCESS) {
            dispatch(setShoppingCartAction(result.payload));
        } else {
            if(Shopping.Store().portalMode) {
                NotificationsApi.add({message: 'Error adding gift card', type: NotificationsType.error});
            }
        }
        dispatch(setInProgressAction(false));
  };
};

// Type the Thunk for declaration in Api
export type IAddMiscellaneousItemAction = (m:IMiscellaneousItem) => any;

export const addMiscellaneousItemAction = (item:IMiscellaneousItem): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		dispatch(setInProgressAction(true));

    try {
      let uri: string = `/portal/cart/AddMiscellaneous/${Shopping.Store().cart.cartId}`;
      let params: IQueryParam[] = [];
      let headers: IQueryParam[] = [];
      let header: IQueryParam = { key:"x-indexic-c", value: Credentials.Store().companyId.toString() }
      headers.push(header);

      let result: ICoreResult = await postJSON(BaseURL.PORTAL, uri, params, item, headers);
      if (result.status === CoreStatus.SUCCESS) {
        const cart = result.payload as ICartDTO;
        ShoppingUtils.SaveCartId(cart.cartId,Shopping.Store().companyId);
        dispatch(setShoppingCartAction(result.payload));
        dispatch(setInProgressAction(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setInProgressAction(false));
    }
  };
};


export type IAddPortalTipItemAction = (tip:IReservationTip) => any;

export const addPortalTipItemAction = (tip:IReservationTip) : IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		dispatch(setInProgressAction(true));

    try {
      const {companyId,cart} = Shopping.Store();
      const result = await PortalCartService.addTip(companyId, cart.cartId, tip);
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(setInProgressAction(false));
  };
}


export type IAddLinkTipItemAction = (tip: IReservationTip, companyId: string) => any;

export const addLinkTipItemAction = (tip: IReservationTip, companyId: string) : IShoppingThunk => {
  return async (dispatch: Dispatch) => {
		dispatch(setInProgressAction(true));

    try {
      const request:LinkAddTipRequest = {...tip, companyId};
      const {cart} = Shopping.Store();
      const result = await LinkCartService.addTip(cart.cartId, request);
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(setInProgressAction(false));
  };
}


// Type the Thunk for declaration in Api
export type IAdjustReservationTicketsAction = (reservationId: number,data:TicketsAdjustment[]) => any;

export const adjustReservationTicketsAction = (reservationId: number,data:TicketsAdjustment[]): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
      dispatch(setInProgressAction(true));
      const {companyId,cart,portalMode} = Shopping.Store();
      const result = await PortalCartService.adjustReservationTickets(companyId.toString(),
          cart.cartId,reservationId.toString(),data)
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
      } else {
          if(portalMode) {

          }
      }
      dispatch(setInProgressAction(false));
  };
};
export type IAdjustReservationAmountAction = (reservationId: number, amount:number) => any;

export const adjustReservationAmountAction = (reservationId: number, amount:number): IShoppingThunk => {
  return async (dispatch: Dispatch) => {
      dispatch(setInProgressAction(true));
      const {companyId,cart,portalMode} = Shopping.Store();
      const result = await PortalCartService.adjustReservationAmount(companyId.toString(),
          cart.cartId,reservationId.toString(),amount)
      if (result.status === CoreStatus.SUCCESS) {
        dispatch(setShoppingCartAction(result.payload));
      } else {
          if(portalMode) {

          }
      }
      dispatch(setInProgressAction(false));
  };
};
export type IRemoveAdjustmentsAction = (reservationId: number) => any;

export const removeAdjustmentsAction = (reservationId: number): IShoppingThunk => {
    return async (dispatch: Dispatch) => {
        dispatch(setInProgressAction(true));
        const {companyId,cart,portalMode} = Shopping.Store();
        const result = await PortalCartService.removeAdjustments(companyId.toString(),
            cart.cartId,reservationId.toString())
        if (result.status === CoreStatus.SUCCESS) {
            dispatch(setShoppingCartAction(result.payload));
        } else {
            if(portalMode) {

            }
        }
        dispatch(setInProgressAction(false));
    };
};

export type IAddGiftCardAction = (cart:ICartDTO) => any
export const addGiftCardAction = (cart:ICartDTO) : IShoppingThunk =>{
  return async (dispatch : Dispatch) =>{
    dispatch(setInProgressAction(true));
    setStorage(cart.cartId);
    const cartCatItem = cart.catalogItems[cart.reservations.length -1];
    if (cartCatItem !== undefined) {
      Tracking.addToCart(cartCatItem.description!, (cartCatItem.price || 0)  * (cartCatItem.quantity || 1));
    }
    if(Shopping.Store().portalMode) {
        NotificationsApi.add({message: 'Catalog added to cart', type: NotificationsType.success})
    }
    dispatch(setShoppingCartAction(cart));
    dispatch(setInProgressAction(false));
  }
}

export interface IShoppingThunks {
	readonly loadShoppingCart: ILoadShoppingCartAction;
	readonly addReservation: IAddReservationAction;
	readonly addCatalogItem: IAddCatalogItemAction;
	readonly addMiscellaneousItem:IAddMiscellaneousItemAction;
  readonly addPortalTipItem: IAddPortalTipItemAction;
  readonly addLinkTipItem: IAddLinkTipItemAction;
	readonly removeReservation: IRemoveReservationAction;
	readonly removeCatalogItem: IRemoveCatalogItemAction;
	readonly deleteCart: IDeleteCartAction;
	readonly applyCoupon: IApplyCouponAction
	readonly preCheckout:IPreCheckoutAction;
	readonly checkout:ICheckoutAction;
	readonly addExistingReservation: IAddExistingReservationAction;
	readonly addReservationRefund: IAddReservationRefundAction;
	readonly checkoutWithRefund: ICheckoutWithRefundAction;
	readonly adjustReservationTickets: IAdjustReservationTicketsAction;
	readonly adjustReservationAmount: IAdjustReservationAmountAction;
	readonly removeAdjustments: IRemoveAdjustmentsAction;
  readonly addGiftCard : IAddGiftCardAction;
}

const BuildShoppingThunks = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) => {
	const ShoppingThunks: IShoppingThunks = {
		loadShoppingCart: (cartId: string) => dispatch(loadShoppingCartAction(cartId)),
		addReservation: (r:ReservationData) => dispatch(addReservationAction(r)),
		addCatalogItem: (cd:CatalogItem) => dispatch(addCatalogItemAction(cd)),
		addMiscellaneousItem: (cd:IMiscellaneousItem) => dispatch(addMiscellaneousItemAction(cd)),
    addPortalTipItem: (tip: IReservationTip) => dispatch(addPortalTipItemAction(tip)),
    addLinkTipItem: (tip: IReservationTip, companyId: string) => dispatch(addLinkTipItemAction(tip, companyId)),
		removeReservation: (r:number,c?:number) => dispatch(removeReservationAction(r,c)),
		removeCatalogItem: (cd:CatalogItem, reservationId= -1) => dispatch(removeCatalogItemAction(cd, reservationId)),
		applyCoupon: (c:string) => dispatch(applyCouponAction(c)),
		deleteCart: () => dispatch(deleteCartAction()),
		preCheckout: (cartId: string) => dispatch(preCheckoutAction(cartId)),
		checkout: (cash,transactionCategoryId) =>	dispatch(checkoutAction(cash, transactionCategoryId)),
		addExistingReservation: (reservationId: number) => dispatch(addExistingReservationAction(reservationId)),
		addReservationRefund: (reservationId: number) => dispatch(addReservationRefundAction(reservationId)),
		checkoutWithRefund: (reason:number) => dispatch(checkoutWithRefundAction(reason)),
    adjustReservationTickets:(reservationId,data) => dispatch(adjustReservationTicketsAction(reservationId,data)),
    adjustReservationAmount:(reservationId,amount) => dispatch(adjustReservationAmountAction(reservationId,amount)),
    removeAdjustments:(reservationId) => dispatch(removeAdjustmentsAction(reservationId)),
    addGiftCard : (cart:ICartDTO) => dispatch(addGiftCardAction(cart))
	};

  return ShoppingThunks;
};

export default BuildShoppingThunks;

import { Dispatch } from "redux";

import { ReservationStoreReducer } from "./redux-io/Reducers";
import BuildReservationActions, {
  IReservationActions,
} from "./redux-io/Actions";
import BuildReservationThunks, { IReservationThunks } from "./redux-io/Thunks";
import { IReservationStore } from "../Reservation";
import {
  ReservationData,
  TicketPerson,
  ReservationDetail,
} from "ares-core/Models/Reservation";

export class ReservationApi {
  store_: IReservationStore;
  dispatcher_?: Dispatch;
  // Thunk Placeholders
  Api: IReservationThunks;
  Actions: IReservationActions;
  //
  constructor() {
    this.store_ = {
      currentRes: {
        eventDateId: 0,
		eventDateTime:"",
        thisIsDeposit: false,
      },
      reservationEditData: null,
      resListItem: null,
      eventDateReservationList: null,
      eventDateSummary: null,
      calendarEvents: null,
	  refundResults: null,
      busy: false,
    };
    //
    // Initialize
    this.Api = BuildReservationThunks(() => {});
    this.Actions = BuildReservationActions(this.dispatcher_!);
  }
  public Store = (s?: IReservationStore) => {
    if (s) this.store_ = s;
    return this.store_;
  };
  public Reducer = () => {
    return ReservationStoreReducer;
  };
  public Dispatcher = (d?: Dispatch) => {
    if (d) {
      this.dispatcher_ = d;
      this.Api = BuildReservationThunks(this.dispatcher_);
      this.Actions = BuildReservationActions(this.dispatcher_!);
    }
    return this.dispatcher_;
  };

  public ClearReservation = () => {
    this.Actions.setCurrentReservation(null);
    this.Actions.setReservationItem(null);
  };

  public ClearReservationSummary = () =>{
    this.Actions.setReservationSummary(null)
  }

  public ClearReservationEditData = () =>{
    this.Actions.setReservationEditData(null)
  }

  // Portal Reservation Rate Modification Functions
  public AddRate = (rate: Partial<ReservationDetail>) => {
    var reservation: ReservationData | null = this.Store().currentRes;
    var detailList: Partial<ReservationDetail>[] = reservation!.ticketEdits
      ? reservation!.ticketEdits
      : [];
    // Find the Rate
    var foundRate: Partial<ReservationDetail> | undefined = detailList.find(
      (d) => rate.rateId === d.rateId,
    );

    if (foundRate === undefined)
      // Update the Store
      this.Actions.setCurrentReservation(reservation);
  };

  // Portal Reservation Ticket  Modification Functions
  public AddTicketPerson = (rateId: number, person: TicketPerson) => {
    var reservation: ReservationData | null = this.Store().currentRes;
    var detailList: Partial<ReservationDetail>[] = reservation!.ticketEdits
      ? reservation!.ticketEdits
      : [];
    // Find the Rate
    var detail: Partial<ReservationDetail> | undefined = detailList.find(
      (d) => d.rateId === rateId,
    );
    // If found add the Ticket Person to the Rate
    if (detail) {
      detail.ticketPersons?.push(person);
      detail.tickets = detail.ticketPersons?.length;
    } else {
      detail = {
        rateId: rateId,
        tickets: 1,
        reservationDetailId: 0,
        ticketPersons: [],
      };
      // Add the
      detail.ticketPersons?.push(person);
      // Add the new Rate
      detailList.push(detail);
    }
    // Update the Store
    this.Actions.setCurrentReservation(reservation);
  };
  public DeleteTicketPerson = (rateId: number, personId: number) => {
    var reservation: ReservationData | null = this.Store().currentRes;
    var detailList: Partial<ReservationDetail>[] = reservation!.ticketEdits
      ? reservation!.ticketEdits
      : [];
    // Find the Rate
    var detail: Partial<ReservationDetail> | undefined = detailList.find(
      (d) => d.rateId === rateId,
    );
    // If found add the Ticket Person to the Rate
    if (detail && detail.ticketPersons) {
      // Filter the array and assign to the rate
      var newArray: Array<TicketPerson> = detail.ticketPersons.filter(
        (tp: TicketPerson) => {
          return tp.ticketPersonId !== personId;
        },
      );
      detail.ticketPersons = newArray;
      detail.tickets = detail.ticketPersons.length;
    }
    // Update the Store
    this.Actions.setCurrentReservation(reservation);
  };
}

const Reservation: ReservationApi = new ReservationApi();
export default Reservation;

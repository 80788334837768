import {
    CatalogIDCode,
    CheckoutPost,
    ICartDTO,
    ICheckoutConfiguration,
    IReservationTip,
    SendCartEmailResponse,
    SendEmailCartInfo,
    SendEmailData
} from "../Models";
import {IShoppingStore, TicketsAdjustment} from "../Shopping";
import {GeneralPortalService} from "./GeneralPortalService";
import {ICoreResult} from "../Types";
import {isEmpty} from "../Utils";
const CASH_LASTNAME = '{Cash}';
const CASH_PROCESS_PAYMENT = '{Cash}';
class PortalCartService extends GeneralPortalService {

    constructor() {
        super('/Portal/Cart');
    }

    createCheckoutRefundDTO(store: IShoppingStore, reason: number): CheckoutPost {
        const dto = this.createCheckoutDTO(store);
        return {...dto,refundReason: reason};
    }

    checkoutRefund(cartId: string, companyId: string, data: CheckoutPost) {
        const uri = this.createUrl(['CheckoutRefund',cartId])
        return this.postRequest<CheckoutPost>(uri, companyId, data);
    }

    stripeTerminalCheckout(companyId: string, terminalId: string, checkoutPost: CheckoutPost) {
        const uri = this.createUrl(['Terminal', 'Stripe', terminalId, 'Checkout'])
        return this.postRequest<CheckoutPost>(uri, companyId, checkoutPost);
    }

    stripeTerminalPaymentReadyToCapture(companyId: string, cartId: string, paymentIntentId: string) {
        const request = { paymentIntentID: paymentIntentId, cartID: cartId };
        const uri = this.createUrl(['StripeTerminalPaymentReady']);
        return this.postRequest<typeof request>(uri, companyId, request);
    }

    adjustReservationTickets(companyId: string, cartId: string, reservationId: string, data:TicketsAdjustment[]) {
        let uri = this.createUrl([cartId,'AdjustReservationTickets',reservationId.toString()]);
        return this.postRequest(uri,companyId, data);
    }

    adjustReservationAmount(companyId:string, cartId:string, reservationId: string, amount: number) {
        let uri = this.createUrl([cartId,'AdjustReservationAmount',reservationId.toString()]);
        return this.postRequest(uri,companyId, {amount, catalogIdCode: CatalogIDCode.Deposit});
    }

    removeAdjustments(companyId:string, cartId:string, reservationId: string) {
        let uri = this.createUrl([cartId,'RemoveAdjustments',reservationId.toString()]);
        return this.postRequest(uri,companyId,{});
    }

    getSendingInfo(companyId: string, cartId: string) {
        let uri = this.createUrl(['SendingInfo',cartId]);
        return this.getRequest<SendEmailCartInfo>(uri,companyId);
    }

    sendCartEmail(companyId: string, data:SendEmailData): Promise<ICoreResult<SendCartEmailResponse>> {
        let uri = this.createUrl(['SendCartEmail']);
        return this.postRequest(uri,companyId,data);
    }

    addTip(companyId: string, cartId: string, tip: IReservationTip): Promise<ICoreResult<ICartDTO>> {
        let uri: string =  this.createUrl(['AddTip', cartId]);
        return this.postRequest(uri, companyId, tip);
    }

    applyCoupon(companyId: string, cartId: string, coupon: string) {
        const uri = this.createUrl([companyId,'ApplyCode',cartId, coupon]);
        return this.postRequest(uri,companyId,{});
    }
    // We make optional passing a companyId
    checkout(cartId:string, data:CheckoutPost, companyId = 0) {
        // Custom uri, don't use createUrl since this checkout points to a different controller
        const uri = `/api/cart/Checkout/${cartId}`;
        return this.postRequest<CheckoutPost>(uri,companyId.toString(), data);
    }
    createCheckoutDTO(store: IShoppingStore, config?:Partial<ICheckoutConfiguration>): CheckoutPost {
        const dto:CheckoutPost = {
            shippingInfo: store.shippingInfo,
            billingInfo: store.billingInfo,
            processorPaymentData: store.processorPaymentData,
            cartId: store.cart.cartId,
            howHeardAnswerID: store.howHeardAnswerID!,
            sendConfirmation:config?.sendConfirmation
        }
        return dto;
    }

    createCheckoutCashDTO(store:IShoppingStore,transactionCategoryId = 1, config?:ICheckoutConfiguration): CheckoutPost {
        const dto = this.createCheckoutDTO(store,config);
        let { billingInfo } = dto;
        if(isEmpty(billingInfo)) {
            billingInfo = {lastName:CASH_LASTNAME}
        } else {
            if(isEmpty(billingInfo.lastName)) {
                billingInfo.lastName = CASH_LASTNAME;
            }
        }
        if(transactionCategoryId > -1) {
            dto.transactionCategoryId = transactionCategoryId;
        }
        return { ...dto, billingInfo, processorPaymentData: CASH_PROCESS_PAYMENT}
    }

}

const service = new PortalCartService();
export default service;

import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  SubscriptionKeyCredential,
  MapsURL,
  SearchURL,
  Aborter,
  Models,
} from "azure-maps-rest";

import { debounceFactory } from "../../Utils/DebounceFactory";
import { IAddressValue } from "ares-core/Models";

let _searchURL: SearchURL;
export function getAzureMapsSearchURL(azureMapsKey: string): SearchURL {
  if (!_searchURL) {
    const subscriptionKeyCredential = new SubscriptionKeyCredential(
      azureMapsKey,
    );

    // Use subscriptionKeyCredential to create a pipeline.
    const pipeline = MapsURL.newPipeline(subscriptionKeyCredential, {
      retryOptions: { maxTries: 4 }, // Retry options
    });
    _searchURL = new SearchURL(pipeline);
  }
  return _searchURL;
}

let searchResults: { [id: string]: IAddressValue[] } = {};

function addressEqual(
  optAddr: IAddressValue,
  valueAddr: IAddressValue,
): boolean {
  return optAddr.addressText === valueAddr.addressText;
}

export interface IAddressProps {
  azureMapsKey: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  value?: IAddressValue | null;
  nearbyLat?: number;
  nearbyLon?: number;
  radius?: number;
  onChange?: (result: IAddressValue | null) => void;
  onBlur?: () => void;
}

export default function Address(props: IAddressProps) {
  const value: IAddressValue = props.value || {addressText: '' ,lat: 0, lon: 0};
  useEffect(() => {
    searchResults = {};
  }, []);

  const [addressList, setAddressList] = useState<IAddressValue[]>(
    props.value ? [props.value] : [],
  );

  const [inputValue, setInputValue] = useState<string | undefined>(
    props.value?.addressText,
  );

  const options = {
    lat: props.nearbyLat,
    lon: props.nearbyLon,
    radius:
      props.radius || (props.nearbyLat && props.nearbyLon ? 30000 : undefined), // radius in meters
  } as Models.SearchGetSearchFuzzyOptionalParams;

  const searchURL = getAzureMapsSearchURL(props.azureMapsKey);
  const doSearch = (searchInput: string) => {
    searchURL
      .searchFuzzy(Aborter.timeout(10000), searchInput, options)
      .then((results) => {
        const newAddressList = results.results?.map((address) => {
          return {
            addressText:
              (address.type == "POI" ? address.poi?.name + ", " : "") +
              address.address?.freeformAddress,
            lat: address.position?.lat,
            lon: address.position?.lon,
          } as IAddressValue;
        });

        searchResults[searchInput] = newAddressList || [];
        setAddressList(searchResults[searchInput]);
      });
  };

  const searchDebounce = debounceFactory(doSearch, 350);
  const handleInputChange = async (event: any, newInputValue: string) => {
    if (newInputValue.length > 2) {
      if (searchResults[newInputValue]) {
        setAddressList(searchResults[newInputValue]);
      } else {
        searchDebounce(newInputValue);
      }
      if (props.onChange && props.value?.addressText !== newInputValue) {
        props.onChange({ addressText: newInputValue } as IAddressValue);
      }
    }
  };

  const handleChange = (
    event: any,
    newValue: string | IAddressValue | null,
  ) => {
    if (props.onChange) {
      if (typeof newValue === "string") {
        newValue = { addressText: newValue } as IAddressValue;
      }
      props.onChange(newValue);
    }
  };
  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          error={props.error}
          required={props.required}
          helperText={props.helperText}
          onBlur={props.onBlur}
          fullWidth={props.fullWidth}
        />
      )}
      getOptionLabel={(option: IAddressValue) => option?.addressText ?? ""}
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={addressList}
      value={value}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      getOptionSelected={addressEqual}
      freeSolo
    />
  );
}

import React from "react";
import { RouteProps } from "react-router-dom";
import { DisplayEventCalendar } from "modules/EventCalendar";
import { DisplayEventReservation } from "modules/EventReservation";
import { DisplayCart } from "modules/Cart";
import { DisplayCatalog } from "modules/Catalog";
import { DisplayCheckout } from "modules/Checkout";
import { ReceiptPage } from "modules/Receipt";
import RedirectHome from "modules/Common/RedirectHome";
import Reschedule from "./modules/Reschedule/Reschedule";
import DisplayEvents from "modules/Events/DisplayEvents";
import UploadDocuments from "modules/UploadDocuments/UploadDocuments";

export interface route extends RouteProps {
  component: React.FunctionComponent<route>;
  routes?: route[];
  key: string;
}

export interface ILinkUrlParams {
  urlFriendlyCompanyName: string;
  secondFriendlyCompanyName?: string;
  urlFriendlyEventName?: string;
  date?: string;
}

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
const AppRoutes: route[] = [
  {
    path: "/boost/:urlFriendlyCompanyName",
    key: "DISPLAY_BOOST",
    component: DisplayEvents,
    exact: true,
  },
  {
    path: "/boost/:urlFriendlyCompanyName/:secondFriendlyCompanyName",
    key: "DISPLAY_DOUBLE_BOOST",
    component: DisplayEvents,
  },
  {
    path: "/cart/:urlFriendlyCompanyName",
    key: "DISPLAY_CART",
    component: DisplayCart,
  },
  {
    path: "/catalog/:urlFriendlyCompanyName",
    key: "DISPLAY_CATALOG",
    component: DisplayCatalog,
  },
  {
    path: "/checkout/:urlFriendlyCompanyName",
    key: "DISPLAY_CHECKOUT",
    component: DisplayCheckout,
  },
  {
    path: "/eventcalendar/:urlFriendlyCompanyName",
    key: "DISPLAY_EVENTCALENDAR",
    component: DisplayEventCalendar,
  },
  {
    path: "/event/:urlFriendlyCompanyName/:urlFriendlyEventName",
    component: DisplayEventReservation,
    key: "EVENT_RESERVATION",
    exact: true,
  },
  {
    path: "/event/:urlFriendlyCompanyName/:urlFriendlyEventName/:urlEventDate",
    component: DisplayEventReservation,
    key: "EVENT_RESERVATION_DATE",
  },
  {
    path: "/event/:urlFriendlyCompanyName",
    key: "DISPLAY_EVENTS",
    component: DisplayEvents,
    exact: true,
  },
  {
    path: "/reschedule/:urlFriendlyCompanyName/:urlFriendlyEventName/:rescheduleKey/:selectedRates",
    component: Reschedule,
    key: "EVENT_RESCHEDULE",
  },
  {
    path: "/homeredirect/:urlFriendlyCompanyName",
    key: "REDIRECT_HOME_URL",
    component: RedirectHome,
    exact: true,
  },
  {
    path: "/Receipt/:urlFriendlyCompanyName/:checkoutTransaction",
    key: "DISPLAY_RECEIPT",
    component: ReceiptPage,
    exact: true,
  },
  {
    path: "/upload/:urlFriendlyCompanyName/:obscuredTransactionId",
    key: "UPLOAD_DOCUMENTS",
    component: UploadDocuments,
    exact: true,
  },
];

export { AppRoutes };

import React, { useState, useEffect, MouseEvent } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  makeStyles, CircularProgress
} from "@material-ui/core";
import {
  ExpandMore,
  CalendarTodayOutlined,
  ArrowBackIos,
  ArrowForwardIos,
} from "@material-ui/icons";

import { Locale, SimpleCalendar } from "ares-core/UI";
import { DateTimeUtils, isEmpty, formatString } from "ares-core/Utils";
import { LinkCompany } from "ares-core/LinkCompany";
import {
  Rate,
  EventDateTimeItem,
  LinkEventDetail,
  ReservationData,
  ReservationDetail, EventTicketRequest,
} from "ares-core/Models";

import { TicketValuesValue } from "ares-core/Models/link";

import { RateSelectionComponent } from "./RateSelection";
import {
  EventTimePickerComponent,
  EventTimePickerProps,
} from "./EventTimePicker";
import { parseISO } from "date-fns";
import { SeatsPreview } from "./SeatsPreview";
import { Alert } from "@material-ui/lab";
import { ValidateSellingWindow } from "../Utis/EventTimeValidations";

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  selectTime: {
    gridColumn: "1 / 3",
    gridRow: 2,
    justifySelf: "stretch",
    display: "grid",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignSelf: "center",
    gridTemplateColumns: "1",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1",
      gridRow: 3,
    },
  },
  onContinue: {
    justifySelf: "end",
    alignSelf: "end",
    gridColumn: 2,
    gridRow: 3,
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1",
      gridRow: "-1",
    },
  },
  seatingChartDiv: {
    width: 120,
    padding: 5,
  }
}));

function TicketValuesToReservationDetails(
  rates: Rate[],
  ticketValues: TicketValuesValue[],
) {
  return ticketValues.map((ticket: any) => {
    const rate = rates.find((rate) => rate.rateId === ticket.rateId);
    return {
      rateId: ticket.rateId,
      tickets: ticket.count,
      rate: rate?.rateEffectives[0].rate ?? 0,
      description: rate?.description,
      durationInSeconds: rate?.durationInSeconds,
      questionGroupId: rate?.questionGroupId,
      ReservationDetailId: 0,
    } as Partial<ReservationDetail>;
  });
}
function TicketValueEqual(
  ticketsOld: TicketValuesValue[],
  ticketsNew: TicketValuesValue[],
) {
  let returnable = false;
  if (ticketsOld.length === ticketsNew.length) {
    if (ticketsOld.length === 0) {
      returnable = true;
    } else {
      const tickets_new = ticketsNew.reduce(
        (a: any, i: any) => ({
          ...a,
          [`${i.rateId}`]: i.count,
        }),
        {},
      );
      const tickets_old = ticketsNew.reduce(
        (a: any, i: any) => ({
          ...a,
          [`${i.rateId}`]: i.count,
        }),
        {},
      );
      Object.keys(tickets_new).map((rateId) => {
        if (tickets_new[rateId] === tickets_old[rateId]) {
          delete tickets_old[rateId];
          delete tickets_new[rateId];
        }
      });
      returnable = tickets_old.length === 0 && tickets_new.length === 0;
    }
  }
  return returnable;
}

export interface DateRatePanelProps {
  defaultDate?: string;
  rates: Rate[];
  eventId: number;
  expanded: boolean;
  eventDates: string[];
  lastReservationDate: string;
  currentEvent: LinkEventDetail;
  eventTimes: EventDateTimeItem[];
  requestedTickets?: EventTicketRequest[];
  isReschedule?: boolean;
  currentReservation: ReservationData;
  handlePanelChange: CallableFunction;
  handleContinue: CallableFunction;
  validTrigger: CallableFunction;
  handleGetEventDates: CallableFunction;
  handleSetDate: CallableFunction;
}

interface IDateRatePanelSummaryProps {
  date: string;
}
function DateRatePanelSummary(props: IDateRatePanelSummaryProps) {
  return (
    <Grid container>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <CalendarTodayOutlined />
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {DateTimeUtils.formatDate(parseISO(props.date))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
interface IAvailableTimes {
  showGetAvailTimes: boolean;
  handleTimesLoad: React.MouseEventHandler<HTMLButtonElement>;
  tickets: any[];
  eventTimesProps: EventTimePickerProps;
}
function AvailableTimes(props: IAvailableTimes) {
  const classes = useStyles();
  return (
    <Box className={classes.selectTime}>
      <Collapse in={!props.showGetAvailTimes}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={props.handleTimesLoad}
          disabled={props.tickets?.length === 0}
        >
          {Locale.getAvailTimes}
        </Button>
      </Collapse>
      <Collapse in={props.showGetAvailTimes}>
        <EventTimePickerComponent {...props.eventTimesProps} />
      </Collapse>
    </Box>
  );
}
interface ISingleDateDisplay {
  nextTime: string;
  multipleTimes: boolean;
  isTimeAvailable: boolean;
  message?: string;
}
function SingleDateDisplay(props: ISingleDateDisplay) {
  return (
    <>
      <Typography variant="h5">
        {DateTimeUtils.longDateString(props.nextTime)}
      </Typography>
      <Typography variant="h5">
        {(!props.multipleTimes ? "@" : "")}
      </Typography>
      <Typography variant="h5" gutterBottom={true}>
        {(!props.multipleTimes ? DateTimeUtils.eventLocalTime(props.nextTime) : "")}
      </Typography>
      <Typography variant="body1" gutterBottom={true} style={{ fontStyle: 'italic' }} >
        {(!props.isTimeAvailable ? props.message : "")}
      </Typography>
    </>
  );
}
interface IContinueButton {
  panelName: string;
  nextStepLabel: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}
function ContinueButton(props: IContinueButton) {
  const classes = useStyles();
  return (
    <Box className={classes.onContinue}>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        value={props.panelName}
        onClick={props.handleClick}
        disabled={props.disabled}
      >
        {props.nextStepLabel}
      </Button>
    </Box>
  );
}

export const DateRatePanelComponent = (props: DateRatePanelProps) => {
  const panelName = "dateRate";
  const date = props.defaultDate;
  const classes = useStyles();
  const today = new Date();
  const [eventMonth, setEventMonth] = useState(
    date ? new Date(date) : new Date(),
  );
  const [tickets, setTickets] = useState<any[]>([]);
  const [selectedTime, setSelectedTime] = useState<number>(0);
  const [showGetAvailTimes, setShowGetAvailTimes] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [calendarItems, setCalendarItems] = useState<Date[]>([])
  const [isSingleTimeAvailable, setIsSingleTimeAvailable] = useState(false);
  const [isDateValidToRateSelection, setIsDateValid] = useState(true)
  const [singleTimeValidationMessage, setSingleTimeValidationMessage] = useState('');
  useEffect(() => {
    let _date = parseISO(props.lastReservationDate);
    let _selectedTime = 0;
    if (props.currentEvent.nextTime) {
      let nextTime = props.currentEvent.nextTime.split("T")[0];
      nextTime += "T00:00:00";
      var nextDate = DateTimeUtils.eventLocalDate(nextTime);
      if (_date < nextDate) {
        // move to 1st available date
        _date = nextDate;
      }
    }
    if (props.currentEvent.singleTimeEventId > 0) {
      _selectedTime = props.currentEvent.singleTimeEventId;
      let response = ValidateSellingWindow(props.currentEvent.nextTime, props.currentEvent.earliestSell, props.currentEvent.latestSell);
      setIsSingleTimeAvailable(response.valid);
      setIsDateValid(response.valid)
      setSingleTimeValidationMessage(response.message);
    }
    handleDateSelect(DateTimeUtils.dateOnly(_date));
    handleSetTime(_selectedTime);
  }, [
    date,
    props.currentEvent.singleTimeEventId,
    props.currentEvent.oneDay,
    props.currentEvent.nextTime,
  ]);
  /**
   * Load initial dates. Excludes selectedDate (disable exhaustive-deps) so
   * LinkCompany.LoadEventDates isn't called every time a user selects a date
   */
  useEffect(() => {
    // Based on the lastReservationDate set the Start and End Dates for data query
    const monthRange = DateTimeUtils.filterPast(
      DateTimeUtils.getMonthRange(parseISO(props.lastReservationDate)),
    ) as Date[];
    const firstOfMonth = monthRange[0];
    setEventMonth(parseISO(props.lastReservationDate));
    if (
      (props.eventDates[0] || "").slice(0, 7) ==
      (props.lastReservationDate || "").slice(0, 7)
    ) {
      return;
    }
    if (firstOfMonth) {
      refreshEventDates(firstOfMonth);
    } else {
      console.error("Empty range month");
    }
  }, [props.eventId, props.lastReservationDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCalendarItems(props.eventDates.map((d) => new Date(d)))
  }, [props.eventDates])
  useEffect(() => {
    if (props.requestedTickets && props.requestedTickets.length > 0) {
      handleSetTickets(props.requestedTickets);
    }
  }, [props.requestedTickets]);
  const refreshEventDates = (startDate: Date, endDate?: Date) => {
    props.handleGetEventDates(startDate, endDate);
  };

  const handleSetTickets = (e: EventTicketRequest[]) => {
    if (TicketValueEqual(e, tickets)) {
      return;
    }
    const selectedTickets: Partial<ReservationDetail>[] = TicketValuesToReservationDetails(
      props.rates,
      e,
    );
    setTickets(e);
    handleSetTime(props.currentEvent.singleTimeEventId || 0);
    setShowGetAvailTimes(false);
    LinkCompany.UpdateReservation({ ticketEdits: selectedTickets });
  };

  const handleCalNavigate = (date: Date) => {
    const today = DateTimeUtils.getUTCToday();
    const temp: Date =
      DateTimeUtils.compareDates(today, date) > 0 ? today : date;
    setEventMonth(temp);
    refreshEventDates(temp);
  };

  const handleSetTime = (selectedTimeId: number) => {
    setSelectedTime(selectedTimeId);
    props.validTrigger(selectedTimeId > 0 && tickets.length > 0);
  };

  const handleDateSelect = (newDate: Date) => {
    setEventMonth(newDate);
    handleSetTime(0);
    setShowGetAvailTimes(false);
    props.handleSetDate(newDate);
  };

  const handleTimesLoad = async () => {
    const selectedTickets: Partial<ReservationDetail>[] = TicketValuesToReservationDetails(
      props.rates,
      tickets,
    );
    LinkCompany.UpdateReservation({ ticketEdits: selectedTickets });
    setLoading(true);
    await LinkCompany.Api.loadEventTimes(
      props.eventId,
      props.lastReservationDate,
      tickets,
    );
    setShowGetAvailTimes(true);
    setTimeout(() => {
      // Only to make it visible to the user if the response is fast
      setLoading(false);
    }, 200);
    handleSetTime(0);
  };
  const handleSelectedTime = (value: any) => {
    handleSetTime(value);
    const resData = {
      eventDateID: parseInt(value),
      ticketEdits: TicketValuesToReservationDetails(props.rates, tickets),
    };
    handleContinue(resData);
  };
  const onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value: currPanel } = e.currentTarget;
    const resData = {
      eventDateID: selectedTime,
      ticketEdits: TicketValuesToReservationDetails(props.rates, tickets),
    };
    handleContinue(resData);
  };
  const handleContinue = (resData: any) => {
    LinkCompany.UpdateReservation(resData);
    props.handleContinue(resData, panelName);
  };

  const SimpleCalendarProps = {
    onSelectDate: handleDateSelect,
    onNavigate: handleCalNavigate,
    startDate: eventMonth,
    selectedDate: parseISO(props.lastReservationDate),
    btnBack: <ArrowBackIos />,
    btnForward: <ArrowForwardIos />,
  };
  const RateSelectionComponentProps = {
    rates: props.rates.filter((r: Rate) => {
      const effectiveRates = r.rateEffectives.filter((effRate) =>
        DateTimeUtils.inRangeStr(
          props.lastReservationDate,
          effRate.effectiveFrom,
          effRate.effectiveTo,
        ),
      );
      return effectiveRates.length > 0;
    }),
    targetDate: props.lastReservationDate,
    setTickets: handleSetTickets,
    currentTickets: props.currentReservation.ticketEdits,
    eventDuration: props.currentEvent.durationInSeconds,
    disabled: Boolean(props.isReschedule) || !isDateValidToRateSelection
  };

  const EventTimePickerComponentProps: EventTimePickerProps = {
    eventEarliestSell: props.currentEvent.earliestSell,
    eventLatestSell: props.currentEvent.latestSell,
    selectedDate: parseISO(props.lastReservationDate),
    selectedTime: selectedTime,
    setSelectedTime: handleSelectedTime,
    times: props.eventTimes.sort((a, b) => {
      if (new Date(a.eventDateTime).getTime() > new Date(b.eventDateTime).getTime()) return 1;
      if (new Date(a.eventDateTime).getTime() < new Date(b.eventDateTime).getTime()) return -1;
      return 0;
    }),
  };
  const AccordionProps = {
    expanded: props.expanded,
    onChange: props.handlePanelChange(panelName),
  };
  const AccordionSummaryProps = {
    expandIcon: <ExpandMore />,
  };
  const SingleDateDisplayProps = {
    nextTime: props.currentEvent.nextTime,
    multipleTimes: props.currentEvent.singleTimeEventId <= 0
  };
  const AvailibleTimesProps = {
    showGetAvailTimes: showGetAvailTimes,
    handleTimesLoad: handleTimesLoad,
    tickets: tickets,
    eventTimesProps: EventTimePickerComponentProps,
  };
  const ContinueButtonProps: IContinueButton = {
    panelName: panelName,
    handleClick: onContinue,
    disabled: tickets.length == 0 || selectedTime == 0,
    nextStepLabel: props.isReschedule ? Locale.rescheduleConfirmButton : Locale.continue
  };
  return (
    <Accordion {...AccordionProps}>
      <AccordionSummary {...AccordionSummaryProps}>
        <DateRatePanelSummary date={props.lastReservationDate} />
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <Grid container>
          <Grid item md={12} style={{ marginBottom: 10 }}>
            {isEmpty(props.currentEvent.seatingChartImg) ? null :
              <>
                <SeatsPreview url={props.currentEvent.seatingChartImg!} />
              </>
            }
          </Grid>
          <Grid item xs={12} md={5}>
            {!props.currentEvent.oneDay ? (
              <SimpleCalendar {...SimpleCalendarProps} selectableDates={calendarItems} />
            ) : (
              <SingleDateDisplay {...SingleDateDisplayProps} isTimeAvailable={isSingleTimeAvailable} message={singleTimeValidationMessage} />
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container wrap="nowrap" alignItems="stretch">
              <Grid item style={isEmpty(props.currentEvent.seatingChartImg) ? { width: "100%" } : undefined}>
                <RateSelectionComponent {...RateSelectionComponentProps} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ flexDirection: 'column', justifyContent: 'center' }}>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            {
              loading && <CircularProgress style={{ marginRight: 5, alignSelf: 'center' }} />
            }
          </Grid>
          <Grid item xs={12}>
            {(props.currentEvent.singleTimeEventId <= 0 ? (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                {
                  AvailibleTimesProps.tickets.length === 0 &&
                  <Alert severity="info" style={{ marginBottom: 5 }}>
                    {
                      formatString(Locale.noRateSelected, DateTimeUtils.formatDateWithLocale(parseISO(props.lastReservationDate), {
                        month: "long",
                        day: "numeric"
                      }))
                    }
                  </Alert>
                }
                <AvailableTimes {...AvailibleTimesProps} />
              </div>
            ) : null)}
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
            <ContinueButton {...ContinueButtonProps} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import { ReactNode } from "react";
import { Rate, RateQuestionGroup } from "./Rates";
import { CatalogDetail } from "./Catalog";
import { ReservationListItem, ReservationListResource, TicketPerson } from "./Reservation";

export interface EventSummary {
  eventId: number;
  companyId: number;
  companyName: string;
  name: string;
  active: boolean;
  colorClass: string;
  eventCode: string;
  maxTickets: number;
  eventTimeType: number;
  thresholdMode: number;
  holdEventDateId: number;
}

export interface VwEventTimeSummary {
  companyId: number;
  eventDateID: number;
  eventId: number;
  eventDate: string;
  startDateTime: string;
  endDateTime: string;
  sellStatus: string;
  ticketsSold: number;
  privateNote: string | null;
  publicNote: string | null;
  maxTicketsOverride: number;
  threshold: number;
  personnelRequired: number;
  personnelIssue: boolean;
  assignedPersonnel: Array<PersonnelAssigned> | null;
  resources: Array<ReservationListResource> | null;
  noFollowUp: boolean;
  noReminder: boolean;
  rateAdjustmentPercent: number;
  rateAdjustmentRoundingUnit: number;
  reservationCount: number;
  ud: string
  uu: string
}

export interface PersonnelAssigned {
  userId: string;
  firstName: string;
  lastName: string;
  companyPersonnelTypeId: number;
  typeName: string;
}

export interface SaveCompanyPersonnel {
  companyPersonnelTypeId: number
  personnel: Array<PersonnelAssigned>
}

export interface SelectedPersonnel {
  companyPersonnelTypeId: number;
  userId: string;
}

export interface AnswerSummary {
  questionType: number;
  question: string;
  answer: string;
  total: number;
}

export interface RequiredPersonnel {
  companyPersonnelTypeID: number;
  quantity: number;
  typeName: string;
  uu: string;
  personnel: PersonnelAssigned[]
}

export interface RequiredPersonnelBody {
  companyPersonnelTypeId: number;
  typeName: number;
  quantity: number;
}

export interface CompanyPersonnel {
  userId: string;
  firstName: string;
  lastName: string;
  userName: string;
  personnelTypes: PersonnelType[];
}

export interface PersonnelType {
  typeName: string;
  companyPersonnelTypeId: number;
}

export interface EventDateReservationSummary {
  reservations: Array<ReservationListItem>;
  eventTimeSummary: VwEventTimeSummary;
  answersSummary: Array<AnswerSummary>; // Need to change this once we have the model for answers
}

export interface PublicNotes {
  SavedPublicNoteID: number;
  Note: string;
  HistoryJson: string | null;
  CompanyID?: number;
  UU?: string;
  UD?: string;
}

export interface EventGroupItem {
  eventGroupId: number;
  eventGroupName: string;
  eventIds: Array<number>;
}

export interface EventCalendarItem {
  companyId: number;
  eventDateID: number;
  eventId: number;
  name: string;
  eventCode: string;
  eventDateTime: string;
  eventDate?: string;
  startDateTime?: string;
  endDateTime?: string;
  sellStatus: string;
  ticketsSold: number;
  eventTimeType: number;
  privateNote: string;
  publicNote: string;
  maxTicketsOverride: number;
  threshold: number;
  eventMaxTickets: number;
  displayOrder: number;
  quantityRequired?: number;
  xmlPersonnel: string;
  thresholdMode: number;
  colorClass?: string;
}

export interface VwEventDisplay {
  imgUrl: string;
  name: string;
  displayOrder: number;
  companyId: number;
  eventId: number;
  companyName: string;
  description: string;
  durationInSeconds: number;
  hideDuration: boolean;
  urlFriendlyName: string;
  excludeFromReseller: boolean;
  lat?: number;
  lon?: number;
  minRate: number;
  maxRate: number;
  colorClass?: string;
}

export interface LinkEventDetail {
  name: string;
  displayOrder: number;
  companyId: number;
  eventId: number;
  companyName: string;
  description: string;
  durationInSeconds: number;
  hideDuration: boolean;
  urlFriendlyName: string;
  excludeFromReseller: boolean;
  lat: number;
  lon: number;
  minRate: number;
  maxRate: number;
  restrictions: string;
  reservationInfo: string;
  mapUrl: string;
  maxTickets: number;
  address: string;
  latestSell: number;
  latestChange?: number;
  earliestSell: number;
  eventTimeType: number;
  ticketRequiredFields: number;
  nextTime: string;
  oneDay: boolean;
  singleTimeEventId: number;
  rates: Rate[];
  deposit: number;
  depositOnly: boolean;
  delivery: boolean;
  questionsJson: string;
  rateQuestionGroups: RateQuestionGroup[];
  addOns: Array<Partial<CatalogDetail>>;
  seatAssignmentChartKey?: string;
  seatAssignmentMode?: SeatAssignmentMode;
  seatingChartImg?: string;
  isWaiverSupported?: boolean;
  active?: boolean
}

export interface EventDateTimeItem {
  eventDateID: number;
  eventDateTime: string;
  sellStatus: string; // O o S
  publicNote: string;
  startTime: string;
  endTime: string;
  reason: string;
  rateAdjustmentPercent: number;
  rateAdjustmentRoundingUnit: number;
  totalTicketAmount: number;
}

export interface EventTicket {
  rateId: number;
  count: number;
  eventId?: number;
  answers: string[];
  attendee: TicketPerson;
}

export interface EventTicketRequest {
  rateId: number;
  count: number;
}

export enum EventTimeTypes {
  SetTime = 0,
  OpenTime = 1,
}

export interface EventCalItem {
  eventId: number;
  displayOrder: number;
  date: Date;
  eventTimeType: EventTimeTypes;
  name: string;
  urlFriendlyName: string;
  companyName: string;
  companyEvent: boolean;
  timesCSV: string;
  colorClass?: string;
}
export interface IEventCalendarList {
  [key: string]: EventCalItem[];
}

export interface EventDateSettings {
  publicNote?: string | null;
  privateNote?: string | null;
  sellStatus?: string;
  threshold?: number;
  maxTicketsOverride?: number;
  noFollowUp?: boolean;
  noReminder?: boolean;
  rateAdjustmentPercent?: number;
  rateAdjustmentRoundingUnit?: number
}
export interface CreateEventDateSettings {
  eventId: string;
  eventDateTime: string;
  startDate?: string;
}

export interface EventsByPersonnel {
  firstName: string;
  lastName: string;
  userId: string;
  events: GroupedPersonnelEventDates[]
}
export interface GroupedPersonnelEventDates {
  typeName: string;
  companyPersonnelTypeId: number;
  eventId: number;
  eventName: string;
  tickets: number;
  eventDateTimeCount: number;
}
export enum SeatAssignmentMode {
  None = 0,
  Automatic = 1,
  AutomaticWithChanges = 2,
  Manual = 3
}